import React, { useEffect, useState } from 'react'
import { Decrypt_Id_Name, DecryptedList, getShowingDayWithOutTime, getShowingWithOutTimeStartDate } from '../../../Include/Common/Utility'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { get_SalebyCash_Chart_Data, get_SalebyProduct_Chart_Data, get_SalebyUdhar_Chart_Data } from '../../../../redux/actions/AllAction'
import { BarCharts } from '../../../Include/Chart/BarCharts'
import { SaleByCashChart } from '../../../Include/Chart/SaleByCashChart'
import { SaleByUdharChart } from '../../../Include/Chart/SaleByUdhar'

const Home = () => {

  const dispatch = useDispatch()
  const [value, setValue] = useState({ FromDate: '', FromDate1: '' })


  const handlChange = (date) => {
    if (date) { setValue({ ...value, ['FromDate']: getShowingDayWithOutTime(date), ['FromDate1']: getShowingWithOutTimeStartDate(date) }) } else { setValue({ ...value, ['FromDate']: '', ['FromDate1']: '' }) }
  }

  return (
    <>
      <h4 className='text-center dashboard-title mb-3' >{
        sessionStorage.getItem('FirmName') && Decrypt_Id_Name(sessionStorage.getItem('FirmName'), 'FiForUserFirmName')
      }</h4>
      <div className="col-12">
        <div className="col-3 col-md-3 col-lg-3 px-3">
          <div className="dropdown__box mb-3">
            <DatePicker
              id='FromDate'
              name='FromDate'
              dateFormat="dd/MM/yyyy"
              onChange={handlChange}
              isClearable
              selected={value?.FromDate1 && new Date(value?.FromDate1)}
              placeholderText={'Select Date...'}
              dropdownMode="select"
              showMonthDropdown
              showYearDropdown
            />
          </div>
        </div>
        <div className="container">
          <div className="row mb-3">
            <div className="col-6 px-3">
              <div className="chart-container">
                <BarCharts />
              </div>
            </div>
            <div className="col-6 px-3">
              <div className="chart-container">
                <SaleByCashChart />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 px-3">
              <div className="chart-container">
                <SaleByUdharChart />
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Home