import React, { useState } from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom';
export const navigationLinks = [
    { id: 1, title: 'Home', },
    { id: 2, title: 'Budget', },
    { id: 3, title: 'Transactions', },
    { id: 4, title: 'Subscriptions', },
    { id: 5, title: 'Loans', },
    { id: 6, title: 'Reports', },
    { id: 7, title: 'Savings', },
    { id: 8, title: 'Financial Advice', },
    { id: 9, title: 'Account', },
    { id: 10, title: 'Settings', }
];

const Testing = () => {
    const [filterData, setfilterDta] = useState(navigationLinks)
    const [value, setValue] = useState({
        labal: '', value: ''
    })
    const columns = [
        {
            name: 'id',
            selector: (row) => row.id,
            sortable: true
        },
        {
            name: 'title',
            selector: (row) => row.title,
            sortable: true
        },
    ]

    const notebookEntryHandler = row => {
        setValue({ ...value, labal: row.title, value: row.id })
        document.getElementById('uu').style.display = 'none'
    }
    
    return (
        <div className='px-3'>
            <div className="col-6 col-md-6 col-lg-5 pt-1 " >
                <div class="text-field">
                    {/* <Link to={''}>
                        <span className='select-search'>
                            <i className='fa fa-search'></i>
                        </span>
                    </Link> */}
                    <input
                        type="text"
                        name='NoofHoles'
                        id='NoofHoles'
                        value={value.labal}
                        required
                        placeholder='Search By Title .....'
                        autoComplete='off'
                        onChange={(e) => {
                            setValue({ ...value, labal: e.target.value })
                            const result = navigationLinks?.filter((item) => {
                                return (item.title.toLowerCase().includes(e.target.value.toLowerCase()))
                            })
                            setfilterDta(result)
                        }
                        }
                        onClick={() => {
                            document.getElementById('uu').style.display = 'block'
                        }}
                    />
                    <Link to={''}>
                        <span className='select-cancel'>
                            <i className='fa fa-times'></i>
                        </span>
                    </Link>
                </div>
            </div>
            <div id='uu' className="col-6 col-md-6 col-lg-5 " style={{ display: 'none', height: '10px' }}>
                <DataTable
                    dense
                    columns={columns}
                    data={filterData}
                    onRowClicked={notebookEntryHandler}
                    // pagination
                    selectableRowsHighlight
                    highlightOnHover
                />
            </div>
        </div>
    )
}

export default Testing