import React from 'react'
import img1 from '../../img/1.svg'
import img2 from '../../img/2.svg'
import img3 from '../../img/3.svg'
import cash from '../../img/cash.svg'
import bank from '../../img/bankbal.ea175b34.svg'
import cash1 from '../../img/cashbal.f75a12cf.svg'
import pay from '../../img/pay.a9bcb90c.svg'
import tax from '../../img/tax_pay.660b78bb.svg'
import total from '../../img/bank.1610932f.svg'
import sale from '../../img/sale.ac92d7dd.svg'
import purchase from '../../img/purchase.d2c382e0.svg'
import { Link } from 'react-router-dom'
const DashBoardHome = () => {
    return (

        <>
            {/* 1 */}
            <div className="identix-card-border identix-growth-pd card col-8 ml-2">
                <div className="card-header-pb dashobard-diff-dropdown dashboard-cm-dropdown d-flex justify-content-between mob-pd-15 card-header">
                    <div className="dashboard-card-title d-flex align-items-center">Growth Pulses</div>
                    <div className="invoice-list-d-flex d-flex align-items-center mobile-row tab-mb-0 mb-0 position-relative">
                        <div>
                            <li className="nav-item dropdown nav-user notifications mr-1">
                                <i class="  dropdown-toggle btn btn-outline-secondary" data-toggle="dropdown" aria-hidden="true" style={{ cursor: 'pointer' }}>Last 7 days</i>
                                <div tabIndex={-1} role="menu" aria-hidden="true" className="dropdown-menu mt-4 "><a tabIndex={0} role="menuitem" className="dropdown-item">Today</a><a tabIndex={0} role="menuitem" className="dropdown-item">Last 7 days</a><a tabIndex={0} role="menuitem" className="dropdown-item">Last 30 days</a><a tabIndex={0} role="menuitem" className="dropdown-item">Current month</a><a tabIndex={0} role="menuitem" className="dropdown-item">Custom date</a>
                                </div>
                            </li>
                        </div>
                    </div>
                </div>
                <div className="mob-pd-15 card-body">
                    <div className="d-flex gap-1 flex-wrap identix-quo-filed-left">
                        <div className="growth-bg-img bg-img-income">
                            <div className="img-icon d-flex align-items-center gap-2 mb-1 mob-mb-0 mob-gap-15">
                                <div className="sm-card d-flex align-items-center justify-content-center">
                                    <img src={img1} />
                                </div>
                                <div className="identix-growth-cards">
                                    <div className="card-inner-title">₹0.00</div>
                                    <div className="card-mini-title d-block d-sm-none  cursor-pointer dashboard-link">Total Income</div>
                                </div>
                            </div>
                            <div className="card-mini-title d-none d-sm-inline cursor-pointer dashboard-link">Total Income</div>
                        </div>
                        <div className="growth-bg-img bg-img-expenses identix-quo-filed-left">
                            <div className="img-icon d-flex align-items-center gap-2 mb-1 mob-mb-0 mob-gap-15">
                                <div className="sm-card d-flex align-items-center justify-content-center">
                                    <img src={img2} />
                                </div>
                                <div className="identix-growth-cards">
                                    <div className="card-inner-title">₹10.00</div>
                                    <div className="card-mini-title d-block d-sm-none cursor-pointer dashboard-link">Total Expenses</div>
                                </div>
                            </div>
                            <div className="card-mini-title d-none d-sm-inline cursor-pointer dashboard-link">Total Expenses</div>
                        </div>
                        <div className="growth-bg-img bg-img-profit identix-quo-filed-left">
                            <div className="img-icon d-flex align-items-center gap-2 mb-1 mob-mb-0 mob-gap-15">
                                <div className="sm-card d-flex align-items-center justify-content-center">
                                    <img src={img3} />
                                </div>
                                <div className="identix-growth-cards">
                                    <div className="card-inner-title">₹(10.00)</div>
                                    <div className="card-mini-title d-block d-sm-none cursor-pointer dashboard-link">Net Profit</div>
                                </div>
                            </div>
                            <div className="card-mini-title  d-none d-sm-inline cursor-pointer dashboard-link">Net Profit</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* 2 */}
            <div className="identix-card-border card col-8 ml-2">
                <div className="card-header-pb dashobard-diff-dropdown dashboard-cm-dropdown d-flex justify-content-between mob-pd-15 card-header">
                    <div className="dashboard-card-title d-flex align-items-center text-dark text-bold">Revenue Inflow</div>
                    <div className="invoice-list-d-flex d-flex align-items-center mobile-row tab-mb-0 mb-0 position-relative">
                        <div>
                            <li className="nav-item dropdown nav-user notifications mr-1">
                                <i class="  dropdown-toggle btn btn-outline-secondary" data-toggle="dropdown" aria-hidden="true" style={{ cursor: 'pointer' }}>Last 7 days</i>
                                <div tabIndex={-1} role="menu" aria-hidden="true" className="dropdown-menu mt-4 "><a tabIndex={0} role="menuitem" className="dropdown-item">Today</a><a tabIndex={0} role="menuitem" className="dropdown-item">Last 7 days</a><a tabIndex={0} role="menuitem" className="dropdown-item">Last 30 days</a><a tabIndex={0} role="menuitem" className="dropdown-item">Current month</a><a tabIndex={0} role="menuitem" className="dropdown-item">Custom date</a>
                                </div>
                            </li>
                        </div>
                    </div>
                </div>
                <div className="mob-pd-15 card-body">
                    <div className="rev-inflow-bg">
                        <div className="row gy-5 row">
                            <div className="col-12 col-md-6 col-xl-4">
                                <div className="rev-inflow-card rev-inflow-cash-collect identix-quo-filed-left">
                                    <div className="img-icon">
                                        <div className="sm-card d-flex align-items-center justify-content-center">
                                            <img src={cash} />
                                        </div>
                                        <div className="card-inner-title">
                                            <div>₹0.00</div>
                                            <div className="card-mini-title cursor-pointer dashboard-link">Total Cash Collected</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                                <div className="rev-inflow-card rev-inflow-bank identix-quo-filed-left">
                                    <div className="img-icon">
                                        <div className="sm-card d-flex align-items-center justify-content-center">
                                            <img src={bank} />
                                        </div>
                                        <div className="card-inner-title">
                                            <div>₹0.00</div>
                                            <div className="card-mini-title cursor-pointer dashboard-link">Total Collection In Bank</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                                <div className="rev-inflow-card rev-inflow-pay identix-quo-filed-left">
                                    <div className="img-icon">
                                        <div className="sm-card d-flex align-items-center justify-content-center">
                                            <img src={cash1} />
                                        </div>
                                        <div className="card-inner-title">
                                            <div>₹(0.00)</div>
                                            <div className="card-mini-title cursor-pointer dashboard-link">Unavoidable GST Payables</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                                <div className="rev-inflow-card rev-inflow-cash-bal identix-quo-filed-left">
                                    <div className="img-icon">
                                        <div className="sm-card d-flex align-items-center justify-content-center">
                                            <img src={pay} /></div>
                                        <div className="card-inner-title">
                                            <div> ₹0.00</div>
                                            <div className="card-mini-title cursor-pointer dashboard-link">Total Cash Balance (As on)</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                                <div className="rev-inflow-card rev-inflow-bank-bal identix-quo-filed-left">
                                    <div className="img-icon">
                                        <div className="sm-card d-flex align-items-center justify-content-center">
                                            <img src={total} /></div>
                                        <div className="card-inner-title">
                                            <div>₹(10.00)</div>
                                            <div className="card-mini-title cursor-pointer dashboard-link">Total Bank Balance (As on)</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                                <div className="rev-inflow-card rev-inflow-tax-pay identix-quo-filed-left">
                                    <div className="img-icon">
                                        <div className="sm-card d-flex align-items-center justify-content-center">
                                            <img src={tax} /></div>
                                        <div className="card-inner-title">
                                            <div>₹0.00</div>
                                            <div className="card-mini-title cursor-pointer dashboard-link">Unavoidable Other Tax Payables</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* 3 */}

            <div className="identix-card-border identix-growth-pd card col-8 ml-2">
                <div className="card-header-pb dashobard-diff-dropdown dashboard-cm-dropdown d-flex justify-content-between mob-pd-15 card-header">
                    <div className="dashboard-card-title d-flex align-items-center">Sale/Purchase</div>
                    <div className="invoice-list-d-flex d-flex align-items-center mobile-row tab-mb-0 mb-0 position-relative">
                        <div>
                            <li className="nav-item dropdown nav-user notifications mr-1">
                                <i class="  dropdown-toggle btn btn-outline-secondary" data-toggle="dropdown" aria-hidden="true" style={{ cursor: 'pointer' }}>Last 7 days</i>
                                <div tabIndex={-1} role="menu" aria-hidden="true" className="dropdown-menu mt-4 "><a tabIndex={0} role="menuitem" className="dropdown-item">Today</a><a tabIndex={0} role="menuitem" className="dropdown-item">Last 7 days</a><a tabIndex={0} role="menuitem" className="dropdown-item">Last 30 days</a><a tabIndex={0} role="menuitem" className="dropdown-item">Current month</a><a tabIndex={0} role="menuitem" className="dropdown-item">Custom date</a>
                                </div>
                            </li>
                        </div>
                    </div>
                </div>
                <div className="mob-pd-15 card-body">
                    <div className="d-flex gap-1 flex-wrap identix-quo-filed-left">
                        <div className="growth-bg-img bg-img-income">
                            <div className="img-icon d-flex align-items-center gap-2 mb-1 mob-mb-0 mob-gap-15">
                                <div className="sm-card d-flex align-items-center justify-content-center " style={{background:'#fff'}}>
                                    <img src={sale} />
                                </div>
                                <div className="card-inner-title identix-quo-filed-left  d-flex align-item-start justify-content-start flex-column">
                                    <div className="card-mini-title cursor-pointer dashboard-link">Total Sale</div>
                                    <div className="sales-pur-amt">₹0.00</div>
                                </div>
                            </div>
                        </div>
                        <div className="growth-bg-img bg-img-expenses identix-quo-filed-left">
                            <div className="img-icon d-flex align-items-center gap-2 mb-1 mob-mb-0 mob-gap-15">
                                <div className="sm-card d-flex align-items-center justify-content-center" style={{background:'#fff'}}>
                                    <img src={purchase} />
                                </div>
                                <div className="card-inner-title identix-quo-filed-left  d-flex align-item-start justify-content-start flex-column">
                                    <div className="card-mini-title cursor-pointer dashboard-link">Total Purchase</div>
                                    <div className="sales-pur-amt">₹0.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         
        </>
    )
}

export default DashBoardHome