import React from 'react'

const UpdatePassword = () => {
    return (
        <>
            <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="PasswordModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="m-1 ">
                                <fieldset style={{ border: '1px solid gray' }}>
                                    <legend >Update Password</legend>
                                    <div className="form-row">
                                        <div className="col-6 col-md-4 col-lg-4 ">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='Oldpassword'
                                                    className=""
                                                    id='Oldpassword'
                                                    required
                                                />
                                                <label className='pt-1'>Old Password</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-4 ">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='newpassword'
                                                    className=""
                                                    id='newpassword'
                                                    required
                                                />
                                                <label className='pt-1'>New Password</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-4 ">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='confirmpassword'
                                                    className=""
                                                    id='confirmpassword'
                                                    required
                                                />
                                                <label className='pt-1'>Confirm Password</label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="btn-box text-right  mr-1 mb-2">
                            <button type="button" class="btn btn-sm btn-success mr-1">Update</button>
                            <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" >Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdatePassword