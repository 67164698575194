import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Auth from './Auth';
import "react-datepicker/dist/react-datepicker.css"
import Journal from './Component/Pages/Voucher/Journal/Journal';
// import Login1 from './Component/Pages/Login/Login1';
import MasterTableTabs from './Component/Pages/MasterTable/MasterTableTabs';
import { ToastContainer } from 'react-toastify';
import AdminSetting from './Component/Pages/AdminSetting/AdminSetting';
import SearchChallan from './Component/Pages/Sale/SearchChalln/SearchChallan';
import EmplyeeTab from './Component/Pages/AdminSetting/NewCompany/Employee/EmplyeeTab';
import SalePartyTab from './Component/Pages/Sale/SaleParty/SalePartyTab';
import PlantTransport from './Component/Pages/PlantTransport/PlantTransport';
import Mining from './Component/Pages/Mining/Mining';
import VehicleDetailsTab from './Component/Pages/PlantTransport/VehicleDetailsForm/VehicleDetailsTab';
import SaleNew from './Component/Pages/SaleNew/SaleNew';
import DashBoard from './Component/Pages/DashBoard/DashBoard';
import Hr from './Component/Pages/Hr/Hr';
import EmpTask_Add_Up from './Component/Pages/Hr/EmpTask/EmpTask_Add_Up'
import Testing from './Component/Pages/Testing';
import NewLogin from './Component/Pages/Login/NewLogin';
import Inventory from './Component/Pages/Inventory/Inventory';
import MainRequest from './Component/Pages/Inventory/AddRequest/MainRequest';
import AddRequest from './Component/Pages/Inventory/AddRequest/AddRequest';
import Quotes_Add_Up from './Component/Pages/Inventory/Quotes/Quotes_Add_Up';
import PurchaseInvoiceAddUp from './Component/Pages/Inventory/PurchaseInvoice/PurchaseInvoiceAddUp';
import DashBoardHome from './Component/Pages/DashBoard/DashBoardHome';
import EmpTask from './Component/Pages/Hr/EmpTask/EmpTask';
import Service from './Component/Pages/Service/Service';
import CreateChallan from './Component/Pages/SaleNew/SaleNewTab/Challan/CreateChallan';
import Voucher from './Component/Pages/Voucher/Voucher';
import Payment from './Component/Pages/Voucher/Payment/Payment';
import Receipt from './Component/Pages/Voucher/Receipt/Receipt';
import Contra from './Component/Pages/Voucher/Contra/Contra';
import Reports from './Component/Pages/Reports/Reports';
import CashBook from './Component/Pages/Reports/CashBook/CashBook';
import DayBook from './Component/Pages/Reports/DayBook/DayBook';

function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <Route path='/' element={<Login1 />} /> */}
          <Route path='/' element={<NewLogin />} />
          <Route path='/DashBoard' element={<Auth cmp={DashBoard} />} />
          <Route path='/CreateChallan' element={<Auth cmp={CreateChallan} path={'CreateChallan'} />} />
          <Route path='/MasterTables' element={<Auth cmp={MasterTableTabs} path={'MasterTable'} />} />
          <Route path='/AdminSetting' element={<Auth cmp={AdminSetting} path={'AdminSetting'} />} />
          <Route path='/Plant-transport' element={<Auth cmp={PlantTransport} path={'Plant-transport'} />} />
          <Route path='/Service' element={<Auth cmp={Service} path={'Service'} />} />
          <Route path='/Voucher' element={<Auth cmp={Voucher} path={'Voucher'} />} />
          <Route path='/Mining' element={<Auth cmp={Mining} path={'Mining'} />} />
          <Route path='/Sale' element={<Auth cmp={SaleNew} path={'Sale'} />} />
          <Route path='/Journal' element={<Auth cmp={Journal} path={'Journal'} />} />
          <Route path='/Payment' element={<Auth cmp={Payment} path={'Payment'} />} />
          <Route path='/Receipt' element={<Auth cmp={Receipt} path={'Receipt'} />} />
          <Route path='/Contra' element={<Auth cmp={Contra} path={'Contra'} />} />
          <Route path='/CashBook' element={<Auth cmp={CashBook} path={'CashBook'} />} />
          <Route path='/DayBook' element={<Auth cmp={DayBook} path={'DayBook'} />} />
          <Route path='/Reports' element={<Auth cmp={Reports} path={'Reports'} />} />
          <Route path='/searchchallan' element={<Auth cmp={SearchChallan} path={'searchchallan'} />} />
          <Route path='/employeetab' element={<Auth cmp={EmplyeeTab} path={'employeetab'} />} />
          <Route path='/employee-task' element={<Auth cmp={EmpTask_Add_Up} path={'employee-task'} />} />
          <Route path='/employee-home' element={<Auth cmp={EmpTask} path={'employee-home'} />} />
          <Route path='/salepartytab' element={<Auth cmp={SalePartyTab} path={'salepartytab'} />} />
          <Route path='/vehicle-details' element={<Auth cmp={VehicleDetailsTab} path={'vehicle-details'} />} />
          <Route path='/HR' element={<Auth cmp={Hr} path={'HR'} />} />
          <Route path='/testing' element={<Auth cmp={Testing} path={'HR'} />} />
          <Route path='/Inventory' element={<Auth cmp={Inventory} path={'Inventory'} />} />
          <Route path='/addRequest' element={<Auth cmp={AddRequest} path={'addRequest'} />} />
          <Route path='/purchaseInvoice' element={<Auth cmp={PurchaseInvoiceAddUp} path={'purchaseInvoice'} />} />
          <Route path='/new-dashboard' element={<Auth cmp={DashBoardHome} path={'new-dashboard'} />} />
          {/* <Route path='/' element={<Auth cmp={NewLogin} path={'/'} />} />
           */}
        </Routes>
        <ToastContainer />
      </BrowserRouter>

    </>
  );
}


export default App;
