import React, { useEffect } from 'react'
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import InventoryMaterial_Add_Up from './InventoryMaterial_Add_Up';
import { delete_Inventory_Material_Data, get_Inventory_Material_Data, get_Single_Inventory_Material_Data } from '../../../../redux/actions/AllAction';
import { Edit_Update_Count, Get_Inventory_Material_Data, Get_Inventory_Material_Filter_Data, MasterTable_Modal_Status } from '../../../../redux/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Three_Search_Filter } from '../../../Filter/Filter';
import ConfirmModal from '../../../Include/Common/ConfirmModal';
import Loader from '../../../Include/Common/Loader';

const InventoryMaterial = (props) => {
    const dispatch = useDispatch()
    const { openPage } = props
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount);
    const inventoryFilterData = useSelector((state) => state.Data.inventoryFilterData)
    const pageLoader = useSelector((state) => state.Data.pageLoader);
    const inventoryData = useSelector((state) => state.Data.inventoryData);
    const userId = useSelector((state) => state.Data.userId)
    const [pageStatus, setPageStatus] = useState("1")
    const [IsActive, setIsActive] = useState()
    const [confirmType, setConfirmType] = useState('')
    const [status, setStatus] = useState(false)
    const [id, setId] = useState()
    const [filterTypeIdOption, setfilterTypeIdOption] = useState('Contains');
    const [filterTypeDescOption, setfilterTypeDescOption] = useState('Contains');
    const [filterTypeAddOption, setfilterTypeAddOption] = useState('Contains');
    const [searchValue1, setSearchValue1] = useState('')
    const [searchValue2, setSearchValue2] = useState('')
    const [searchValue3, setSearchValue3] = useState('')

    useEffect(() => {
        dispatch(get_Inventory_Material_Data(pageStatus))
    }, [pageStatus])

    const columns = [
        {
            name: 'code',
            selector: (row) => row.Code,
            sortable: true
        },
        {
            name: 'HSNCode',
            selector: (row) => row.HSNCode,
            sortable: true
        },
        {
            name: 'ConsumptionGroup',
            selector: (row) => row.ConsumptionGroup,
            sortable: true
        },
        {
            name: 'MaterialGroup',
            selector: (row) => row.MaterialGroup,
            sortable: true
        },
        {
            name: 'MaterialType',
            selector: (row) => row.MaterialType,
            sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 55, fontWeight: '700' }}>Action</p>,
            cell: row => <>
                <div style={{ position: 'absolute', top: 4, right: 40 }}>
                    {
                        pageStatus === "1" ?
                            <Link to={`/MasterTables?page=${openPage}`} onClick={() => setEditStatus(row)} data-toggle="modal" data-target="#InventoryModal"
                                className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn" ><i className="fa fa-edit"></i>
                            </Link>
                            : <></>
                    }
                    {
                        pageStatus === "1" ?
                            < Link to={`/MasterTables?page=${openPage}`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive(0); setConfirmType("InActive"); setId(row.ID) }}
                                className="btn btn-sm  text-white px-1 py-0 mr-1" style={{ background: "#ddd" }}>
                                <i class="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true"></i>
                            </Link>
                            :
                            <Link to={`/MasterTables?page=${openPage}`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive(1); setConfirmType("Active"); setId(row.ID) }}
                                className="btn btn-sm  text-white px-1 py-0 mr-4" style={{ background: "#ddd" }}>
                                <i class="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true"></i>
                            </Link>
                    }

                </div>
            </>
        }
    ]
    const setEditStatus = (row) => {
        setStatus(true); setId(row.ID); dispatch(get_Single_Inventory_Material_Data(row.ID)); dispatch({ type: MasterTable_Modal_Status, payload: true })
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
    }

    const setNewStatus = () => {
        setStatus(false); setId(''); dispatch({ type: Get_Inventory_Material_Data, payload: [] }); dispatch({ type: MasterTable_Modal_Status, payload: true })
    };
    const UpdActiveDeactive = () => {
        dispatch(delete_Inventory_Material_Data(id, IsActive, userId, pageStatus))
    }
    return (
        <>
            <div className="col-12 col-md-12 col-lg-12  px-3">
                <div className="col-12 col-md-6 col-lg-12 ">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class={`nav-link ${pageStatus === '1' ? 'active' : ''}`} onClick={() => setPageStatus("1")} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true">Active</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class={`nav-link ${pageStatus === '0' ? 'active' : ''}`} onClick={() => setPageStatus("0")} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true">InActive</a>
                        </li>
                    </ul>
                </div>
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">{openPage}</p>
                    {pageStatus === "1" &&
                        <Link to={`/MasterTables?page=${openPage}`} onClick={setNewStatus} className="text-white px-2 py-0 "
                            data-toggle="modal" data-target="#InventoryModal" >
                            <i className="fa fa-plus"></i>
                        </Link>}
                </div>
                <div className="col-12 mt-2 px-3">
                    <div className="row">
                        <div className="col-3">
                            <input type="text" value={searchValue1} onChange={(e) => {
                                setSearchValue1(e.target.value);
                                const result = Three_Search_Filter(inventoryData, e.target.value, searchValue2, searchValue3, filterTypeIdOption, 'Code', 'HSNCode', 'ConsumptionGroup')
                                dispatch({ type: Get_Inventory_Material_Filter_Data, payload: result });
                            }}
                                className='form-control' placeholder='Search By Code...' />
                        </div>
                        <div className='col-1 '>
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    <i className="fa fa-filter"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className=' nav-filter-dropdown'>
                                    <Dropdown.Item onClick={() => setfilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setfilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setfilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setfilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setfilterTypeIdOption('End with')}>End with</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="col-3">
                            <input type="text" value={searchValue2} onChange={(e) => {
                                setSearchValue2(e.target.value);
                                const result = Three_Search_Filter(inventoryData, searchValue1, e.target.value, searchValue3, filterTypeAddOption, 'Code', 'HSNCode', 'ConsumptionGroup')
                                dispatch({ type: Get_Inventory_Material_Filter_Data, payload: result });
                            }}
                                className='form-control' placeholder='Search By HSN code ...' />
                        </div>
                        <div className='col-1'>
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    <i className="fa fa-filter"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className=' nav-filter-dropdown'>
                                    <Dropdown.Item onClick={() => setfilterTypeAddOption('Contains')}>Contains</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setfilterTypeAddOption('is equal to')}>is equal to</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setfilterTypeAddOption('is not equal to')}>is not equal to </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setfilterTypeAddOption('Starts With')}>Starts With</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setfilterTypeAddOption('End with')}>End with</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="col-3">
                            <input type="text" value={searchValue3} onChange={(e) => {
                                setSearchValue3(e.target.value);
                                const result = Three_Search_Filter(inventoryData, searchValue1, searchValue2, e.target.value, filterTypeDescOption, 'Code', 'HSNCode', 'ConsumptionGroup')
                                dispatch({ type: Get_Inventory_Material_Filter_Data, payload: result });
                            }}
                                className='form-control' placeholder='Search By Consumption....' />
                        </div>
                        <div className='col-1 '>
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    <i className="fa fa-filter"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className=' nav-filter-dropdown'>
                                    <Dropdown.Item onClick={() => setfilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setfilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setfilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setfilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setfilterTypeDescOption('End with')}>End with</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12  mb-3">
                        <div className="table-responsive">
                            {pageLoader ?
                                <DataTable
                                dense
                                columns={columns}
                                data={inventoryFilterData}
                                pagination
                                selectableRowsHighlight
                                highlightOnHover
                                /> : <Loader />}
                        </div>
                    </div>
                </div>
            </div>
            <InventoryMaterial_Add_Up {...{ status, pageStatus }} />
            <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />
        </>
    )
}

export default InventoryMaterial