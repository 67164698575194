import React from 'react'
import Select from "react-select";
import DatePicker from 'react-datepicker'
import { Link } from 'react-router-dom';
const Contra = () => {
  // custuom style withoutColor
  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 31,
      minHeight: 30,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
      fontWeight: 500,
    }),
  }

  return (
    <>
      <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">Contra Voucher</p>
        </div>
        <div className="form-row px-2">
          <div className="col-3 col-md-2 col-lg-1 mt-2 pt-2">
            <label className='new-label'>Contra No:</label>
          </div>
          <div className="col-9 col-md-3 col-lg-2 text-field">
            <input
              type="text"
              name='voucherid'
              className=""
              id='voucherid'
              required
              readOnly
            />
          </div>
          <div className="col-3 col-md-3 col-lg-2 mt-2 pt-2">
            <label className='new-label'>DATE:</label>
          </div>
          <div class="col-9 col-md-4 col-lg-2 text-field mt-2">
            <DatePicker
              id='date'
              name='date'
              className=''
              isClearable
              placeholderText={'Select...'}
            />
          </div>

        </div>
        <div className="form-row ">
          <div className="col-3 col-md-2 col-lg-1 mt-2 pt-2">
            <label className='new-label'>Account:</label>
          </div>
          <div className="col-6 col-md-6 col-lg-6 ">
            <div class="text-field">
              <input
                type="text"
                name='amount'
                className=""
                id='amount'
                required
              />
            </div>
          </div>
          <div class="col-12 mt-2">
            <h5 className="card-title d-flex justify-content-between p-1" >
              <div className=''>
                <h5>Particulars</h5>
              </div>
              <div className=''>
                <h5>Amount</h5>
              </div>
            </h5>
            <div class="card-body">
              <div className="input-container">
                <div className=" back-box mt-2" >
                  <div className="row d-flex justify-content-between  ">
                    <div className='col-6 text-field ' >
                      <input type="text" id="input" placeholder="Input 1" />
                    </div>
                    <div className='col-2 text-field'>
                      <input type="text" id="input" placeholder="Input 1" />
                    </div>
                      <p className='input-p pl-2'>Amount</p>
                  </div>
                </div>
                <div className="back-box mt-2">
                  <div className="row d-flex justify-content-between  ">
                    <div className='col-6 text-field'>
                      <input type="text" id="input" placeholder="Input 2" />
                    </div>
                    <div className='col-2 text-field'>
                      <input type="text" id="input" placeholder="Input 2" />
                    </div>
                  </div>
                  <p className='input-p pl-2'>Amount</p>
                </div>
                <div className="back-box mt-2">
                  <div className="row d-flex justify-content-between  ">
                    <div className='col-6 text-field'>
                      <input type="text" id="input" placeholder="Input 3" />
                    </div>
                    <div className='col-2 text-field'>
                      <input type="text" id="input" placeholder="Input 3" />
                    </div>
                  </div>
                  <p className='input-p pl-2'>Amount</p>
                </div>
                <div className="back-box mt-2">
                  <div className="row d-flex justify-content-between  ">
                    <div className='col-6 text-field'>
                      <input type="text" id="input" placeholder="Input 4" />
                    </div>
                    <div className='col-2 text-field'>
                      <input type="text" id="input" placeholder="Input 4" />
                    </div>
                  </div>
                  <p className='input-p pl-2'>Amount</p>
                </div>
                <div className="back-box mt-2">
                  <div className="row d-flex justify-content-between  ">
                    <div className='col-6 text-field'>
                      <input type="text" id="input" placeholder="Input 5" />
                    </div>
                    <div className='col-2 text-field'>
                      <input type="text" id="input" placeholder="Input 5" />
                    </div>
                  </div>
                  <p className='input-p pl-2'>Amount</p>
                </div>
                <div className="back-box mt-2">
                  <div className="row d-flex justify-content-between  ">
                    <div className='col-6 text-field'>
                      <input type="text" id="input" placeholder="Input 6" />
                    </div>
                    <div className='col-2 text-field'>
                      <input type="text" id="input" placeholder="Input 6" />
                    </div>
                  </div>
                  <p className='input-p pl-2'>Amount</p>
                </div>
                <div className="back-box mt-2">
                  <div className="row d-flex justify-content-between  ">
                    <div className='col-6 text-field'>
                      <input type="text" id="input" placeholder="Input 7" />
                    </div>
                    <div className='col-2 text-field'>
                      <input type="text" id="input" placeholder="Input 7" />
                    </div>
                  </div>
                  <p className='input-p pl-2'>Amount</p>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-box text-end col-lg-12 mt-1 pt-1 mb-2">
            <button type="button" class="btn btn-sm btn-success mr-1">Save</button>
            <Link to={'/Voucher'}>
            <button type="button" class="btn btn-sm btn-success mr-1">Close</button>
            
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contra