import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Dropdown from 'react-bootstrap/Dropdown'
import ConfirmModal from '../../../Include/Common/ConfirmModal';
import { delete_InvoiceType_Data, get_Purchase_Invoice_Data, get_Single_Purchase_Invoice_Data } from '../../../../redux/actions/AllAction';
import { useDispatch, useSelector } from 'react-redux';
import { Edit_Update_Count, Get_Invoice_Filter_Data, Get_Invoice_ID, Get_Single_PurchaseInvoice_Data, Page_Status, Status } from '../../../../redux/actionTypes';
import { Three_Search_Filter, Two_Value_Filter } from '../../../Filter/Filter';


const PurchaseInvoice = () => {
    const dispatch = useDispatch()
    const purchaseInvoiceData = useSelector((state) => state.Data.purchaseInvoiceData)
    const invoiceFilterData = useSelector((state) => state.Data.invoiceFilterData)
    const userId = useSelector((state) => state.Data.userId)
    const invoiceID = useSelector((state) => state.Data.invoiceID)
    const [IsActive, setIsActive] = useState()
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const [filterTypeIdOption, setfilterTypeIdOption] = useState('Contains');
    const [filterTypeDescOption, setfilterTypeDescOption] = useState('Contains');
    const [filterTypeAddOption, setfilterTypeAddOption] = useState('Contains');
    const [searchValue1, setSearchValue1] = useState('')
    const [searchValue2, setSearchValue2] = useState('')
    const [searchValue3, setSearchValue3] = useState('')
    const [pageStatus, setPageStatus] = useState("1")
    const [id, setId] = useState('')
    const [confirmType, setConfirmType] = useState('')

    useEffect(() => {
        dispatch(get_Purchase_Invoice_Data(pageStatus))
    }, [pageStatus]);

    const columns = [
        {
            name: 'ChallanNo',
            selector: (row) => row.ChallanNo,
            sortable: true
        },
        {
            name: 'InvoiceNo',
            selector: (row) => row.InvoiceNo,
            sortable: true
        },
        {
            name: 'InvoiceType',
            selector: (row) => row.InvoiceType,
            sortable: true
        },
        {
            name: 'VehicleNo',
            selector: (row) => row.VehicleNo,
            sortable: true
        },
        {
            name: 'VehicleNo',
            selector: (row) => row.VehicleNo,
            sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 55, fontWeight: '700' }}>Action</p>,
            cell: row => <>
                <div style={{ position: 'absolute', top: 4, right: 40 }}>

                    <Link to='/purchaseInvoice' onClick={(e) => setEditStatus(row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn"><i className="fa fa-edit"></i>
                    </Link>
                    <Link to='/Inventory?sub=Purchase%20Invoice' data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('0'); setId(row.ID) }}
                        className="btn btn-sm bg-green text-white px-1 py-0 mr-1" >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </Link>

                </div>
            </>
        }
    ]

    const setEditStatus = (row) => {
        dispatch(get_Single_Purchase_Invoice_Data(row.ID)); setId(row.ID)
        dispatch({ type: Get_Invoice_ID, payload: row.ID })
        dispatch({ type: Get_Single_PurchaseInvoice_Data, payload: row });
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: Page_Status, payload: true })
    }

    const newStatus = () => {
        dispatch({ type: Get_Single_PurchaseInvoice_Data, payload: [] });
        dispatch({ type: Get_Invoice_ID, payload: '' })
        dispatch({ type: Page_Status, payload: false })
        // dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
    }
 
    const UpdActiveDeactive = () => {
        dispatch(delete_InvoiceType_Data(id, IsActive, userId, pageStatus))
    }
    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Purchase Invoice</p>
                    <div style={{ marginLeft: 'auto' }}>
                        <Link to={'/purchaseInvoice'} onClick={newStatus} className="btn btn-sm  text-white px-2 py-0"  >
                            <i className="fa fa-plus"></i>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-2 px-3">
                <div className="row">
                    <div className="col-3">
                        <input type="text"  value={searchValue1} onChange={(e) => {
                            setSearchValue1(e.target.value);
                            const result = Two_Value_Filter(purchaseInvoiceData, e.target.value, searchValue2, filterTypeIdOption, 'ChallanNo', 'InvoiceNo', 'InvoiceType')
                            dispatch({ type: Get_Invoice_Filter_Data, payload: result });
                        }} className='form-control' placeholder='Search By Challan No...' />
                    </div>
                    <div className='col-1 '>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item >Contains</Dropdown.Item>
                                <Dropdown.Item >is equal to</Dropdown.Item>
                                <Dropdown.Item >is not equal to </Dropdown.Item>
                                <Dropdown.Item >Starts With</Dropdown.Item>
                                <Dropdown.Item >End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="col-3">
                        <input type="text" value={searchValue2} onChange={(e) => {
                            setSearchValue2(e.target.value);
                            const result = Three_Search_Filter(purchaseInvoiceData, searchValue1, e.target.value, searchValue3, filterTypeAddOption, 'ChallanNo', 'InvoiceNo', 'InvoiceType')
                            dispatch({ type: Get_Invoice_Filter_Data, payload: result });
                        }} className='form-control' placeholder='Search By Invoice No. ...' />
                    </div>
                    <div className='col-1 '>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item >Contains</Dropdown.Item>
                                <Dropdown.Item >is equal to</Dropdown.Item>
                                <Dropdown.Item >is not equal to </Dropdown.Item>
                                <Dropdown.Item >Starts With</Dropdown.Item>
                                <Dropdown.Item >End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="col-3">
                        <input type="text"  value={searchValue3} onChange={(e) => {
                            setSearchValue3(e.target.value);
                            const result = Three_Search_Filter(purchaseInvoiceData, searchValue1, searchValue2, e.target.value, filterTypeDescOption, 'LeaveStatus', 'LeaveReaon', 'LeaveFrom')
                            dispatch({ type: Get_Invoice_Filter_Data, payload: result });
                        }} className='form-control' placeholder='Search By Invoice Type...' />
                    </div>
                    <div className='col-1 '>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item >Contains</Dropdown.Item>
                                <Dropdown.Item >is equal to</Dropdown.Item>
                                <Dropdown.Item >is not equal to </Dropdown.Item>
                                <Dropdown.Item >Starts With</Dropdown.Item>
                                <Dropdown.Item >End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="col-12 pt-2 px-3">
                <DataTable
                    columns={columns}
                    data={invoiceFilterData}
                    dense
                    paginationPerPage={'10'}
                    paginationRowsPerPageOptions={[5, 10, 15]}
                    highlightOnHover
                    noContextMenu
                    pagination
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                />
            </div>
            <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />
        </>
    )
}

export default PurchaseInvoice