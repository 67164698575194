import React, { useState, useEffect } from 'react'
import Select from "react-select";
import DatePicker from 'react-datepicker'
import SubTab from '../../../../Utility/SubTab';
import { useNavigate } from "react-router-dom";
import { VehicleDetailsSubTabs, VehicleDetailsTabs } from '../../../../Utility/TabsArray';
import InsuranceDetails from '../InsuranceDetails/InsuranceDetails';
import { useDispatch, useSelector } from "react-redux";
import RTODetails from '../RTODetails/RTODetails';
import OtherRenewal from '../OtherRenewal/OtherRenewal';
import { Link } from 'react-router-dom';
import { SpaceNotAllowWithoutReq, SpaceNotAllow } from "../../../../../Validation/Validation";
import PUCDetails from '../PUCDetails/PUCDetails';
import { get_CostHead_Dropdown, get_EquipmentVehicle_Dropdown, save_Equipment_Vechicle_Data, update_Equipment_Vechicle_Data } from '../../../../../../redux/actions/AllAction';
import { Get_Error_Status, Get_Single_Vechicle_Data, MasterTable_Modal_Status } from '../../../../../../redux/actionTypes';
import MasSideBar from '../../../../../Include/Common/MasSideBar';
import { changeArrayFormatDrop } from '../../../../../Include/Common/ArrayFormat';
// import { save_Equipment_Vechicle_Data } from '../../../../../../redux/api';


const Home = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const customStylesWithOutColor = {
        control: (base) => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    };

    const [showPage, setShowPage] = useState('Insurancedetails');
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const pageStatus = useSelector((state) => state.Data.pageStatus)
    const singlevehicleData = useSelector((state) => state?.Data?.singlevehicleData[0])
    const equipmentVehicleDropDown = changeArrayFormatDrop(useSelector((state) => state?.Data?.equipmentVehicleDropDown),'VehicleType','ID');
    const userId = useSelector((state) => state.Data.userId)
    const [value, setValue] = useState({
        RegNumber: '',
        IdentificationNo: '',
        CostHeadID: '',
        CostHead: '',
        Make: '',
        Model: '',
        EqupmentTypeID: '',
        EqupmentType: '',
        EmptyWeight: '',
        EngineChasisNo: '',
        Remarks: '',
        VehPurchaseDate: '',
        PurchaseAmt: '',
        DepreciatedValue: '',
        ID: '',
        Updatedbyid: ''
    })
    const [errors, setErrors] = useState({
        'RegistrationError': '', 'IdentificationError': '', 'EmptywieghtError': '', 'EngineChasisError': '', 'MakeError': '', 'ModalError': ''
    });
    const { RegistrationError, IdentificationError, EmptywieghtError, EngineChasisError, MakeError, ModalError } = errors;

    useEffect(() => {
        if (equipmentVehicleDropDown.length === 0) dispatch(get_EquipmentVehicle_Dropdown());
    }, [])

    const handelChange = (e) => {
        if (e.target.name === 'RegNumber' || e.target.name === 'IdentificationNo' || e.target.name === 'EmptyWeight') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setValue({
                ...value,
                [e.target.name]: checkNumber
            })
        } else
            setValue({
                ...value,
                [e.target.name]: e.target.value,
            });
    }
    const ChangeDropDown = (e, Name) => {
        if (e) {
            setValue({
                ...value,
                [Name]: e.label,
            })
        } else setValue({
            ...value,
            [Name]: null
        })
    }

    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.RegNumber)) {
            setErrors((prevValues) => { return { ...prevValues, ["RegistrationError"]: SpaceNotAllow(value.RegNumber) } });
            // dispatch({ type: Get_Error_Status, payload: true })
        }
        if (SpaceNotAllowWithoutReq(value.IdentificationNo)) {
            setErrors((prevValues) => { return { ...prevValues, ["IdentificationError"]: SpaceNotAllowWithoutReq(value.IdentificationNo) } });
        }
        if (SpaceNotAllowWithoutReq(value.EmptyWeight)) {
            setErrors((prevValues) => { return { ...prevValues, ["EmptywieghtError"]: SpaceNotAllowWithoutReq(value.EmptyWeight) } });
        }
        if (SpaceNotAllowWithoutReq(value.EngineChasisNo)) {
            setErrors((prevValues) => { return { ...prevValues, ["EngineChasisError"]: SpaceNotAllowWithoutReq(value.EngineChasisNo) } });
        }
        if (SpaceNotAllowWithoutReq(value.Make)) {
            setErrors((prevValues) => { return { ...prevValues, ["MakeError"]: SpaceNotAllowWithoutReq(value.Make) } });
        }
        if (SpaceNotAllowWithoutReq(value.Model)) {
            setErrors((prevValues) => { return { ...prevValues, ["ModalError"]: SpaceNotAllowWithoutReq(value.Model) } });
        }
    }
    useEffect(() => {
        if (RegistrationError === "true" && IdentificationError === "true" && EmptywieghtError === "true" && EngineChasisError === "true" && MakeError === "true" && ModalError === "true") {
            if (pageStatus) {
                dispatch(update_Equipment_Vechicle_Data(value)); setErrors({ 'RegistrationError': '' })
            }
            else {
                dispatch(save_Equipment_Vechicle_Data(value)); setErrors({ 'RegistrationError': '' })
            }
        }
    }, [RegistrationError, IdentificationError, EmptywieghtError, EngineChasisError, MakeError, ModalError])


    useEffect(() => {
        if (singlevehicleData) {
            setValue({
                ...value,
                RegNumber: singlevehicleData?.RegNumber,
                IdentificationNo: singlevehicleData?.IdentificationNo,
                CostHeadID: '',
                CostHead: '',
                Make: singlevehicleData?.Make,
                Model: singlevehicleData?.Model,
                EqupmentTypeID: singlevehicleData?.EqupmentTypeID,
                EqupmentType: singlevehicleData?.EqupmentType,
                EmptyWeight: singlevehicleData?.EmptyWeight,
                EngineChasisNo: singlevehicleData?.EngineChasisNo,
                Remarks: '',
                VehPurchaseDate: '',
                PurchaseAmt: '',
                DepreciatedValue: '',
                ID: singlevehicleData?.ID,
                Updatedbyid: userId
            })
        }
        else {
            setValue({
                ...value,
                RegNumber: '',
                IdentificationNo: '',
                CostHeadID: '',
                CostHead: '',
                Make: '',
                Model: '',
                EqupmentTypeID: '',
                EqupmentType: '',
                EmptyWeight: '',
                EngineChasisNo: '',
                Remarks: '',
                VehPurchaseDate: '',
                PurchaseAmt: '',
                DepreciatedValue: '',
                ID: '',
                Updatedbyid: ''
            })
        }
    }, [singlevehicleData, editUpdateCount])
    
    const Close_Model = () => {
        dispatch({ type: Get_Single_Vechicle_Data, payload: [] });
        setValue({
            RegNumber: '',
            IdentificationNo: '',
            CostHeadID: '',
            CostHead: '',
            Make: '',
            Model: '',
            EqupmentTypeID: '',
            EqupmentType: '',
            EmptyWeight: '',
            EngineChasisNo: '',
            Remarks: '',
            VehPurchaseDate: '',
            PurchaseAmt: '',
            DepreciatedValue: '',
            ID: ''

        });
        setErrors({
            'RegistrationError': '', 'IdentificationError': '', 'EmptywieghtError': '', 'EngineChasisError': '', 'MakeError': '', 'ModalError': '',
        })
    }

    return (
        <>
            <div className="col-12">
                <div className="row ">
                    {/* Vehicle Detials */}
                    <div className="col-12 col-md-12 col-lg-12 px-3">
                        <div className="bg-line  py-1 px-2  d-flex justify-content-between align-items-center">
                            <p className="p-0 m-0 d-flex align-items-center">Vehicle/Machine Details</p>
                        </div>
                        <div className="form-row px-1">
                            <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                                <label className="label-field">Registration No.</label>
                                <span className="form-required">*</span>
                            </div>
                            <div className="col-3 col-md-3 col-lg-3">
                                <div class="text-field">
                                    <input
                                        type="text"
                                        name="RegNumber"
                                        className="requiredColor"
                                        value={value.RegNumber}
                                        id="RegNumber"
                                        required
                                        onChange={handelChange}
                                    />
                                    {errors.RegistrationError !== "true" ? (
                                        <span
                                            style={{
                                                color: "red",
                                                fontSize: "13px",
                                                margin: "0px",
                                                padding: "0px",
                                            }}
                                        >
                                            {errors.RegistrationError}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-1 col-md-1 col-lg-2 ">
                            </div>
                            <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                                <label className="label-field ">Cost Head:</label>
                            </div>
                            <div className="col-4 col-md-4 col-lg-3">
                                <div className="dropdown__box">
                                    <Select
                                        name="accounting"
                                        styles={customStylesWithOutColor}
                                        isClearable
                                        placeholder="Select.."
                                    />
                                </div>
                            </div>
                            <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                                <label className="label-field">Identification No.</label>
                            </div>
                            <div className="col-3 col-md-3 col-lg-3">
                                <div class="text-field">
                                    <input
                                        type="text"
                                        name="IdentificationNo"
                                        className=""
                                        id="IdentificationNo"
                                        value={value.IdentificationNo}
                                        required
                                        onChange={handelChange}
                                    />
                                    {errors.IdentificationError !== "true" ? (
                                        <span
                                            style={{
                                                color: "red",
                                                fontSize: "13px",
                                                margin: "0px",
                                                padding: "0px",
                                            }}
                                        >
                                            {errors.IdentificationError}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-1 col-md-1 col-lg-2 ">
                            </div>
                            <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                                <label className="label-field ">Make:</label>
                            </div>
                            <div className="col-4 col-md-4 col-lg-3">
                                <div className="text-field">
                                    <input
                                        type="text"
                                        name="Make"
                                        classmodal=""
                                        id="Make"
                                        value={value.Make}
                                        required
                                        onChange={handelChange}
                                    />
                                    {errors.MakeError !== "true" ? (
                                        <span
                                            style={{
                                                color: "red",
                                                fontSize: "13px",
                                                margin: "0px",
                                                padding: "0px",
                                            }}
                                        >
                                            {errors.MakeError}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                                <label className="label-field">Empty Weight:</label>
                            </div>
                            <div className="col-3 col-md-3 col-lg-3">
                                <div class="text-field">
                                    <input
                                        type="text"
                                        name="EmptyWeight"
                                        className=""
                                        id="EmptyWeight"
                                        value={value.EmptyWeight}
                                        required
                                        onChange={handelChange}
                                    />
                                    {errors.EmptywieghtError !== "true" ? (
                                        <span
                                            style={{
                                                color: "red",
                                                fontSize: "13px",
                                                margin: "0px",
                                                padding: "0px",
                                            }}
                                        >
                                            {errors.EmptywieghtError}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-1 col-md-1 col-lg-2 ">
                            </div>
                            <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                                <label className="label-field ">Modal:</label>
                            </div>
                            <div className="col-4 col-md-4 col-lg-3 ">
                                <div className="text-field">
                                    <input
                                        type="text"
                                        name="Model"
                                        classmodal=""
                                        id="Model"
                                        value={value.Model}
                                        required
                                        onChange={handelChange}
                                    />
                                    {errors.ModalError !== "true" ? (
                                        <span
                                            style={{
                                                color: "red",
                                                fontSize: "13px",
                                                margin: "0px",
                                                padding: "0px",
                                            }}
                                        >
                                            {errors.ModalError}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                                <label className="label-field">Engine Chassis No.</label>
                            </div>
                            <div className="col-3 col-md-3 col-lg-3">
                                <div class="text-field">
                                    <input
                                        type="text"
                                        name="EngineChasisNo"
                                        className=""
                                        id="EngineChasisNo"
                                        required
                                        value={value.EngineChasisNo}
                                        onChange={handelChange}
                                    />
                                    {errors.EngineChasisError !== "true" ? (
                                        <span
                                            style={{
                                                color: "red",
                                                fontSize: "13px",
                                                margin: "0px",
                                                padding: "0px",
                                            }}
                                        >
                                            {errors.EngineChasisError}
                                        </span>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-1 col-md-1 col-lg-2 ">
                            </div>
                            <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                                <label className="label-field ">Equipment/Vehicle Type:</label>
                            </div>
                            <div className="col-4 col-md-4 col-lg-3 d-flex px-0">
                                <div className="col-11 col-md-11 col-lg-11 ">
                                    <div className="dropdown__box">
                                        <Select
                                            name="EqupmentType"
                                            styles={customStylesWithOutColor}
                                            options={equipmentVehicleDropDown}
                                            onChange={(e) => ChangeDropDown(e, 'EqupmentType')}
                                            value={equipmentVehicleDropDown.filter((obj) => obj.label === value?.EqupmentType
                                            )}
                                            isClearable
                                            placeholder="Select.."
                                        />
                                    </div>
                                </div>
                                <div className="col-1 col-md-1 col-lg-1  px-0 " style={{ marginTop: '14px' }}>
                                    <Link to="" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal" onClick={() => dispatch({ type: MasterTable_Modal_Status, payload: true })}>
                                        <i className="fa fa-plus"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-box text-end  col-lg-12  mt-2  ">
                    {
                        pageStatus ?
                            <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button> :
                            <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Save</button>
                    }
                    <Link to='/Plant-Transport?sub=Vehicle%20Details%20Form'>
                        <button type="button" class="btn btn-sm btn-success mr-1" onClick={Close_Model} >Close</button>
                    </Link>
                </div>
            </div>
            <div className="col-12 col-sm-12">
                <div className="row ">
                    <div className={`col-12 col-md-12`}>
                        <div className="row">
                            <div className="col-12 pl-3 ">
                                <SubTab tabs={VehicleDetailsSubTabs} showPage={showPage} setShowPage={setShowPage} status={pageStatus} />
                            </div>
                        </div>
                    </div>
                    {singlevehicleData?.ID ?
                        showPage === 'Insurancedetails' ?
                            <InsuranceDetails />
                            :
                            showPage === 'RTOdetails' ?
                                <RTODetails />
                                :
                                showPage === 'otherrenewal' ?
                                    <OtherRenewal />
                                    :
                                    showPage === 'pucdetails' ?
                                        <PUCDetails />
                                        :
                                        <></> : ''
                    }
                </div>
            </div>
            <MasSideBar
                showCol1='VehicleType' idName='id'
                getUrl='/VehicleType/GetData_VehicleType'
                addUrl='/VehicleType/InsertVehicleType'
                singleDataUrl='/VehicleType/GetSingleData_VehicleType'
                upUrl='/VehicleType/UpdateVehicleType'
                delUrl='/VehicleType/DeleteVehicleType'
            />
        </>
    )
}

export default Home