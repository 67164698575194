import React, {  useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { get_Dashboard_Task_Data } from '../../../../../redux/actions/AllAction';

const EmpTask = () => {
    const dispatch = useDispatch()
    const userId = useSelector((state) => state.Data.userId)
    const taskData = useSelector((state) => state.Data.taskData)

    useEffect(() => {
        if (taskData.length === 0) dispatch(get_Dashboard_Task_Data(userId))
    }, []);


    const columns = [
        {
            name: 'Task Type',
            selector: (row) => row.Tasktype,
            sortable: true
        },
        {
            name: 'AssignEmpName',
            selector: (row) => row.AssignEmpName,
            sortable: true
        },
        {
            name: 'AssignDate',
            // selector: (row) => getShowingDayWithOutTime(row.AssignDate),
            sortable: true
        },
        {
            name: 'ExpectedDate',
            selector: (row) => row.ExpectedDate,
            sortable: true
        },
        // {
        //     name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 40, fontWeight: '700' }}>Action</p>,
        //     cell: row => <>
        //         <div style={{ position: 'absolute', top: 4, right: 40 }}>
        //             {
        //                 pageStatus === "1" ?
        //                     <Link to='/employee-task' onClick={() => setEditStatus(row)}
        //                         className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn" data-toggle="modal" data-target="#EmpStatusModal"><i className="fa fa-edit"></i>
        //                     </Link> : ''
        //             }
        //         </div>
        //     </>
        // }
    ]

    const setEditStatus = (row) => {
        
    }
    const setNewStatus = () => {
        // dispatch({ type: Status, payload: false }); dispatch({ type: Edit_Id, payload: '' }); dispatch({ type: Get_Single_Emp_Task_D, payload: [] })
        // dispatch({ type: Replay_Status, payload: true })
    }

    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Employee Task</p>
                    {/* <div style={{ marginLeft: 'auto' }}>
                        <Link to={'/employee-task'} onClick={setNewStatus} className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#" >
                            <i className="fa fa-plus"></i>
                        </Link>
                    </div> */}
                </div>
            </div>

            <div className="col-12 pt-2 px-3">
                <DataTable
                    dense
                    columns={columns}
                    // data={empTaskData}
                    paginationPerPage={'10'}
                    paginationRowsPerPageOptions={[5, 10, 15]}
                    highlightOnHover
                    noContextMenu
                    pagination
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    noDataComponent={"There are no data to display"}
                />
            </div>
            {/* <EmpTask_Add_Up /> */}
        </>
    )
}

export default EmpTask