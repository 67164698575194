import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from "react-select";
import DatePicker from 'react-datepicker'
import { Link } from 'react-router-dom';
import { DecryptedList, getShowingDayWithOutTime, getShowingWithOutTimeStartDate } from '../../../Include/Common/Utility';
import { SpaceNotAllow, SpaceNotAllowWithoutReq } from '../../../Validation/Validation';
import { Get_Error_Status } from '../../../../redux/actionTypes';
import { toastifyError } from '../../../Include/Common/AlertMsg';
import { get_Discount_DropData, get_Gst_DropData, get_Party_Sale_Data, get_Receivedby_DropData, get_supplier_DropData, save_Quotes_Enter_Data, update_Quotes_Enter_Data } from '../../../../redux/actions/AllAction';
import { changeArrayFormatDrop } from '../../../Include/Common/ArrayFormat';

const Quotes_Add_Up = (props) => {
    const dispatch = useDispatch()
    const userId = useSelector((state) => state.Data.userId)
    const { status, pageStatus } = props
    const receivedbyDropData = useSelector((state) => state.Data.receivedbyDropData);
    const discountDropData = useSelector((state) => state.Data.discountDropData)
    const gstSlabsDropData = useSelector((state) => state.Data.gstSlabsDropData)
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount);
    const partySaleData = changeArrayFormatDrop(useSelector((state) => state.Data.partySaleData), 'Name', 'Id');
    const singlequotesData = useSelector((state) => state?.Data?.singlequotesData[0]);
    const customStylesWithOutColor = {
        control: (base) => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    };
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }
    const [value, setValue] = useState({
        RequestId: '',
        requestno: '',
        SupplierId: '',
        SupplierName: '',
        Rate: '',
        Insurance: '',
        GST: '',
        Discount: '',
        DiscountType: '',
        ReceiveDate: '',
        ReceiveDate1: '',
        Receivedby: '',
        ReceivedbyID: '',
        Remarks: '',
        Createdbyid: userId,
        ID: ''
    });
    const [value1, setValue1] = useState({
        IsLedger: '1',
        Iscommon: '0',
        IsUnRegular: '0',
        IsSupplier: '0',
        Iscredit: '0',
        IsActive: "1",
    })
    const handelChange = (e) => {
        if (e.target.name === 'Requestid' || e.target.name === 'Rate' || e.target.name === 'Insurance' || e.target.name === 'Discount') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setValue({
                ...value,
                [e.target.name]: checkNumber
            })
        }
        else {
            setValue({
                ...value,
                [e.target.name]: e.target.value,
            });
        }
    }
    useEffect(() => {
        dispatch(get_Party_Sale_Data(value1));
    }, [value1])

    const [errors, setErrors] = useState({
        RequestidError: '', RateError: '', SupplierNameError: ''
    })
    const { RequestidError, RateError, SupplierNameError } = errors;

    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllowWithoutReq(value.RequestId)) {
            setErrors(prevValues => { return { ...prevValues, ['RequestidError']: SpaceNotAllowWithoutReq(value.RequestId) } })
            dispatch({ type: Get_Error_Status, payload: true });
        };
        if (SpaceNotAllowWithoutReq(value.Rate)) {
            setErrors(prevValues => { return { ...prevValues, ['RateError']: SpaceNotAllowWithoutReq(value.Rate) } })
        };
    };
    useEffect(() => {
        if (singlequotesData) {
            setValue(pre => {
                return {
                    ...pre,
                    RequestId: singlequotesData?.RequestId,
                    requestno: singlequotesData?.requestno,
                    SupplierId: singlequotesData.SupplierId,
                    SupplierName: singlequotesData?.SupplierName,
                    Rate: singlequotesData?.Rate,
                    Insurance: singlequotesData?.Insurance,
                    GST: singlequotesData?.GST,
                    Discount: singlequotesData?.Discount,
                    DiscountType: singlequotesData.DiscountType,
                    ReceiveDate: singlequotesData?.ReceiveDate,
                    ReceiveDate1: singlequotesData?.ReceiveDate?.split("/")?.reverse()?.join("/"),
                    Receivedby: singlequotesData?.Receivedby,
                    ID: singlequotesData?.id,
                    Remarks: singlequotesData.Remarks,
                    Updatedbyid: userId,
                }
            })
        } else resetHooks()

    }, [singlequotesData, editUpdateCount])
    useEffect(() => {
        dispatch(get_Discount_DropData())
        dispatch(get_Gst_DropData())
        if (receivedbyDropData.length === 0) dispatch(get_Receivedby_DropData())
    }, [])

    const handleDropdown = (e, Name) => {
        if (Name === 'ReceivedbyID') {
            setValue(pre => { return { ...pre, ['ReceivedbyID']: e?.value, ['Receivedby']: e?.label } })
        } else if (Name === 'SupplierId') {
            setValue(pre => { return { ...pre, ['SupplierId']: e?.value, ['SupplierName']: e?.label } })
        } else if (Name === 'DiscountTypeId') {
            setValue(pre => { return { ...pre, ['DiscountTypeId']: e?.value, ['DiscountType']: e?.label } })
        } else if (Name === 'GSTID') {
            setValue(pre => { return { ...pre, ['GSTID']: e?.value, ['GST']: e?.label } })
        }
    }

    useEffect(() => {
        if (RequestidError === 'true' && RateError === 'true') {
            if (status) { dispatch(update_Quotes_Enter_Data(value, pageStatus)); setErrors({ ['RequestidError']: '' }) }
            else { dispatch(save_Quotes_Enter_Data(value, pageStatus)); setErrors({ ['RequestidError']: '' }) }
        }
    }, [RequestidError, RateError]);

    const resetHooks = () => {
        setValue(pre => {
            return {
                ...pre,
                RequestId: '',
                requestno: '',
                SupplierId: '',
                SupplierName: '',
                Rate: '',
                Insurance: '',
                GST: '',
                Discount: '',
                DiscountType: '',
                ReceiveDate: '',
                ReceiveDate1: '',
                Receivedby: '',
                Remarks: '',
                Updatedbyid: userId, ID: ''
            }
        })
        setErrors(pre => { return { ...pre, RequestidError: '', RateError: '' } })
    }

    return (
        <>
            <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="AddQuotesModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="m-1 ">
                                <fieldset style={{ border: '1px solid gray' }}>
                                    <legend >Add Quotes</legend>
                                    <div className="form-row" style={{ marginTop: '-10px' }}>
                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='RequestId'
                                                    className="requiredColor"
                                                    id='RequestId'
                                                    required
                                                    onChange={handelChange}
                                                    value={value.RequestId}
                                                />
                                                <label className='pt-1'>Req ID</label>
                                            </div>
                                            {errors.RequestidError !== 'true' ? (
                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.RequestidError}</span>
                                            ) : null}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div className="dropdown__box" >
                                                <Select
                                                    name='SupplierNameId'
                                                    styles={customStylesWithOutColor}
                                                    value={partySaleData?.filter((obj) => obj?.label === value?.SupplierName)}
                                                    options={partySaleData}
                                                    isClearable
                                                    placeholder="Select.."
                                                    onChange={(e) => handleDropdown(e, 'SupplierId')}
                                                />
                                                <label htmlFor='' >Supplier Id</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='Rate'
                                                    className="requiredColor"
                                                    id='Rate'
                                                    required
                                                    onChange={handelChange}
                                                    value={value.Rate}
                                                />
                                                <label className='pt-1'>Rate</label>
                                            </div>
                                            {errors.RateError !== 'true' ? (
                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.RateError}</span>
                                            ) : null}
                                        </div>
                                        {/* <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div className="dropdown__box" >
                                                <Select
                                                    name='insurance'
                                                    styles={customStylesWithOutColor}
                                                    isClearable
                                                />
                                                <label htmlFor='' >Insurance</label>
                                            </div>
                                        </div> */}
                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='Insurance'
                                                    className=""
                                                    id='Insurance'
                                                    required
                                                    value={value.Insurance}
                                                    onChange={handelChange}
                                                />
                                                <label className='pt-1'>Insurance</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 d-flex mt-2 px-0">
                                            <div className="col-6 col-md-6 col-lg-10 ">
                                                <div className="dropdown__box" >
                                                    <Select
                                                        name='GSTID'
                                                        styles={customStylesWithOutColor}
                                                        isClearable
                                                        value={gstSlabsDropData?.filter((obj) => obj?.label === value?.GST)}
                                                        options={gstSlabsDropData}
                                                        onChange={(e) => handleDropdown(e, 'GSTID')}
                                                    />
                                                    <label htmlFor='' >GST</label>
                                                </div>
                                            </div>

                                            <div className="col-1   px-0" style={{ marginTop: '13px' }}>
                                                <Link to={'/Inventory?page=Enter%20Quotes'} className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal"
                                                >
                                                    <i className="fa fa-plus"></i>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='Discount'
                                                    className=""
                                                    id='Discount'
                                                    required
                                                    onChange={handelChange}
                                                    value={value.Discount}
                                                />
                                                <label className='pt-1'>Discount Enter</label>
                                            </div>
                                        </div>

                                        <div className="col-6 col-md-6 col-lg-4 d-flex mt-2 px-0">
                                            <div className="col-6 col-md-6 col-lg-10  ">
                                                <div className="dropdown__box" >
                                                    <Select
                                                        name='DiscountTypeId'
                                                        styles={customStylesWithOutColor}
                                                        isClearable
                                                        value={discountDropData?.filter((obj) => obj?.label === value?.DiscountType)}
                                                        options={discountDropData}
                                                        onChange={(e) => handleDropdown(e, 'DiscountTypeId')}
                                                    />
                                                    <label htmlFor='' >Discount Type</label>
                                                </div>
                                            </div>
                                            <div className="col-1   px-0" style={{ marginTop: '13px' }}>
                                                <Link to={'/Inventory?page=Enter%20Quotes'} className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal"
                                                >
                                                    <i className="fa fa-plus"></i>
                                                </Link>
                                            </div>
                                        </div>
                                        <div class="col-4 col-md-4 col-lg-4 mt-1 ">
                                            <div className="dropdown__box">
                                                <DatePicker
                                                    id='ReceiveDate'
                                                    name='ReceiveDate'
                                                    dateFormat="dd/MM/yyyy "
                                                    timeInputLabel
                                                    isClearable={value.ReceiveDate ? true : false}
                                                    selected={value.ReceiveDate1 && new Date(value.ReceiveDate1)}
                                                    onChange={(date) => date ? setValue(pre => { return { ...pre, ['ReceiveDate']: getShowingDayWithOutTime(date), ['ReceiveDate1']: getShowingWithOutTimeStartDate(date) } }) :
                                                        setValue(pre => { return { ...pre, ['ReceiveDate']: '', ['ReceiveDate1']: '' } })
                                                    }
                                                    placeholderText={'Select...'}
                                                    dropdownMode="select"
                                                    showMonthDropdown
                                                    minDate={new Date()}
                                                    showYearDropdown
                                                />
                                                <label htmlFor="" className='pt-1'>Received Date</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div className="dropdown__box" >
                                                <Select
                                                    name='Receivedby'
                                                    styles={customStylesWithOutColor}
                                                    value={receivedbyDropData?.filter((obj) => obj?.label === value?.Receivedby)}
                                                    options={receivedbyDropData}
                                                    isClearable
                                                    className="requiredColor"
                                                    placeholder="Select.."
                                                    onChange={(e) => handleDropdown(e, 'ReceivedbyID')}
                                                />
                                                <label htmlFor='' >Received By</label>
                                            </div>
                                        </div>
                                        <div className="col-12  col-md-12 col-lg-12 mt-2 ">
                                            <div className="dropdown__box">
                                                <textarea
                                                    name="Remarks"
                                                    id="Remarks"
                                                    cols="30"
                                                    rows="1"
                                                    value={value.Remarks}
                                                    onChange={handelChange}
                                                    className="form-control"
                                                >
                                                </textarea>
                                                <label htmlFor='' >Remarks</label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="btn-box text-right  mr-1  mt-2">
                                    {status ?
                                        <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error} >Update</button> : <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error} >Save</button>}
                                    <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={resetHooks}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Quotes_Add_Up