import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { delete_Employee, get_Employee_Data, get_Single_Employee_Data } from '../../../../../redux/actions/AllAction';
import DataTable from 'react-data-table-component';
import { Edit_Update_Count, Employee_ID, Get_Employee_Filter_Data, Get_Single_Employee_Data, Status } from '../../../../../redux/actionTypes';
import Dropdown from 'react-bootstrap/Dropdown'
import { Three_Search_Filter } from '../../../../Filter/Filter';
import ConfirmModal from '../../../../Include/Common/ConfirmModal';


const Employee = () => {

    const dispatch = useDispatch()
    const employeeData = useSelector((state) => state.Data.employeeData)
    const employeeFilterData = useSelector((state) => state.Data.employeeFilterData)
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const userId = useSelector((state) => state.Data.userId)
    const employeeId = useSelector((state) => state.Data.employeeId)
    const [filterTypeIdOption, setfilterTypeIdOption] = useState('Contains');
    const [filterTypeDescOption, setfilterTypeDescOption] = useState('Contains');
    const [filterTypeAddOption, setfilterTypeAddOption] = useState('Contains');
    const [searchValue1, setSearchValue1] = useState('')
    const [searchValue2, setSearchValue2] = useState('')
    const [searchValue3, setSearchValue3] = useState('')
    const [pageStatus, setPageStatus] = useState("1")
    const [IsActive, setIsActive] = useState()
    const [confirmType, setConfirmType] = useState('')   

    useEffect(() => {
        dispatch(get_Employee_Data(pageStatus))
    }, [pageStatus])

    useEffect(() => {
        if (pageStatus) { setSearchValue1(''); setSearchValue2(''); setSearchValue3('') }
      }, [pageStatus])
      
    const columns = [
        {
            name: 'Empcode',
            selector: (row) => row.EmpCode,
            sortable: true
        },
        {
            name: 'EmpName',
            selector: (row) => row.EmpName,
            sortable: true
        },
        {
            name: 'Designation',
            selector: (row) => row.Designation,
            sortable: true
        },
        {
            name: 'Unit',
            selector: (row) => row.Unit,
            sortable: true
        },
        {
            name: 'Department',
            selector: (row) => row.Department,
            sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 55, fontWeight: '700' }}>Action</p>,
            cell: row => <>
                <div style={{ position: 'absolute', top: 4, right: 40 }}>
                    {
                        pageStatus === "1" ?
                            <Link to='/employeetab' onClick={() => setEditStatus(row)}
                                className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn"><i className="fa fa-edit"></i>
                            </Link>
                            : <></>
                    }                                       
                    {
                        pageStatus === "1" ?
                            < Link to={`/AdminSetting?sub=Operator`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive(0); setConfirmType("InActive"); dispatch({ type: Employee_ID, payload: row.ID }) }}
                                className="btn btn-sm  text-white px-1 py-0 mr-1" style={{ background: "#ddd" }}>
                                <i class="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true"></i>
                            </Link>
                            :
                            <Link to={`/AdminSetting?sub=Operator`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive(1); setConfirmType("Active"); dispatch({ type: Employee_ID, payload: row.ID }) }}
                                className="btn btn-sm  text-white px-1 py-0 mr-4" style={{ background: "#ddd" }}>
                                <i class="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true"></i>
                            </Link>
                    }

                </div>
            </>
        }
    ]

    const setEditStatus = (row) => {
        dispatch(get_Single_Employee_Data(row.ID)); dispatch({ type: Status, payload: true }); dispatch({ type: Employee_ID, payload: row.ID });
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
    }

    const setNewStatus = () => {
        dispatch({ type: Status, payload: false }); dispatch({ type: Employee_ID, payload: '' }); dispatch({ type: Get_Single_Employee_Data, payload: [] })
    };

    const UpdActiveDeactive = () => {
        dispatch(delete_Employee(employeeId, IsActive, pageStatus, userId));
    }

    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="col-12 col-md-6 col-lg-12 ">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class={`nav-link ${pageStatus === '1' ? 'active' : ''}`} onClick={() => setPageStatus("1")} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true">Active</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class={`nav-link ${pageStatus === '0' ? 'active' : ''}`} onClick={() => setPageStatus("0")} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true">InActive</a>
                        </li>
                    </ul>
                </div>
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Employee</p>
                    <div style={{ marginLeft: 'auto' }}>
                        <Link to={'/employeetab'} className="btn btn-sm  text-white px-2 py-0" onClick={() => setNewStatus()} >
                            <i className="fa fa-plus"></i>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="col-12 mt-2 px-3">
                <div className="row">

                    <div className="col-3">
                        <input type="text" value={searchValue1} onChange={(e) => {
                            setSearchValue1(e.target.value);
                            const result = Three_Search_Filter(employeeData, e.target.value, searchValue2, searchValue3, filterTypeIdOption, 'EmpCode', 'EmpName', 'Department')
                            dispatch({ type: Get_Employee_Filter_Data, payload: result });
                        }}
                            className='form-control' placeholder='Search By EmpCode...' />
                    </div>
                    <div className='col-1 '>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('End with')}>End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="col-3">
                        <input type="text" value={searchValue2} onChange={(e) => {
                            setSearchValue2(e.target.value);
                            const result = Three_Search_Filter(employeeData, searchValue1, e.target.value, searchValue3, filterTypeAddOption, 'EmpCode', 'EmpName', 'Department')
                            dispatch({ type: Get_Employee_Filter_Data, payload: result });
                        }}
                            className='form-control' placeholder='Search By EmpName. ...' />
                    </div>
                    <div className='col-1 '>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('Contains')}>Contains</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('is equal to')}>is equal to</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('is not equal to')}>is not equal to </Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('Starts With')}>Starts With</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('End with')}>End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="col-3">
                        <input type="text" value={searchValue3} onChange={(e) => {
                            setSearchValue3(e.target.value);
                            const result = Three_Search_Filter(employeeData, searchValue1, searchValue2, e.target.value, filterTypeDescOption, 'EmpCode', 'EmpName', 'Department')
                            dispatch({ type: Get_Employee_Filter_Data, payload: result });
                        }}
                            className='form-control' placeholder='Search By Department...' />
                    </div>
                    <div className='col-1 '>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('End with')}>End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>

            <div className="col-12 pt-2 px-3">
                <DataTable
                    columns={columns}
                    data={employeeFilterData}
                    dense
                    paginationPerPage={'10'}
                    paginationRowsPerPageOptions={[5, 10, 15]}
                    highlightOnHover
                    noContextMenu
                    pagination
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    noDataComponent={"There are no data to display"}
                />
            </div>
            <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />
        </>
    )
}

export default Employee