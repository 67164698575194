import React, {useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import ConfirmModal from '../../../../Include/Common/ConfirmModal';
import ItemDetailsAddUp from './ItemDetailsAddUp';
import { delete_ItemDetails_Data, get_AddItem_Data, get_Single_AddItem_Data } from '../../../../../redux/actions/AllAction';
import { Edit_Update_Count, Modal_Status } from '../../../../../redux/actionTypes';
import { useDispatch, useSelector } from 'react-redux';

const ItemDetails = () => {
    const dispatch = useDispatch()
    const editUpdateCount = useSelector((state) => state?.Data?.editUpdateCount)
    const userId = useSelector((state) => state?.Data?.userId)
    const invoiceID = useSelector((state)=> state?.Data?.invoiceID)
    const [pageStatus, setPageStatus] = useState("1")
    const [status, setStatus] = useState(false)
    const addItemData = useSelector((state) => state?.Data?.addItemData)
    const [id, setId] = useState('')
    const [IsActive, setIsActive] = useState()
    const [confirmType, setConfirmType] = useState('')

    useEffect(() => {
         if (invoiceID) dispatch(get_AddItem_Data(invoiceID, pageStatus))
    }, [pageStatus]);

    const columns = [
        {
            name: 'Product',
            selector: (row) => row.Product,
            sortable: true
        },
        {
            name: 'SGSTP',
            selector: (row) => row.SGSTP,
            sortable: true
        },
        {
            name: 'Qty',
            selector: (row) => row.Qty,
            sortable: true
        },
        {
            name: 'Rate',
            selector: (row) => row.Rate,
            sortable: true
        },
        {
            name: 'TotalAmt',
            selector: (row) => row.TotalAmt,
            sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 55, fontWeight: '700' }}>Action</p>,
            cell: row => <>
                <div style={{ position: 'absolute', top: 4, right: 40 }}>
                    {
                        pageStatus === "1" ?
                            <Link to='/purchaseInvoice' onClick={(e) => setEditStatus(row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn" data-toggle="modal" data-target="#AddItemModal"><i className="fa fa-edit"></i>
                            </Link>
                            : <></>
                    }
                      <Link to='/purchaseInvoice' data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('0'); setId(row.ID) }}
                        className="btn btn-sm bg-green text-white px-1 py-0 mr-1" >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </Link>

                </div>
            </>
        }
    ] 
    const setEditStatus = (row) => {
        dispatch(get_Single_AddItem_Data(row.ID))
        setStatus(true); setId(row.ID);
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: Modal_Status, payload: true });
    }

    const newStatus = () => {
        dispatch(get_Single_AddItem_Data(''));
        setStatus(false); setId('');
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: Modal_Status, payload: true });
    }
    const UpdActiveDeactive = () => {
        dispatch(delete_ItemDetails_Data(id, IsActive, userId, pageStatus, invoiceID))
    }

  return (  
    <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Item Details</p>
                    {invoiceID ? 
                    <div style={{ marginLeft: 'auto' }}>
                        <Link to={'/purchaseInvoice'} onClick={newStatus} className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#AddItemModal"  >
                            <i className="fa fa-plus"></i>
                        </Link> 
                    </div> : '' }
                </div>
            </div>
            <div className="col-12 pt-2 px-3">
                <DataTable
                    columns={columns}
                    data={addItemData}
                    dense
                    paginationPerPage={'10'}
                    paginationRowsPerPageOptions={[5, 10, 15]}
                    highlightOnHover
                    noContextMenu
                    pagination
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    noDataComponent={"There are no data to display"}
                />
            </div>
            <ItemDetailsAddUp {...{status, setPageStatus}}/>
            <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />
        </>
  )
}

export default ItemDetails


