import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { getShowingDayWithOutTime, getShowingWithOutTimeStartDate } from '../../../Include/Common/Utility';
import { SpaceNotAllow, SpaceNotAllowWithoutReq } from '../../../Validation/Validation';
import { Get_Error_Status } from '../../../../redux/actionTypes';
import { save_Meter_Reading_Data, update_Meter_Reading_Data } from '../../../../redux/actions/AllAction';

const MeterReading_Add_Up = (props) => {
    const dispatch = useDispatch();
    const { status, pageStatus } = props
    const userId = useSelector((state) => state.Data.userId);
    const singlemeterReadingData = useSelector((state) => state?.Data?.singleMeterReadingData[0])
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    const startRef = React?.useRef();
    const startRef1 = React?.useRef();
    const startRef2 = React?.useRef();
    const startRef3 = React?.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
        }
    };
    const [errors, setErrors] = useState({
        ReadingError: '', KWHStartError: '', KWHEndError: '', KVAHStartError: '', KVAHEndError: '', PowerCutError: ''
    })
    const { ReadingError, KWHStartError,  KVAHStartError } = errors;
    const [value, setValue] = useState({
        ReadingDate1: '',
        ReadingDate: '',
        KWHStartReading: '',
        KWHEndReading: '',
        KVAHStartReading: '',
        KVAHEndReading: '',
        PowerCutDuration: '',
        Updatedbyid: '',
        Createdbyid: userId
    });

    useEffect(() => {
        if (ReadingError === 'true' && KWHStartError === 'true' && KVAHStartError === 'true') {
            if (status) { dispatch(update_Meter_Reading_Data(value, pageStatus)); }
            else { dispatch(save_Meter_Reading_Data(value, pageStatus)); }
        }
    }, [ReadingError, KWHStartError, KVAHStartError]);
    
    const handelChange = (e) => {
        if ( e.target.name === "KWHStartReading" || e.target.name === "KWHEndReading" || e.target.name === 'KVAHStartReading' || e.target.name === 'KVAHEndReading' ||  e.target.name === "PowerCutDuration") {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setValue({
                ...value,
                [e.target.name]: checkNumber
            })
        }
        else
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    }

    useEffect(() => {
        if (singlemeterReadingData) {
            setValue({
                ...value,
                ReadingDate: new Date(singlemeterReadingData?.ReadingDate),
                ReadingDate1: singlemeterReadingData?.ReadingDate?.split("/")?.reverse()?.join("/"),
                KWHStartReading: singlemeterReadingData?.KWHStartReading,
                KWHEndReading: singlemeterReadingData?.KWHEndReading,
                KVAHStartReading: singlemeterReadingData?.KVAHStartReading,
                KVAHEndReading: singlemeterReadingData?.KVAHEndReading,
                PowerCutDuration: singlemeterReadingData?.PowerCutDuration,
                Updatedbyid: userId,
                ID: singlemeterReadingData?.ID
            })
        }
        else {
            setValue({
                ...value,
                ReadingDate: '',
                ReadingDate1: '',
                KWHStartReading: '',
                KWHEndReading: '',
                KVAHStartReading: '',
                KVAHEndReading: '',
                PowerCutDuration: '',
                Updatedbyid: '',
            })
        }
    }, [singlemeterReadingData, editUpdateCount])
    
    const resetHooks = () => {
        setValue(pre => {
            return {
                ...pre, ReadingDate: '',
                ReadingDate1: '',
                KWHStartReading: '',
                KWHEndReading: '',
                KVAHStartReading: '',
                KVAHEndReading: '',
                PowerCutDuration: '',
                ID: ''
            }
        })
        setErrors(pre => { return { ...pre, ReadingError: '', KWHStartError: '', KWHEndError: '', KVAHStartError: '', KVAHEndError: '', PowerCutError: '' } })
    }

    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllowWithoutReq(value.ReadingDate)) {
            setErrors(prevValues => { return { ...prevValues, ['ReadingError']: SpaceNotAllowWithoutReq(value.ReadingDate) } });
            dispatch({ type: Get_Error_Status, payload: true });
        }
        if (SpaceNotAllowWithoutReq(value.KWHStartReading)) {
            setErrors(prevValues => { return { ...prevValues, ['KWHStartError']: SpaceNotAllowWithoutReq(value.KWHStartReading) } })
        }
        if (SpaceNotAllowWithoutReq(value.KVAHStartReading)) {
            setErrors(prevValues => { return { ...prevValues, ['KVAHStartError']: SpaceNotAllowWithoutReq(value.KVAHStartReading) } })
        }   
    };
    return (
        <>
            <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="MeterReadingModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="m-1 ">
                                <fieldset style={{ border: '1px dashed #80808078', borderBottom:'none',borderLeft:'none', borderRight:'none' }}>
                                    <legend >Meter Reading</legend>
                                    <div className="form-row" style={{ marginTop: '-16px' }}>
                                        <div className="col-6 col-md-6 col-lg-6 ">
                                            <div className="dropdown__box ">
                                                <DatePicker
                                                    id='ReadingDate'
                                                    name='ReadingDate'
                                                    ref={startRef}
                                                    onKeyDown={onKeyDown}
                                                    onChange={(date) => date ? setValue(pre => { return { ...pre, ['ReadingDate']: getShowingDayWithOutTime(date), ['ReadingDate1']: getShowingWithOutTimeStartDate(date) } }) :
                                                        setValue(pre => { return { ...pre, ['ReadingDate']: '', ['ReadingDate1']: '' } })
                                                    }
                                                    dateFormat="dd/MM/yyyy"
                                                    timeInputLabel
                                                    className='requiredColor'
                                                    isClearable
                                                    selected={value.ReadingDate1 && new Date(value.ReadingDate1)}
                                                    placeholderText={'Select...'}
                                                    minDate={new Date()}
                                                    dropdownMode="select"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                                <label htmlFor="" className='pt-1 pl-0'>Reading Date</label>
                                                {errors.ReadingError !== "true" ? (
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            fontSize: "13px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                        }}
                                                    >
                                                        {errors.ReadingError}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                {/* kwh reading */}
                                <fieldset style={{ border: '1px dashed #80808078', borderBottom:'none',borderLeft:'none', borderRight:'none' }}>
                                    <legend >KWH Reading Details</legend>
                                    <div className="form-row" style={{ marginTop: '-12px' }}>
                                        <div className="col-12 col-md-6 col-lg-6 " style={{ marginTop: '6px' }}>
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='KWHStartReading'
                                                    id='KWHStartReading'
                                                    className='requiredColor'
                                                    required
                                                    value={value.KWHStartReading}
                                                    onChange={handelChange}
                                                />
                                                <label className='pt-1'>Start Reading</label>
                                                {errors.KWHStartError !== "true" ? (
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            fontSize: "13px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                        }}
                                                    >
                                                        {errors.KWHStartError}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 " style={{ marginTop: '6px' }}>
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='KWHEndReading'
                                                    className=""
                                                    id='KWHEndReading'
                                                    required
                                                    value={value.KWHEndReading}
                                                    onChange={handelChange}
                                                />
                                                <label className='pt-1'>End Reading</label>
                                                {errors.KWHEndError !== "true" ? (
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            fontSize: "13px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                        }}
                                                    >
                                                        {errors.KWHEndError}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                {/* kvah */}
                                <fieldset style={{ border: '1px dashed #80808078', borderBottom:'none',borderLeft:'none', borderRight:'none' }}>
                                    <legend >KVAH Reading Details</legend>
                                    <div className="form-row" style={{ marginTop: '-12px' }}>
                                        <div className="col-12 col-md-6 col-lg-6 " style={{ marginTop: '6px' }}>
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='KVAHStartReading'
                                                    className='requiredColor'
                                                    id='KVAHStartReading'
                                                    required
                                                    value={value.KVAHStartReading}
                                                    onChange={handelChange}
                                                />
                                                <label className='pt-1'>Start Reading</label>
                                                {errors.KVAHStartError !== "true" ? (
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            fontSize: "13px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                        }}
                                                    >
                                                        {errors.KVAHStartError}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 " style={{ marginTop: '6px' }}>
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='KVAHEndReading'
                                                    className=""
                                                    id='KVAHEndReading'
                                                    required
                                                    value={value.KVAHEndReading}
                                                    onChange={handelChange}
                                                />
                                                <label className='pt-1'>End Reading</label>
                                                {errors.KVAHEndError !== "true" ? (
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            fontSize: "13px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                        }}
                                                    >
                                                        {errors.KVAHEndError}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <hr style={{marginTop:'-1px'}}/>
                                <div className="col-12 col-md-6 col-lg-6 mt-4 pl-3" >
                                    <div class="text-field">
                                        <input
                                            type="text"
                                            name='PowerCutDuration'
                                            className=""
                                            id='PowerCutDuration'
                                            required
                                            value={value.PowerCutDuration}
                                            onChange={handelChange}
                                        />
                                        <label className='pt-1'>Power Cut Duration</label>
                                        {errors.PowerCutError !== "true" ? (
                                            <span
                                                style={{
                                                    color: "red",
                                                    fontSize: "13px",
                                                    margin: "0px",
                                                    padding: "0px",
                                                }}
                                            >
                                                {errors.PowerCutError}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btn-box text-right  mr-1 mb-2">
                            {status ?
                                <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button>
                                : <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Save</button>}
                            <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={resetHooks}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MeterReading_Add_Up