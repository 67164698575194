import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import TabsMap from './TabsMap'
import { useDispatch, useSelector } from 'react-redux'
import { get_Menu } from '../../../redux/actions/AllAction'
import UpdatePassword from './UpdatePassword/UpdatePassword'
import AddMenu from './Menu/AddMenu'
import { toastifyError } from '../Common/AlertMsg'
import purchase from '../../img/purchase.png'
import sale from '../../img/sale.png'
import voucher from '../../img/voucher.png'
import account from '../../img/account.png'
import inventory from '../../img/inventory.png'
import setting from '../../img/setting.png'
import Hr from '../../img/Hr.png'
import report from '../../img/reports.png'
import table from '../../img/tables.png'
import minning from '../../img/mining.png'
import transport from '../../img/transport.png'
import service from '../../img/service.png'
import dash from '../../img/dash.png'
import { Decrypt_Id_Name } from '../Common/Utility'
const Header = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch();
  const menuData = useSelector((state) => state.Data.menuData);
  const userId = useSelector((state) => state.Data.userId);
  const checkPage = useSelector((state) => state.Data.checkPage);
  const [activeClass, setActiveClass] = useState(sessionStorage.getItem('MenuName') ? sessionStorage.getItem('MenuName') : 'DashBoard')

  useEffect(() => {
    if (menuData?.length === 0 && userId) dispatch(get_Menu(userId));
  }, [userId])

  useEffect(() => {
    const planel = document.querySelectorAll('.planel')
    planel.forEach(panel => {
      panel.addEventListener('click', () => {
        removeActiveClasses()
        panel.classList.add('ipsNavBar_active')
      })
    })
    function removeActiveClasses() {
      planel.forEach(panel => {
        panel.classList.remove('ipsNavBar_active')
      })
    }
  }, [])

  const Logout = () => {
    sessionStorage.clear()
    navigate('/')
  }
  const location = useLocation(); // Get the current location object

  // Function to determine if a nav item should be active
  const isActive = (path) => {
    return location.pathname === path; // Check if the current path matches the nav item's path
  };
  return (
    <>
      <div id="ipsLayout_header">
        <nav data-controller="core.front.core.navBar" className=" ipsResponsive_showDesktop" id="ips_uid_467_2">
          <div className="ipsNavBar_primary ipsLayout_container ">
            {/* <ul data-role="primaryNavBar" className="ipsClearfix">
              {
                menuData?.map((menu, index) => (
                  <li className={activeClass === menu?.MenuName ? "planel ipsNavBar_active" : "planel"} key={index}
                    onClick={() => {
                      if (!checkPage) {
                        sessionStorage.setItem('MenuName', menu.MenuName)
                        setActiveClass(menu.MenuName)
                      } else toastifyError('Please Add Request List')
                    }
                    }>
                    <Link to={!checkPage && '/' + menu?.MenuName.replace(/ +/g, "")} data-navitem-id={1} id="ips_uid_467_3" data-ipsmenu data-ipsmenu-appendto="#elNavSecondary_1" className='add_class'>
                      {menu?.DisplayName}<span className="ipsNavBar_active__identifier" />
                    </Link>
                    {
                      menu.Submenuclass.length > 0 ?
                        <ul className="ipsNavBar_secondary ipsHide" data-role="secondaryNavBar">
                          <TabsMap tabs={menu.Submenuclass} MenuName={menu.MenuName} />
                        </ul>
                        : <></>
                    }
                  </li>
                ))
              }
            </ul> */}

            {/* <li className="nav-item dropdown nav-user notifications mr-1">
              <i class="fa fa-user  dropdown-toggle" data-toggle="dropdown" aria-hidden="true" style={{ color: '#fff', cursor: 'pointer' }}></i>
              <div className="dropdown-menu mt-2 dropdown-menu-right nav-user-dropdown" aria-labelledby="navbarDropdownMenuLink2">
                <a className="dropdown-item" href="#"><i className="fa fa-user mr-2" />My Account</a>
                <Link to={''} style={{ textDecoration: 'none' }}>
                  <a className="dropdown-item" href="#"><i className="fa fa-cog mr-2" />My Setting</a>
                </Link>
                <Link to={''} style={{ textDecoration: 'none' }} data-toggle="modal" data-target="#PasswordModal" >
                  <a className="dropdown-item" href="#"><i className="fa fa-key mr-2" />Update Password</a>
                </Link>
                <Link to={''} style={{ textDecoration: 'none' }} data-toggle="modal" data-target="#MenuModal" >
                  <a className="dropdown-item" href="#"><i className="fa fa-key mr-2" />Menu</a>
                </Link>
                <a className="dropdown-item" href="#"><i className="fa fa-exchange mr-2" />Switch Account</a>
                <a className="dropdown-item" onClick={() => Logout()}><i className="fa fa-power-off mr-2" />Logout</a>
              </div>
            </li> */}
            <ul data-role="primaryNavBar" className="ipsClearfix " >
              <li className={`nav-item ${isActive('/DashBoard?sub=Home') ? 'active' : ''} `}>
                <Link to={'/DashBoard?sub=Home'}>
                  <img src={dash} alt="" style={{ width: '20px' }} />
                  <a className="nav-link active" aria-current="page" >Dashboard</a>
                </Link>
              </li>
          
              <li className={`nav-item ${isActive('/Sale') ? 'active' : ''} d-sm-none  d-lg-inline-block`}>
                <Link to={'/Sale'}>
                  <img src={sale} alt="" style={{ width: '20px' }} />
                  <a className="nav-link" href="#"> Sale</a>
                </Link>
              </li>
              
              <li className={`nav-item ${isActive('#') ? 'active' : ''} d-sm-none  d-lg-inline-block`}>
                {/* <i className="fa fa-bookmark"></i> */}
                <Link to={'#'}>
                  <img src={purchase} alt="" style={{ width: '20px' }} />
                  <a className="nav-link" href="#">Purchase</a>
                </Link>
              </li>
              <li className={`nav-item ${isActive('/Voucher') ? 'active' : ''} d-sm-none  d-lg-inline-block`}>
                <Link to={'/Voucher'}>
                  <img src={voucher} alt="" style={{ width: '20px' }} />
                  <a className="nav-link" href="#">Voucher</a>
                </Link>
              </li>
              <li className={`nav-item ${isActive('#') ? 'active' : ''} d-sm-none  d-lg-inline-block`}>
                <Link to={'#'}>
                  <img src={account} alt="" style={{ width: '20px' }} />
                  <a className="nav-link" href="#">Account</a>
                </Link>
              </li>
              <li className={`nav-item ${isActive('#') ? 'active' : ''} d-sm-none  d-lg-inline-block`}>
                <Link to={'#'}>
                  <img src={inventory} alt="" style={{ width: '20px' }} />
                  <a className="nav-link" href="#"> Inventory</a>
                </Link>
              </li>
              <li className={`nav-item ${isActive('#') ? 'active' : ''}  d-sm-none  d-lg-inline-block`}>
                <Link to={'#'}>
                  <img src={setting} alt="" style={{ width: '20px' }} />
                  <a className="nav-link" href="#"> Admin Setting</a>
                </Link>
              </li>
              <li className={`nav-item ${isActive('#') ? 'active' : ''} d-sm-none  d-lg-inline-block`}>
                <Link to={'#'}>
                  <img src={Hr} alt="" style={{ width: '20px' }} />
                  <a className="nav-link" href="#"> HR</a>
                </Link>
              </li>
              <li className={`nav-item ${isActive('/Reports') ? 'active' : ''} d-sm-none  d-lg-inline-block`}>
                <Link to={'/Reports'}>
                  <img src={report} alt="" style={{ width: '20px' }} />
                  <a className="nav-link" href="#">Reports</a>
                </Link>
              </li>
              <li className={`nav-item ${isActive('/MasterTables') ? 'active' : ''} d-sm-none  d-lg-inline-block`}>
                <Link to={'/MasterTables'}>
                  <img src={table} alt="" style={{ width: '20px' }} />
                  <a className="nav-link"> Master Table</a>
                </Link>
              </li>
              <li className={`nav-item ${isActive('#') ? 'active' : ''} d-sm-none  d-lg-inline-block`}>
                <Link to={'#'}>
                  <img src={minning} alt="" style={{ width: '20px' }} />
                  <a className="nav-link" href="#"> Mining</a>
                </Link>
              </li>
              <li className={`nav-item ${isActive('#') ? 'active' : ''} d-sm-none  d-lg-inline-block`}>
                <Link to={'#'}>
                  <img src={transport} alt="" style={{ width: '20px' }} />
                  <a className="nav-link" href="#"> Plant Transport</a>
                </Link>
              </li>
              <li className={`nav-item ${isActive('#') ? 'active' : ''} d-sm-none  d-lg-inline-block`}>
                <Link to={'#'}>
                  <img src={service} alt="" style={{ width: '20px' }} />
                  <a className="nav-link" href="#"> Services</a>
                </Link>
              </li>
              <li className={`nav-item ${isActive('#') ? 'active' : ''} d-lg-none  d-sm-inline-block`}>
                <Link to={'#'}>
                  <img src={service} alt="" style={{ width: '20px' }} />
                  <a className="nav-link" href="#"> Cash Book</a>
                </Link>
              </li>
              <li className={`nav-item ${isActive('#') ? 'active' : ''} d-lg-none  d-sm-inline-block`}>
                <Link to={'#'}>
                  <img src={service} alt="" style={{ width: '20px' }} />
                  <a className="nav-link" href="#"> Day Book</a>
                </Link>
              </li>
              <li className={`nav-item ${isActive('#') ? 'active' : ''} d-lg-none  d-sm-inline-block`}>
                <Link to={'#'}>
                  <img src={service} alt="" style={{ width: '20px' }} />
                  <a className="nav-link" href="#">Stock Report</a>
                </Link>
              </li>
              <li className={`nav-item ${isActive('#') ? 'active' : ''} d-lg-none  d-sm-inline-block`}>
                <Link to={'#'}>
                  <img src={service} alt="" style={{ width: '20px' }} />
                  <a className="nav-link" href="#">Ledger Report</a>
                </Link>
              </li>
              <li className={`nav-item ${isActive('#') ? 'active' : ''} d-lg-none  d-sm-inline-block`}>
                <Link to={'#'}>
                  <img src={service} alt="" style={{ width: '20px' }} />
                  <a className="nav-link" href="#">Attendance Reports</a>
                </Link>
              </li>
       

              {/* <li className="nav-item dropdown nav-user notifications  " >
                <span className="" data-toggle="dropdown" >
                  <span className="text-uppercase text-red ">
                    {
                      sessionStorage.getItem('FirmName'

                      ) && Decrypt_Id_Name(sessionStorage.getItem('FirmName'), 'FiForUserFirmName')
                    }&nbsp;
                    <i class="fa fa solid fa-caret-down"></i>
                  </span>
                </span>
                <div className="dropdown-menu mt-2 dropdown-menu-right nav-user-dropdown" >
                  <div className="header-card">
                    <h6 className="text-center mt-2" style={{ fontSize: '15px' }}>{
                      sessionStorage.getItem('FirmName') && Decrypt_Id_Name(sessionStorage.getItem('FirmName'), 'FiForUserFirmName')
                    }</h6>
                    <Link className="dropdown-item" >
                      <i className="fa fa-sign-out"  onClick={() => Logout()}></i>&nbsp;LogOut
                    </Link>
                  </div>
                </div>

              </li> */}
              {/* <div class="dropdown dropstart text-end">
                <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown">
                  <i class="fa fa-user"></i>
                </button>

                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">Normal</a></li>
                  <li><a class="dropdown-item active" href="#">Active</a></li>
                  <li><a class="dropdown-item disabled" href="#">Disabled</a></li>
                </ul>
              </div> */}
              <li className="nav-item dropdown nav-user notifications  " >
                <i className="fa fa-user  dropdown-toggle" data-toggle="dropdown" aria-hidden="true" style={{ color: '#000', cursor: 'pointer', padding: '0px 50px' }}></i>
                <div className="dropdown-menu mt-2 dropdown-menu-right nav-user-dropdown" aria-labelledby="navbarDropdownMenuLink2">
                  <a className="dropdown-item" href="#"><i className="fa fa-user mr-2" />My Account</a>
                  <Link to={''} style={{ textDecoration: 'none' }}>
                    <a className="dropdown-item" href="#"><i className="fa fa-cog mr-2" />My Setting</a>
                  </Link>
                  <Link to={''} style={{ textDecoration: 'none' }} data-toggle="modal" data-target="#PasswordModal" >
                    <a className="dropdown-item" href="#"><i className="fa fa-key mr-2" />Update Password</a>
                  </Link>
                  <Link to={''} style={{ textDecoration: 'none' }} data-toggle="modal" data-target="#MenuModal" >
                    <a className="dropdown-item" href="#"><i className="fa fa-key mr-2" />Menu</a>
                  </Link>
                  <a className="dropdown-item" href="#"><i className="fa fa-exchange mr-2" />Switch Account</a>
                  <a className="dropdown-item" onClick={() => Logout()}><i className="fa fa-power-off mr-2" />Logout</a>
                </div>
              </li>
            </ul>
          </div>
        </nav >
        {/* <div className="ipsResponsive_showDesktop1">
          <nav className="navbar  navbar-light bg-light">
            <div className=" " id="navbarNav">
              <ul className="navbar-nav me-auto">
                <li className={`nav-item ${isActive('/DashBoard?sub=Home') ? 'active' : ''}`}>
                  <Link to={'/DashBoard?sub=Home'}>
                    <img src={dash} alt="" style={{ width: '20px' }} />
                    <a className="nav-link active " aria-current="page" >Dashboard</a>
                  </Link>
                </li>

                <li className={`nav-item ${isActive('#') ? 'active' : ''}`}>
                  <Link to={'#'}>
                    <img src={service} alt="" style={{ width: '20px' }} />
                    <a className="nav-link" href="#">Books</a>
                  </Link>
                </li>
                <li className={`nav-item ${isActive('#') ? 'active' : ''}`}>
                  <Link to={'#'}>
                    <img src={service} alt="" style={{ width: '20px' }} />
                    <a className="nav-link" href="#">Stock Report</a>
                  </Link>
                </li>
                <li className={`nav-item ${isActive('#') ? 'active' : ''}`}>
                  <Link to={'#'}>
                    <img src={service} alt="" style={{ width: '20px' }} />
                    <a className="nav-link" href="#">Ledger Report</a>
                  </Link>
                </li>
                <li className={`nav-item ${isActive('#') ? 'active' : ''}`}>
                  <Link to={'#'}>
                    <img src={setting} alt="" style={{ width: '20px' }} />
                    <a className="nav-link" href="#"> Admin Setting</a>
                  </Link>
                </li>
                <li className={`nav-item ${isActive('/Reports') ? 'active' : ''}`}>
                  <Link to={'/Reports'}>
                    <img src={report} alt="" style={{ width: '20px' }} />
                    <a className="nav-link" href="#">Reports</a>
                  </Link>
                </li>
                <li className={`nav-item ${isActive('/MasterTables') ? 'active' : ''}`}>
                  <Link to={'/MasterTables'}>
                    <img src={table} alt="" style={{ width: '20px' }} />
                    <a className="nav-link"> Master Table</a>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div> */}
      </div >
      <UpdatePassword />
      <AddMenu />
    </>
  )
}

export default Header