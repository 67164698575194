import React, { useState } from 'react';
import Select from "react-select";
import { Get_Error_Status, Relation, RelationAge, RelationMobile, RelationName } from '../../../../../../../redux/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { get_Relation_Drop_Data, update_Employee_Family } from '../../../../../../../redux/actions/AllAction';
import { toastifyError } from '../../../../../../Include/Common/AlertMsg';
import { SpaceNotAllow } from '../../../../../../Validation/Validation';
import { changeArrayFormatDrop } from '../../../../../../Include/Common/ArrayFormat';

const FamilyDetails = () => {

    const dispatch = useDispatch()
    const relationData = changeArrayFormatDrop(useSelector((state) => state.Data.relationDropData),'Description','ID')
    const pageStatus = useSelector((state) => state.Data.Status)
    const employeeId = useSelector((state) => state.Data.employeeId)
    const employeeSingleData = useSelector((state) => state.Data.employeeSingleData[0]);
    const userId = useSelector((state) => state.Data.userId)
    const [state, setState] = useState([])
    const [rowCount, setRowCount] = useState(0);

    const [value, setValue] = useState({
        Relation1: '', Relation2: '', Relation3: '', Relation4: '', Relation5: '', Relation6: '',
        RelationName1: '', RelationName2: '', RelationName3: '', RelationName4: '', RelationName5: '', RelationName6: '',
        RelationAge1: '', RelationAge2: '', RelationAge3: '', RelationAge4: '', RelationAge5: '', RelationAge6: '',
        RelationMobile1: '', RelationMobile2: '', RelationMobile3: '', RelationMobile4: '', RelationMobile5: '', RelationMobile6: '', ID: '', Updatedbyid: userId
    })
    const [errors, setErrors] = useState({ RelationError: '', AgeError: '' })
    const { RelationError, AgeError } = errors
    useEffect(() => {
        if (relationData.length === 0) dispatch(get_Relation_Drop_Data())
    }, [])

    useEffect(() => {
        if (employeeSingleData) {
            setValue(pre => {
                return {
                    ...pre, Relation1: employeeSingleData.Relation1, Relation2: employeeSingleData.Relation2, Relation3: employeeSingleData.Relation3, Relation4: employeeSingleData.Relation4, Relation5: employeeSingleData.Relation5, Relation6: employeeSingleData.Relation6,
                    RelationName1: employeeSingleData.RelationName1, RelationName2: employeeSingleData.RelationName2, RelationName3: employeeSingleData.RelationName3, RelationName4: employeeSingleData.RelationName4, RelationName5: employeeSingleData.RelationName5, RelationName6: employeeSingleData.RelationName6,
                    RelationAge1: employeeSingleData.RelationAge1, RelationAge2: employeeSingleData.employeeSingleData, RelationAge3: employeeSingleData.employeeSingleData, RelationAge4: employeeSingleData.employeeSingleData, RelationAge5: employeeSingleData.RelationAge5, RelationAge6: employeeSingleData.RelationAge6,
                    RelationMobile1: employeeSingleData.employeeSingleData, RelationMobile2: employeeSingleData.RelationMobile2, RelationMobile3: employeeSingleData.RelationMobile3, RelationMobile4: employeeSingleData.RelationMobile4, RelationMobile5: employeeSingleData.RelationMobile5, RelationMobile6: employeeSingleData.RelationMobile6, ID: employeeSingleData.ID
                }
            })
        }
        for (let i = 1; i < 7; i++) {
            if (employeeSingleData[Relation + i]) {
                setRowCount(i)
            }
        }
    }, [employeeSingleData])

    useEffect(() => {
        if (rowCount > 0) {
            const newArray = [];
            for (let i = 1; i <= rowCount; i++) {
                newArray.push({
                    id: i,
                    [Relation + i]: employeeSingleData[Relation + i],
                    [RelationName + i]: employeeSingleData[RelationName + i],
                    [RelationAge + i]: employeeSingleData[RelationAge + i],
                    [RelationMobile + i]: employeeSingleData[RelationMobile + i]
                })
            }
            setState(newArray);
        }
    }, [rowCount])

    const handleChange = (idx, type) => e => {
        const upd_obj = state.map(obj => {
            if (obj.id == idx) {
                if (type === 'Drop') {
                    obj[Relation + idx] = e.label;
                    setValue(pre => { return { ...pre, [Relation + idx]: e.label } })
                } 
                else {
                    obj[e.target.name] = e.target.value;
                    setValue(pre => { return { ...pre, [e.target.name]: e.target.value } })
                }
            }
            return obj;
        })
        setState(upd_obj);
    };

    const handleAddRow = () => {
        const item = {
            id: state.length + 1,
            [Relation + (state.length + 1)]: "",
            [RelationName + (state.length + 1)]: "",
            [RelationAge + (state.length + 1)]: "",
            [RelationMobile + (state.length + 1)]: ""
        };
        if (state.length < 6) {
            setState([...state, item]);
        }
    };

    const handleRemoveSpecificRow = (idx) => () => {
        const rows = [...state]
        const data = rows.filter(function (element) {
            return element.id !== idx;
        });
        setState(data);
        setValue(pre => { return { ...pre, [Relation + idx]: '', [RelationName + idx]: '', [RelationAge + idx]: '', [RelationMobile + idx]: '' } })
    }

    // Check validation on Field
    const check_Validation_Error = (e) => {
        e.preventDefault()
        // if (SpaceNotAllow(value.Relation)) {
        //     setErrors(prevValues => { return { ...prevValues, ['RelationError']: SpaceNotAllow(value.Relation) } })
        //     dispatch({ type: Get_Error_Status, payload: true })
        // }
        // if (SpaceNotAllow(value.RelationAge)) {
        //     setErrors(prevValues => { return { ...prevValues, ['AgeError']: SpaceNotAllow(value.RelationAge) } })
        //     dispatch({ type: Get_Error_Status, payload: true })
        // }
        const res = state.filter((element, id) => {
            return element[Relation + (id + 1)] === '' || element[Relation + (id + 1)] === null;
        });
        if (res.length === 0) {
            if (pageStatus) dispatch(update_Employee_Family(value, employeeId))
        } else toastifyError(' Relation Field is Empty')
    }

    // custuom style withoutColor
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Family Details</p>
                    <div style={{ marginLeft: 'auto' }}>
                        <button onClick={handleAddRow} className="btn btn-sm btn-success px-2 py-0 ml-auto">
                            <i className='fa fa-plus'></i>
                        </button>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 col-md-12 col-lg-12" >
                        <table class="table table-bordered" >
                            <thead style={{ backgroundColor: 'blanchedalmond ' }}>
                                <tr>
                                    <th >S.no</th>
                                    <th>Relation</th>
                                    <th>Name</th>
                                    <th>Age</th>
                                    <th>Mobile</th>
                                    <th className='text-right'>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {state?.map((item, idx) => (
                                    <tr id="addr0" key={idx}>
                                        <td className='payment-serial pt-3'>{idx + 1}</td>
                                        <td>
                                            <div className="dropdown__table">
                                                <Select
                                                    name={Relation + idx + 1}
                                                    styles={customStylesWithOutColor}
                                                    isClearable
                                                    placeholder="Select.."
                                                    onChange={handleChange(item.id, 'Drop')}
                                                    options={relationData}
                                                    value={relationData?.filter((obj) => obj.label === value[Relation + item.id])}
                                                />
                                            </div>
                                            {errors.RelationError !== 'true' ? (
                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.RelationError}</span>
                                            ) : null}
                                        </td>

                                        <td className='payment-amount1'>
                                            <div className="text-field">
                                                <input
                                                    type="text"
                                                    id='RelationName1'

                                                    name={`RelationName${idx + 1}`}
                                                    value={item[RelationName + item.id]}
                                                    onChange={handleChange(item.id)}
                                                    required
                                                />
                                            </div>
                                        </td>
                                        <td className='payment-amount1'>
                                            <div className="text-field">
                                                <input
                                                    type="text"
                                                    className='requiredColor'
                                                    name={`RelationAge${idx + 1}`}
                                                    value={item[RelationAge + item.id]}
                                                    onChange={handleChange(item.id)}
                                                    required
                                                />
                                            </div>
                                            {errors.AgeError !== 'true' ? (
                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.AgeError}</span>
                                            ) : null}
                                        </td>
                                        <td className='payment-amount1'>
                                            <div className="text-field">
                                                <input
                                                    type="text"
                                                    name={`RelationMobile${idx + 1}`}
                                                    value={item[RelationMobile + item.id]}
                                                    onChange={handleChange(item.id)}
                                                    required
                                                />
                                            </div>
                                        </td>
                                        <td className='payment-button ' style={{ paddingTop: '22px' }}>
                                            <button
                                                className="btn btn-outline-danger btn-sm"

                                                onClick={handleRemoveSpecificRow(item.id)}
                                                style={{ fontSize: '12px' }} >
                                                <i className="fa fa-trash"></i>
                                            </button>
                                        </td>

                                    </tr>

                                ))}
                            </tbody>
                        </table>

                        <div className="btn-box text-end col-12 col-md-12 col-lg-12 ">
                            <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FamilyDetails