import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Select from "react-select";
import { getShowingDayWithOutTime, getShowingWithOutTimeStartDate } from '../../../Include/Common/Utility';
import DatePicker from 'react-datepicker'
import MasSideBar from '../../../Include/Common/MasSideBar';
import { useDispatch, useSelector } from 'react-redux';
import { get_BenchNo_Drop_Data, get_Pit_Drop_Data, get_Site_Direction_Drop_Data, save_Blast_Entry_Data, update_Blast_Data } from '../../../../redux/actions/AllAction';
import { RequiredFieldSelectBox, SpaceNotAllow, SpaceNotAllowWithoutReq } from '../../../Validation/Validation';
import { Get_Error_Status } from '../../../../redux/actionTypes';
import { changeArrayFormat, changeArrayFormatDrop } from '../../../Include/Common/ArrayFormat';

const BlastEntry_Add_Up = (props) => {
    const dispatch = useDispatch()
    const { status, pageStatus } = props
    const pitblockDropdata = changeArrayFormatDrop(useSelector((state) => state.Data.pitblockDropdata),'Description','ID');
    const benchNoData = changeArrayFormatDrop(useSelector((state) => state.Data.benchNoData),'Bench','ID');
    const userId = useSelector((state) => state.Data.userId)
    const siteDirectionDrpData = changeArrayFormatDrop(useSelector((state) => state.Data.siteDirectionDrpData),'Description','ID');
    const editUpdateCount = useSelector((state) => state?.Data?.editUpdateCount);
    const singleBlastData = useSelector((state) => state.Data.singleBlastData[0]);
    const modalStatus = useSelector((state) => state.Data.modalStatus)
    const [urlName, setUrlName] = useState('')
    const [showCol, setShowCol] = useState('')
    const [idName, setIdName] = useState('')
    const [actionType, setActionType] = useState('')

    useEffect(() => {
        if (pitblockDropdata.length === 0) dispatch(get_Pit_Drop_Data());
        if (benchNoData.length === 0) dispatch(get_BenchNo_Drop_Data());
        if (siteDirectionDrpData.length === 0) dispatch(get_Site_Direction_Drop_Data());
        // dispatch(get_Pit_Block_Data());
    }, []);

    const [value, setValue] = useState({
        BenchNoID: '',
        BenchNo: '',
        Pit: '',
        PitID: '',
        SiteDirection: '',
        SiteDirectionID: '',
        HoleDiameter: '',
        Holedepth: '',
        BenchHeight: '',
        Burden: '',
        Spacing: '',
        TotalNoOfHole: '',
        NumbersofRow: '',
        Remarks: '',
        BlastNo: '',
        BlastingDate: '',
        BlastingDate1: '',

        'NumbersofHoles': '',
        'Gravity': '',
        'ExpProd': '',
        'ActProd': '',
    });

    const [errors, setErrors] = useState({
        'BenchNoError': '',
        'PitNoError': '',
        'siteError': '',
        'BlastNoError': '',
        'BlastingError': ''
    })


    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (RequiredFieldSelectBox(value.BenchNo)) {
            setErrors(prevValues => { return { ...prevValues, ['BenchNoError']: RequiredFieldSelectBox(value.BenchNo) } });
            dispatch({ type: Get_Error_Status, payload: true });
        }
        if (RequiredFieldSelectBox(value.Pit)) {
            setErrors(prevValues => { return { ...prevValues, ['PitNoError']: RequiredFieldSelectBox(value.Pit) } });
        }
        if (RequiredFieldSelectBox(value.SiteDirection)) {
            setErrors(prevValues => { return { ...prevValues, ['siteError']: RequiredFieldSelectBox(value.SiteDirection) } });
        }
        if (SpaceNotAllow(value.BlastNo)) {
            setErrors(prevValues => { return { ...prevValues, ['BlastNoError']: SpaceNotAllow(value.BlastNo) } });
        }
        if (SpaceNotAllow(value.BlastingDate)) {
            setErrors(prevValues => { return { ...prevValues, ['BlastingError']: SpaceNotAllow(value.BlastingDate) } });
        }
    };

    const { BenchNoError, PitNoError, siteError, BlastNoError, BlastingError } = errors;
      
    useEffect(() => {
        if (BenchNoError === 'true' && PitNoError === 'true' && siteError === 'true' && BlastNoError === 'true' && BlastingError === 'true') {
            if (status) { dispatch(update_Blast_Data(value, pageStatus)); setErrors({ ['HoleDiameterError']: '' }) }
            else { dispatch(save_Blast_Entry_Data(value, pageStatus)); setErrors({ ['HoleDiameterError']: '' }); }
              
        };
    }, [BenchNoError, PitNoError, siteError, BlastNoError, BlastingError]);

    const handleDropdown = (e, name, label) => {
        if (e) {
            setValue({
                ...value,
                [name]: e.value,
                [label]: e.label
            })
        } else {
            setValue({
                ...value,
                [name]: null,
                [label]: '',
            })
        }
    }

    const handleChange = (e) => {
        if (e.target.name === "HoleDiameter" || e.target.name === "Holedepth" || e.target.name === 'BenchHeight' || e.target.name === 'Burden' || e.target.name === "Spacing" || e.target.name === "TotalNoOfHole" || e.target.name === "NumbersofRow") {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setValue({
                ...value,
                [e.target.name]: checkNumber
            })
        }else 
        setValue({ ...value, [e.target.name]: e.target.value, });
    }

    useEffect(() => {
        if (singleBlastData) {
            setValue(pre => {
                return {
                    ...pre,
                    BenchNoID: singleBlastData.BenchNoID,
                    BenchNo: singleBlastData.BenchNo,
                    Pit: singleBlastData.Pit,
                    BlastNo: singleBlastData.BlastNo,
                    PitID: singleBlastData.PitID,
                    SiteDirection: singleBlastData.SiteDirection,
                    SiteDirectionID: singleBlastData.SiteDirectionID,
                    HoleDiameter: singleBlastData.HoleDiameter,
                    Holedepth: singleBlastData.Holedepth,
                    BenchHeight: singleBlastData.BenchHeight,
                    Burden: singleBlastData.Burden,
                    Spacing: singleBlastData.Spacing,
                    TotalNoOfHole: singleBlastData.TotalNoOfHole,
                    NumbersofRow: singleBlastData.NumbersofRow,
                    Remarks: singleBlastData.Remarks,
                    BlastingDate: singleBlastData?.BlastingDate,
                    BlastingDate1: singleBlastData?.BlastingDate?.split("/")?.reverse()?.join("/"),
                    ID: singleBlastData.ID
                }
            })
        } else resetHooks()
    }, [singleBlastData, editUpdateCount])

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    const startRef = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
        }
    };
    const resetHooks = () => {
        setValue(pre => {
            return {
                ...pre, BenchNoID: '', BenchNo: '', Pit: '', PitID: '', SiteDirection: '', SiteDirectionID: '', HoleDiameter: '', Holedepth: '', BenchHeight: '', Burden: '', Spacing: '', TotalNoOfHole: '', NumbersofRow: '', Remarks: '', BlastingDate: '',
                BlastingDate1: '', NumbersofHoles: '', Gravity: '', ExpProd: '', ActProd: '',    // use to modify date
            }
        })
        setErrors(pre => { return { ...pre, BenchNoError: '', PitNoError: '', siteError: '', BlastNoError: '', BlastingError: '' } })
    }

    const setUrls = (idName, urlName, showCol, actionType) => {
        setIdName(idName); setUrlName(urlName); setShowCol(showCol); setActionType(actionType)
    }

    return (
        <>
            {
                modalStatus &&
                <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="BlastEntryModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="m-1 ">
                                    <fieldset style={{ border: '1px solid gray' }}>
                                        <legend >Blast Entry</legend>
                                        <div className="form-row" style={{ marginTop: '-10px' }}>
                                            <div className="col-12 col-md-12 col-lg-6 d-flex px-0">
                                                <div className="col-11 col-md-11 col-lg-10 mt-1">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            name='BenchNoID'
                                                            value={benchNoData?.filter((obj) => obj.value?.toString() === value?.BenchNoID?.toString())}
                                                            options={benchNoData}
                                                            onChange={(e) => handleDropdown(e, 'BenchNoID', 'BenchNo')}
                                                            isClearable
                                                            styles={colourStyles}
                                                            placeholder="Select.."
                                                        />
                                                        <label htmlFor="" className='pl-0'>Bench No.
                                                            <span className="form-required ">*</span>
                                                        </label>
                                                        {errors.BenchNoError !== "true" ? (
                                                            <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.BenchNoError} </span>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-6 d-flex px-0">
                                                <div className="col-11 col-md-11 col-lg-10 mt-1">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            name='PitID'
                                                            value={pitblockDropdata?.filter((obj) => obj.value?.toString() === value?.PitID?.toString())}
                                                            options={pitblockDropdata}
                                                            onChange={(e) => handleDropdown(e, 'PitID', 'Pit')}
                                                            isClearable
                                                            styles={colourStyles}
                                                            placeholder="Select.."
                                                        />
                                                        <label htmlFor="" className='pl-0'>PIT No.
                                                            <span className="form-required ">*</span>
                                                        </label>
                                                        {errors.PitNoError !== "true" ? (
                                                            <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.PitNoError} </span>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-1 col-md-1 col-lg-1   px-0 py-0" style={{ marginTop: '17px' }}>
                                                    <Link to="/Mining?sub=Blast%20Entry" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal" onClick={() =>
                                                        setUrls('ID', 'MiningPit', 'Description', 'Get_PitBlock_Dropdown')} >
                                                        <i className="fa fa-plus"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-6 d-flex px-0">
                                                <div className="col-11 col-md-11 col-lg-10 mt-3">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            name='SiteDirectionID'
                                                            value={siteDirectionDrpData?.filter((obj) => obj.value?.toString() === value?.SiteDirectionID?.toString())}
                                                            options={siteDirectionDrpData}
                                                            onChange={(e) => handleDropdown(e, 'SiteDirectionID', 'SiteDirection')}
                                                            isClearable
                                                            styles={colourStyles}
                                                            placeholder="Select.."
                                                        />
                                                        <label htmlFor="" className='pl-0'>Site Direction</label>
                                                    </div>
                                                    {errors.siteError !== "true" ? (
                                                        <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.siteError} </span>
                                                    ) : null}
                                                </div>
                                                <div className="col-1 col-md-1 col-lg-1   px-0 py-0" style={{ marginTop: '30px' }}>
                                                    <Link to="/Mining?sub=Blast%20Entry" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal" onClick={() =>
                                                        setUrls('ID', 'MiningSiteDirection', 'Description', 'Get_Site_Direction_Dropdown')} >
                                                        <i className="fa fa-plus"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-5" style={{ marginTop: '16px' }}>
                                                <div class="text-field">
                                                    <input type="text" name='BlastNo' id='BlastNo' onChange={handleChange} value={value.BlastNo} className="requiredColor" required />
                                                    <label className='pt-1'>Blast No.</label>

                                                </div>
                                                {errors.BlastNoError !== "true" ? (
                                                    <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.BlastNoError} </span>
                                                ) : null}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-5 pt-3">
                                                <div className="dropdown__box">
                                                    <DatePicker
                                                        id='BlastingDate'
                                                        name='BlastingDate'
                                                        className='requiredColor'
                                                        ref={startRef}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => date ? setValue(pre => { return { ...pre, ['BlastingDate']: getShowingDayWithOutTime(date), ['BlastingDate1']: getShowingWithOutTimeStartDate(date) } }) :
                                                            setValue(pre => { return { ...pre, ['BlastingDate']: '', ['BlastingDate1']: '' } })
                                                        }
                                                        dateFormat="dd/MM/yyyy"
                                                        timeInputLabel
                                                        isClearable
                                                        selected={value.BlastingDate1 && new Date(value.BlastingDate1)}
                                                        placeholderText={'Select...'}
                                                        dropdownMode="select"
                                                        minDate={new Date()}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                    <label htmlFor="" className='pl-0 pt-1' >Blasting Date</label>
                                                </div>
                                                {errors.BlastingError !== "true" ? (
                                                    <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.BlastingError} </span>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-1">
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-5" style={{ marginTop: '19px' }}>
                                                <div class="text-field">
                                                    <input type="text" name='HoleDiameter' id='HoleDiameter' value={value?.HoleDiameter} onChange={handleChange} className="" required />
                                                    <label className='pt-1'>Hole Diameter In MM:</label>
                                                    {errors.HoleDiameterError !== "true" ? (
                                                        <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.HoleDiameterError} </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-5" style={{ marginTop: '15px' }}>
                                                <div class="text-field">
                                                    <input type="text" name='Holedepth' id='Holedepth' value={value?.Holedepth} onChange={handleChange} className="" required />
                                                    <label className='pt-1'>Hole Depth In Mtrs:</label>
                                                    {errors.HoledepthError !== "true" ? (
                                                        <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.HoledepthError} </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-5" style={{ marginTop: '15px' }}>
                                                <div class="text-field">
                                                    <input type="text" name='BenchHeight' id='BenchHeight' value={value?.BenchHeight} onChange={handleChange} className="" required />
                                                    <label className='pt-1'>Bench Height In Mtrs:</label>
                                                    {errors.BenchHeightError !== "true" ? (
                                                        <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.BenchHeightError} </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-5" style={{ marginTop: '15px' }}>
                                                <div class="text-field">
                                                    <input type="text" name='Burden' id='Burden' value={value?.Burden} onChange={handleChange} className="" required />
                                                    <label className='pt-1'>Burden In Mtrs:</label>
                                                    {errors.BurdenError !== "true" ? (
                                                        <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.BurdenError} </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-5" style={{ marginTop: '15px' }}>
                                                <div class="text-field">
                                                    <input type="text" name='Spacing' id='Spacing' value={value?.Spacing} onChange={handleChange} className="" required />
                                                    <label className='pt-1'>Spacing In Mtrs:</label>
                                                    {errors.SpacingError !== "true" ? (
                                                        <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.SpacingError} </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-5" style={{ marginTop: '15px' }}>
                                                <div class="text-field">
                                                    <input type="text" name='TotalNoOfHole' id='TotalNoOfHole' value={value?.TotalNoOfHole} onChange={handleChange} className="" required />
                                                    <label className='pt-1'>Total No Of Hole:</label>
                                                    {errors.TotalNoOfHoleError !== "true" ? (
                                                        <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.TotalNoOfHoleError} </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-5" style={{ marginTop: '15px' }}>
                                                <div class="text-field">
                                                    <input type="text" name='NumbersofRow' id='NumbersofRow' value={value?.NumbersofRow} onChange={handleChange} className="" required />
                                                    <label className='pt-1'>Number Of Rows</label>
                                                    {errors.NumbersofRowError !== "true" ? (
                                                        <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.NumbersofRowError} </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12 mt-3" >
                                                <div className="dropdown__box">
                                                    <textarea name='Remarks' value={value?.Remarks} onChange={handleChange} id="Remarks" cols="30" rows='1' className="form-control"></textarea>
                                                    <label htmlFor="" className='pl-0'>Remarks </label>
                                                    {errors.RemarksError !== "true" ? (
                                                        <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.RemarksError} </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div className="btn-box text-right  mr-1 mb-2">
                                {status ? <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button> : <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Save</button>}
                                <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={resetHooks}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                urlName &&
                <MasSideBar
                    showCol1={showCol} idName={idName} urlName={urlName} actionType={actionType}
                    getUrl={`/${urlName}/GetData_${urlName}`}
                    addUrl={`/${urlName}/Insert${urlName}`}
                    singleDataUrl={`/${urlName}/GetSingleData_${urlName}`}
                    upUrl={`/${urlName}/Update${urlName}`}
                    delUrl={`/${urlName}/Delete${urlName}`}
                />
            }
        </>
    )
}

export default BlastEntry_Add_Up