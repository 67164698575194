import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Select from "react-select";
import MasSideBar from '../../../Include/Common/MasSideBar';
import { get_ExlosiveType_Drop_Data, get_Party_Sale_Data, get_Unit_Drop_Data, get_Unit_Dropdown, save_Explosive_Data, update_Explosive_Data } from '../../../../redux/actions/AllAction';
import { RequiredFieldSelectBox, SpaceNotAllow, SpaceNotAllowWithoutReq } from '../../../Validation/Validation';
import { Get_Error_Status, MasterTable_Modal_Status } from '../../../../redux/actionTypes';
import { toastifyError } from '../../../Include/Common/AlertMsg';
import { changeArrayFormatDrop } from '../../../Include/Common/ArrayFormat';

const ExplosiveExtry_Add_Up = (props) => {
    const dispatch = useDispatch();
    const { status, pageStatus } = props
    const userId = useSelector((state) => state.Data.userId);
    const unitDropdown = changeArrayFormatDrop(useSelector((state) => state.Data.unitDropdown),'UnitType','ID');
    const partySaleData = changeArrayFormatDrop(useSelector((state) => state.Data.partySaleData), 'Name', 'Id');
    const exlosiveTypeDropData = changeArrayFormatDrop(useSelector((state) => state.Data.exlosiveTypeDropData),'Description','ID');
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount);
    const modalStatus = useSelector((state) => state.Data.modalStatus)
    const singleExplosiveData = useSelector((state) => state.Data.singleExplosiveData[0]);
    const [urlName, setUrlName] = useState('')
    const [showCol, setShowCol] = useState('')
    const [idName, setIdName] = useState('')
    const [actionType, setActionType] = useState('')
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }
    const [value, setValue] = useState({
        SupplierName: '',
        SupplierID: '',
        ExplosiveName: '',
        ExplosiveTypeID: '',
        ExplosiveType: '',
        UnitTypeID: '',
        UnitType: '',
        ExplosiveRate: '',
        Remarks: '',
        Updatedbyid: '',
        Createdbyid: userId
    });
    const [errors, setErrors] = useState({
        ExplosiveError: '', RateError: '', RemarkError: ''
    })
    const [value1, setValue1] = useState({
        IsLedger: '1',
        Iscommon: '0',
        IsUnRegular: '0',
        IsSupplier: '0',
        Iscredit: '0',
        IsActive: "1",
    })

    const { ExplosiveError, SupplierError} = errors;
    const handelChange = (e) => {
        if (e.target.name === 'ExplosiveRate') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setValue({
                ...value,
                [e.target.name]: checkNumber
            })
        } else
            setValue({
                ...value,
                [e.target.name]: e.target.value,
            });
    }

    useEffect(() => {
        if (ExplosiveError === 'true' && SupplierError === 'true' ) {
            if (status) { dispatch(update_Explosive_Data(value, pageStatus)); }
            else { dispatch(save_Explosive_Data(value, pageStatus)); }
        }
    }, [ExplosiveError, SupplierError]);

    useEffect(() => {
        dispatch(get_Unit_Dropdown());
        dispatch(get_ExlosiveType_Drop_Data())
    }, []);
    useEffect(() => {
        dispatch(get_Party_Sale_Data(value1));
    }, [value1])
    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.ExplosiveName)) {
            setErrors(prevValues => { return { ...prevValues, ['ExplosiveError']: SpaceNotAllow(value.ExplosiveName) } });
            dispatch({ type: Get_Error_Status, payload: true });
        }
        if (SpaceNotAllow(value.SupplierName)) {
            setErrors(prevValues => { return { ...prevValues, ['SupplierError']: SpaceNotAllow(value.SupplierName) } });
        }
    };

    const handleDropdown = (e, Name) => {
        if (Name === 'UnitTypeID') {
            setValue(pre => { return { ...pre, ['UnitTypeID']: e?.value, ['UnitType']: e?.label } })
        } else if (Name === 'ExplosiveTypeID') {
            setValue(pre => { return { ...pre, ['ExplosiveTypeID']: e?.value, ['ExplosiveType']: e?.label } })
        } else if (Name === 'SupplierID') {
            setValue(pre => { return { ...pre, ['SupplierID']: e?.value, ['SupplierName']: e?.label } })
        }
    }

    useEffect(() => {
        if (singleExplosiveData) {
            setValue({
                ...value, SupplierName: singleExplosiveData.SupplierName, SupplierID: singleExplosiveData.SupplierID, ExplosiveName: singleExplosiveData.ExplosiveName, ExplosiveTypeID: singleExplosiveData.ExplosiveTypeID, ExplosiveType: singleExplosiveData.ExplosiveType, UnitTypeID: singleExplosiveData.UnitTypeID, UnitType: singleExplosiveData.UnitType, ExplosiveRate: singleExplosiveData.ExplosiveRate, Remarks: singleExplosiveData.Remarks, Updatedbyid: userId, ID: singleExplosiveData?.ID
            })
        }
        else {
            setValue({
                ...value, SupplierName: '', SupplierID: '', ExplosiveName: '', ExplosiveTypeID: '', ExplosiveType: '', UnitTypeID: '', UnitType: '', ExplosiveRate: '', Remarks: '', Updatedbyid: '',
            })
        }
    }, [singleExplosiveData, editUpdateCount])

    const setUrls = (idName, urlName, showCol) => {
        setIdName(idName); setUrlName(urlName); setShowCol(showCol); setActionType(actionType)
    }
    const resetHooks = () => {
        setValue(pre => {
            return {
                ...pre, SupplierName: '', SupplierID: '', ExplosiveName: '', ExplosiveTypeID: '', ExplosiveType: '', UnitTypeID: '', UnitType: '', ExplosiveRate: '', Remarks: '',
            }
        })
        setErrors(pre => { return { ...pre, ExplosiveError: '', SupplierError: '', RateError: '', RemarkError: '' } })
    }
    return (
        <>
            {modalStatus &&
                <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="ExplosiveExtryModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="m-1 ">
                                    <fieldset style={{ border: '1px solid gray' }}>
                                        <legend >Explosive Extry</legend>
                                        <div className="form-row" style={{ marginTop: '-15px' }}>
                                            <div className="col-12 col-md-12 col-lg-5 " style={{ marginTop: '10px' }}>
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='ExplosiveName'
                                                        className="requiredColor"
                                                        id='ExplosiveName'
                                                        required
                                                        onChange={handelChange}
                                                        value={value.ExplosiveName}
                                                    />
                                                    <label className='pt-1'>Explosive Name</label>
                                                    {errors.ExplosiveError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.ExplosiveError}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-1 col-md-1 col-lg-1 mt-1">
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-6 d-flex px-0 pt-1">
                                                <div className="col-11 col-md-11 col-lg-11 mt-1">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            name='SupplierID'
                                                            styles={colourStyles}
                                                            value={partySaleData?.filter((obj) => obj?.label === value?.SupplierName)}
                                                            options={partySaleData}
                                                            isClearable
                                                            placeholder="Select.."
                                                            onChange={(e) => handleDropdown(e, 'SupplierID')}
                                                        />
                                                        <label htmlFor="">Supplier</label>
                                                    </div>
                                                    {errors.SupplierError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.SupplierError}
                                                        </span>
                                                    ) : null}
                                                </div>
                                                <div className="col-1 col-md-1 col-lg-1   px-0 py-0" style={{ marginTop: '17px' }}>
                                                    <Link to="/Mining?sub=Explosive%20Extry" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal"
                                                    onClick={() =>
                                                        setUrls('ID', 'Party', 'Name', 'get_Party_Sale_Data')}
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-6 d-flex px-0 pt-1">
                                                <div className="col-11 col-md-11 col-lg-10 mt-1">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            name='UnitType'
                                                            styles={customStylesWithOutColor}
                                                            isClearable
                                                            placeholder="Select.."
                                                            onChange={(e) => handleDropdown(e, 'ExplosiveTypeID')}
                                                            value={exlosiveTypeDropData?.filter((obj) => obj?.label === value?.ExplosiveType)}
                                                            options={exlosiveTypeDropData}
                                                        />
                                                        <label htmlFor="">Type</label>
                                                    </div>
                                                </div>
                                                <div className="col-1 col-md-1 col-lg-1   px-0 py-0" style={{ marginTop: '17px' }}>
                                                    <Link to="/Mining?sub=Explosive%20Extry" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal"
                                                        onClick={() =>
                                                            setUrls('ID', 'MiningExplosiveType', 'Description', 'Get_ExplosiveType_Dropdown')}
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-6 d-flex px-0 pt-1">
                                                <div className="col-11 col-md-11 col-lg-11 mt-1">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            name='Unit'
                                                            styles={customStylesWithOutColor}
                                                            isClearable
                                                            placeholder="Select.."
                                                            onChange={(e) => handleDropdown(e, 'UnitTypeID')}
                                                            value={unitDropdown?.filter((obj) => obj?.label === value?.UnitType)}
                                                            options={unitDropdown}
                                                        />
                                                        <label htmlFor="">Unit</label>
                                                    </div>
                                                </div>
                                                <div className="col-1 col-md-1 col-lg-1   px-0 py-0" style={{ marginTop: '17px' }}>

                                                    <Link to="/Mining?sub=Explosive%20Extry" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal"
                                                        onClick={() =>
                                                            setUrls('ID', 'UnitType', 'UnitType', 'Get_Unit_DropDown')}
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6" style={{ marginTop: '10px' }}>
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='ExplosiveRate'
                                                        className=""
                                                        id='ExplosiveRate'
                                                        required
                                                        onChange={handelChange}
                                                        value={value.ExplosiveRate}
                                                    />
                                                    <label className='pt-1'>Rate</label>
                                                    {errors.RateError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.RateError}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12 mt-1 pt-1" >
                                                <div className="dropdown__box">
                                                    <textarea name='Remarks' id="Remarks"
                                                        cols="30" rows='1' className="form-control " value={value.Remarks} onChange={handelChange}></textarea>
                                                    <label htmlFor="">Explosive Description </label>
                                                    {errors.RemarkError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.RemarkError}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div className="btn-box text-right  mr-1 mb-2">
                                {status ? <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button> : <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Save</button>}

                                <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={resetHooks} >Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                urlName &&
                <MasSideBar
                    showCol1={showCol} idName={idName} urlName={urlName} actionType={actionType}
                    getUrl={`/${urlName}/GetData_${urlName}`}
                    addUrl={`/${urlName}/Insert${urlName}`}
                    singleDataUrl={`/${urlName}/GetSingleData_${urlName}`}
                    upUrl={`/${urlName}/Update${urlName}`}
                    delUrl={`/${urlName}/Delete${urlName}`}
                />
            }

        </>
    )
}

export default ExplosiveExtry_Add_Up