import React from 'react'

const ChallanPending = () => {
  return (
   <>
   <div>Challan Pending</div>
   </>
  )
}

export default ChallanPending