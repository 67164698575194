import React, { useEffect, useRef, useState } from 'react'
import Select from "react-select";
import DatePicker from 'react-datepicker'
import { get_Employee_Attendance_Data, get_Single_Employee_Data ,get_Employee_Data} from '../../../../redux/actions/AllAction';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Decrypt_Id_Name } from '../../../Include/Common/Utility';
import { useReactToPrint } from "react-to-print";
import { Edit_Update_Count, Employee_ID, Get_Emp_Attendance, Get_Month, Get_Year, Status } from '../../../../redux/actionTypes';
import { changeArrayFormatDrop } from '../../../Include/Common/ArrayFormat';


const month = [
    { label: "January", value: '01' },
    { label: "February", value: '02' },
    { label: "March", value: '03' },
    { label: "April", value: '04' },
    { label: "May", value: '05' },
    { label: "June", value: '06' },
    { label: "July", value: '07' },
    { label: "August", value: '08' },
    { label: "September", value: '09' },
    { label: "October", value: '10' },
    { label: "November", value: '11' },
    { label: "December", value: '12' }
];


class ComponentToPrint extends React.Component {
    constructor(props) {
        super(props);
    };

    render() {
        const { days, value, setEditStatus } = this.props
        return (
            <>
                <div className="col-12 col-md-12 col-lg-12 pt-2 px-4 printElement1">
                    <p className='mb-1 mt-2' style={{ borderBottom: '1px solid black', fontSize: '16px' }}>
                        Montly Attendance Report {value.Year && value.month + '-' + value.Year?.getFullYear()}
                    </p>
                    <h5 className='pt-2 mt-2' style={{ borderBottom: '1px solid black', marginTop: '-10px', }}>
                        {sessionStorage.getItem('FirmName') && Decrypt_Id_Name(sessionStorage.getItem('FirmName'), 'FiForUserFirmName')}
                    </h5>
                    <div className="table-responsive">
                        <table className="table mt-2" style={{ width: '100%' }}>
                            <thead >
                                <tr className='table-static'>
                                    <th className='col-3 col-md-3 col-lg-1'>Name</th>
                                    <th>1</th>
                                    <th>2</th>
                                    <th>3</th>
                                    <th>4</th>
                                    <th>5</th>
                                    <th>6</th>
                                    <th>7</th>
                                    <th>8</th>
                                    <th>9</th>
                                    <th>10</th>
                                    <th>11</th>
                                    <th>12</th>
                                    <th>13</th>
                                    <th>14</th>
                                    <th>15</th>
                                    <th>16</th>
                                    <th>17</th>
                                    <th>18</th>
                                    <th>19</th>
                                    <th>20</th>
                                    <th>21</th>
                                    <th>22</th>
                                    <th>23</th>
                                    <th>24</th>
                                    <th>25</th>
                                    <th>26</th>
                                    <th>27</th>
                                    {days === 28 && <th>28</th>}
                                    {days === 29 && <> <th>28</th> <th>29</th> </>}
                                    {days === 30 && <> <th>28</th> <th>29</th> <th>30</th> </>}
                                    {days === 31 && <> <th>28</th> <th>29</th> <th>30</th> <th>31</th> </>}
                                    <th>Present</th>
                                    <th className='text-right'>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.empAttendanceData?.map((obj) => (
                                        <tr>
                                            <Link className='attendance-name' to='/employeetab?sub=Attendance' onClick={() => setEditStatus(obj)} style={{ cursor: 'pointer' }}><td className='col-3 col-md-3 col-lg-1 ' style={{ textDecoration: 'none' }}>{obj.Name}</td></Link>
                                            <td>{obj[1]}</td>
                                            <td>{obj[2]}</td>
                                            <td>{obj[3]}</td>
                                            <td>{obj[4]}</td>
                                            <td>{obj[5]}</td>
                                            <td>{obj[6]}</td>
                                            <td>{obj[7]}</td>
                                            <td>{obj[8]}</td>
                                            <td>{obj[9]}</td>
                                            <td>{obj[10]}</td>
                                            <td>{obj[11]}</td>
                                            <td>{obj[12]}</td>
                                            <td>{obj[13]}</td>
                                            <td>{obj[14]}</td>
                                            <td>{obj[15]}</td>
                                            <td>{obj[16]}</td>
                                            <td>{obj[17]}</td>
                                            <td>{obj[18]}</td>
                                            <td>{obj[19]}</td>
                                            <td>{obj[20]}</td>
                                            <td>{obj[21]}</td>
                                            <td>{obj[22]}</td>
                                            <td>{obj[23]}</td>
                                            <td>{obj[24]}</td>
                                            <td>{obj[25]}</td>
                                            <td>{obj[26]}</td>
                                            <td>{obj[27]}</td>
                                            {days === 28 && <td>{obj[28]}</td>}
                                            {days === 29 && <> <td>{obj[28]}</td> <td>{obj[29]}</td> </>}
                                            {days === 30 && <> <td>{obj[28]}</td> <td>{obj[29]}</td> <td>{obj[30]}</td> </>}
                                            {days === 31 && <> <td>{obj[28]}</td> <td>{obj[29]}</td> <td>{obj[30]}</td> <td>{obj[31]}</td> </>}
                                            <td>{obj.PresentDays}</td>
                                            <td className='text-right'>
                                                <Link to={'/HR?sub=Attendance%20Report'} className="btn btn-sm bg-green text-white px-1 py-0 mr-3" style={{ fontSize: '10px', marginTop: '-5px' }}>
                                                    <i className="fa fa-edit"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* <div className="col-12">
                    <nav aria-label="Page navigation example" className='mr-4' style={{ float: 'right' }}>
                        <ul className="pagination">
                            <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item"><a className="page-link" href="#">Next</a></li>
                        </ul>
                    </nav>
                </div> */}
            </>
        );
    }
}


//useEffect(() => { setValue({ 'ID': taskID }) }, [taskID]);


const AttendanceReport = () => {
    const dispatch = useDispatch();
    const employeeData = changeArrayFormatDrop(useSelector((state) => state.Data.employeeData), 'EmpName', 'ID');
    const empAttendanceData = useSelector((state) => state.Data.empAttendanceData)
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const attendanceMonth = useSelector((state) => state.Data.attendanceMonth)
    const attendanceYear = useSelector((state) => state.Data.attendanceYear)
    const [empData, setEmpData] = useState({  EmpName: '', EmpID: '' })
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [value, setValue] = useState({
        month: '', Year: '',
    })
    const [days, setDays] = useState()
         
    useEffect(()=>{
        dispatch(get_Employee_Data('1'));
    },[])
     
    const handleChange = (e) => {
        if (e) {
            setValue(pre => { return { ...pre, month: e.value } })
            dispatch({ type: Get_Month, payload: e.value })
            if (value.Year) { dispatch(get_Employee_Attendance_Data(e.value, value?.Year?.getFullYear())); 
                setDays(new Date(value?.Year?.getFullYear(), e.value, 0).getDate()) }
        }
        else { setValue(pre => { return { ...pre, month: null, Year: null } }); dispatch({ type: Get_Emp_Attendance, payload: [] }); dispatch({ type: Get_Month, payload: null }) }
    }

    const handleChangeDate = (date) => {
        setDays(new Date(date?.getFullYear(), value.month, 0).getDate())
        dispatch({ type: Get_Year, payload: date })
        setValue(pre => { return { ...pre, Year: date } })
        dispatch(get_Employee_Attendance_Data(value.month, date?.getFullYear()))
    };

    const setEditStatus = (row) => {
        dispatch(get_Single_Employee_Data(row.EmpID)); 
        dispatch({ type: Status, payload: true }); 
        dispatch({ type: Employee_ID, payload: row.EmpID });
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
    }

    const customStylesWithOutColor = { control: (base) => ({ ...base, height: 31, minHeight: 30, fontSize: 14, margintop: 2, boxShadow: 0, fontWeight: 500, }), };

    const changeDropdown = e => {
        if (e) { setEmpData(pre => { return { ...pre, ['EmpID']: e?.value, ['EmpName']: e?.label } }); }
        else { setEmpData(pre => { return { ...pre, ['EmpID']: '', ['EmpName']: '' } }); };
    }
    return (
        <div>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Attendance Report</p>
                    <div style={{ marginLeft: 'auto' }}>
                        <Link to={'/HR?sub=Attendance%20Report'} onClick={handlePrint} className="btn btn-sm  text-white px-2 py-0"  >
                            <i className="fa fa-print"></i>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-4 mt-2">
                <div className="form-row">
                    <div className="col-4 col-md-4 col-lg-4  ">
                        <div className="dropdown__box">
                            <Select
                                name="EmployeeName"
                                styles={customStylesWithOutColor}
                                isClearable
                                onChange={changeDropdown}
                                value={employeeData?.filter((obj) => obj.value === empData.EmpID)}
                                options={employeeData}
                                placeholder="Select.."
                            />
                            <label htmlFor="" className='pl-0'>Employee Name</label>
                        </div>
                    </div>
                    <div className="col-4 col-md-3 col-lg-2" >
                        <div className="dropdown__box">
                            <Select
                                name="month"
                                styles={customStylesWithOutColor}
                                isClearable
                                onChange={handleChange}
                                value={month?.filter((obj) => obj.value === attendanceMonth)}
                                options={month}
                                placeholder="Select.."
                            />
                            <label htmlFor="" className='pl-0'>Month</label>
                        </div>
                    </div>
                    <div className="col-4 col-md-4 col-lg-2 " style={{ marginTop: '-4px' }}>
                        <div className="dropdown__box">
                            <DatePicker
                                isClearable={attendanceYear ? true : false}
                                selected={attendanceYear}
                                onChange={handleChangeDate}
                                showYearPicker
                                minDate={new Date()}
                                dateFormat="yyyy"
                            />
                            <label htmlFor="" className='pt-1'>Year</label>
                        </div>
                    </div>
                </div>
            </div>
            <ComponentToPrint ref={componentRef} {...{ days, value, empAttendanceData, days, setEditStatus }} />
        </div>
    );
};
export default AttendanceReport
