import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux'
import { delete_ServiceConfiguration_Data, get_ServiceConfiguration_Data, get_ServiceConfiguration_Dropdown, get_Single_ServiceConfiguration_Data, save_Service_Data, update_Service_Data } from '../../../../redux/actions/AllAction';
import ConfirmModal from '../../../Include/Common/ConfirmModal';
import { Modal_Status, Edit_Update_Count } from '../../../../redux/actionTypes';

const ServiceConfiguration = () => {
    const dispatch = useDispatch();
    const [IsActive, setIsActive] = useState()
    const [editType, setEditType] = useState('')
    const [pageStatus, setPageStatus] = useState("1")
    const [status, setStatus] = useState(false)
    const userId = useSelector((state) => state.Data.userId);

    const [confirmType, setConfirmType] = useState('')
    const [Serviceid, setServiceid] = useState('')
    const filterServiceData = useSelector((state) => state.Data.serviceConfigurationData)
    const ServiceDropDown = useSelector((state) => state.Data.serviceConfigurationDropdown);
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const singleServiceConfigurationData = useSelector((state) => state.Data.singleServiceConfigurationData)

    const [errors, setErrors] = useState({})
    const [Checked, setChecked] = useState(true)

    const [value, setValue] = useState({
        'LedgerID': '', 'ServiceIn': '', 'ServiceValue': '', 'IsAfter': '', 'ServiceNextValue': '',
        'Remarks': '', 'ID': '', 'Companyid': '', 'Createdbyid': userId,
    });

    const Handlechange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        });
    };
    const HandleService = (e) => {
        let isChecked = e.target.checked;
        setValue({
            ...value,
            [e.target.name]: isChecked ? true : false
        });
        setChecked(isChecked ? false : true)
    }
    const HandleDropdown = (e, name) => {
        setValue(pre => { return { ...pre, [name]: e?.value } })
    };
    useEffect(() => {
        dispatch(get_ServiceConfiguration_Dropdown(true));

    }, []);

    useEffect(() => {
        dispatch(get_ServiceConfiguration_Data(pageStatus))
    }, []);

    useEffect(() => {
        if (singleServiceConfigurationData) {
            setValue({
                ...value,
                LedgerID: singleServiceConfigurationData[0]?.LedgerID,
                ServiceIn: Number(singleServiceConfigurationData[0]?.ServiceIn),
                ServiceNextValue: singleServiceConfigurationData[0]?.ServiceNextValue,
                IsAfter: singleServiceConfigurationData[0]?.IsAfter,
                ServiceValue: singleServiceConfigurationData[0]?.ServiceValue,
                Remarks: singleServiceConfigurationData[0]?.Remarks,
                ID: singleServiceConfigurationData[0]?.ID,
                Companyid: singleServiceConfigurationData[0]?.Companyid,
                Createdbyid: userId,
                Updatedbyid: userId
            })
        }
        else {
            setValue({
                ...value,
                LedgerID: '', ServiceIn: '', ServiceValue: '', Remarks: '',
                Companyid: '', ServiceNextValue: '', ID: '', Createdbyid: userId,
            })
        }
    }, [singleServiceConfigurationData, editUpdateCount])


    const setEditStatus = (row) => {
        dispatch(get_Single_ServiceConfiguration_Data(row.ID)); setServiceid(row.ID)
        setStatus(true);
        dispatch({ type: Modal_Status, payload: true })
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        
    }
    const UpdActiveDeactive = (row) => {
        dispatch(delete_ServiceConfiguration_Data(Serviceid, IsActive, userId, pageStatus))
         resetHooks()
    };
    const resetHooks = () => {
        setStatus(false)
        setValue(pre => {
            return {
                ...pre,
                LedgerID: '', ServiceIn: '', ServiceValue: '', ServiceNextValue: '', Remarks: '',
                IsAfter: '', Companyid: '', ID: '', Createdbyid: userId
            }
        })
        setErrors(pre => { return { ...pre } });
    }
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    };

    const columns = [
        {
            name: 'LedgerID',
            selector: (row) => row.LedgerID,
            sortable: true
        }, {
            name: 'ServiceValue',
            selector: (row) => row.ServiceValue,
            sortable: true
        }, {
            name: 'Remarks',
            selector: (row) => row.Remarks,
            sortable: true
        }, {
            name: 'ServiceNextValue',
            selector: (row) => row.ServiceNextValue,
            sortable: true
        }, {
            name: 'ID',
            selector: (row) => row.ID,
            sortable: true
        }, {
            name: 'ServiceIn',
            selector: (row) => row.ServiceIn,
            sortable: true
        }, {
            name: 'Createdbyid',
            selector: (row) => row.Createdbyid,
            sortable: true
        },

        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 35, fontWeight: '700' }}>Action</p>,
            cell: row => <>
                <div style={{ position: 'absolute', top: 4, right: 40 }}>

                    <button to={'#'} onClick={() => setEditStatus(row)}
                        className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn"  ><i className="fa fa-edit"></i>
                    </button>

                    <button to={'#'} data-toggle="modal" data-target="#ConfirmModal"
                        className="btn btn-sm bg-green text-white px-1 py-0 mr-1" onClick={(e) => { setServiceid(row.ID); setConfirmType(row.ID); setIsActive('0') }} >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                </div> 
            </>
        }
    ]

    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Service</p>
                </div>
                <div className="row mt-2">
                    <div className="col-6 col-md-6 col-lg-7 mt-1">
                        <div className="dropdown__box">
                            <Select
                                name='LedgerID'
                                styles={customStylesWithOutColor}
                                isClearable
                                placeholder="Select.."
                                value={ServiceDropDown?.filter((obj) => obj.value === value.LedgerID)}
                                options={ServiceDropDown}
                                onChange={(e) => HandleDropdown(e, 'LedgerID')}
                            />
                            <label htmlFor="">Account</label>
                        </div>
                    </div>
                    <div className="col-3 col-md-6 col-lg-5 mt-1 d-flex">
                        <div className="col-3 col-md-7 col-lg-7 pt-1 ">
                            <div class="text-field">
                                <input
                                    type="number"
                                    name='ServiceValue'
                                    className=""
                                    id='ServiceValue'
                                    required
                                    value={value.ServiceValue}
                                    onChange={Handlechange}
                                />
                                <label className='pt-1'>Service Value</label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="dropdown__box">
                                <Select
                                    name='ServiceIn'
                                    styles={customStylesWithOutColor}
                                    isClearable
                                    placeholder="Select.."
                                    value={ServiceDropDown?.filter((obj) => obj.value === value.ServiceIn)}
                                    options={ServiceDropDown}
                                    onChange={(e) => HandleDropdown(e, 'ServiceIn')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-12  col-md-12 col-lg-12 mt-2">
                        <div className="dropdown__box">
                            <textarea
                                name="Remarks"
                                id="Remarks"
                                cols="30"
                                rows="2"
                                className="form-control"
                                value={value.Remarks}
                                onChange={Handlechange}
                            >
                            </textarea>
                            <label htmlFor="">Remarks</label>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-1">
                        <div class="form-check mt-2">
                            <input
                                type="checkbox"
                                name='IsAfter'
                                class="form-check-input"
                                onChange={(e) => HandleService(e)}
                                value={value.IsAfter}
                                id="flexCheckDefault" />
                            <label class="form-check-label" for="flexCheckDefault">
                                For Next
                            </label>
                        </div>
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 ">
                        <div class="text-field">
                            <input
                                type="number"
                                name='ServiceNextValue'
                                className=""
                                id='fornext'
                                required
                                value={value.ServiceNextValue}
                                onChange={Handlechange}
                                disabled={Checked}
                            />
                            <label className='pt-1'>For All Next</label>
                        </div>
                    </div>
                </div >
                <div className="btn-box text-right  mr-1 ">
                    {
                        status ?
                            <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { dispatch(update_Service_Data(value)); resetHooks(); }}>Update</button>
                            :
                            <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { dispatch(save_Service_Data(value)); }}> Save</button>
                    }
                    <button type="button" class="btn btn-sm btn-success mr-1" onClick={resetHooks}>New</button>
                </div>
            </div>
            <div className="col-12 mt-2">
                <DataTable
                    dense
                    columns={columns}
                    data={filterServiceData}
                    pagination
                    selectableRowsHighlight
                    highlightOnHover
                />
            </div>
            <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />
        </>
    );
};
export default ServiceConfiguration;