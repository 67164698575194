import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom'
import Select from "react-select";
import { delete_Assign_Group_Data, get_Consumption_Drop_Data, get_Group_Drop_Data, get_Material_Group_Data, save_Assign_Group_Data } from '../../../../redux/actions/AllAction';
import ConfirmModal from '../../../Include/Common/ConfirmModal';
import { RequiredFieldSelectBox } from '../../../Validation/Validation';


const AssignGroupType = (props) => {
    const dispatch = useDispatch();
    const consumptionDropDown = useSelector((state) => state?.Data?.consumptionDropDown)
    const groupDropDown = useSelector((state) => state?.Data?.groupDropDown)
    const userId = (useSelector((state) => state?.Data?.userId))
    const assignGroupData = useSelector((state) => state?.Data?.assignGroupData)
    const [pageStatus, setPageStatus] = useState("1")
    const [confirmType, setConfirmType] = useState('')
    const [IsActive, setIsActive] = useState()
    const [id, setId] = useState('')
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }
    const [value, setValue] = useState({
        MaterialConsumptionGroup: '',
        MaterialConsumptionGroupID: '',
        MaterialConsumptionType: '',
        MaterialConsumption: '',
        ID: ''
    })
    const [errors, setErrors] = useState({
        ConsumptiongroupError: '', ConsumptionTypeError: ''
    })
    const { ConsumptiongroupError, ConsumptionTypeError } = errors
    
    const changeDropdown = (e, name) => {
        if (e) {
            if (name === 'MaterialConsumptionGroupID') {
                setValue(pre => { return { ...pre, ['MaterialConsumptionGroupID']: e.value, ['MaterialConsumptionGroup']: e.label } })
            } else if (name === 'MaterialConsumptionTypeID') {
                setValue(pre => { return { ...pre, ['MaterialConsumptionTypeID']: e.value, ['MaterialConsumptionType']: e.label } })
            }
        } else {
            if (name === 'MaterialConsumptionGroupID') {
                setValue(pre => { return { ...pre, ['MaterialConsumptionGroupID']: '', ['MaterialConsumptionGroup']: '' } })
            } else if (name === 'MaterialConsumptionTypeID') {
                setValue(pre => { return { ...pre, ['MaterialConsumptionTypeID']: '', ['MaterialConsumptionType']: '' } })
            }
        }
    }

    useEffect(() => {
        dispatch(get_Consumption_Drop_Data(value));
        dispatch(get_Group_Drop_Data(value));
    }, [])

    const { MaterialConsumptionGroupID, MaterialConsumptionTypeID } = value

    useEffect(() => {
        if (MaterialConsumptionGroupID || MaterialConsumptionTypeID) {
            dispatch(get_Material_Group_Data(value));
        }
    }, [MaterialConsumptionGroupID, MaterialConsumptionTypeID])


    useEffect(() => {
        if (ConsumptiongroupError === 'true' && ConsumptionTypeError === 'true') {
            dispatch(save_Assign_Group_Data(value))
        }
    }, [ConsumptiongroupError, ConsumptionTypeError]);

    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (RequiredFieldSelectBox(value.MaterialConsumptionGroup)) {
            setErrors(prevValues => { return { ...prevValues, ['ConsumptiongroupError']: RequiredFieldSelectBox(value.MaterialConsumptionGroup) } })
        };
        if (RequiredFieldSelectBox(value.MaterialConsumptionType)) {
            setErrors(prevValues => { return { ...prevValues, ['ConsumptionTypeError']: RequiredFieldSelectBox(value.MaterialConsumptionType) } })
        };
    };
    const columns = [
        {
            name: 'Consumption Type',
            selector: (row) => row.MaterialConsumptionType,
            sortable: true
        },
        {
            name: 'Consumption Group',
            selector: (row) => row.MaterialConsumptionGroup,
            sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 35 }}>Action</p>,
            cell: row => <>
                <div style={{ position: 'absolute', top: 4, right: 40 }}>
                    <Link to='/MasterTables?page=Assign%20Group-Type' data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('0'); setConfirmType("InActive"); setId(row.ID) }}
                        className="btn btn-sm bg-green text-white px-1 py-0 mr-1" >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </Link>

                </div>
            </>
        }
    ]
    const UpdActiveDeactive = () => {
        dispatch(delete_Assign_Group_Data(id, IsActive, userId, pageStatus));
    }
    return (
        <>
            <div className="col-12 col-md-12 col-lg-12  px-3">
                <div className="form-row" style={{ marginTop: '-10px' }}>
                    <div className="col-2 col-md-2 col-lg-2 mt-3 pt-2">
                        <label className="label-field">Consumption Type:</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                        <div className="dropdown__box" >
                            <Select
                                name='MaterialConsumptionTypeID'
                                styles={colourStyles}
                                isClearable
                                className='requiredColor'
                                options={consumptionDropDown}
                                onChange={(e) => changeDropdown(e, 'MaterialConsumptionTypeID')}
                                value={consumptionDropDown?.filter((obj) => obj.value === value?.MaterialConsumptionTypeID
                                )}
                            />
                        </div>
                        {errors.ConsumptiongroupError !== "true" ? (
                            <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.ConsumptiongroupError} </span>
                        ) : null}
                    </div>
                    <div className="col-1 col-md-1 col-lg-1 mt-3 pt-2">
                    </div>
                    <div className="col-2 col-md-3 col-lg-2 mt-3 pt-2">
                        <label className="label-field">Mat group Bearing:</label>
                    </div>
                    <div className="col-4 col-md-3 col-lg-3 mt-2">
                        <div className="dropdown__box" >
                            <Select
                                name='MaterialConsumptionGroupID'
                                styles={colourStyles}
                                isClearable
                                options={groupDropDown}
                                onChange={(e) => changeDropdown(e, 'MaterialConsumptionGroupID')}
                                value={groupDropDown?.filter((obj) => obj.value === value?.MaterialConsumptionGroupID
                                )}
                            />
                        </div>
                        {errors.ConsumptionTypeError !== "true" ? (
                            <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.ConsumptionTypeError} </span>
                        ) : null}
                    </div>
                    <div className="btn-box text-end  col-lg-2  " style={{ marginTop: '21px' }}>
                        <button type="button" class="btn btn-sm btn-success mr-1 mb-1" onClick={check_Validation_Error}>
                            Assign Selected to Type</button>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Assign Group Type</p>
                    <div style={{ marginLeft: 'auto' }}>
                        {/* <Link to={''} className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#">
                            <i className="fa fa-plus"></i>
                        </Link> */}
                    </div>
                </div>

                <div className="col-12 ">
                    <DataTable
                        dense
                        columns={columns}
                        data={assignGroupData}
                        pagination
                        selectableRowsHighlight
                        highlightOnHover
                    />
                </div>
            </div>
            <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType}></ConfirmModal>
        </>
    )
}

export default AssignGroupType