import React from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PitBlockBench from './PitBlockBench/PitBlockBench';
import ExplosiveExtry from './ExplosiveExtry/ExplosiveExtry';
import DrillingEntry from './DrillingEntry/DrillingEntry';
import BlastEntry from './BlastEntry/BlastEntry';

const Mining = () => {
    const dispatch = useDispatch();
    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get('sub');

    return (
        <>
            {
                openPage === 'Pit Block Bench' &&
                <PitBlockBench />
            }
            {
                openPage === 'Explosive Extry' &&
                <ExplosiveExtry />
            }
            {
                openPage === 'Drilling Entry' &&
                <DrillingEntry />
            }
            {
                openPage === 'Blast Entry' &&
                <BlastEntry />
            }
        </>
    )
}

export default Mining