import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SpaceNotAllow } from '../../Validation/Validation';
import { Get_Error_Status } from '../../../redux/actionTypes';

const Invoice = () => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({
        'usernameError': '', 'passwordError': '', 'productNameError': '', 'CodeError': '', 'districtError': '', 'PincodeError': '', 'codeError': ''
    })
    const companySingelData = useSelector((state) => state.Data.companySingelData[0])
    const [value, setValue] = useState({
        EInvoiceUserName: '',
        EInvoicePassword: '',
        EInvoiceProduct: '',
        EInvoiceHSNCode: '',
        District: '',
        PIN: '',
        Updatedbyid: ''
    }) 
    useEffect(() => {
        if (companySingelData) {
            setValue({
                EInvoiceUserName: companySingelData.EInvoiceUserName,
                EInvoicePassword: companySingelData.EInvoicePassword,
                EInvoiceProduct: companySingelData.EInvoiceProduct,
                EInvoiceHSNCode: companySingelData.EInvoiceHSNCode,
                District: companySingelData.District,
                PIN: companySingelData.PIN,
                Updatedbyid: companySingelData.Updatedbyid
            })
        } else {
            setValue({
                EInvoiceUserName: '',
                EInvoicePassword: '',
                EInvoiceProduct: '',
                EInvoiceHSNCode: '',
                District: '',
                PIN: '',
                Updatedbyid: ''
            })
        }
    }, [companySingelData])

    const check_Validation_Error = (e) => {
        if (SpaceNotAllow(value.EInvoiceUserName)) {
            setErrors(prevValues => { return { ...prevValues, ['usernameError']: SpaceNotAllow(value.EInvoiceUserName) } })
            dispatch({ type: Get_Error_Status, payload: true });
        }
        if (SpaceNotAllow(value.EInvoicePassword)) {
            setErrors(prevValues => { return { ...prevValues, ['passwordError']: SpaceNotAllow(value.EInvoicePassword) } })
        }
        if (SpaceNotAllow(value.EInvoiceProduct)) {
            setErrors(prevValues => { return { ...prevValues, ['productNameError']: SpaceNotAllow(value.EInvoiceProduct) } })
        }
        if (SpaceNotAllow(value.EInvoiceProduct)) {
            setErrors(prevValues => { return { ...prevValues, ['CodeError']: SpaceNotAllow(value.EInvoiceProduct) } })
        }
        if (SpaceNotAllow(value.District)) {
            setErrors(prevValues => { return { ...prevValues, ['districtError']: SpaceNotAllow(value.District) } })
        }
        if (SpaceNotAllow(value.PIN)) {
            setErrors(prevValues => { return { ...prevValues, ['PincodeError']: SpaceNotAllow(value.PIN) } })
        }
    }
    const {usernameError, passwordError, productNameError, CodeError, districtError, PincodeError,  codeError} = errors
    const handlChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
    }
    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">E-Invoice</p>
                </div>
                <div className="form-row mt-2">
                    <div className="col-6 col-md-4 col-lg-2 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='EInvoiceUserName'
                                className=""
                                value={value.EInvoiceUserName}
                                id='EInvoiceUserName'
                                required
                                onChange={handlChange}
                            />
                            <label className='pt-1'>User Name</label>
                            {errors.usernameError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.usernameError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='EInvoicePassword'
                                className=""
                                value={value.EInvoicePassword}
                                id='EInvoicePassword'
                                required
                                onChange={handlChange}
                            />
                            <label className='pt-1'>Password</label>
                            {errors.passwordError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.passwordError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='EInvoiceProduct'
                                value={value.EInvoiceProduct}
                                className=""
                                id='EInvoiceProduct'
                                required
                                onChange={handlChange}
                            />
                            <label className='pt-1'>Product Name</label>
                            {errors.productNameError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.productNameError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='EInvoiceHSNCode'
                                className=""
                                id='EInvoiceHSNCode'
                                value={value.EInvoiceHSNCode}
                                required
                                onChange={handlChange}
                            />
                            <label className='pt-1'>HSN Code</label>
                            {errors.CodeError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.CodeError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-3 col-lg-3 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='District'
                                className=""
                                value={value.District}
                                id='District'
                                required
                                onChange={handlChange}
                            />
                            <label className='pt-1'>District</label>
                            {errors.districtError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.districtError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-3 col-lg-1 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='PIN'
                                className=""
                                id='PIN'
                                required
                                value={value.PIN}
                                onChange={handlChange}
                            />
                            <label className='pt-1'>Pin Code</label>
                            {errors.PincodeError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.PincodeError}</span>
                            ) : null}
                        </div>
                    </div>

                </div>

                <div className="btn-box text-right mt-2 mr-1 mb-2">
                    <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button>
                    <button type="button" class="btn btn-sm btn-success mr-1">Close</button>
                </div>
            </div>

        </>
    )
}

export default Invoice