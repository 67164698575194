import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown'
import DataTable from 'react-data-table-component';
import { fetchPostData } from '../../../Hooks/FetchData';
import { formatDate, getPreviousDate } from '../../../Hooks/utility';
import { Table } from 'react-bootstrap';

const DayBook = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [ChkNarration, setChkNarration] = useState(false);
    const [filterTypeIdOption, setfilterTypeIdOption] = useState('All');
    const [cashBookData, setCashBookData] = useState([]);
    const [totalDebit, setTotalDebit] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
    const [closingDebit, setClosingDebit] = useState(0);
    const [closingCredit, setClosingCredit] = useState(0);
    const [selectedRow, setSelectedRow] = useState(null);

    const [errors, setErrors] = useState({
        'startDateError': "", 'endDateError': ""
    })

    const Get_Report_Data = () => {
        if (!startDate || startDate == '' || startDate == null) {
            setErrors({ ...errors, ['startDateError']: "Select Date" })
        } else if (!endDate || endDate == '' || endDate == null) {
            setErrors({ ...errors, ['endDateError']: "Select Date" })
        } else {
            const val = { FromDate: formatDate(startDate), ToDate: formatDate(endDate), StrFilter: filterTypeIdOption, ChkNarration: ChkNarration, mbolIsPrint: '1', LedgerName: "Cash" }
            fetchPostData('AccountingdayBook/GetData_AccountingdayBook', val).then((data) => {
                if (data) {
                    try {
                        console.log(data)
                        setCashBookData(data?.data)
                        setSelectedRow(null)
                        setErrors({ ...errors, ['endDateError']: "", ['startDateError']: "" })
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                }
            })
        }
    }

    useEffect(() => {
        if (cashBookData?.length > 0) {
            let totalDebit = 0;
            let totalCredit = 0;

            // if (cashBookData?.OpeningAmount > 0) {
            //     totalCredit += parseFloat(cashBookData?.OpeningAmount)
            // } else if (cashBookData?.OpeningAmount < 0) {
            //     totalDebit += parseFloat(cashBookData?.OpeningAmount)
            // }

            cashBookData.forEach((item) => {
                totalDebit += parseFloat(item.DebitAmt) || 0;
                totalCredit += parseFloat(item.CreditAmt) || 0;
            });

            setTotalDebit(totalDebit);
            setTotalCredit(totalCredit);

            if (totalDebit > totalCredit) {
                setClosingDebit(totalDebit - totalCredit);
                setClosingCredit(0);
            } else {
                setClosingDebit(0);
                setClosingCredit(totalCredit - totalDebit);
            }
        } else {
            setTotalDebit(0);
            setTotalCredit(0);
            setClosingDebit(0);
            setClosingCredit(0);
        }
    }, [cashBookData]);

    useEffect(() => {
        // if (startDate && endDate) {
        Get_Report_Data()
        // }
    }, [filterTypeIdOption])


    const handleCheck = (e) => {
        if (e.target.name === "Narration") {
            if (e.target.checked) {
                setChkNarration(e.target.checked)
            } else {
                setChkNarration(false)
            }
        }
    }

    const showReport = () => {
        // if (startDate && endDate) {
        Get_Report_Data()
        // }
    }

    const handleRowClick = (item) => {
        setSelectedRow(item);
        console.log('Selected row data:', item);

    };

    const columns = [
        {
            name: 'Date',
            selector: row => row.Date,
            sortable: true,
            width: '10%'
        },
        {
            name: 'Particulare',
            selector: row => (
                <div onClick={() => handleRowClick(row)}>
                    <p className='my-2'><b>{row.Name}</b></p>
                    {row.Children && row.Children.map(child => (
                        <div key={child.ID} className='d-flex' style={{ width: "100%" }}>
                            <span style={{ width: "50%" }}><b>{child.Name}</b></span>
                            <span><b>{child.Amt}</b></span>
                        </div>
                    ))}
                    {row.Narration &&
                        <div className='mt-2' style={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
                            <p>{row.Narration.Narration}</p>
                        </div>
                    }
                </div>
            ),
            width: "40%"
        },
        {
            name: 'Vch Type',
            selector: row => row.VchrType,
            sortable: true,
            width: '10%'
        },
        {
            name: 'Vch No',
            selector: row => row.VchrNo,
            sortable: true,
            width: '10%'
        },
        {
            name: 'DebitAmt',
            selector: row => row.DebitAmt,
            sortable: true,
            width: '10%'
        },
        {
            name: 'CreditAmt',
            selector: row => row.CreditAmt,
            sortable: true,
            width: '10%'
        },
    ];

    const tableCustomStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#001f3f ',
                borderBottomColor: '#FFFFFF',
                outline: '1px solid #FFFFFF',
            },
        },
    }

    const conditionalRowStyles = [
        {
            when: row => selectedRow?.ID === row?.ID,
            style: {
                backgroundColor: '#001f3fbd',
                color: 'white',
                cursor: 'pointer',
            },
        },
    ];


    return (
        <>

            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-1 mt-2">
                        <h6 className="p-0 m-0 d-flex align-items-center">Day Book</h6>
                    </div>

                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                        <label className='new-label'>From Date:</label>
                    </div>
                    <div class="col-9 col-md-4 col-lg-2 ">
                        <DatePicker
                            selected={startDate ? new Date(startDate) : null}
                            onChange={(date) => {
                                if (date) {
                                    setStartDate(date)
                                    setEndDate(null)
                                } else {
                                    setStartDate(null)
                                    setEndDate(null)
                                }
                            }}
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date(getPreviousDate(new Date()))}
                            maxDate={new Date()}
                            isClearable={startDate ? true : false}
                            placeholderText={"From Date..."}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            autoComplete="off"
                        // filterTime={filterPassedTime}
                        />
                        {errors.startDateError && <label style={{ color: "red" }}>{errors?.startDateError}</label>}

                    </div>
                    <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                        <label className='new-label'>To Date:</label>
                    </div>
                    <div class="col-9 col-md-4 col-lg-2 ">
                        <DatePicker
                            selected={endDate ? new Date(endDate) : null}
                            onChange={(date) => {
                                setEndDate(date)
                            }}
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date(startDate)}
                            maxDate={new Date()}
                            isClearable={endDate ? true : false}
                            placeholderText={"To Date..."}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            autoComplete="off"
                            disabled={startDate ? false : true}
                            readOnly={startDate ? false : true}
                        // filterTime={filterPassedTime}
                        />
                        {errors.endDateError && <label style={{ color: "red" }}>{errors?.endDateError}</label>}

                    </div>
                    <div class="col-4 col-md-4 col-lg-1 mt-2">
                        <div className="form-check ">
                            <input className="form-check-input" type="checkbox" id="Narration" name='Narration' onChange={handleCheck}
                                value={ChkNarration} checked={ChkNarration} />
                            <label className="form-check-label" htmlFor="Narration">Narration
                            </label>
                        </div>
                    </div>
                    <div className='col-4 col-md-4 col-lg-1'>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <div className='text-center'>{filterTypeIdOption}</div>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('All')}>All</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('Contra')}>Contra</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('Journal')}>Journal </Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('Payment')}>Payment</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('Receipt')}>Receipt</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('Sales')}>Sales</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="col-4 col-md-4 col-lg-1 pt-1">
                        <button type="button" class="btn btn-sm btn-success mr-1" onClick={showReport}>Report</button>
                    </div>
                </div>
                <div className="form-row bt mb-1">
                    <div className="col-12 px-3 mt-2 bb" style={{ overflowY: 'scroll' }}>
                        <DataTable
                            columns={columns}
                            persistTableHead={true}
                            dense
                            data={cashBookData || []}
                            pagination={false}
                            // paginationPerPage={'50'}
                            // paginationRowsPerPageOptions={[50, 100, 150, 200]}
                            // showPaginationBottom={50}
                            highlightOnHover
                            responsive
                            onRowClicked={(row) => {
                                handleRowClick(row);
                            }}
                            // fixedHeaderScrollHeight='400px'
                            fixedHeader
                            customStyles={tableCustomStyles}
                            conditionalRowStyles={conditionalRowStyles}
                            noDataComponent={"There are no data to display"}
                        />
                    </div>
                </div>
                <div className="footer">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-3 col-md-3 col-lg-8">
                                <button type="button" class="btn btn-sm btn-success mr-1">Print</button>
                            </div>
                            <div className="col-9 col-md-9 col-lg-4">

                                <div className="row">
                                    <div className="col-4">
                                        <p className='text-dark'>Current Total
                                        </p>
                                    </div>
                                    <div className="col-2">
                                        <span className='text-gray'>{totalDebit}</span>
                                    </div>
                                    <div className="col-1">
                                        <span className='text-gray'>
                                            {totalCredit}
                                        </span>
                                    </div>
                                </div>
                                <div className="row" style={{ borderTop: "2px solid", paddingTop: "10px" }}>

                                    <div className="col-4">
                                        <p className='text-dark'>Closing Balance
                                        </p>
                                    </div>
                                    <div className="col-2">
                                        <span className=' text-gray'>{closingDebit}</span>
                                    </div>
                                    <div className="col-1">
                                        <span className='text-gray'>
                                            {closingCredit}
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default DayBook