import axios from "axios";

const API = axios.create({ baseURL: "https://tiwariapi.arustu.com/api" });

export const fetchPostData = async (url, postData) => {
    var reUseUrl = url;
    var reUseData = postData;
    try {
        if (Object.keys(postData).length !== 0) {
            const res = await API.post(url, postData);
            // console.log(res, 'res')
            // console.log(res.data, 'resdata')
            // const TextData = JSON.parse(res?.data);
            // console.log(TextData,'innner')
            // const innerData = JSON.parse(TextData.data);
            return res.data
        } else {
            console.log(`${url}-----${postData}`)
        }
    } catch (error) {
        if (error?.response?.status === 401) {
            // console.log(reUseUrl)
            // console.log(reUseData)
            if (Object.keys(reUseData)?.length !== 0) {
                const res = await API.post(reUseUrl, reUseData);
                // console.log("ReHit the url after token expire", res)
                const TextData = JSON.parse(res?.data?.data);

                return TextData?.Table
            } else {
                console.log(`${url}-----${postData}`)
            }
        }
        if (error.response) {
            console.log(`${error.response?.request?.responseURL} -- ${error.response?.data?.Message}`)
        }
        return []
    }
};