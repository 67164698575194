import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SpaceNotAllow, Email_Field } from '../../Validation/Validation';
import {  update_Company_Gst_Data } from '../../../redux/actions/AllAction';
const Gst = () => {
    // custuom style withoutColor
    const companySingelData = useSelector((state) => state.Data.companySingelData[0])
    const pageStatus = useSelector((state) => state.Data.Status)
    const errorStatus = useSelector((state) => state.Data.errorStatus)
    const dispatch = useDispatch();
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    const [errors, setErrors] = useState({
        'NameError': '', 'phoneError': '', 'MobileError': '', 'emailError': '', 'GstNoError': '', 'StateError': '', 'jurisdictionError': '', 'RoyalityError': '', 'AccountNoError': '', 'Address1Error': '', 'Address2Error':'', 'BankNameError' : '', 'BankName1Error': '', 'AccountNo1Error': '', 'IfsccodeError': '', 'Ifsccode1Error': '', 'codeError': ''
    })
    const [value, setValue] = useState({
        GSTFirm: '',
        Phone: '',
        Mobile: '',
        Email: '',
        GSTNo: '',
        StateCode: '',
        Jurisdiction: '',
        Address1: '',
        Address2: '',
        Royalty: '',
        BankName: '',
        AcNo: '',
        IfscCode: '',
        BankName1: '',
        AcNo1: '',
        IfscCode1: '',
        updatedbyid: ''
    })
    useEffect(() => {
        if (companySingelData) {
            setValue({
                GSTFirm: companySingelData.GSTFirm,
                Phone: companySingelData.Phone,
                Mobile: companySingelData.Mobile,
                Email: companySingelData.Email,
                GSTNo: companySingelData.GSTNo,
                StateCode: companySingelData.StateCode,
                Jurisdiction: companySingelData.Jurisdiction,
                Address1: companySingelData.Address1,
                Address2: companySingelData.Address2,
                Royalty: companySingelData.Royalty,
                BankName: companySingelData.BankName,
                AcNo: companySingelData.AcNo,
                IfscCode: companySingelData.IfscCode,
                BankName1: companySingelData.BankName1,
                AcNo1: companySingelData.AcNo1,
                IfscCode1: companySingelData.IfscCode1,
                updatedbyid: companySingelData.updatedbyid
            })
        }
    }, [companySingelData])

    const handlChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
    }
    const {NameError, phoneError, MobileError, emailError, GstNoError, StateError, jurisdictionError, RoyalityError, Address1Error , Address2Error, BankNameError,BankName1Error, AccountNoError, AccountNo1Error, IfsccodeError, Ifsccode1Error, codeError} = errors
    useEffect(() => {
        if (NameError === 'true' && MobileError === 'true' && emailError === 'true' && phoneError === 'true' && GstNoError === 'true' && StateError === 'true' && jurisdictionError === 'true' &&  RoyalityError === 'true' &&  Address1Error === 'true' && Address2Error=== 'true' &&  BankNameError === 'true' && BankName1Error === 'true' && AccountNoError === 'true' && AccountNo1Error === 'true' && IfsccodeError === 'true' && Ifsccode1Error === 'true' && errorStatus) {
            dispatch(update_Company_Gst_Data(value))
        } 
    }, [NameError, phoneError, MobileError, emailError, GstNoError, StateError, jurisdictionError, RoyalityError, Address1Error , Address2Error, BankNameError,BankName1Error, AccountNoError, AccountNo1Error, IfsccodeError, Ifsccode1Error,  codeError, errorStatus])
    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.GSTFirm)) {
            setErrors(prevValues => { return { ...prevValues, ['NameError']: SpaceNotAllow(value.GSTFirm) } })
        }
        if (SpaceNotAllow(value.Phone)) {
            setErrors(prevValues => { return { ...prevValues, ['phoneError']: SpaceNotAllow(value.Phone) } })
        }
        if (SpaceNotAllow(value.Mobile)) {
            setErrors(prevValues => { return { ...prevValues, ['MobileError']: SpaceNotAllow(value.Mobile) } })
        }
        if (Email_Field(value.Email)) {
            setErrors(prevValues => { return { ...prevValues, ['emailError']: Email_Field(value.Email) } })
        }
        if (SpaceNotAllow(value.GSTNo)) {
            setErrors(prevValues => { return { ...prevValues, ['GstNoError']: SpaceNotAllow(value.GSTNo) } })
        }
        if (SpaceNotAllow(value.StateCode)) {
            setErrors(prevValues => { return { ...prevValues, ['StateError']: SpaceNotAllow(value.StateCode) } })
        }
        if (SpaceNotAllow(value.Jurisdiction)) {
            setErrors(prevValues => { return { ...prevValues, ['jurisdictionError']: SpaceNotAllow(value.Jurisdiction) } })
        }
        if (SpaceNotAllow(value.Royalty)) {
            setErrors(prevValues => { return { ...prevValues, ['RoyalityError']: SpaceNotAllow(value.Royalty) } })
        }
        if (SpaceNotAllow(value.Address1)) {
            setErrors(prevValues => { return { ...prevValues, ['Address1Error']: SpaceNotAllow(value.Address1) } })
        }
        if (SpaceNotAllow(value.Address2)) {
            setErrors(prevValues => { return { ...prevValues, ['Address2Error']: SpaceNotAllow(value.Address2) } })
        }
        if (SpaceNotAllow(value.BankName)) {
            setErrors(prevValues => { return { ...prevValues, ['BankNameError']: SpaceNotAllow(value.BankName) } })
        }
        if (SpaceNotAllow(value.AcNo)) {
            setErrors(prevValues => { return { ...prevValues, ['AccountNoError']: SpaceNotAllow(value.AcNo) } })
        }
        if (SpaceNotAllow(value.IfscCode)) {
            setErrors(prevValues => { return { ...prevValues, ['IfsccodeError']: SpaceNotAllow(value.IfscCode) } })
        }
        if (SpaceNotAllow(value.AcNo1)) {
            setErrors(prevValues => { return { ...prevValues, ['AccountNo1Error']: SpaceNotAllow(value.AcNo1) } })
        }
        if (SpaceNotAllow(value.IfscCode1)) {
            setErrors(prevValues => { return { ...prevValues, ['Ifsccode1Error']: SpaceNotAllow(value.IfscCode1) } })
        }
        if (SpaceNotAllow(value.BankName1)) {
            setErrors(prevValues => { return { ...prevValues, ['BankName1Error']: SpaceNotAllow(value.BankName1) } })
        }
    }
    
    return (
        <>

            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">GST</p>
                </div>
                <div className="form-row mt-2">
                    <div className="col-6 col-md-6 col-lg-6 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='GSTFirm'
                                className=""
                                value={value.GSTFirm}
                                id='GSTFirm'
                                required
                                onChange={handlChange}
                            />
                            <label >Name</label>
                            {errors.NameError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.NameError}</span>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-6 col-md-3 col-lg-3 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='Phone'
                                className=""
                                id='Phone'
                                value={value.Phone}
                                required
                                onChange={handlChange}
                            />
                            <label >Phone</label>
                            {errors.phoneError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.phoneError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-3 col-lg-3 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='Mobile'
                                className=""
                                id='Mobile'
                                required
                                value={value.Mobile}
                                onChange={handlChange}
                            />
                            <label >Mobile</label>
                            {errors.MobileError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.MobileError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 mt-4">
                        <div class="text-field">
                        <input
                                type="text"
                                name='Email'
                                value={value.Email}
                                className=""
                                id='Email'
                                required
                                onChange={handlChange}
                            />
                            <label >Email</label>
                            {errors.emailError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.emailError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 mt-4">
                        <div class="text-field">
                            <input
                                type="text"
                                name='GSTNo'
                                className=""
                                id='GSTNo'
                                required
                                value={value.GSTNo}
                                onChange={handlChange}                            />
                            <label >GSTIN</label>
                            {errors.GstNoError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.GstNoError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mt-4">
                        <div class="text-field">
                            <input
                                type="text"
                                name='Jurisdiction'
                                className=""
                                value={value.Jurisdiction}
                                id='Jurisdiction'
                                required
                                onChange={handlChange}
                            />
                            <label >Jurisdiction</label>
                            {errors.jurisdictionError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.jurisdictionError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mt-4">
                        <div class="text-field">
                            <input
                                type="text"
                                name='StateCode'
                                className=""
                                value={value.StateCode}
                                id='StateCode'
                                required
                                onChange={handlChange}
                            />
                            <label >State Code</label>
                            {errors.StateError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.StateError}</span>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-6 col-md-4 col-lg-2 mt-4">
                        <div class="text-field">
                            <input
                                type="text"
                                name='Royalty'
                                className=""
                                value={value.Royalty}
                                id='Royalty'
                                required
                                onChange={handlChange}
                            />
                            <label >Royality On Weight</label>
                            {errors.RoyalityError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.RoyalityError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-12  col-md-6 col-lg-6 mt-3" >
                        <div className="dropdown__box">
                            <textarea name='Address1' id="Address1" cols="30" rows='1' className="form-control pt-2 pb-2 " onChange={handlChange} value={value.Address1}></textarea>
                            <label htmlFor="" >Address 1</label>
                            {errors.Address1Error !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.Address1Error}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-12  col-md-6 col-lg-6 mt-3" >
                        <div className="dropdown__box">
                            <textarea name='Address2' id="Address2" cols="30" rows='1' value={value.Address2}className="form-control pt-2 pb-2 " onChange={handlChange}></textarea>
                            <label htmlFor="" >Address 2</label>
                            {errors.Address2Error !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.Address2Error}</span>
                            ) : null}
                        </div>
                    </div>




                </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Bank Detail 1</p>
                </div>
                <div className="form-row mt-2">
                    <div className="col-6 col-md-4 col-lg-4 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='BankName'
                                className=""
                                id='BankName'
                                required
                                value={value.BankName}
                                onChange={handlChange}
                            />
                            <label >Name</label>
                            {errors.BankNameError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.BankNameError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='AcNo'
                                className=""
                                id='AcNo'
                                required
                                value={value.AcNo}
                                onChange={handlChange}
                            />
                            <label >A/C No.:</label>
                            {errors.AccountNoError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.AccountNoError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='IfscCode'
                                className=""
                                id='IfscCode'
                                required
                                value={value.IfscCode}
                                onChange={handlChange}
                            />
                            <label >IFSC Code</label>
                            {errors.IfsccodeError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.IfsccodeError}</span>
                            ) : null}
                        </div>
                    </div>
                    {/* <div className="btn-box text-end col-12 col-lg-4  mt-2 pt-1">
                        <button type="button" class="btn btn-sm btn-success mr-1">Save</button>
                        <button type="button" class="btn btn-sm btn-success mr-1">Close</button>
                    </div> */}
                </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Bank Detail 2</p>
                </div>
                <div className="form-row mt-2">
                    <div className="col-6 col-md-4 col-lg-4 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='BankName1'
                                className=""
                                id='BankName1'
                                required
                                value={value.BankName1}
                                onChange={handlChange}
                            />
                            <label >Name</label>
                            {errors.BankName1Error !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.BankName1Error}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='AcNo1'
                                className=""
                                id='AcNo1'
                                required
                                value={value.AcNo1}
                                onChange={handlChange}
                            />
                            <label >A/C No.:</label>

                            {errors.AccountNo1Error !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.AccountNo1Error}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='IfscCode1'
                                className=""
                                id='IfscCode1'
                                required
                                value={value.IfscCode1}
                                onChange={handlChange}
                            />
                            <label >IFSC Code</label>
                            {errors.Ifsccode1Error !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.Ifsccode1Error}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="btn-box col-12 text-end col-lg-4 mt-2 pt-1">
                        <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button>
                        <button type="button" class="btn btn-sm btn-success mr-1">Cancel</button>
                    </div>
                </div>
            </div>
        </>


                            
    )
                            
                            }

export default Gst