import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import { getShowingWithOutTime } from '../../../Include/Common/Utility';
import Select from "react-select";

const AttendanceNew = () => {
    const [loginDate, setloginDate] = useState();
    const [logoutDate, setlogoutDate] = useState();
    const [value, setValue] = useState();
    const startRef = React.useRef();
    const startRef1 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
        }
    };
    const customStylesWithOutColor = {
        control: (base) => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    };
    return (
        <>
            <div className="col-12">
                <div className="form-row px-3">
                    <div className="col-6 col-md-6 col-lg-3 pt-2 ">
                        <div className="dropdown__box">
                            <Select
                                name="Shift"
                                styles={customStylesWithOutColor}
                                isClearable
                                placeholder="Select.."
                            />
                            <label htmlFor="" className='pl-0'>Shift</label>
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-3 pt-1">
                        <div className="dropdown__box">
                            <DatePicker
                                id='logoutDate'
                                name='logoutDate'
                                ref={startRef1}
                                onKeyDown={onKeyDown}
                                onChange={(date) => { setlogoutDate(date); setValue({ ...value, ['logoutDate']: date ? getShowingWithOutTime(date) : null }) }}
                                className='drop-date'
                                dateFormat="MM/dd/yyyy HH:mm"
                                isClearable={value?.logoutDate ? true : false}
                                selected={logoutDate}
                                placeholderText={value?.logoutDate ? value.logoutDate : 'Select...'}
                                dropdownMode="select"
                                showMonthDropdown
                                showYearDropdown
                            />
                            <label htmlFor="" className='pl-0 pt-1' >Date</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Employee Attendance</p>
                </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                {/* <div className="table-responsive" style={{ overflow: 'inherit' }}> */}
                <div className="table-responsive" >
                    <table className="table ">
                        <thead className='thead-light'>
                            <tr>
                                <th>ID</th>
                                <th>UserName</th>
                                <th>Login Time</th>
                                <th>Logout Time</th>
                                <th >Working Hours</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Testing 1</td>
                                <td>
                                    <div className="col-8 col-md-8 col-lg-8 px-0 mt-1">
                                        <div className="date-box">
                                            <input type="datetime-local" name="" className='form-control' id="" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="col-8 col-md-8 col-lg-8 px-0 mt-1">
                                        <div className="date-box">
                                            <input type="datetime-local" isClearable name="" className='form-control' id="" />
                                        </div>
                                    </div>
                                </td>
                                <td className=''>
                                    8
                                </td>
                                <td>
                                    <div className="col-12 col-md-12 col-lg-12 px-0 mt-1" >
                                        <div className="date-box">
                                            <textarea name='Remarks' id="Remarks"
                                                cols="0" rows='1' className="form-control " ></textarea>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Testing 2</td>
                                <td>
                                    <div className="col-8 col-md-8 col-lg-8 px-0 mt-1">
                                        <div className="date-box">
                                            <input type="datetime-local" name="" className='form-control' id="" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="col-8 col-md-8 col-lg-8 px-0 mt-1">
                                        <div className="date-box">
                                            <input type="datetime-local" name="" className='form-control' id="" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    8
                                </td>
                                <td>
                                    <div className="col-12 col-md-12 col-lg-12 px-0 mt-1" >
                                        <div className="date-box">
                                            <textarea name='Remarks' id="Remarks"
                                                cols="0" rows='1' className="form-control " ></textarea>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Testing 3</td>
                                <td>
                                    <div className="col-8 col-md-8 col-lg-8 px-0 mt-1">
                                        <div className="date-box">
                                            <input type="datetime-local" name="" className='form-control' id="" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="col-8 col-md-8 col-lg-8 px-0 mt-1">
                                        <div className="date-box">
                                            <input type="datetime-local" name="" className='form-control' id="" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    8
                                </td>
                                <td>
                                    <div className="col-12 col-md-12 col-lg-12 px-0 mt-1" >
                                        <div className="date-box">
                                            <textarea name='Remarks' id="Remarks"
                                                cols="0" rows='1' className="form-control " ></textarea>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="btn-box text-right  mr-1 mb-2">
                <button type="button" class="btn btn-sm btn-success mr-1" >Save</button>
                <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1">Close</button>
            </div>

        </>
    )
}

export default AttendanceNew