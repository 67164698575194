import React, { useState, useEffect } from 'react'
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap';
import { changeArrayFormatDrop } from '../../../../Include/Common/ArrayFormat';
import Leave_Add_Up from './Leave_Add_Up';
import { delete_EmpLeave, get_Emp_Leave_Data, get_Employee_Data, get_Single_EmpLeave_Data } from '../../../../../redux/actions/AllAction';
import ConfirmModal from '../../../../Include/Common/ConfirmModal';
import Loader from '../../../../Include/Common/Loader';
import { Edit_Update_Count, Get_EmpLeave_Filter_Data, MasterTable_Modal_Status } from '../../../../../redux/actionTypes';
import { Three_Search_Filter } from '../../../../Filter/Filter';

const LeaveList = (props) => {
    const {openPage} = props
    const dispatch = useDispatch()
    const employeeData = changeArrayFormatDrop(useSelector((state) => state.Data.employeeData), 'EmpName', 'ID');
    const empLeaveData = (useSelector((state) => state.Data.empLeaveData))
    const empLeaveFilterData = (useSelector((state) => state.Data.empLeaveFilterData))
    const pageLoader = (useSelector((state) => state.Data.pageLoader))
    const [status, setStatus] = useState(false)
    const editUpdateCount = (useSelector((state) => state.Data.editUpdateCount))
    const userId = useSelector((state) => state.Data.userId)
    const [pageStatus, setPageStatus] = useState("1")
    const [IsActive, setIsActive] = useState()
    const [id, setId] = useState('')
    const [filterTypeIdOption, setfilterTypeIdOption] = useState('Contains');
    const [filterTypeDescOption, setfilterTypeDescOption] = useState('Contains');
    const [filterTypeAddOption, setfilterTypeAddOption] = useState('Contains');
    const [searchValue1, setSearchValue1] = useState('')
    const [searchValue2, setSearchValue2] = useState('')
    const [searchValue3, setSearchValue3] = useState('')
    const [confirmType, setConfirmType] = useState('')
    const [empData, setEmpData] = useState({
        EmpName: '', EmpID: ''
    })
    const customStylesWithOutColor = {
        control: (base) => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    };

    useEffect(() => {
        if (employeeData.length === 0) dispatch(get_Employee_Data('1'))
    }, []);

    useEffect(() => {
        if (empData.EmpID) dispatch(get_Emp_Leave_Data(empData.EmpID, pageStatus))
    }, [pageStatus])

    useEffect(() => {
        if (pageStatus) { setSearchValue1(''); setSearchValue2(''); }
      }, [pageStatus])

    const changeDropdown = e => {
        if (e) {
            setEmpData(pre => { return { ...pre, ['EmpID']: e?.value, ['EmpName']: e?.label } }); dispatch(get_Emp_Leave_Data(e?.value, pageStatus))
        } else {
            setEmpData(pre => { return { ...pre, ['EmpID']: '', ['EmpName']: '' } });
        };
    }
    
    const columns = [
        {
            name: 'Leave',
            selector: (row) => row.LeaveStatus,
            sortable: true
        },
        {
            name: 'Leave Reason',
            selector: (row) => row.LeaveReason,
            sortable: true
        },
        {
            name: 'Leave From Date',
            selector: (row) => row.Leavefrom,
            sortable: true
        },
        {
            name: 'Leave To Date',
            selector: (row) => row.LeaveTo,
            sortable: true
        },

        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 55, fontWeight: '700' }}>Action</p>,
            cell: row => <>
                <div style={{ position: 'absolute', top: 4, right: 40 }}>
                    {
                        pageStatus === "1" ?
                            <Link to= '/DashBoard?sub=Leave' onClick={() => setEditStatus(row)}
                                className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn" data-toggle="modal" data-target="#EmpLeaveModal"><i className="fa fa-edit"></i>
                            </Link> : ''
                    }
                    {
                        pageStatus === "1" ?
                            < Link to={`/DashBoard?sub=Leave`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('0'); setConfirmType("InActive"); setId(row.ID) }}
                                className="btn btn-sm  text-white px-1 py-0 mr-1" style={{ background: "#ddd" }}>
                                <i class="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true"></i>
                            </Link>
                            :
                            <Link to={`/DashBoard?sub=Leave`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('1'); setConfirmType("Active"); setId(row.ID) }}
                                className="btn btn-sm  text-white px-1 py-0 mr-4" style={{ background: "#ddd" }}>
                                <i class="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true"></i>
                            </Link>
                    }

                </div>
            </>
        }
    ]

    const setEditStatus = (row) => {
        dispatch(get_Single_EmpLeave_Data(row.ID));
        setStatus(true)
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: MasterTable_Modal_Status, payload: true });
    }

    const newStatus = () => {
        dispatch(get_Single_EmpLeave_Data(''));
        setStatus(false);
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: MasterTable_Modal_Status, payload: true })
    }

    const UpdActiveDeactive = () => {
        dispatch(delete_EmpLeave(id, IsActive, userId, pageStatus, empData?.EmpID))
    }

    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="col-12 col-md-12 col-lg-6">
                    <div className="dropdown__box">
                        <Select
                            name="EmployeeName"
                            styles={customStylesWithOutColor}
                            isClearable
                            onChange={changeDropdown}
                            value={employeeData?.filter((obj) => obj.value === empData.EmpID)}
                            options={employeeData}
                            placeholder="Select.."
                        />
                        <label htmlFor="" className='pl-0'>Employee Name</label>
                    </div>
                </div>
                {empData.EmpID ? <div className="col-12 col-md-6 col-lg-12 ">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class={`nav-link ${pageStatus === '1' ? 'active' : ''}`} onClick={() => setPageStatus("1")} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true">Active</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class={`nav-link ${pageStatus === '0' ? 'active' : ''}`} onClick={() => setPageStatus("0")} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true">InActive</a>
                        </li>
                    </ul>
                </div> : ''}
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Employee Leave</p>
                    {pageStatus === "1" && empData.EmpID ? <div style={{ marginLeft: 'auto' }}>
                        <Link to={'/DashBoard?sub=Leave'} onClick={newStatus} className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#EmpLeaveModal" >
                            <i className="fa fa-plus"></i>
                        </Link>
                    </div> : ''}
                </div>
            </div>
            <div className="col-12 mt-2 px-3">
                <div className="row">
                    <div className="col-3">
                        <input type="text" value={searchValue1} onChange={(e) => {
                            setSearchValue1(e.target.value);
                            const result = Three_Search_Filter(empLeaveData, e.target.value, searchValue2, searchValue3, filterTypeIdOption, 'LeaveStatus', 'LeaveReason', 'LeaveFrom')
                            dispatch({ type: Get_EmpLeave_Filter_Data, payload: result });
                        }}
                            className='form-control' placeholder='Search By Leave Status...' />
                    </div>
                    <div className='col-1 '>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('End with')}>End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="col-3">
                        <input type="text" value={searchValue2} onChange={(e) => {
                            setSearchValue2(e.target.value);
                            const result = Three_Search_Filter(empLeaveData, searchValue1, e.target.value, searchValue3, filterTypeAddOption, 'LeaveStatus', 'LeaveReason', 'LeaveFrom')
                            dispatch({ type: Get_EmpLeave_Filter_Data, payload: result });
                        }}
                            className='form-control' placeholder='Search By Leave Reason ...' />
                    </div>
                    <div className='col-1'>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('Contains')}>Contains</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('is equal to')}>is equal to</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('is not equal to')}>is not equal to </Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('Starts With')}>Starts With</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('End with')}>End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="col-3">
                        <input type="text" value={searchValue3} onChange={(e) => {
                            setSearchValue3(e.target.value);
                            const result = Three_Search_Filter(empLeaveData, searchValue1, searchValue2, e.target.value, filterTypeDescOption, 'LeaveStatus', 'LeaveReason', 'LeaveFrom')
                            dispatch({ type: Get_EmpLeave_Filter_Data, payload: result });
                        }}
                            className='form-control' placeholder='Search By Leave From....' />
                    </div>
                    <div className='col-1 '>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('End with')}>End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            {empData.EmpID ?
                <div className="col-12 pt-2 px-3">
                    {pageLoader ?
                        <DataTable
                            dense
                            columns={columns}
                            data={empData.EmpID ? empLeaveFilterData : ''}
                            paginatio
                            selectableRowsHighlight
                            highlightOnHover
                        /> :
                        <Loader />
                    }
                </div> : ''}|
            <Leave_Add_Up  {... { status, pageStatus, empData }} />
            <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />
        </>
    )
}

export default LeaveList