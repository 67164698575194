import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import BlastEntry_Add_Up from './BlastEntry_Add_Up'
import { useDispatch, useSelector } from 'react-redux'
import { delete_Blast_Data, get_Blast_Entry_Data, get_Single_Blast_Data } from '../../../../redux/actions/AllAction'
import { Edit_Update_Count, Get_Blast_Filter_Data, MasterTable_Modal_Status, Modal_Status } from '../../../../redux/actionTypes'
import ConfirmModal from '../../../Include/Common/ConfirmModal'
import { Three_Search_Filter } from '../../../Filter/Filter'
import { Dropdown } from 'react-bootstrap'
import Loader from '../../../Include/Common/Loader'

const BlastEntry = () => {

    const dispatch = useDispatch();
    const blastEntryData = (useSelector((state) => state.Data.blastEntryData));
    const blastFilterData = (useSelector((state) => state.Data.blastFilterData));
    const editUpdateCount = (useSelector((state) => state.Data.editUpdateCount));
    const pageLoader = useSelector((state) => state.Data.pageLoader);
    const userId = useSelector((state) => state.Data.userId);
    const modalStatus = useSelector((state) => state.Data.modalStatus);
    const [pageStatus, setPageStatus] = useState("1");
    const [status, setStatus] = useState(false);
    const [IsActive, setIsActive] = useState();
    const [id, setId] = useState('');
    const [confirmType, setConfirmType] = useState('');
    const [filterTypeIdOption, setfilterTypeIdOption] = useState('Contains');
    const [filterTypeDescOption, setfilterTypeDescOption] = useState('Contains');
    const [filterTypeAddOption, setfilterTypeAddOption] = useState('Contains');
    const [searchValue1, setSearchValue1] = useState('')
    const [searchValue2, setSearchValue2] = useState('')
    const [searchValue3, setSearchValue3] = useState('')

    useEffect(() => {
        dispatch(get_Blast_Entry_Data(pageStatus));
    }, [pageStatus]);

    useEffect(() => {
        if (pageStatus) { setSearchValue1(''); setSearchValue2(''); setSearchValue3('') }
      }, [pageStatus])
    const columns = [
        {
            name: 'Pit',
            selector: (row) => row.Pit,
            sortable: true
        },
        {
            name: 'SiteDirection',
            selector: (row) => row.SiteDirection,
            sortable: true
        },
        {
            name: 'TotalNoOfHole',
            selector: (row) => row.TotalNoOfHole,
            sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 55, fontWeight: '700' }}>Action</p>,
            cell: row => <>
                <div style={{ position: 'absolute', top: 4, right: 40 }}>
                    {
                        pageStatus === "1" ?
                            <Link to='/Mining?sub=Blast%20Entry' onClick={() => setEditStatus(row)}
                                className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn" data-toggle="modal" data-target="#BlastEntryModal"><i className="fa fa-edit"></i>
                            </Link> : ''
                    }
                    {
                        pageStatus === "1" ?
                            < Link to={`/Mining?sub=Blast%20Entry`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('0'); setConfirmType("InActive"); setId(row.ID) }}
                                className="btn btn-sm  text-white px-1 py-0 mr-1" style={{ background: "#ddd" }}>
                                <i class="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true"></i>
                            </Link>
                            :
                            <Link to={`/Mining?sub=Blast%20Entry`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('1'); setConfirmType("Active"); setId(row.ID) }}
                                className="btn btn-sm  text-white px-1 py-0 mr-4" style={{ background: "#ddd" }}>
                                <i class="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true"></i>
                            </Link>
                    }
                </div>
            </>
        }
    ]

    const setEditStatus = (row) => {
        dispatch(get_Single_Blast_Data(row.ID))
        setStatus(true);; setId(row.ID)
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: Modal_Status, payload: true });
    }

    const newStatus = () => {
        // dispatch(get_Single_Blast_Data(''))
        setStatus(false); setId('')
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: Modal_Status, payload: true })
    }

    const UpdActiveDeactive = () => {
        dispatch(delete_Blast_Data(id, IsActive, pageStatus, userId))
    }

    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="col-12 col-md-6 col-lg-12 ">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class={`nav-link ${pageStatus === '1' ? 'active' : ''}`} onClick={() => setPageStatus("1")} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true">Active</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class={`nav-link ${pageStatus === '0' ? 'active' : ''}`} onClick={() => setPageStatus("0")} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true">InActive</a>
                        </li>
                    </ul>
                </div>
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Blast Entry</p>
                    {pageStatus === "1" && <div style={{ marginLeft: 'auto' }}>
                        <Link to={'/Mining?sub=Blast%20Entry'} onClick={newStatus} className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#BlastEntryModal">
                            <i className="fa fa-plus"></i>
                        </Link>
                    </div>}
                </div>
            </div>
            <div className="col-12 mt-2 px-3">
                <div className="row">
                    <div className="col-3">
                        <input type="text" value={searchValue1} onChange={(e) => {
                            setSearchValue1(e.target.value);
                            const result = Three_Search_Filter(blastEntryData, e.target.value, searchValue2, searchValue3, filterTypeIdOption, 'Pit', 'SiteDirection', 'TotalNoOfHole')
                            dispatch({ type: Get_Blast_Filter_Data, payload: result });
                        }}
                            className='form-control' placeholder='Search By Pit...' />
                    </div>
                    <div className='col-1 '>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('End with')}>End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="col-3">
                        <input type="text" value={searchValue2} onChange={(e) => {
                            setSearchValue2(e.target.value);
                            const result = Three_Search_Filter(blastEntryData, searchValue1, e.target.value, searchValue3, filterTypeAddOption, 'Pit', 'SiteDirection', 'TotalNoOfHole')
                            dispatch({ type: Get_Blast_Filter_Data, payload: result });
                        }}
                            className='form-control' placeholder='Search By Site Direction ...' />
                    </div>
                    <div className='col-1'>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('Contains')}>Contains</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('is equal to')}>is equal to</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('is not equal to')}>is not equal to </Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('Starts With')}>Starts With</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('End with')}>End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="col-3">
                        <input type="text" value={searchValue3} onChange={(e) => {
                            setSearchValue3(e.target.value);
                            const result = Three_Search_Filter(blastEntryData, searchValue1, searchValue2, e.target.value, filterTypeDescOption, 'Pit', 'SiteDirection', 'TotalNoOfHole')
                            dispatch({ type: Get_Blast_Filter_Data, payload: result });
                        }}
                            className='form-control' placeholder='Search By Total no. of Hole....' />
                    </div>
                    <div className='col-1 '>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('End with')}>End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="col-12 pt-2 px-3">
                {pageLoader ?
                    <DataTable
                        dense
                        columns={columns}
                        data={blastFilterData}
                        pagination
                        selectableRowsHighlight
                        highlightOnHover
                    /> :
                    <Loader />
                }
            </div>
            {modalStatus && <BlastEntry_Add_Up {...{ status, pageStatus }} />}
            <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />
        </>
    )
}

export default BlastEntry