import React, { useState } from 'react'
import Select from "react-select";
import DatePicker from 'react-datepicker'
const Journal = () => {
    // custuom style withoutColor
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    };

    const [state, setState] = useState({
        rows: [{}]
    })
    const [jouranl, setJournal] = useState({
        rows: [{}]
    })
    const handleChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...state.rows];
        rows[idx] = {
            [name]: value
        };
        setState({
            rows
        });
    };
    const handleCase = idx => e => {
        const { name, value } = e.target;
        const rows = [...jouranl.rows];
        rows[idx] = {
            [name]: value
        };
        setJournal({
            rows
        });
    };
    const handleAddRow = () => {
        const item = {
            name: "",
            mobile: ""
        };
        setState({
            rows: [...state.rows, item]
        });
    };
    const handleAddJournal = () => {
        const item = {
            name: "",
            mobile: ""
        };
        setJournal({
            rows: [...jouranl.rows, item]
        });
    };

    const handleRemoveRow = () => {
        setState({
            rows: state.rows.slice(0, -1)
        });
    };
    const handleRemoveSpecificRow = (idx) => () => {
        const rows = [...state.rows]
        rows.splice(idx, 1)
        setState({ rows })
    }
    const handleRemoveSpecificJournal = (idx) => () => {
        const rows = [...jouranl.rows]
        rows.splice(idx, 1)
        setJournal({ rows })
    }
    return (
        <>
            <div className="col-12 col-md-12 col-lg-12  px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Journal Voucher</p>
                </div>
                <div className="form-row mt-3">
                    <div className="col-6 col-md-2 col-lg-2 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='receipt'
                                className="readonlyColor"
                                id='receipt'
                                required
                                readOnly
                            />
                            <label >Journal No.</label>
                        </div>
                    </div>
                    <div class="col-6 col-md-3 col-lg-2 ">
                        <div className="date__box">
                            <DatePicker
                                id='date'
                                name='date'
                                className=''
                                isClearable
                                placeholderText={'Select...'}
                            />
                            <label htmlFor="" >Date</label>
                        </div>
                    </div>
                    <div className="col-12 col-lg-8 text-right mt-4 px-3">
                        <button onClick={handleAddRow} className="btn btn-sm btn-success">
                            Add Row
                        </button>
                    </div>
                </div>
                <div className="row mb-0 bb">
                    <div className="col-12 col-md-12 col-lg-6" style={{ overflowY: 'scroll', maxHeight: '350px' }}>
                        <div className="bg-line  py-1 px-2 mt-1 d-flex">
                            <p className="p-0 m-0 text-center">Journal By</p>
                            <button onClick={handleAddJournal} className="btn btn-sm btn-success px-2 py-0 ml-auto">
                                <i className='fa fa-plus'></i>
                            </button>
                        </div>
                        <table class="table table-bordered" >
                            <thead style={{ backgroundColor: '#f2f2f2' }}>
                                <tr>
                                    <th >S.no</th>
                                    <th>Particular</th>
                                    <th>Amount</th>
                                    <th className='text-right'>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {jouranl.rows.map((item, idx) => (
                                    <tr id="addr0" key={idx}>
                                        <td className='payment-serial'>{idx}</td>
                                        <td>
                                            <input
                                                type="text"
                                                name="name"
                                                value={jouranl.rows[idx].name}
                                                onChange={handleCase(idx)}
                                                className="form-control"
                                            />
                                        </td>

                                        <td className='payment-amount1'>
                                            <input
                                                type="text"
                                                name="mobile"
                                                value={jouranl.rows[idx].mobile}
                                                onChange={handleCase(idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td className='payment-button'>
                                            <button
                                                className="btn btn-outline-danger btn-sm"

                                                onClick={handleRemoveSpecificJournal(idx)}
                                            >
                                                <i className="fa fa-trash"></i>
                                            </button>
                                        </td>

                                    </tr>

                                ))}
                            </tbody>
                        </table>
                        <div className="row">
                            <div className="col-6 col-md-8 col-lg-7 mt-1">
                            </div>
                            <div className="col-6 col-md-3 col-lg-3 ml-3">
                                <div class="text-field">
                                    <input
                                        type="text"
                                        className='readonlyColor'
                                        name='amount'
                                        id='amount'
                                        required
                                        readOnly
                                    />
                                    <label className="pt-1">Total Amount:</label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-md-12 col-lg-6" style={{ overflowY: 'scroll', maxHeight: '350px' }}>
                        <div className="bg-line  py-1 px-2 mt-1 d-flex">
                            <p className="p-0 m-0 text-center">Journal To</p>
                            <button onClick={handleAddRow} className="btn btn-sm btn-success px-2 py-0 ml-auto">
                                <i className='fa fa-plus'></i>
                            </button>
                        </div>
                        <table class="table table-bordered" >
                            <thead style={{ backgroundColor: '#f2f2f2' }}>
                                <tr>
                                    <th >S.no</th>
                                    <th>Particular</th>
                                    <th>Amount</th>
                                    <th className='text-right'>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.rows.map((item, idx) => (
                                    <tr id="addr0" key={idx}>
                                        <td className='payment-serial'>{idx}</td>
                                        <td>
                                            <input
                                                type="text"
                                                name="name"
                                                value={state.rows[idx].name}
                                                onChange={handleChange(idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td className='payment-amount1'>
                                            <input
                                                type="text"
                                                name="mobile"
                                                value={state.rows[idx].mobile}
                                                onChange={handleChange(idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td className='payment-button'>
                                            <button
                                                className="btn btn-outline-danger btn-sm"

                                                onClick={handleRemoveSpecificRow(idx)}
                                            >
                                                <i className="fa fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="row">
                            <div className="col-6 col-md-8 col-lg-7 mt-1">
                            </div>
                            <div className="col-6 col-md-3 col-lg-3 ml-3">
                                <div class="text-field">
                                    <input
                                        type="text"
                                        className='readonlyColor'
                                        name='amount'
                                        id='amount'
                                        required
                                        readOnly
                                    />
                                    <label className="pt-1">Total Amount:</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="form-row">
                    <div className="col-6 col-md-2 col-lg-3 mt-3  ml-3">
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 mt-1">
                        <div class="text-field">
                            <input
                                type="text"
                                className='readonlyColor'
                                name='amount'
                                id='amount'
                                required
                                readOnly
                            />
                            <label className="pt-1">Total Amount:</label>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 col-lg-4 mt-3  ml-2">
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 mt-1">
                        <div class="text-field">
                            <input
                                type="text"
                                className='readonlyColor'
                                name='amount'
                                id='amount'
                                required
                                readOnly

                            />
                            <label className="pt-1">Total Amount:</label>
                        </div>
                    </div>
                </div> */}
                <div className="form-row mt-2">
                    <div className="col-12  col-md-12 col-lg-8 mt-1  " >
                        <div className="text-field " >
                            <textarea name='Narration' id="Narration" cols="30" rows='1' className="form-control pt-2 pb-2 " ></textarea>
                            <label htmlFor="" className='pt-1'>Narration:</label>
                        </div>
                    </div>
                    <div className="btn-box text-end col-lg-4  mt-3">
                        <button type="button" class="btn btn-sm btn-success mr-1">Save</button>
                        {/* <button type="button" class="btn btn-sm btn-success mr-1">New</button> */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default Journal