import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Select from "react-select";
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux';
import { get_BloodGroup_Drop_Data, get_Gender_Drop_Data, get_Martial_Drop_Data, get_Religion_Drop_Data, update_Employee_Personal } from '../../../../../../../redux/actions/AllAction';
import { Email_Field, RequiredFieldSelectBox, SpaceNotAllow, SpaceNotAllowWithoutReq } from '../../../../../../Validation/Validation';
import { Get_Error_Status, MasterTable_Modal_Status } from '../../../../../../../redux/actionTypes';
import { toastifyError } from '../../../../../../Include/Common/AlertMsg';
import MasSideBar from '../../../../../../Include/Common/MasSideBar';
import { getShowingDayWithOutTime, getShowingWithOutTimeStartDate } from '../../../../../../Include/Common/Utility';
import { changeArrayFormatDrop } from '../../../../../../Include/Common/ArrayFormat';

const PersonalDetails = ({ openPage }) => {

    const dispatch = useDispatch()
    const bloodGroupData = changeArrayFormatDrop(useSelector((state) => state.Data.bloodGroupDropData),'BloodGroup','ID')
    const martialData = changeArrayFormatDrop(useSelector((state) => state.Data.martialDropData),'Description','ID')
    const religionData = changeArrayFormatDrop(useSelector((state) => state.Data.religionDropData),'Description','ID')
    const genderData = changeArrayFormatDrop(useSelector((state) => state.Data.genderDropData),'Description','ID')
    const errorStatus = useSelector((state) => state.Data.errorStatus)
    const pageStatus = useSelector((state) => state.Data.Status)
    const employeeId = useSelector((state) => state.Data.employeeId)
    const employeeSingleData = useSelector((state) => state.Data.employeeSingleData[0]);
    const userId = useSelector((state) => state.Data.userId)


    const [value, setValue] = useState({
        MobileNo: '', Emailid: '', sex: '', Maritial: '', Bloodgroup: '', DOB: '', DOB1: '', CompanyMobileNo: '', Religion: '', ADHARACARD: '', DRIVERSLICENSE: '', PANCARD: '', RATIONCARD: '', VOTERCARD: '', ID: '', Updatedbyid: userId
    })

    const [errors, setErrors] = useState({
        MobileNoErrors: '', EmailidErrors: '', sexErrors: '', MaritialErrors: '', BloodgroupErrors: '', DOBErrors: '', CompanyMobileNoErrors: '', ReligionErrors: '', ADHARACARDErrors: '', DRIVERSLICENSEErrors: '', PANCARDErrors: '',EmailIdErrors: '', RATIONCARDErrors: '', VOTERCARDErrors: '',
    })
    // Check All Field Format is True Then Submit 
    const { MobileNoErrors, ReligionErrors, sexErrors, PANCARDErrors, EmailIdErrors } = errors
    const [urlName, setUrlName] = useState('')
    const [showCol, setShowCol] = useState('')
    const [idName, setIdName] = useState('')

    useEffect(() => {
        dispatch(get_BloodGroup_Drop_Data())
        dispatch(get_Gender_Drop_Data())
        dispatch(get_Religion_Drop_Data())
        dispatch(get_Martial_Drop_Data())
    }, [])

    useEffect(() => {
        if (employeeSingleData) {
            setValue(pre => { return { ...pre, MobileNo: employeeSingleData?.MobileNo, Emailid: employeeSingleData?.Emailid, sex: employeeSingleData?.sex, Maritial: employeeSingleData?.Maritial, Bloodgroup: employeeSingleData?.Bloodgroup, DOB: employeeSingleData?.DOB, DOB1: employeeSingleData?.DOB?.split("/").reverse().join("/"), CompanyMobileNo: employeeSingleData?.CompanyMobileNo, Religion: employeeSingleData?.Religion, ADHARACARD: employeeSingleData?.ADHARACARD, DRIVERSLICENSE: employeeSingleData?.DRIVERSLICENSE, PANCARD: employeeSingleData?.PANCARD, RATIONCARD: employeeSingleData?.RATIONCARD, VOTERCARD: employeeSingleData.VOTERCARD, ID: employeeSingleData.ID } })
        }
    }, [employeeSingleData])


    const handlChange = (e) => {
        if (e.target.name === 'MobileNo'|| e.target.name ==='ADHARACARD'|| e.target.name === 'RATIONCARD') {
             const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
             setValue({
                 ...value,
                 [e.target.name]: checkNumber
             })
         } else if(e.target.name === 'PANCARD' || e.target.name === 'VOTERCARD' || e.target.name === 'DRIVERSLICENSE') {
            setValue({
                ...value,
                [e.target.name]: e.target.value.toUpperCase(),
            }); 
         }
         else {
             setValue({
                 ...value,
                 [e.target.name]: e.target.value,
             });
         }
 
     }

    const handleDropdown = (e, name) => {
        setValue(pre => { return { ...pre, [name]: e?.value } })
    }

    // Check validation on Field
    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.MobileNo)) {
            setErrors(prevValues => { return { ...prevValues, ['MobileNoErrors']: SpaceNotAllow(value.MobileNo) } })
            dispatch({ type: Get_Error_Status, payload: true })
        }
        if (RequiredFieldSelectBox(value.Religion)) {
            setErrors(prevValues => { return { ...prevValues, ['ReligionErrors']: RequiredFieldSelectBox(value.Religion) } })
        }
        if (Email_Field(value.Emailid)) {
            setErrors(prevValues => {return {...prevValues, ['EmailIdErrors']: Email_Field(value.Emailid)}})
        }
        if (RequiredFieldSelectBox(value.sex)) {
            setErrors(prevValues => { return { ...prevValues, ['sexErrors']: RequiredFieldSelectBox(value.sex) } })
        }
        if (SpaceNotAllow(value.PANCARD)) {
            setErrors(prevValues => { return { ...prevValues, ['PANCARDErrors']: SpaceNotAllow(value.PANCARD) } })
        }
        if (SpaceNotAllow(value.DOB)) {
            setErrors(prevValues => { return { ...prevValues, ['DOBErrors']: SpaceNotAllow(value.DOB) } })
        }

    }
    useEffect(() => {
        if (MobileNoErrors === 'true' && ReligionErrors === 'true' && sexErrors === 'true' && PANCARDErrors === 'true' && errorStatus) {
            if (pageStatus) dispatch(update_Employee_Personal(value, employeeId))
        }
    }, [MobileNoErrors, ReligionErrors, sexErrors, PANCARDErrors, errorStatus])

    // custuom style withoutColor
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        })
    }
    const setUrls = (idName, urlName, showCol) => {
        setIdName(idName); setUrlName(urlName); setShowCol(showCol); dispatch({ type: MasterTable_Modal_Status, payload: true })
    }

    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Personal Details</p>
                </div>
                <div className="form-row mt-1 px-1">
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className='label-field'>Mobile:</label>
                        {/* <span className="form-required">*</span> */}
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='MobileNo'
                                className='requiredColor'
                                value={value.MobileNo}
                                onChange={handlChange}
                                maxLength={10}
                                id='mobile'
                                required
                            />
                        </div>
                        {errors.MobileNoErrors !== 'true' ? (
                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.MobileNoErrors}</span>
                        ) : null}
                    </div>
                    <div className="col-1 col-md-1 col-lg-1  ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className='label-field'>Company Mobile allotted:</label>
                        {/* <span className="form-required">*</span> */}
                    </div>
                    <div className="col-4 col-md-4 col-lg-3 mt-3">
                        <div class="form-check ">
                            <input class="form-check-input" name='Common' type="checkbox" id="flexCheckDefault6" />

                        </div>
                    </div>
                    <div className=" col-lg-1  ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className='label-field'>email id:</label>
                        {/* <span className="form-required">*</span> */}
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='Emailid'
                                value={value.Emailid}
                                onChange={handlChange}
                                id='Emailid'
                                required
                            />
                        </div>
                        {errors.EmailIdErrors !== 'true' ? (
                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.EmailIdErrors}</span>
                        ) : null}
                    </div>
                    <div className="col-1 col-md-1 col-lg-1 mt-2 pt-2">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className='label-field '>religion:</label>
                    </div>
                    <div className="col-4 col-md-4 col-lg-3 d-flex px-0">
                        <div className="col-11 col-md-11 col-lg-11 ">
                            <div className="dropdown__box">
                                <Select
                                    name='Religion'
                                    styles={colourStyles}
                                    isClearable
                                    value={religionData?.filter((obj) => obj.value?.toString() === value?.Religion?.toString())}
                                    options={religionData}
                                    onChange={(e) => handleDropdown(e, 'Religion')}
                                    placeholder="Select.."
                                />
                            </div>
                            {errors.ReligionErrors !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ReligionErrors}</span>
                            ) : null}
                        </div>
                        <div className="col-1 col-md-1 col-lg-1  px-0 py-0" style={{ marginTop: '14px' }}>
                            <Link to="" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal" onClick={() => setUrls('id', 'Religion', 'Description')}>
                                <i className="fa fa-plus"></i>
                            </Link>
                        </div>
                    </div>
                    <div className=" col-lg-1 ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className='label-field '>gender:</label>
                    </div>

                    <div className="col-3 col-md-3 col-lg-3 d-flex px-0">
                        <div className="col-11 col-md-11 col-lg-11 ">
                            <div className="dropdown__box">
                                <Select
                                    name='sex'
                                    styles={colourStyles}
                                    options={genderData}
                                    value={genderData?.filter((obj) => obj?.value?.toString() === value?.sex?.toString())}
                                    onChange={(e) => handleDropdown(e, 'sex')}
                                    isClearable
                                    placeholder="Select.."
                                />
                            </div>
                            {errors.sexErrors !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.sexErrors}</span>
                            ) : null}
                        </div>
                        <div className="col-1 col-md-1 col-lg-1  px-0 py-0" style={{ marginTop: '14px' }}>
                            <Link to="" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal" onClick={() => setUrls('id', 'Gender', 'Description')}>
                                <i className="fa fa-plus"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-1 col-md-1 col-lg-1">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className='label-field '>blood group:</label>
                    </div>
                    <div className="col-4 col-md-4 col-lg-3 d-flex px-0">
                        <div className="col-11 col-md-11 col-lg-11 ">
                            <div className="dropdown__box">
                                <Select
                                    name='Bloodgroup'
                                    styles={customStylesWithOutColor}
                                    isClearable
                                    value={bloodGroupData?.filter((obj) => obj.value?.toString() === value?.Bloodgroup?.toString())}
                                    onChange={(e) => handleDropdown(e, 'Bloodgroup')}
                                    options={bloodGroupData}
                                    placeholder="Select.."
                                />
                            </div>
                        </div>
                        <div className="col-1 col-md-1 col-lg-1  px-0 py-0" style={{ marginTop: '14px' }}>
                            <Link to="" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal" onClick={() => setUrls('Id', 'BloodGroup', 'BloodGroup')}>
                                <i className="fa fa-plus"></i>
                            </Link>
                        </div>
                    </div>
                    <div className=" col-lg-1 ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className='label-field'>aadhaar card:</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='ADHARACARD'
                                value={value.ADHARACARD}
                                onChange={handlChange}
                                maxLength={16}
                                id='ADHARACARD'
                                required
                            />
                        </div>
                    </div>
                    <div className="col-1 col-md-1 col-lg-1 mt-2 pt-2">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className='label-field '>martial status:</label>
                    </div>
                    <div className="col-4 col-md-4 col-lg-3 d-flex px-0" >
                        <div className="col-11 col-md-11 col-lg-11 ">
                            <div className="dropdown__box">
                                <Select
                                    name='Maritial'
                                    styles={customStylesWithOutColor}
                                    isClearable
                                    options={martialData}
                                    value={martialData?.filter((obj) => obj.value?.toString() === value?.Maritial?.toString())}
                                    onChange={(e) => handleDropdown(e, 'Maritial')}
                                    placeholder="Select.."
                                />
                            </div>
                        </div>
                        <div className="col-1 col-md-1 col-lg-1 px-0 py-0" style={{ marginTop: '14px' }} >
                            <Link to="" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal" onClick={() => setUrls('id', 'Martial', 'Description')}>
                                <i className="fa fa-plus"></i>
                            </Link>
                        </div>
                    </div>
                    <div className=" col-lg-1 ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className='label-field '>pan NUMBER:</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='PANCARD'
                                className='requiredColor'
                                value={value.PANCARD}
                                onChange={handlChange}
                                maxLength={10}
                                id='PANCARD'
                                required
                            />
                        </div>
                        {errors.PANCARDErrors !== 'true' ? (
                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.PANCARDErrors}</span>
                        ) : null}
                    </div>

                    <div className="col-1 col-md-1 col-lg-1 mt-2 pt-2">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className='label-field '>RATION card:</label>
                    </div>
                    <div className="col-4 col-md-4 col-lg-3 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='RATIONCARD'
                                value={value.RATIONCARD}
                                onChange={handlChange}
                                id='RATIONCARD'
                                maxLength={10}
                                required
                            />
                        </div>
                    </div>
                    <div className=" col-lg-1 ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className='label-field '>date of birth:</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 " style={{ marginTop: '-5px' }}>
                        <div className="dropdown__box">
                            <DatePicker
                                id='DOB'
                                name='DOB'
                                className='requiredColor'
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => { if (date) { setValue({ ...value, ['DOB']: getShowingDayWithOutTime(date), ['DOB1']: getShowingWithOutTimeStartDate(date) }) } else { setValue({ ...value, ['DOB']: '', ['DOB1']: '' }) } }}
                                isClearable
                                selected={value?.DOB1 && new Date(value?.DOB1)}
                                placeholderText={'Select...'}
                                dropdownMode="select"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={new Date()}
                            />
                        </div>
                        {errors.DOBErrors !== 'true' ? (
                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DOBErrors}</span>
                        ) : null}
                    </div>
                    <div className="col-1 col-md-1 col-lg-1 ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className='label-field '>driving license:</label>
                    </div>
                    <div className="col-4 col-md-4 col-lg-3 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='DRIVERSLICENSE'
                                value={value.DRIVERSLICENSE}
                                onChange={handlChange}
                                id='DRIVERSLICENSE'
                                maxLength={16}
                                required
                            />
                        </div>
                    </div>
                    <div className=" col-lg-1 ">
                    </div>

                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className='label-field '>voter id:</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='VOTERCARD'
                                value={value.VOTERCARD}
                                onChange={handlChange}
                                maxLength={10}
                                id='VOTERCARD'
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="btn-box text-end col-12 col-md-12 col-lg-12 ">
                    <button type="button" className="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button>
                    <Link to={openPage === 'Attendance' ? '/HR?sub=Attendance%20Report' : '/AdminSetting?sub=Operator'}>
                        <button type="button" className="btn btn-sm btn-success mr-1">Close</button>
                    </Link>
                </div>
            </div>
            {
                urlName &&
                <MasSideBar
                    showCol1={showCol} idName={idName}
                    getUrl={`/${urlName}/GetData_${urlName}`}
                    addUrl={`/${urlName}/Insert${urlName}`}
                    singleDataUrl={`/${urlName}/GetSingleData_${urlName}`}
                    upUrl={`/${urlName}/Update${urlName}`}
                    delUrl={`/${urlName}/Delete${urlName}`}
                />
            }

        </>
    )
}

export default PersonalDetails