import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import Select from "react-select";


const MeterReading_Add = () => {
    const [date, setDate] = useState();
    const [fitnessExpdate, setFitnessExpDate] = useState();
    const [permitRenewdate, setpermitRenewdate] = useState();
    const [permitExpdate, setpermitExpdate] = useState();
    const [pucRenewdate, setpucRenewdate] = useState();
    const [pucExpdate, setpucExpdate] = useState();
    const [value, setValue] = useState();

    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();
    const startRef3 = React.useRef();
    const startRef4 = React.useRef();
    const startRef5 = React.useRef();
    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
            startRef4.current.setOpen(false);
            startRef5.current.setOpen(false);
        }
    };
    // custuom style withoutColor
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    return (
        <>
            <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="MeterReadingModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="m-1 ">
                                <fieldset style={{ border: '1px solid gray' }}>
                                    <legend >Meter Reading</legend>
                                    <div className="form-row" style={{ marginTop: '-10px' }}>
                                        <div className="col-12 col-md-6 col-lg-4 mt-1 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='equipment'
                                                    className=""
                                                    id='equipment'
                                                    required
                                                />
                                                <label className='pt-1'>Equipment Identification No.</label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 mt-1 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='make'
                                                    className="readonlyColor"
                                                    id='make'
                                                    required
                                                    readOnly
                                                />
                                                <label className='pt-1'>Make</label>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-6 col-lg-4">
                                            <div className="dropdown__box">
                                                <DatePicker
                                                    id='date'
                                                    name='date'
                                                    ref={startRef}
                                                    onKeyDown={onKeyDown}
                                                    onChange={(date) => { setDate(date); setValue({ ...value, }) }}
                                                    className=''
                                                    dateFormat="MM/dd/yyyy "
                                                    timeInputLabel
                                                    isClearable={value?.date ? true : false}
                                                    selected={date}
                                                    placeholderText={value?.date ? value.date : 'Select...'}
                                                    dropdownMode="select"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                                <label htmlFor="" className='pt-1'>Date</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 mt-1">
                                            <div className="dropdown__box">
                                                <Select
                                                    name='operator'
                                                    styles={customStylesWithOutColor}
                                                    isClearable
                                                    placeholder="Select.."
                                                />
                                                <label htmlFor="">Operator/Driver</label>
                                            </div>
                                        </div>

                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='count'
                                                    className=""
                                                    id='count'
                                                    required
                                                />
                                                <label className='pt-1'>Count Operator</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='mtereading'
                                                    className=""
                                                    id='mtereading'
                                                    required
                                                />
                                                <label className='pt-1'>Mtr Reading Start</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='mtrend'
                                                    className=""
                                                    id='mtrend'
                                                    required
                                                />
                                                <label className='pt-1'>Mtr Reading End</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='dim/css'
                                                    className=""
                                                    id='dim/css'
                                                    required
                                                />
                                                <label className='pt-1'>A-Dim/CSS Reading</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='tphreading'
                                                    className=""
                                                    id='tphreading'
                                                    required
                                                />
                                                <label className='pt-1'>TPH Reading</label>
                                            </div>
                                        </div>
                                        <div className="col-12  col-md-12 col-lg-12 mt-2">
                                            <div className="dropdown__box">
                                                <textarea
                                                    name="remarks"
                                                    id="remarks"
                                                    cols="30"
                                                    rows="1"
                                                    className="form-control   "
                                                >
                                                </textarea>
                                                    <label htmlFor="">Remarks</label>
                                            </div>
                                        </div>

                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="btn-box text-right  mr-1 mb-2">
                            <button type="button" class="btn btn-sm btn-success mr-1">Save</button>
                            <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" >Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MeterReading_Add