import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { get_SalebyProduct_Chart_Data } from "../../../redux/actions/AllAction";
import ChartDate from "./ChartDate";

export const BarCharts = () => {
  const dispatch = useDispatch()

  const data = useSelector((state) => state.Data.salabyProduct)

  // const [isStacked, setIsStacked] = useState(false);
  const [chartType, setChartType] = useState('bar');
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  useEffect(() => {
    if (data?.length === 0) {
      dispatch(get_SalebyProduct_Chart_Data(new Date(), new Date()))
    }
  }, [])

  useEffect(() => {
    if (fromDate && toDate) {
      dispatch(get_SalebyProduct_Chart_Data(fromDate, toDate))
    }
  }, [fromDate, toDate]);

  const chartData = {
    series: [
      {
        name: "Netweight",
        data: data.map(item => item.Netweight),
        type: chartType,
      }
    ],
    categories: data.map(item => item.ProductName)
  };

  const options = {
    chart: {
      type: chartType,
      height: 500,
      // width: 50,
      // stacked: isStacked,
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [
            {
              icon: `<svg fill="#000000" width="20px" height="20px" viewBox="0 -2 30 40" version="1.1"  preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path class="clr-i-outline clr-i-outline-path-1" d="M32,5H4A2,2,0,0,0,2,7V29a2,2,0,0,0,2,2H32a2,2,0,0,0,2-2V7A2,2,0,0,0,32,5ZM4,29V7H32V29Z"></path><path d="M 7 10 L 13 10 L 13 26 L 11.4 26 L 11.4 11.6 L 8.6 11.6 L 8.6 26 L 7 26 Z" class="clr-i-outline clr-i-outline-path-2"></path><path d="M 15 19 L 21 19 L 21 26 L 19.4 26 L 19.4 20.6 L 16.6 20.6 L 16.6 26 L 15 26 Z" class="clr-i-outline clr-i-outline-path-3"></path><path d="M 23 16 L 29 16 L 29 26 L 27.4 26 L 27.4 17.6 L 24.6 17.6 L 24.6 26 L 23 26 Z" class="clr-i-outline clr-i-outline-path-4"></path><rect x="0" y="0" width="36" height="36" fill-opacity="0"/></svg>`,
              title: 'Switch to Bar Chart',
              class: 'custom-icon-bar',
              index: -1,
              click: () => setChartType('bar')
            },
            {
              icon: `<svg fill="#000000" width="20px" height="20px" viewBox="0 -2 30 40" version="1.1"  preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path class="clr-i-outline clr-i-outline-path-1" d="M 32 5 L 4 5 C 2.895 5 2 5.895 2 7 L 2 29 C 2 30.105 2.895 31 4 31 L 32 31 C 33.105 31 34 30.105 34 29 L 34 7 C 34 5.895 33.105 5 32 5 Z M 4 29 L 4 7 L 32 7 L 32 29 Z"></path><polygon points="15.62 15.222 9.602 23.968 5.55 20.384 6.61 19.186 9.308 21.572 15.634 12.38 22.384 22.395 29.138 13.47 30.414 14.436 22.308 25.145" class="clr-i-outline clr-i-outline-path-2"></polygon><rect x="0" y="0" width="36" height="36" fill-opacity="0"/></svg>`,
              title: 'Switch to Line Chart',
              class: 'custom-icon-line',
              index: -1,
              click: () => setChartType('line')
            },
          ]
        },
      },
    },
    xaxis: {
      categories: chartData.categories,
      title: {
        text: 'Products',
        style: {
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      axisBorder: {
        show: true,
        color: '#000000',
        height: 1,
        width: '100%',
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        rotate: -45,
        style: {
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      title: {
        text: 'Netweight',
        offsetX: 10,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      labels: {
        style: {
          fontSize: '12px'
        },
        formatter: (value) => `${value.toFixed(0)}`,
      },
      axisBorder: {
        show: true,
        color: '#000000',
        offsetX: 0,
        offsetY: 0,
      },
    },
    title: {
      text: 'Product Netweights',
      align: 'center',
      margin: 10,
      style: {
        fontSize: '14px',
        fontWeight: 'bold'
      }
    },
    stroke: {
      show: true,
      width: 1.5,
      // // colors:["#ea580c","#14532d","#ef4444","#0ea5e9"]
      // colors: ["#14532d","#0ea5e9", "#ef4444",  "#ea580c"],
    },
    markers: {
      size: 4,
      shape: ["circle"],
      hover: {
        size: 5,
      },
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value) => value.toFixed(2),
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
    },
    noData: {
      text: "No Data Available To Show",
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        // color: undefined,
        fontSize: '14px',
        fontWeight: 'bold'
        // fontFamily: undefined
      }
    },
  };


  return (
    <>
      <ChartDate fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} />
      <div style={{ paddingTop: "2rem" }}>
        <ApexCharts
          options={options}
          series={chartData.series}
          height={450}
        />
      </div>
    </>
  );
};


