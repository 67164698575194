import React, { useState, useEffect } from 'react';
import { toastifyError } from '../../../../../../Include/Common/AlertMsg';
import { update_Employee_Education } from '../../../../../../../redux/actions/AllAction';
import { useDispatch, useSelector } from 'react-redux';
import { SpaceNotAllowWithoutReq } from '../../../../../../Validation/Validation';
import { Get_Error_Status } from '../../../../../../../redux/actionTypes';


const Educational = () => {

    const dispatch = useDispatch()
    const errorStatus = useSelector((state) => state.Data.errorStatus)
    const pageStatus = useSelector((state) => state.Data.Status)
    const employeeId = useSelector((state) => state.Data.employeeId)
    const employeeSingleData = useSelector((state) => state.Data.employeeSingleData[0]);
    const userId = useSelector((state) => state.Data.userId)

    const [value, setValue] = useState({
        XthBoard: '', XthSchoolName: '', XthPassignYear: '', XthGrad: '', XIIthBoard: '', XIIthSchoolName: '', XIIthPassignYear: '', XIIthGrad: '', DegreeBoard: '', DegreeSchoolName: '', DegreePassignYear: '', DegreeGrad: '', PostDegreeBoard: '', PostDegreeSchoolName: '', PostDegreePassignYear: '', PostDegreeGrad: '', OtherDegreeBoard: '', OtherDegreeSchoolName: '', OtherDegreePassignYear: '', OtherDegreeGrad: '', ID:'', Updatedbyid:userId
    })

    const [errors, setErrors] = useState({
        XthBoardErrors: '', XthSchoolNameErrors: '', XthPassignYearErrors: '', XthGradErrors: '', XIIthBoardErrors: '', XIIthSchoolNameErrors: '', XIIthPassignYearErrors: '', XIIthGradErrors: '', DegreeBoardErrors: '', DegreeSchoolNameErrors: '', DegreePassignYearErrors: '', DegreeGradErrors: '', PostDegreeBoardErrors: '', PostDegreeSchoolNameErrors: '', PostDegreePassignYearErrors: '', PostDegreeGradErrors: '', OtherDegreeBoardErrors: '', OtherDegreeSchoolNameErrors: '', OtherDegreePassignYearErrors: '', OtherDegreeGradErrors: '',
    })

    useEffect(() => {
        if (employeeSingleData) {
            setValue(pre => { return { ...pre,  XthBoard: employeeSingleData.XthBoard, XthSchoolName: employeeSingleData.XthSchoolName, XthPassignYear: employeeSingleData.XthPassignYear, XthGrad: employeeSingleData.XthGrad, XIIthBoard: employeeSingleData.XIIthBoard, XIIthSchoolName: employeeSingleData.XIIthSchoolName, XIIthPassignYear: employeeSingleData.XIIthPassignYear, XIIthGrad: employeeSingleData.XIIthGrad, DegreeBoard: employeeSingleData.DegreeBoard, DegreeSchoolName: employeeSingleData.DegreeSchoolName, DegreePassignYear: employeeSingleData.DegreePassignYear, DegreeGrad: employeeSingleData.DegreeGrad, PostDegreeBoard: employeeSingleData.PostDegreeBoard, PostDegreeSchoolName: employeeSingleData.PostDegreeSchoolName, PostDegreePassignYear: employeeSingleData.PostDegreePassignYear, PostDegreeGrad: employeeSingleData.PostDegreeGrad, OtherDegreeBoard: employeeSingleData.OtherDegreeBoard, OtherDegreeSchoolName: employeeSingleData.OtherDegreeSchoolName, OtherDegreePassignYear: employeeSingleData.OtherDegreePassignYear, OtherDegreeGrad: employeeSingleData.OtherDegreeGrad, ID: employeeSingleData.ID } });
        }
    }, [employeeSingleData])

    // const handleChange = (e) => {
    //     const { name, value } = e.target
    //     if (e.target === 'XthPassignYear') {
    //         const checkNumber = e.target.replace(/[^0-9\s]/g, "");
    //         setValue({
    //             ...value,
    //             [e.target.name]: checkNumber
    //         })
    //     }
    //     setValue(pre => { return { ...pre, [name]: value } })
    // }
    const handleChange = (e) => {
        if (e.target.name === 'XthPassignYear' || e.target.name === 'XIIthPassignYear' || e.target.name === 'DegreePassignYear'||  e.target.name === 'PostDegreePassignYear'||  e.target.name === 'OtherDegreePassignYear' ) {
             const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
             setValue({
                 ...value,
                 [e.target.name]: checkNumber
             })
         } else if(e.target.name === 'XthBoard' || e.target.name === 'XIIthBoard' || e.target.name === 'DegreeBoard'||  e.target.name === 'PostDegreeBoard'||  e.target.name === 'OtherDegreeBoard') {
            setValue({
                ...value,
                [e.target.name]: e.target.value.toUpperCase(),
            });
         }
         else {
             setValue({
                 ...value,
                 [e.target.name]: e.target.value,
             });
         }
 
     }
    // Check validation on Field
    const check_Validation_Error = (e) => {
        e.preventDefault()
        const { 
            XthBoard, XthSchoolName, XthPassignYear, XthGrad, XIIthBoard, XIIthSchoolName, XIIthPassignYear, XIIthGrad, DegreeBoard, DegreeSchoolName, DegreePassignYear, DegreeGrad, PostDegreeBoard, PostDegreeSchoolName, PostDegreePassignYear, PostDegreeGrad, OtherDegreeBoard, OtherDegreeSchoolName, OtherDegreePassignYear, OtherDegreeGrad
        } = value
        if (XthBoard || XthSchoolName || XthPassignYear || XthGrad || XIIthBoard || XIIthSchoolName || XIIthPassignYear || XIIthGrad || DegreeBoard || DegreeSchoolName || DegreePassignYear || DegreeGrad || PostDegreeBoard || PostDegreeSchoolName || PostDegreePassignYear || PostDegreeGrad || OtherDegreeBoard || OtherDegreeSchoolName || OtherDegreePassignYear || OtherDegreeGrad) {
            if (SpaceNotAllowWithoutReq(XthBoard)) {
                setErrors(prevValues => { return { ...prevValues, ['XthBoardErrors']: SpaceNotAllowWithoutReq(XthBoard) } })
                dispatch({ type: Get_Error_Status, payload: true })
            }
            if (SpaceNotAllowWithoutReq(XthSchoolName)) {
                setErrors(prevValues => { return { ...prevValues, ['XthSchoolNameErrors']: SpaceNotAllowWithoutReq(XthSchoolName) } })
                dispatch({ type: Get_Error_Status, payload: true })
            }
            if (SpaceNotAllowWithoutReq(XthPassignYear)) {
                setErrors(prevValues => { return { ...prevValues, ['XthPassignYearErrors']: SpaceNotAllowWithoutReq(XthPassignYear) } })
                dispatch({ type: Get_Error_Status, payload: true })
            }
            if (SpaceNotAllowWithoutReq(XthGrad)) {
                setErrors(prevValues => { return { ...prevValues, ['XthGradErrors']: SpaceNotAllowWithoutReq(XthGrad) } })
                dispatch({ type: Get_Error_Status, payload: true })
            }
            if (SpaceNotAllowWithoutReq(XIIthBoard)) {
                setErrors(prevValues => { return { ...prevValues, ['XIIthBoardErrors']: SpaceNotAllowWithoutReq(XIIthBoard) } })
            }
            if (SpaceNotAllowWithoutReq(XIIthSchoolName)) {
                setErrors(prevValues => { return { ...prevValues, ['XIIthSchoolNameErrors']: SpaceNotAllowWithoutReq(XIIthSchoolName) } })
            }
            if (SpaceNotAllowWithoutReq(XIIthPassignYear)) {
                setErrors(prevValues => { return { ...prevValues, ['XIIthPassignYearErrors']: SpaceNotAllowWithoutReq(XIIthPassignYear) } })
            }
            if (SpaceNotAllowWithoutReq(XIIthGrad)) {
                setErrors(prevValues => { return { ...prevValues, ['XIIthGradErrors']: SpaceNotAllowWithoutReq(XIIthGrad) } })
            }
        } else toastifyError('Field is Empty')
    }

    // Check All Field Format is True Then Submit 
    const { XthBoardErrors, XthSchoolNameErrors, XthPassignYearErrors, XthGradErrors, XIIthBoardErrors, XIIthSchoolNameErrors, XIIthPassignYearErrors, XIIthGradErrors } = errors

    useEffect(() => {
        if (XthBoardErrors === 'true' && XthSchoolNameErrors === 'true' && XthPassignYearErrors === 'true' && XthGradErrors === 'true' && XIIthSchoolNameErrors === 'true' && XIIthPassignYearErrors === 'true' && XIIthGradErrors === 'true' && errorStatus) {
            if (pageStatus) dispatch(update_Employee_Education(value, employeeId))
        }
    }, [XthBoardErrors, XthSchoolNameErrors, XthPassignYearErrors, XthGradErrors, XIIthBoardErrors, XIIthSchoolNameErrors, XIIthPassignYearErrors, XIIthGradErrors, errorStatus])

    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Educational</p>
                    <div style={{ marginLeft: 'auto' }}>
                        {/* <Link to='' className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#">
                            <i className="fa fa-plus"></i>
                        </Link> */}
                    </div>
                </div>
                {/* xth board */}
                <div className="row mt-2">
                    <div className="col-2 col-md-2 col-lg-2 " >
                        <label className='pt-1'>Qualification</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 " >
                        <label className='pt-1'>Name Of Degree/Board</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 " >
                        <label className='pt-1'>School/College</label>
                    </div>
                    <div className="col-2 col-md-2 col-lg-2" >
                        <label className='pt-1'>Passing Year</label>
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 " >
                        <label className='pt-1'>Grade</label>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-1" >
                        <label className='label-field'>Class Xth</label>
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 " >
                        <div className="text-field">
                            <input
                                type="text"
                                name='XthBoard'
                                value={value.XthBoard}
                                onChange={handleChange}
                                id='XthBoard'
                                required
                            />
                        </div>
                    </div>
                    <div className="col-1 col-md-1 col-lg-1 ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='XthSchoolName'
                                value={value.XthSchoolName}
                                onChange={handleChange}
                                id='XthSchoolName'
                                required
                            />
                        </div>
                    </div>
                    <div className="col-1 col-md-1 col-lg-1 ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='XthPassignYear'
                                value={value.XthPassignYear}
                                id='XthPassignYear'
                                maxLength={4}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    {/* <div className="col-4 col-md-4 col-lg-1 ">
                    </div> */}
                    <div className="col-2 col-md-2 col-lg-2 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='XthGrad'
                                value={value.XthGrad}
                                maxLength={3}
                                onChange={handleChange}
                                id='XthGrad'
                                required
                            />
                        </div>
                    </div>
                </div>
                {/* class xii */}
                <div className="row mt-2">
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-1" >
                        <label className='label-field'>Class XIIth</label>
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 " >
                        <div className="text-field">
                            <input
                                type="text"
                                name='XIIthBoard'
                                value={value.XIIthBoard}
                                onChange={handleChange}
                                id='XIIthBoard'
                                required
                            />
                        </div>
                    </div>
                    <div className="col-1 col-md-1 col-lg-1 ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='XIIthSchoolName'
                                value={value.XIIthSchoolName}
                                id='XIIthSchoolName'
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-1 col-md-1 col-lg-1 ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='XIIthPassignYear'
                                value={value.XIIthPassignYear}
                                onChange={handleChange}
                                id='XIIthPassignYear'
                                required
                            />
                        </div>
                    </div>
                    {/* <div className="col-4 col-md-4 col-lg-1 ">
                    </div> */}
                    <div className="col-2 col-md-2 col-lg-2 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='XIIthGrad'
                                value={value.XIIthGrad}
                                onChange={handleChange}
                                id='XIIthGrad'
                                required
                            />
                        </div>
                    </div>
                </div>
                {/* degree */}
                <div className="row mt-2">
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-1" >
                        <label className='label-field'>Degree</label>
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 " >
                        <div className="text-field">
                            <input
                                type="text"
                                name='DegreeBoard'
                                value={value.DegreeBoard}
                                onChange={handleChange}
                                id='DegreeBoard'
                                required
                            />
                        </div>
                    </div>
                    <div className="col-1 col-md-1 col-lg-1 ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='DegreeSchoolName'
                                id='DegreeSchoolName'
                                value={value.DegreeSchoolName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-1 col-md-1 col-lg-1 ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='DegreePassignYear'
                                value={value.DegreePassignYear}
                                onChange={handleChange}
                                id='DegreePassignYear'
                                required
                            />
                        </div>
                    </div>
                    {/* <div className="col-4 col-md-4 col-lg-1 ">
                    </div> */}
                    <div className="col-2 col-md-2 col-lg-2 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='DegreeGrad'
                                value={value.DegreeGrad}
                                onChange={handleChange}
                                id='DegreeGrad'
                                required
                            />
                        </div>
                    </div>
                </div>
                {/* post graduate */}
                <div className="row mt-2">
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-1" >
                        <label className='label-field'>Post Graduate</label>
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 " >
                        <div className="text-field">
                            <input
                                type="text"
                                name='PostDegreeBoard'
                                value={value.PostDegreeBoard}
                                onChange={handleChange}
                                id='PostDegreeBoard'
                                required
                            />
                        </div>
                    </div>
                    <div className="col-1 col-md-1 col-lg-1 ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='PostDegreeSchoolName'
                                value={value.PostDegreeSchoolName}
                                onChange={handleChange}
                                id='PostDegreeSchoolName'
                                required
                            />
                        </div>
                    </div>
                    <div className="col-1 col-md-1 col-lg-1 ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='PostDegreePassignYear'
                                id='PostDegreePassignYear'
                                value={value.PostDegreePassignYear}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    {/* <div className="col-4 col-md-4 col-lg-1 ">
                    </div> */}
                    <div className="col-2 col-md-2 col-lg-2 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='PostDegreeGrad'
                                value={value.PostDegreeGrad}
                                onChange={handleChange}
                                id='PostDegreeGrad'
                                required
                            />
                        </div>
                    </div>
                </div>
                {/* others */}
                <div className="row mt-2">
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-1" >
                        <label className='label-field'>Others</label>
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 " >
                        <div className="text-field">
                            <input
                                type="text"
                                name='OtherDegreeBoard'
                                value={value.OtherDegreeBoard}
                                onChange={handleChange}
                                id='OtherDegreeBoard'
                                required
                            />
                        </div>
                    </div>
                    <div className="col-1 col-md-1 col-lg-1 ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='OtherDegreeSchoolName'
                                value={value.OtherDegreeSchoolName}
                                id='OtherDegreeSchoolName'
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-1 col-md-1 col-lg-1 ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='OtherDegreePassignYear'
                                value={value.OtherDegreePassignYear}
                                onChange={handleChange}
                                id='OtherDegreePassignYear'
                                required
                            />
                        </div>
                    </div>
                    {/* <div className="col-4 col-md-4 col-lg-1 ">
                    </div> */}
                    <div className="col-2 col-md-2 col-lg-2 ">
                        <div className="text-field">
                            <input
                                type="text"
                                name='OtherDegreeGrad'
                                value={value.OtherDegreeGrad}
                                onChange={handleChange}
                                id='OtherDegreeGrad'
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="btn-box text-end col-12 col-md-12 col-lg-12 mt-3">
                    <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error} >Update</button>
                </div>
            </div>
        </>
    )
}

export default Educational

