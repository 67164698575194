import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import Select from "react-select";
import { changeArrayFormatDrop } from '../../../Include/Common/ArrayFormat';
import { get_Employee_Task_Data, get_Employee_Data, get_Single_Employee_Task_Data, update_Employee_Task_Data, get_Status_Drop_Data, get_EmpCount_Task_Data, get_EmpStatus_TaskData, delete_EmpTask_Data, get_Single_Count_Task_Data } from '../../../../redux/actions/AllAction';
import { Edit_Id, Edit_Update_Count, Get_Emp_ID, Get_Emp_Task_Data, Get_Single_Emp_Task_Data, Get_Status_ID, Replay_Status, Status } from '../../../../redux/actionTypes';
import { getShowingDayWithOutTime } from '../../../Include/Common/Utility';
import ConfirmModal from '../../../Include/Common/ConfirmModal';
import EmpTask_Add_Up from './EmpTask_Add_Up';

const EmpTask = () => {
    const dispatch = useDispatch()
    const employeeData = changeArrayFormatDrop(useSelector((state) => state.Data.employeeData), 'EmpName', 'ID');
    const singleTaskCountData = useSelector((state) => state?.Data?.singleTaskCountData[0])
    const emp = (useSelector((state) => state.Data.statusDropDown));
    const taskID = useSelector((state) => state?.Data?.taskID)
    const empTaskData = useSelector((state) => state.Data.empTaskData)
    const empID = useSelector((state) => state.Data.empID)
    const statusID = useSelector((state) => state.Data.statusID)
    const userId = useSelector((state) => state.Data.userId)
    const empTaskDropDown = changeArrayFormatDrop(useSelector((state) => state.Data.empTaskDropDown),'TaskStatus','ID');
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const [pageStatus, setPageStatus] = useState("1")
    const [confirmType, setConfirmType] = useState('Delete')
    const [IsActive, setIsActive] = useState()
    const [id, setId] = useState('')
    const [value, setValue] = useState({
        AssignEmpName: "",
        EmpName: "",
        ExpectedDate: "",
        ID: "",
        StatusID: "",
        Tasktype: ""
    })
    useEffect(() => { setValue({ 'ID': taskID }) }, [taskID]);

    useEffect(() => {
        dispatch(get_Employee_Data('1')); dispatch(get_EmpStatus_TaskData())
    }, []);

    const changeDropdown = (e, name) => {
        if (e) {
            if (name === "EmpName") { dispatch({ type: Get_Emp_ID, payload: e.value }); }
            else if (name === 'StatusID') { dispatch({ type: Get_Status_ID, payload: e.value }); dispatch(get_Single_Count_Task_Data(empID, e.value)) }
        }
        else { dispatch({ type: Get_Status_ID, payload: '' }); dispatch({ type: Get_Emp_ID, payload: '' }); dispatch({ type: Get_Emp_Task_Data, payload: [] }); }
    }

    useEffect(() => {
        if (singleTaskCountData) {
            setValue({
                ...value,
                AssignEmpName: singleTaskCountData.AssignEmpName,
                EmpName: singleTaskCountData.EmpName,
                ExpectedDate: singleTaskCountData.ExpectedDate,
                ID: singleTaskCountData.ID,
                StatusID: singleTaskCountData.StatusID,
                Tasktype: singleTaskCountData.Tasktypegit 
            })
        } else {
            setValue({
                AssignEmpName: '',
                EmpName: '',
                ExpectedDate: '',
                ID: '',
                StatusID: '',
                Tasktype: ''
            })
        }
    }, [singleTaskCountData, editUpdateCount])

    const columns = [
        {
            name: 'Task Type',
            selector: (row) => row.Tasktype,
            sortable: true
        },
        {
            name: 'AssignEmpName',
            selector: (row) => row.AssignEmpName,
            sortable: true
        },
        {
            name: 'AssignDate',
            selector: (row) => getShowingDayWithOutTime(row.AssignDate),
            sortable: true
        },
        {
            name: 'ExpectedDate',
            selector: (row) => row.ExpectedDate,
            sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 40, fontWeight: '700' }}>Action</p>,
            cell: row => <>
                <div style={{ position: 'absolute', top: 4, right: 40 }}>
                    {
                        pageStatus === "1" ?
                            <Link to='/employee-task' onClick={() => setEditStatus(row)}
                                className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn" data-toggle="modal" data-target="#EmpStatusModal"><i className="fa fa-edit"></i>
                            </Link> : ''
                    }
                    <Link to={`/employee-home`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('0'); setConfirmType("Delete"); setId(row.ID) }}
                        className="btn btn-sm bg-green text-white px-1 py-0 mr-1" >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </Link>
                </div>
            </>
        }
    ]

    const setEditStatus = (row) => {
        dispatch({ type: Status, payload: true }); dispatch({ type: Edit_Id, payload: row.ID })
        dispatch(get_Single_Employee_Task_Data(row.ID));
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 }); dispatch({ type: Replay_Status, payload: false })
    }

    const setNewStatus = () => {
        dispatch({ type: Status, payload: false });
         dispatch({ type: Edit_Id, payload: '' });
          dispatch({ type: Get_Single_Emp_Task_Data, payload: [] }) 
        dispatch({ type: Replay_Status, payload: true })
    }

    const customStylesWithOutColor = {
        control: (base) => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    };
    const UpdActiveDeactive = () => {
        dispatch(delete_EmpTask_Data(id, IsActive, userId, statusID, empID ))
      }
    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="row">
                    <div className="col-6 col-md-6 col-lg-6">
                        <div className="dropdown__box">
                            <Select
                                name="EmpName"
                                styles={customStylesWithOutColor}
                                isClearable
                                onChange={(e) => { changeDropdown(e, "EmpName") }}
                                value={employeeData?.filter((obj) => obj.value === empID)}
                                options={employeeData}
                                placeholder="Select.."
                            />
                            <label htmlFor="" className='pl-0'>Employee Name</label>
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-6">
                        <div className="dropdown__box">
                            <Select
                                name="StatusID"
                                styles={customStylesWithOutColor}
                                isClearable
                                onChange={(e) => { changeDropdown(e, "StatusID") }}
                                value={empTaskDropDown?.filter((obj) => obj.value === statusID)}
                                options={empTaskDropDown}
                                placeholder="Select.."
                            />
                            <label htmlFor="" className='pl-0'>Status</label>
                        </div>
                    </div>
                </div>
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Employee Task</p>
                    <div style={{ marginLeft: 'auto' }}>
                        <Link to={'/employee-task'} onClick={setNewStatus} className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#" >
                            <i className="fa fa-plus"></i>
                        </Link>
                        <Link to={'/HR?sub=Emp-Task'}  className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#" >
                            <i className="fa fa-close"></i>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="col-12 pt-2 px-3">
                <DataTable
                    dense
                    columns={columns}
                    data={empTaskData}
                    paginationPerPage={'10'}
                    paginationRowsPerPageOptions={[5, 10, 15]}
                    highlightOnHover
                    noContextMenu
                    pagination
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    noDataComponent={"There are no data to display"}
                />
            </div>
            <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />
            {/* <EmpTask_Add_Up /> */}
        </>
    )
}

export default EmpTask