import React from 'react'
import { Link } from 'react-router-dom'
import HourReading_Add from './HourReading_Add'
import DataTable from 'react-data-table-component'

const HourReading = () => {
  return (
    <>
      <div className="col-12 col-md-12 col-lg-12  px-3">
        <div className="bg-line  py-1 px-2  d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">Hour Reading</p>
          <div style={{ marginLeft: 'auto' }}>
            <Link to={'/Plant-Transport?sub=Daily%20Running'} className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#HourReadingModal">
              <i className="fa fa-plus"></i>
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="col-12 px-2  mb-3">
        <div className="table-responsive">
          <table className="table table-bordered">
            <tr className="border-bottom">
              <th>reg no</th>
              <th>maintainance type</th>
              <th>change hrs</th>
              <th>last change date</th>
              <th>last change hrs</th>
              <th>next change hrs</th>
              <th className='text-right'>Action</th>
            </tr>
            <tr>
              <td>Testing </td>
              <td>Testing </td>
              <td>Testing </td>
              <td>Testing </td>
              <td>Testing </td>
              <td>Testing </td>
           

              <td className='text-right'>
                <Link to={'/Plant-Transport?sub=Daily%20Running'}>
                  <button type='button' className="btn btn-sm bg-green text-white  py-0 " data-toggle="modal" data-target="#HourReadingModal"><i className="fa fa-edit"></i>
                  </button>
                </Link>
                <button type='button' className="btn btn-sm bg-green text-white py-0 ml-1"><i className="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div> */}

      <div className="col-12 ">
        <DataTable
          dense
          // columns={columns}
          // data={nameSearchValue}
          pagination
          selectableRowsHighlight
          highlightOnHover
        />
      </div>
      <HourReading_Add />
    </>
  )
}

export default HourReading