import React, { memo, useEffect, useState } from 'react'
import Select from "react-select";
import { customStylesWithOutColor } from '../../../../Include/Common/Utility';
import { get_Product_Dropdown, get_Unit_Dropdown, save_AddItem_Data, update_AddItem_Data } from '../../../../../redux/actions/AllAction';
import { useDispatch, useSelector } from 'react-redux';
import { RequiredFieldSelectBox, SpaceNotAllow } from '../../../../Validation/Validation';
import { Get_Error_Status } from '../../../../../redux/actionTypes';
import { Link } from 'react-router-dom';
import { changeArrayFormatDrop } from '../../../../Include/Common/ArrayFormat';
const ItemDetailsAddUp = (props) => {
    const dispatch = useDispatch()
    const userId = useSelector((state) => state.Data.userId);
    const productDropdown = changeArrayFormatDrop(useSelector((state) => state.Data.productDropdown),'Name','ID')
    const modalStatus = useSelector((state) => state.Data.modalStatus)
    const editUpdateCount = useSelector((state) => state.Data.singleAddItemData)
    const singleAddItemData = useSelector((state) => state.Data.singleAddItemData[0])
    const companyId = useSelector((state) => state.Data.companyId)
    const { status, pageStatus } = props
    const invoiceID = useSelector((state) => state?.Data?.invoiceID)
    const unitDropdown = changeArrayFormatDrop(useSelector((state) => state.Data.unitDropdown),'UnitType','ID');
    
    const [value, setValue] = useState({
        PurchaseInvoiceID: invoiceID,
        Product: '',
        ProductID: '',
        Qty: '',
        UnitType: '',
        UnitTypeID: '',
        Rate: '',
        TotalTaxAmt: '',
        IGSTP: '',
        IGSTAmt: '',
        CGSTP: '',
        CGSTAmt: '',
        SGSTP: '',
        SGSTAmt: '',
        TotalAmt: '',
        companyid: companyId,
        Createdbyid: userId,
    })

    useEffect(() => {
        setValue(prs => { return { ...prs, ['PurchaseInvoiceID']: invoiceID } })
    }, [invoiceID])

    const [errors, setErrors] = useState({
        ItemError: '', RateError: '', QtyError: ''
    })

    const { ItemError, RateError, QtyError } = errors
    const handlChange = (e) => {
        if (e.target.name === 'Qty' || e.target.name === 'TotalTaxAmt' || e.target.name === 'IGSTP' || e.target.name === 'SGSTP' || e.target.name === 'CGSTP' || e.target.name === 'SGSTAmt' || e.target.name === 'IGSTAmt' || e.target.name === 'SGSTAmt' || e.target.name === 'CGSTAmt' || e.target.name === "TotalAmt") {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setValue({
                ...value,
                [e.target.name]: checkNumber
            })
        }
        else {
            setValue({
                ...value,
                [e.target.name]: e.target.value,
            });
        }

    }
    useEffect(() => {
        dispatch(get_Product_Dropdown())
        dispatch(get_Unit_Dropdown());
    }, [])
    const handleDropdown = (e, name) => {
        if (name === 'ProductID') {
            setValue(pre => { return { ...pre, ['ProductID']: e?.value, ['Product']: e?.label } })
        }
        else if (name === 'UnitTypeID') {
            setValue(pre => { return { ...pre, ['UnitTypeID']: e?.value, ['UnitType']: e?.label } })
        }
    }
    useEffect(() => {
        if (ItemError === 'true' && QtyError === 'true' && RateError === 'true') {
            if (status) { dispatch(update_AddItem_Data(value, invoiceID)); setErrors({ ['ItemError']: '' }) }
            else { dispatch(save_AddItem_Data(value, invoiceID)); setErrors({ ['ItemError']: '' }) }
        }
    }, [ItemError, QtyError, RateError])

    useEffect(() => {
        if (singleAddItemData) {
            setValue({
                ...value,
                PurchaseInvoiceID: invoiceID,
                Product: singleAddItemData.Product,
                ProductID: singleAddItemData.ProductID,
                Qty: singleAddItemData.Qty,
                UnitType: singleAddItemData.UnitType,
                UnitTypeID: singleAddItemData.UnitTypeID,
                Rate: singleAddItemData.Rate,
                TotalTaxAmt: singleAddItemData.TotalTAXAmt,
                IGSTP: singleAddItemData.IGSTP,
                IGSTAmt: singleAddItemData.IGSTAmt,
                CGSTP: singleAddItemData.CGSTP,
                CGSTAmt: singleAddItemData.CGSTAmt,
                SGSTP: singleAddItemData.SGSTP,
                SGSTAmt: singleAddItemData.SGSTAmt,
                TotalAmt: singleAddItemData.TotalAmt,
                companyid: companyId,
                UpdatedId: userId,
                ID: singleAddItemData.ID
            })
        }
        else {
            setValue({
                ...value,
                PurchaseInvoiceID: invoiceID,
                Product: '',
                ProductID: '',
                Qty: '',
                UnitType: '',
                UnitTypeID: '',
                Rate: '',
                TotalTaxAmt: '',
                IGSTP: '',
                IGSTAmt: '',
                CGSTP: '',
                CGSTAmt: '',
                SGSTP: '',
                SGSTAmt: '',
                TotalAmt: '',
                companyid: '',
            })
        }
    }, [singleAddItemData, editUpdateCount])

    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (RequiredFieldSelectBox(value.Product)) {
            setErrors(prevValues => { return { ...prevValues, ['ItemError']: RequiredFieldSelectBox(value.Product) } });
            dispatch({ type: Get_Error_Status, payload: true });
        }
        if (SpaceNotAllow(value.Qty)) {
            setErrors(prevValues => { return { ...prevValues, ['QtyError']: SpaceNotAllow(value.Qty) } });
        }
        if (SpaceNotAllow(value.Rate)) {
            setErrors(prevValues => { return { ...prevValues, ['RateError']: SpaceNotAllow(value.Rate) } })
        }
    };
    const resetHooks = () => {
        setValue(pre => {
            return {
                ...pre, Product: '',
                ProductID: '',
                Qty: '',
                UnitType: '',
                UnitTypeID: '',
                Rate: '',
                TotalTaxAmt: '',
                IGSTP: '',
                IGSTAmt: '',
                CGSTP: '',
                CGSTAmt: '',
                SGSTP: '',
                SGSTAmt: '',
                TotalAmt: '',
                companyid: '',
                ID: ''
            }
        })
        setErrors(pre => { return { ...pre, ItemError: '', QtyError: '', RateError: '' } })
    }
    return (
        <>
            {modalStatus &&
                <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="AddItemModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="m-1 ">
                                    <fieldset style={{ border: '1px solid gray' }}>
                                        <legend >Add Item</legend>
                                        <div className="form-row" style={{ marginTop: '-10px' }}>
                                            <div className="col-6 col-md-6 col-lg-4 d-flex mt-2 px-0">
                                                <div className="col-10 col-md-10 col-lg-10 ">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            name='ProductID'
                                                            value={productDropdown?.filter((obj) => obj.value === value.ProductID)}
                                                            options={productDropdown}
                                                            styles={customStylesWithOutColor}
                                                            isClearable
                                                            className="requiredColor"
                                                            placeholder="Select.."
                                                            onChange={(e) => handleDropdown(e, 'ProductID')}
                                                        />
                                                        <label>Item</label>
                                                    </div>
                                                    {errors.ItemError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.ItemError}
                                                        </span>
                                                    ) : null}
                                                </div>
                                                <div className="col-1   px-0" style={{ marginTop: '13px' }}>
                                                    <Link to={'/purchaseInvoice'} className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal"
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 mt-2">
                                                <div className="text-field">
                                                    <input
                                                        type="text"
                                                        name='Qty'
                                                        className=""
                                                        id='Qty'
                                                        value={value.Qty}
                                                        onChange={handlChange}
                                                        required
                                                    />
                                                    <label className='pt-1'>Qty.</label>
                                                </div>
                                                {errors.QtyError !== "true" ? (
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            fontSize: "13px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                        }}
                                                    >
                                                        {errors.QtyError}
                                                    </span>
                                                ) : null}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 mt-1 d-flex px-0">
                                            <div className="col-11 col-md-10 col-lg-11">
                                                <div className="dropdown__box">
                                                    <Select
                                                        name='UnitTypeID'
                                                        styles={customStylesWithOutColor}
                                                        isClearable
                                                        placeholder="Select.."
                                                        onChange={(e) => handleDropdown(e, 'UnitTypeID')}
                                                        value={unitDropdown?.filter((obj) => obj?.label === value?.UnitType)}
                                                        options={unitDropdown}
                                                    />
                                                    <label>NOS</label>
                                                </div>
                                            </div>
                                            <div className="col-1   px-0" style={{ marginTop: '13px' }}>
                                                    <Link to={'/purchaseInvoice'} className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal"
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                    </Link>
                                                </div>
                                                </div>
                                            <div className="col-6 col-md-6 col-lg-4 mt-2">
                                                <div className="text-field">
                                                    <input
                                                        type="text"
                                                        name='Rate'
                                                        className=""
                                                        id='Rate'
                                                        onChange={handlChange}
                                                        value={value.Rate}
                                                        required
                                                    />
                                                    <label className='pt-1'>Rate</label>
                                                </div>
                                                {errors.RateError !== "true" ? (
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            fontSize: "13px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                        }}
                                                    >
                                                        {errors.RateError}
                                                    </span>
                                                ) : null}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 mt-2">
                                                <div className="text-field">
                                                    <input
                                                        type="text"
                                                        name='TotalTaxAmt'
                                                        className=""
                                                        id='TotalTaxAmt'
                                                        value={value.TotalTaxAmt}
                                                        onChange={handlChange}
                                                        required
                                                    />
                                                    <label className='pt-1'>Total Taxable Amt</label>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 mt-2">
                                                <div className="text-field">
                                                    <input
                                                        type="text"
                                                        name='IGSTP'
                                                        className=""
                                                        id='IGSTP'
                                                        value={value.IGSTP}
                                                        onChange={handlChange}
                                                        required
                                                    />
                                                    <label className='pt-1'>Igst Percentage</label>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 mt-1 pt-1">
                                                <div className="text-field">
                                                    <input
                                                        type="text"
                                                        name='IGSTAmt'
                                                        className=""
                                                        id='IGSTAmt'
                                                        value={value.IGSTAmt}
                                                        onChange={handlChange}
                                                        required
                                                    />
                                                    <label className='pt-1'>IGST Amount</label>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 mt-2">
                                                <div className="text-field">
                                                    <input
                                                        type="text"
                                                        name='CGSTP'
                                                        className=""
                                                        id='CGSTP'
                                                        value={value.CGSTP}
                                                        onChange={handlChange}
                                                        required
                                                    />
                                                    <label className='pt-1'>CGST Percentage</label>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 mt-2">
                                                <div className="text-field">
                                                    <input
                                                        type="text"
                                                        name='CGSTAmt'
                                                        className=""
                                                        id='CGSTAmt'
                                                        value={value.CGSTAmt}
                                                        onChange={handlChange}
                                                        required
                                                    />
                                                    <label className='pt-1'>CGST Amt</label>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 mt-2">
                                                <div className="text-field">
                                                    <input
                                                        type="text"
                                                        name='SGSTP'
                                                        className=""
                                                        id='SGSTP'
                                                        value={value.SGSTP}
                                                        onChange={handlChange}
                                                        required
                                                    />
                                                    <label className='pt-1'>SGST Percentage</label>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 mt-1 pt-1">
                                                <div className="text-field">
                                                    <input
                                                        type="text"
                                                        name='SGSTAmt'
                                                        className=""
                                                        id='SGSTAmt'
                                                        value={value.SGSTAmt}
                                                        onChange={handlChange}
                                                        required
                                                    />
                                                    <label className='pt-1'>SGST Amount</label>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 mt-2">
                                                <div className="text-field">
                                                    <input
                                                        type="text"
                                                        name='TotalAmt'
                                                        className=""
                                                        id='TotalAmt'
                                                        value={value.TotalAmt}
                                                        onChange={handlChange}
                                                        required
                                                    />
                                                    <label className='pt-1'>Grand Total</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div className="btn-box text-right  mr-1  mt-2">
                                        {status ? <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error} >Update</button> : <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error} >Save</button>}
                                        <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={resetHooks}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default memo(ItemDetailsAddUp)