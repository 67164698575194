import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux'
import { JoiningDate, LeavingDate, LeavingReason, OrganizationName, OrganizationSalary } from '../../../../../../../redux/actionTypes';
import { update_Employee_Eorganization } from '../../../../../../../redux/actions/AllAction';
import { toastifyError } from '../../../../../../Include/Common/AlertMsg';
import { getShowingDayWithOutTime, getShowingWithOutTimeStartDate } from '../../../../../../Include/Common/Utility';

const PreviousEmployement = () => {

    const dispatch = useDispatch()
    const pageStatus = useSelector((state) => state.Data.Status)
    const employeeId = useSelector((state) => state.Data.employeeId)
    const employeeSingleData = useSelector((state) => state.Data.employeeSingleData[0]);
    const userId = useSelector((state) => state.Data.userId)

    const [state, setState] = useState([])
    const [rowCount, setRowCount] = useState(0);

    const [value, setValue] = useState({
        OrganizationName1: '', OrganizationName2: '', OrganizationName3: '', OrganizationName4: '', JoiningDate1: null, JoiningDate2: null, JoiningDate3: null, JoiningDate4: null, LeavingDate1: null, LeavingDate2: null, LeavingDate3: null, LeavingDate4: null,
        JoiningDate11: null, JoiningDate21: null, JoiningDate31: null, JoiningDate41: null, LeavingDate11: null, LeavingDate21: null, LeavingDate31: null, LeavingDate41: null, OrganizationSalary1: '', OrganizationSalary2: '', OrganizationSalary3: '', OrganizationSalary4: '', LeavingReason1: '', LeavingReason2: '', LeavingReason3: '', LeavingReason4: '', ID: '',
        Updatedbyid: userId
    })

    useEffect(() => {
        if (employeeSingleData) {
            setValue(pre => {
                return {
                    ...pre, OrganizationName1: employeeSingleData?.OrganizationName1, OrganizationName2: employeeSingleData?.OrganizationName2, OrganizationName3: employeeSingleData?.OrganizationName3, OrganizationName4: employeeSingleData?.OrganizationName4,
                    JoiningDate1: employeeSingleData?.JoiningDate1,
                    JoiningDate2: employeeSingleData?.JoiningDate2,
                    JoiningDate3: employeeSingleData?.JoiningDate3,
                    JoiningDate4: employeeSingleData?.JoiningDate4,
                    LeavingDate1: employeeSingleData?.LeavingDate1,
                    LeavingDate2: employeeSingleData?.LeavingDate2,
                    LeavingDate3: employeeSingleData?.LeavingDate3,
                    LeavingDate4: employeeSingleData?.LeavingDate4,
                    JoiningDate11: employeeSingleData?.JoiningDate1?.split("/").reverse().join("/"),
                    JoiningDate21: employeeSingleData?.JoiningDate2?.split("/").reverse().join("/"),
                    JoiningDate31: employeeSingleData?.JoiningDate3?.split("/").reverse().join("/"),
                    JoiningDate41: employeeSingleData?.JoiningDate4?.split("/").reverse().join("/"),
                    LeavingDate11: employeeSingleData?.LeavingDate1?.split("/").reverse().join("/"),
                    LeavingDate21: employeeSingleData?.LeavingDate2?.split("/").reverse().join("/"),
                    LeavingDate31: employeeSingleData?.LeavingDate3?.split("/").reverse().join("/"),
                    LeavingDate41: employeeSingleData?.LeavingDate4?.split("/").reverse().join("/"),
                    OrganizationSalary1: employeeSingleData?.OrganizationSalary1, OrganizationSalary2: employeeSingleData?.OrganizationSalary2, OrganizationSalary3: employeeSingleData?.OrganizationSalary3, OrganizationSalary4: employeeSingleData?.OrganizationSalary4, LeavingReason1: employeeSingleData?.LeavingReason1, LeavingReason2: employeeSingleData?.LeavingReason2, LeavingReason3: employeeSingleData?.LeavingReason3, LeavingReason4: employeeSingleData?.LeavingReason4, ID: employeeSingleData?.ID
                }
            })
            for (let i = 1; i < 5; i++) {
                if (employeeSingleData[OrganizationName + i] && employeeSingleData[OrganizationName + i] !== '') {
                    setRowCount(i)
                }
            }
        }

    }, [employeeSingleData])

    useEffect(() => {
        if (rowCount > 0) {
            const newArray = [];
            for (let i = 1; i <= rowCount; i++) {
                newArray.push({
                    id: i,
                    [OrganizationName + i]: employeeSingleData[OrganizationName + i],
                    [JoiningDate + i]: employeeSingleData[JoiningDate + i],
                    [LeavingDate + i]: employeeSingleData[LeavingDate + i],
                    [JoiningDate + i + '1']: employeeSingleData[JoiningDate + i]?.split("/").reverse().join("/"),
                    [LeavingDate + i + '1']: employeeSingleData[LeavingDate + i]?.split("/").reverse().join("/"),
                    [LeavingReason + i]: employeeSingleData[LeavingReason + i],
                    [OrganizationSalary + i]: employeeSingleData[OrganizationSalary + i]
                })
            }
            setState(newArray);
        }
    }, [rowCount])

    const handleChange = (idx, type) => e => {
        const upd_obj = state.map(obj => {
            if (obj.id == idx) {
                if (type) {

                    if (e) {
                        obj[type + idx] = getShowingDayWithOutTime(e);
                        obj[type + idx + '1'] = getShowingWithOutTimeStartDate(e);
                        setValue(pre => { return { ...pre, [type + idx]: getShowingDayWithOutTime(e), [type + idx + '1']: getShowingWithOutTimeStartDate(e) } })
                    } else {
                        obj[type + idx] = '';
                        obj[type + idx + '1'] = '';
                        setValue(pre => { return { ...pre, [type + idx]: '', [type + idx + '1']: '' } })
                    }
                } else {
                    obj[e.target.name] = e.target.value;
                    setValue(pre => { return { ...pre, [e.target.name]: e.target.value } })
                }
            }
            return obj;
        })
        setState(upd_obj);
    };

    const handleAddRow = () => {
        const item = {
            id: state.length + 1,
            [OrganizationName + (state.length + 1)]: "",
            [JoiningDate + (state.length + 1)]: "",
            [LeavingDate + (state.length + 1)]: "",
            [JoiningDate + (state.length + 1) + '1']: "",
            [LeavingDate + (state.length + 1) + '1']: "",
            [OrganizationSalary + (state.length + 1)]: "",
            [LeavingReason + (state.length + 1)]: ""
        };
        if (state.length < 4) {
            setState([...state, item]);
        }
    };

    const handleRemoveSpecificRow = (idx) => () => {
        const rows = [...state]
        const data = rows.filter(function (element) {
            return element.id !== idx;
        });
        setState(data);
        setValue(pre => { return { ...pre, [OrganizationName + idx]: '', [JoiningDate + idx]: '', [LeavingDate + idx]: '', [JoiningDate + idx + '1']: '', [LeavingDate + idx + '1']: '', [OrganizationSalary + idx]: '', [LeavingReason + idx]: '' } })
    }

    // Check validation on Field
    const check_Validation_Error = (e) => {
        e.preventDefault()
        const res = state.filter((element, id) => {
            return element[OrganizationName + (id + 1)] === '' || element[OrganizationName + (id + 1)] === null;
        });
        if (res.length === 0) {
            if (pageStatus) dispatch(update_Employee_Eorganization(value, employeeId))
        } else toastifyError('OrganizationName Field is Empty')
    }

    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Previous Employement</p>
                    <div style={{ marginLeft: 'auto' }}>
                        <button onClick={handleAddRow} className="btn btn-sm btn-success px-2 py-0 ml-auto">
                            <i className='fa fa-plus'></i>
                        </button>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 col-md-12 col-lg-12" >
                        <table class="table table-bordered" >
                            <thead style={{ backgroundColor: 'blanchedalmond ' }}>
                                <tr>
                                    <th >S.no</th>
                                    <th>name of organization</th>
                                    <th>JOINING DATE</th>
                                    <th>leaving DATE</th>
                                    <th>salary</th>
                                    <th>Reason OF LEAVING</th>
                                    <th className='text-right'>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {state?.map((item, idx) => (
                                    <tr id="addr0" key={idx}>
                                        <td className='payment-serial pt-3'>{idx + 1}</td>
                                        <td>
                                            <div className="text-field mt-3" >
                                                <input
                                                    type="text"
                                                    name={`OrganizationName${idx + 1}`}
                                                    value={item[OrganizationName + item.id]}
                                                    onChange={handleChange(item.id)}
                                                    required
                                                />
                                            </div>
                                        </td>

                                        <td className='payment-amount1' >
                                            <div className="dropdown__box" >
                                                <DatePicker
                                                    id='JoiningDate'
                                                    name='JoiningDate'
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={handleChange(item.id, 'JoiningDate')}
                                                    isClearable
                                                    selected={item[JoiningDate + item.id + '1'] && new Date(item[JoiningDate + item.id + '1'])}
                                                    placeholderText={'Select...'}
                                                    dropdownMode="select"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                        </td>
                                        <td className='payment-amount1'>
                                            <div className="dropdown__box">
                                                <DatePicker
                                                    id='LeavingDate'
                                                    name='LeavingDate'
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={handleChange(item.id, 'LeavingDate')}
                                                    isClearable
                                                    selected={item[LeavingDate + item.id + '1'] && new Date(item[LeavingDate + item.id + '1'])}
                                                    placeholderText={'Select...'}
                                                    dropdownMode="select"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                        </td>
                                        <td className='payment-amount1 '>
                                            <div className="text-field mt-3">
                                                <input
                                                    type="text"
                                                    name={`OrganizationSalary${idx + 1}`}
                                                    value={item[OrganizationSalary + item.id]}
                                                    onChange={handleChange(item.id)}
                                                    required
                                                />
                                            </div>
                                        </td>
                                        <td className='payment-amount1' >
                                            <div className="dropdown__box">
                                                <textarea name={`LeavingReason${idx + 1}`} id="LeavingReason" cols="30" rows='1' className="form-control"
                                                    value={item[LeavingReason + item.id]} onChange={handleChange(item.id)}
                                                ></textarea>
                                            </div>
                                        </td>
                                        <td className='payment-button ' style={{ paddingTop: '23px' }}>
                                            <button
                                                className="btn btn-outline-danger btn-sm"
                                                onClick={handleRemoveSpecificRow(item.id)} style={{ fontSize: '12px' }} >
                                                <i className="fa fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>

                                ))}
                            </tbody>
                        </table>
                        <div className="btn-box text-end col-12 col-md-12 col-lg-12 ">
                            <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error} >Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PreviousEmployement