const iconHome = <i className="fa fa-home" style={{ fontSize: '20px' }}></i>
export const EmplyeeTabs = [
    {
        tab: iconHome,
        path: "home",
    },
    {
        tab: "Personal Details",
        path: "personaldetails"
    },   
    {
        tab: "Contact Address",
        path: "contactaddress"
    },   
    {
        tab: "Family Details",
        path: "familydetails"
    },   
    {
        tab: "Educational",
        path: "educational"
    },   
    {
        tab: "Previous Employement",
        path: "previousemployement"
    },   
    {
        tab: "Bank Details",
        path: "bankdetails"
    },   
    // {
    //     tab: "Salary",
    //     path: "salary"
    // },   
    {
        tab: "Documents",
        path: "documents"
    },   
    {
        tab: "Status",
        path: "status"
    },   
]
export const SalePartyTabs = [
    {
        tab: iconHome,
        path: "home",
    },
    {
        tab: "Bank Details",
        path: "bankdetails"
    },   
     
]
export const VehicleDetailsTabs = [
    {
        path: "home",
    },
     
]
export const VehicleDetailsSubTabs = [
    {
        tab: "Insurance Details",
        path: "Insurancedetails",
    },
    {
        tab: "RTO Details",
        path: "RTOdetails",
    },
    {
        tab: "Fitness Details",
        path: "otherrenewal",
    },
    {
        tab: "PUC Details",
        path: "pucdetails",
    },
     
]
export const    DailyRunningTabs = [
    {
        tab: "Meter Reading",
        path: "meterreading",
    },
    {
        tab: "Hour Reading",
        path: "hourreading",
    },
  
     
]