import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from "react-select";
import { Get_Error_Status, Get_Make_DropDown } from '../../../../redux/actionTypes';
import { get_Employee_Data, get_Product_Data, get_Product_Dropdown, get_Unit_Dropdown, save_Request_List_Data, update_Request_List_Data } from '../../../../redux/actions/AllAction';
import { changeArrayFormatDrop } from '../../../Include/Common/ArrayFormat';
import { RequiredFieldSelectBox, SpaceNotAllow } from '../../../Validation/Validation';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import MasSideBar from '../../../Include/Common/MasSideBar';

const AddRequest_Add_Up = (props) => {
    const dispatch = useDispatch()
    const { status, pageStatus } = props
    const userId = useSelector((state) => state.Data.userId)
    const ReqId = useSelector((state) => state.Data.reqID)
    const modalStatus = useSelector((state) => state.Data.modalStatus)
    const productDropdown = useSelector((state) => state.Data.productDropdown)
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const singleRequestListData = useSelector((state) => state.Data.singleRequestListData[0])
    const unitDropdown = useSelector((state) => state.Data.unitDropdown)
    const employeeData = changeArrayFormatDrop(useSelector((state) => state.Data.employeeData), 'EmpName', 'ID');
    const [urlName, setUrlName] = useState('')
    const [showCol, setShowCol] = useState('')
    const [idName, setIdName] = useState('')
    const [actionType, setActionType] = useState('')
    const customStylesWithOutColor = {
        control: (base) => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    };

    useEffect(() => { setValue({ 'RequestID': ReqId }) }, [ReqId]);

    const [value, setValue] = useState({
        RequestID: ReqId,
        Productid: '',
        Product: '',
        ReqQty: '',
        MakeID: '',
        Make: '',
        TobeApprovedbyID: '',
        TobeApprovedby: '',
        IsforStock: 0,
        Remarks: '',
        Createdbyid: userId,
        ID: ''
    })
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }
    const [errors, setErrors] = useState({
        ReqError: '', ItemCodeError: ''
    })
    const { ReqError, ItemCodeError } = errors

    const handelChange = (e) => {
        if (e.target.name === 'IsforStock') {
            setValue({
                ...value,
                [e.target.name]: e.target.checked
            });
        } else if (e.target.name === 'ReqQty') {
            const checkNumber = e.target.value.replace(/[^0-9\.s]/g, "");
            setValue({
                ...value,
                [e.target.name]: checkNumber
            })
        }
        else {
            setValue({
                ...value,
                [e.target.name]: e.target.value,
            });
        }

    }

    useEffect(() => {
        if (singleRequestListData) {
            setValue({
                ...value,
                RequestID: ReqId,
                Productid: singleRequestListData.Productid,
                Product: singleRequestListData.Product,
                ReqQty: singleRequestListData.ReqQty,
                MakeID: singleRequestListData.MakeID,
                Make: singleRequestListData.Make,
                TobeApprovedbyID: singleRequestListData.TobeApprovedbyID,
                TobeApprovedby: singleRequestListData.TobeApprovedby,
                IsforStock: singleRequestListData.IsforStock,
                Remarks: singleRequestListData.Remarks,
                Updatedbyid: userId,
                ID: singleRequestListData?.ID
            })
        }
        else {
            setValue({
                ...value,
                RequestID: ReqId,
                Productid: '',
                Product: '',
                ReqQty: '',
                MakeID: '',
                Make: '',
                TobeApprovedbyID: '',
                TobeApprovedby: '',
                IsforStock: 0,
                Remarks: '',
                Updatedbyid: '',
            })
        }
    }, [singleRequestListData, editUpdateCount])

    useEffect(() => {
        dispatch(get_Product_Dropdown())
        dispatch(get_Unit_Dropdown())
        if (employeeData.length === 0) dispatch(get_Employee_Data('1'))
    }, [])

    const handleDropdown = (e, name, label) => {
        if (e) {
            setValue({
                ...value,
                [name]: e.value,
                [label]: e.label
            })
        } else {
            setValue({
                ...value,
                [name]: null,
                [label]: '',
            })
        }
    }
    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (RequiredFieldSelectBox(value.Product)) {
            setErrors(prevValues => { return { ...prevValues, ['ItemCodeError']: RequiredFieldSelectBox(value.Product) } })
            dispatch({ type: Get_Error_Status, payload: true })
        }
        if (SpaceNotAllow(value.ReqQty)) {
            setErrors(prevValues => { return { ...prevValues, ['ReqError']: SpaceNotAllow(value.ReqQty) } })

        }

    }
    useEffect(() => {
        if (ReqError === 'true' && ItemCodeError === 'true') {
            if (status) { dispatch(update_Request_List_Data(value, ReqId)); setErrors({ ['ReqError']: '' }) }
            else { dispatch(save_Request_List_Data(value, ReqId)); setErrors({ ['ReqError']: '' }) }
        }
    }, [ReqError, ItemCodeError])
    const resetHooks = () => {
        setValue(pre => {
            return {
                ...pre,
                RequestID: '',
                Productid: '',
                Product: '',
                ReqQty: '',
                MakeID: '',
                Make: '',
                TobeApprovedbyID: '',
                TobeApprovedby: '',
                IsforStock: 0,
                Remarks: '',
                Updatedbyid: '',
                ID: ''
            }
        })
        setErrors(pre => { return { ...pre, ReqError: '', ItemCodeError: '' } })
    }
    const setUrls = (idName, urlName, showCol, actionType) => {
        setIdName(idName); setUrlName(urlName); setShowCol(showCol); setActionType(actionType)
    }
    return (
        <>
            {modalStatus &&
                <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="AddRequestModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="m-1 ">
                                    <fieldset style={{ border: '1px solid gray' }}>
                                        <legend >Add Item Request</legend>
                                        <div className="form-row" style={{ marginTop: '-10px' }}>
                                            <div className="col-6 col-md-6 col-lg-4 d-flex mt-2">
                                                <div className="col-10 col-md-10 col-lg-10 ">
                                                    <div class="text-field">
                                                        <Select
                                                            name='Productid'
                                                            styles={colourStyles}
                                                            value={productDropdown?.filter((obj) => obj.value?.toString() === value?.Productid?.toString())}
                                                            options={productDropdown}
                                                            isClearable
                                                            className="requiredColor"
                                                            placeholder="Select.."
                                                            onChange={(e) => handleDropdown(e, 'Productid', 'Product')}
                                                        />
                                                        <label className='pt-1'>Item Code</label>
                                                    </div>
                                                    {errors.ItemCodeError !== 'true' ? (
                                                        <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ItemCodeError}</span>
                                                    ) : null}
                                                </div>
                                                <div className="col-1 mt-2 pt-1 px-0">
                                                    <Link to="/addRequest" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal"
                                                        onClick={() =>
                                                            setUrls('id', 'ProductGroup', 'Name', 'Get_Product_Dropdown')}
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-4 mt-2">
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='ReqQty'
                                                        className="requiredColor"
                                                        id='ReqQty'
                                                        required
                                                        value={value.ReqQty}
                                                        onChange={handelChange}
                                                    />
                                                    <label className='pt-1'>Req Qty</label>
                                                </div>
                                                {errors.ReqError !== 'true' ? (
                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ReqError}</span>
                                                ) : null}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 d-flex mt-2 ">
                                                <div className="col-10 col-md-10 col-lg-10 ">
                                                    <div class="text-field">
                                                        <Select
                                                            name='Productid'
                                                            styles={customStylesWithOutColor}
                                                            // value={unitDropdown?.filter((obj) => obj.value === value.Productid)}
                                                            options={unitDropdown}
                                                            isClearable
                                                            // className="requiredColor"
                                                            placeholder="Select.."
                                                            // onChange={(e) => handleDropdown(e, 'Productid', 'Product')}
                                                        />
                                                        <label className='pt-1'>Unit</label>
                                                    </div>
                                                </div>
                                                <div className="col-1 mt-2 pt-1 px-0">
                                                    <Link to="" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal"
                                                     onClick={() =>
                                                        setUrls('id', 'UnitType', 'UnitType', 'GetData_UnitType')}
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 mt-2">
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='Make'
                                                        className=""
                                                        id='Make'
                                                        required
                                                        value={value.Make}
                                                        onChange={handelChange}
                                                    />
                                                    <label className='pt-1'>Make</label>
                                                </div>
                                                {/* {errors.ReqError !== 'true' ? (
                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ReqError}</span>
                                                ) : null} */}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 mt-2">
                                                <div className="dropdown__box" >
                                                    <Select
                                                        name='TobeApprovedbyID'
                                                        styles={customStylesWithOutColor}
                                                        value={employeeData?.filter((obj) => obj.value?.toString() === value?.TobeApprovedbyID?.toString())}
                                                        options={employeeData}
                                                        isClearable
                                                        placeholder="Select.."
                                                        onChange={(e) => handleDropdown(e, 'TobeApprovedbyID', 'TobeApprovedby')}
                                                    />
                                                    <label htmlFor='' >To Be Approved By</label>
                                                </div>
                                                {/* <div className="col-1 col-md-1 col-lg-1   px-0 py-0" style={{ marginTop: '14px' }}>
                                            <Link to="/addRequest" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal" onClick={() => setUrls('ID', 'Employee', 'EmpName', 'get_Employee_Data')}>
                                                <i className="fa fa-plus"></i>
                                            </Link>
                                        </div> */}

                                            </div>
                                            <div className="form-check mt-4">
                                                <input className="form-check-input" type="checkbox" id="IsforStock" name="IsforStock" defaultValue="something" checked={value.IsforStock} value={value.IsforStock} onChange={handelChange} />
                                                <label className="form-check-label">For Stock</label>
                                            </div>
                                            <div className="col-12  col-md-12 col-lg-12 mt-2 ">
                                                <div className="dropdown__box">
                                                    <textarea
                                                        name="Remarks"
                                                        id="Remarks"
                                                        cols="30"
                                                        rows="1"
                                                        className="form-control"
                                                        value={value.Remarks}
                                                        onChange={handelChange}
                                                    >
                                                    </textarea>
                                                    <label htmlFor='' >Remarks</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div className="btn-box text-right  mr-1  mt-2">
                                        {status ? <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button> :
                                            <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Save</button>}

                                        <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={resetHooks} >Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                urlName &&
                <MasSideBar
                    showCol1={showCol} idName={idName} urlName={urlName} actionType={actionType}
                    getUrl={`/${urlName}/GetData_${urlName}`}
                    addUrl={`/${urlName}/Insert${urlName}`}
                    singleDataUrl={`/${urlName}/GetSingleData_${urlName}`}
                    upUrl={`/${urlName}/Update${urlName}`}
                    delUrl={`/${urlName}/Delete${urlName}`}
                />
            }
        </>
    )
}

export default memo(AddRequest_Add_Up)