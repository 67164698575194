import React, { useState } from 'react'
import Header from './Component/Include/Headers/Header'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Auth = ({ cmp, path }) => {
    const Cmp = cmp
    const navigate = useNavigate()

    useEffect(() => {
        if (!sessionStorage.getItem('userId')) navigate('/')
    }, [])

    return (
        <>
            {/* {
                path !== 'CreateChallan' ?
                    <>
                        <Header />
                        <Cmp />
                    </>
                    : <Cmp />
            } */}

            <>
                <Header />
                <Cmp />
            </>
            {/* :
             <Cmp /> */}

        </>
    )
}

export default Auth