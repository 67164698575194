import React, { useEffect } from 'react'
import EmpStatus from './EmpStatus/EmpStatus';
import { Link, useLocation } from 'react-router-dom';
import EmpLeave from './EmpLeave/EmpLeave';
import EmpAssignShift from './EmpAssignShift/EmpAssignShift';
import EmpTask from './EmpTask/EmpTask';
import AttendanceNew from './Attendance/AttendanceNew';
import AttendanceReport from './AttendanceReport/AttendanceReport';
import status from '../../img/images.png'
import leave from '../../img/empleave.png'
import attendance from '../../img/empattemdance.png'
import shift from '../../img/empshift.png'
import task from '../../img/emptask.png'
import report from '../../img/empreport.png'
import EmpTaskMain from './EmpTask/EmpTaskMain';

const Hr = () => {
    // const dispatch = useDispatch();
    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get('sub');

    // const companySingelData = useSelector((state) => state.Data.companySingelData)
    // const companyId = useSelector((state) => state.Data.companyId)

    // useEffect(() => {
    //     if (companySingelData.length === 0) dispatch(get_Single_Company_Data(companyId))
    // }, [])

    return (
        <>
            {
                openPage ?
                    <>
                        {
                            openPage === 'Emp-Status' &&
                            <EmpStatus />
                        }
                        {
                            openPage === 'Emp-Leave' &&
                            <EmpLeave />
                        }
                        {
                            openPage === 'Emp-Attendance' &&
                            <AttendanceNew />
                        }
                        {
                            openPage === 'Emp-Assign Shift' &&
                            <EmpAssignShift />
                        }
              
                        {
                            openPage === 'Emp-Task' &&
                            <EmpTaskMain />
                        }
                        {
                            openPage === 'Attendance Report' &&
                            <AttendanceReport />
                        }
                    </>
                    :
                    <>
                        <div className="section-body  px-2 ">
                            <div className="col-12">
                                <div className="card">
                                    <div className="md">
                                        <h5 className="form-set  text-center text-danger" >Welcome To HR Module</h5>
                                    </div>
                                    <div className="main-dashboard  px-2">
                                        <div className="box-container ">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-6 col-md-4 col-lg-3">
                                                        <Link to={'/HR?sub=Emp-Status'} className='dash-p'>
                                                            <div className="box ">
                                                                <img src={status} alt="" className='hr-images' />
                                                                <p className='bt'>Emp-Status</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-lg-3">
                                                        <Link to={'/HR?sub=Emp-Leave'} className='dash-p'>
                                                            <div className="box">
                                                                <img src={leave} alt="" className='hr-images' />
                                                                <p className='bt'>Emp-Leave</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-lg-3">
                                                        <Link to={'/HR?sub=Emp-Attendance'} className='dash-p'>
                                                            <div className="box">
                                                                <img src={attendance} alt="" className='hr-images' />
                                                                <p className='bt'>Emp-Attendance</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-lg-3">
                                                        <Link to={'/HR?sub=Emp-Assign%20Shift'} className='dash-p'>
                                                            <div className="box">
                                                                <img src={shift} alt="" className='hr-images' />
                                                                <p className='bt'>Emp-Assign Shift</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-lg-3">
                                                        <Link to={'/HR?sub=Emp-Task'} className='dash-p'>
                                                            <div className="box">
                                                                <img src={task} alt="" className='hr-images' />
                                                                <p className='bt'>Emp-Task</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-lg-3 ">
                                                         <Link to={'/HR?sub=Attendance%20Report'} className='dash-p'>
                                                            <div className="box">
                                                                <img src={report} alt="" className='hr-images' />
                                                                <p className='bt '>Attendance Report</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>

            }
        </>
    )
}

export default Hr