import React, { useEffect, useState } from 'react'
import { customStylesWithOutColor } from '../../Common/Utility'
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { delete_Menu_Data, get_Menu, save_Menu_Data, update_Menu_Data } from '../../../../redux/actions/AllAction';
import { changeArrayFormatDrop } from '../../Common/ArrayFormat';
import { Get_ChildMenu_Data, Get_SubMenu_Data } from '../../../../redux/actionTypes';
import { Link } from 'react-router-dom';
import ConfirmModal from '../../Common/ConfirmModal';

const AddMenu = () => {
    const dispatch = useDispatch();
    const menuData = changeArrayFormatDrop(useSelector((state) => state.Data.menuData), 'DisplayName', 'ID');
    const menu = useSelector((state) => state.Data.menuData);
    const userId = useSelector((state) => state.Data.userId);
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount);
    const subMenuData = changeArrayFormatDrop(useSelector((state) => state.Data.subMenuData), 'DisplayName', 'ID');
    const subMenu = useSelector((state) => state.Data.subMenuData);
    const childMenuData = changeArrayFormatDrop(useSelector((state) => state.Data.childMenuData), 'DisplayName', 'ID');
    const [value, setValue] = useState({ MenuName: '', ID: '', MenuName1: '', ID1: '', MenuName2: '', ID2: '', MenuName3: '', ID3: '' })
    const [editStatus, setEditStatus] = useState(0)
    const [editType, setEditType] = useState('')

    useEffect(() => {
        if (menuData?.length === 0 && userId) dispatch(get_Menu(userId));
    }, [userId]);

    useEffect(() => {
        if (value.ID) {
            if (value.ID === value.ID1) filterSubMenu(value.ID);
            if (value.ID === value.ID2) filterChildMenu(value.ID);
        }
    }, [editUpdateCount])

    const handleChange = (e) => {
        if (e) { setValue(pre => { return { ...pre, ['ID']: e.value, ['ID1']: e.value, ['MenuName1']: e.label } }); filterSubMenu(e.value); }
        else { setValue(pre => { return { ...pre, ['ID']: '', ['ID1']: '', ['MenuName1']: '', ['ID2']: '', ['MenuName2']: '', ['ID3']: '', ['MenuName3']: '' } }); }
    };

    const handleSubMenu = (e) => {
        if (e) { setValue(pre => { return { ...pre, ['ID']: e.value, ['ID2']: e.value, ['MenuName2']: e.label } }); filterChildMenu(e.value); setEditStatus(1) }
        else { setValue(pre => { return { ...pre, ['ID']: pre.ID1, ['ID2']: '', ['MenuName2']: '', ['ID3']: '', ['MenuName3']: '' } }); setEditStatus(0) }
    };

    const handleChildMenu = (e) => {
        if (e) { setValue(pre => { return { ...pre, ['ID']: e.value, ['ID3']: e.value, ['MenuName3']: e.label } }); setEditStatus(2) }
        else { setValue(pre => { return { ...pre, ['ID']: pre.ID2, ['ID3']: '', ['MenuName3']: '' } }); setEditStatus(1) }
    };

    const filterSubMenu = (ID) => {
        const res = menu?.filter((item) => { if (item.ID === ID) return item; })
        if (res) dispatch({ type: Get_SubMenu_Data, payload: res[0]?.Submenuclass });
    };

    const filterChildMenu = (ID) => {
        const res = subMenu?.filter((item) => { if (item.ID === ID) return item; })
        if (res) dispatch({ type: Get_ChildMenu_Data, payload: res[0]?.ChildSubmenuclass });
    };

    const editMenu = (type) => {
        if (type === 'Menu') { setValue(pre => { return { ...pre, ['MenuName']: value.MenuName1 } }) }
        if (type === 'SubMenu') { setValue(pre => { return { ...pre, ['MenuName']: value.MenuName2 } }) }
        if (type === 'ChildMenu') { setValue(pre => { return { ...pre, ['MenuName']: value.MenuName3 } }) }
        setEditType(type)
    }

    const resetHooks = () => {
        console.log("call Reset")
        setValue(pre => { return { ...pre, ['ID']: '', ['ID1']: '', ['MenuName1']: '', ['ID2']: '', ['MenuName2']: '', ['MenuName']: '', ['MenuName3']: '', ['ID3']: '', } });
        setEditType(''); setEditStatus(0); setEditType('')
    }

    // const deleteMenus = () => {
    //     dispatch(delete_Menu_Data(value.ID, userId))
    // }

    // console.log(value.MenuName);

    return (
        <>
            <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="MenuModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="m-1 ">
                                {
                                    editType === 'Menu' || editType === 'SubMenu' || editType === 'ChildMenu' ?
                                        <fieldset style={{ border: '1px solid gray' }}>
                                            <legend >Add Menu</legend>
                                            <div className="form-row">
                                                <div className="col-6 col-md-4 col-lg-4 ">
                                                    <div class="text-field">
                                                        <input
                                                            type="text"
                                                            name='MenuName'
                                                            value={value?.MenuName}
                                                            onChange={(e) => setValue(pre => { return { ...pre, ['MenuName']: e.target.value } })}
                                                            id='MenuName'
                                                            required
                                                        />
                                                        <label className='pt-1'>{editType}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        :
                                        <fieldset style={{ border: '1px solid gray' }}>
                                            <legend >Add Menu</legend>
                                            <div className="row px-1">
                                                <div className="col-12 col-md-6 col-lg-5  dropdown__box">
                                                    <Select
                                                        name='Menu'
                                                        styles={customStylesWithOutColor}
                                                        isClearable
                                                        value={menuData?.filter((obj) => obj.value === value.ID1)}
                                                        options={menuData}
                                                        onChange={handleChange}
                                                        className="requiredColor"
                                                        placeholder="Select.."
                                                    />
                                                    <label className='pl-3'>Menu</label>
                                                </div>
                                                {editStatus === 0 &&
                                                    <div className="col-2 col-md-6 col-lg-2 mt-3">
                                                        <Link to='#' onClick={() => editMenu('Menu')} className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn"><i className="fa fa-edit"></i> </Link>
                                                        <Link to='#' data-toggle="modal" data-target="#ConfirmModal" className="btn btn-sm bg-green text-white px-1 py-0 mr-1" >
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </Link>
                                                    </div>
                                                }
                                                <div className="col-6 col-md-4 col-lg-5 ">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            name='subMenuData'
                                                            styles={customStylesWithOutColor}
                                                            options={subMenuData}
                                                            value={subMenuData?.filter((obj) => obj.value === value.ID2)}
                                                            isClearable
                                                            onChange={handleSubMenu}
                                                            className="requiredColor"
                                                            placeholder="Select.."
                                                        />
                                                        <label className=''>Sub Menu</label>
                                                    </div>
                                                </div>
                                                {editStatus === 1 &&
                                                    <div className="col-2 col-md-6 col-lg-2 mt-3 ">
                                                        <Link to='' onClick={() => editMenu('SubMenu')} className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn"><i className="fa fa-edit"></i> </Link>
                                                        <Link to='#' data-toggle="modal" data-target="#ConfirmModal" className="btn btn-sm bg-green text-white px-1 py-0 mr-1" >
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </Link>
                                                    </div>}
                                            </div>
                                            <div className="row px-1">
                                                <div className="col-12 col-md-6 col-lg-5 mt-4  dropdown__box">
                                                    <Select
                                                        name='Menu'
                                                        styles={customStylesWithOutColor}
                                                        isClearable
                                                        value={childMenuData?.filter((obj) => obj.value === value.ID3)}
                                                        options={childMenuData}
                                                        onChange={handleChildMenu}
                                                        className="requiredColor"
                                                        placeholder="Select.."
                                                    />
                                                    <label className='pl-3'>Child Menu</label>
                                                </div>
                                                {editStatus === 2 &&
                                                    <div className="col-2 col-md-6 col-lg-2 mt-4">
                                                        <Link to='#' onClick={() => editMenu('ChildMenu')} className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn"><i className="fa fa-edit"></i> </Link>
                                                        <Link to='#' data-toggle="modal" data-target="#ConfirmModal" className="btn btn-sm bg-green text-white px-1 py-0 mr-1" >
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </Link>
                                                    </div>}
                                                <div className="col-6 col-md-6 col-lg-5 mt-3 ">
                                                    <div className="text-field">
                                                        <input
                                                            type="text"
                                                            onChange={(e) => setValue(pre => { return { ...pre, ['MenuName']: e.target.value } })}
                                                            name='MenuName'
                                                            value={value?.MenuName}
                                                            className=""
                                                            id='MenuName'
                                                            required
                                                        />
                                                        <label className='pt-1'>Menu Name</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </fieldset>
                                }
                            </div>
                        </div>
                        <div className="btn-box text-right  mr-1 mb-2">
                            <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { resetHooks(); }}>Clear</button>
                            {
                                editType || !value.MenuName3 ?
                                    <>
                                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => editType ? dispatch(update_Menu_Data(value, userId, setEditType, editUpdateCount)) : dispatch(save_Menu_Data(value, userId, setValue))}> {editType ? "Update" : !value.MenuName3 && "Save"}</button>
                                    </>
                                    :
                                    <></>
                            }
                            <button type="button" data-dismiss={editType === '' && "modal"} className="btn btn-sm btn-success mr-1" onClick={() => editType ? setEditType('') : resetHooks()} >Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ConfirmModal func={deleteMenus} confirmType='Delete' /> */}
        </>
    )
}

export default AddMenu