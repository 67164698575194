import React from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import EmpAssignShift_Add_Up from './EmpAssignShift_Add_Up'

const EmpAssignShift = () => {
    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Employee Assign Shift</p>
                    <div style={{ marginLeft: 'auto' }}>
                        <Link to={'/HR?sub=Emp-Assign%20Shift'} className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#EmpAssignShiftModal" >
                            <i className="fa fa-plus"></i>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="col-12 pt-2 px-3">
                <DataTable
                    dense
                    paginationPerPage={'10'}
                    paginationRowsPerPageOptions={[5, 10, 15]}
                    highlightOnHover
                    noContextMenu
                    pagination
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    noDataComponent={"There are no data to display"}
                />
            </div>
            <EmpAssignShift_Add_Up />
        </>
    )
}

export default EmpAssignShift