import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Dropdown from 'react-bootstrap/Dropdown'
import { Edit_Update_Count, Get_Master_Table_Filter_Data, Get_Single_PlantService_Data, Get_Single_Product_Data, MasterTable_Modal_Status } from '../../../../redux/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../../Include/Common/ConfirmModal';
import Loader from '../../../Include/Common/Loader';
import { changeArrayFormat } from '../../../Include/Common/ArrayFormat';
import { delete_PlantService_Data,  get_PlantService_Data, get_Single_PlantService_Data,   } from '../../../../redux/actions/AllAction';
import { Two_Value_Filter } from '../../../Filter/Filter';
import PlantSerivceProcess_Add_Up from './PlantSerivceProcess_Add_Up';


const PlantServiceProcess = (props) => {
  const dispatch = useDispatch();
  const { openPage, showCol1, showCol2, idName, delUrl, upUrl, singleDataUrl, addUrl, getUrl } = props
  const [filterTypeIdOption, setfilterTypeIdOption] = useState('Contains');
  const [filterTypeDescOption, setfilterTypeDescOption] = useState('Contains');
  const [searchValue1, setSearchValue1] = useState('')
  const [searchValue2, setSearchValue2] = useState('')
  const plantServiceData = useSelector((state) => state.Data.plantServiceData)
  const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
  const masterTableLoader = useSelector((state) => state.Data.masterTableLoader)
  const masterTableFilterData = useSelector((state) => state.Data.masterTableFilterData)
  const [pageStatus, setPageStatus] = useState("1")
  const [IsActive, setIsActive] = useState()
  const [confirmType, setConfirmType] = useState('')
  const [id, setId] = useState('')
  const [status, setStatus] = useState(false)
  const userId = useSelector((state) => state.Data.userId)

  useEffect(() => {
    dispatch(get_PlantService_Data(pageStatus));
  }, [pageStatus])

  useEffect(() => {
    if (openPage) setPageStatus('1'); setSearchValue1(''); setSearchValue2('');
  }, [openPage])
  useEffect(() => {
    if (pageStatus) { setSearchValue1(''); setSearchValue2('') }
  }, [pageStatus])

  const columns = [
    {
      name: 'Service Type',
      selector: (row) => row.ServiceType,
      sortable: true
    },
   
    {
      name: 'Group Name',
      selector: (row) => row.GroupName,
      sortable: true
    },
    {
      name: 'Process Name',
      selector: (row) => row.ProcessName,
      sortable: true
    },


    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 55,fontWeight:'700' }}>Action</p>,
      cell: row => <>
        <div style={{ position: 'absolute', top: 4, right: 40 }}>
          {
            pageStatus === "1" ?
              <Link to={`/MasterTables?page=${openPage}`} onClick={(e) => setEditValue(row)} data-toggle="modal" data-target="#PlantProcessModal" className="btn btn-sm bg-green text-white px-1 py-0 mr-1"><i className="fa fa-edit"></i>
              </Link> : <></>
          }
          {
            pageStatus === "1" ?
              < Link to={`/MasterTables?page=${openPage}`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive(0); setConfirmType("InActive"); setId(row.ID) }}
                className="btn btn-sm  text-white px-1 py-0 mr-1" style={{ background: "#ddd" }}>
                <i class="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true"></i>
              </Link>
              :
              <Link to={`/MasterTables?page=${openPage}`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive(1); setConfirmType("Active"); setId(row.ID) }}
                className="btn btn-sm  text-white px-1 py-0 mr-1" style={{ background: "#ddd" }}>
                <i class="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true"></i>
              </Link>
          }
        </div>
      </>
    }
  ]
  const setEditValue = (row) => {
    dispatch(get_Single_PlantService_Data(row.ID));
    setStatus(true);
    dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
    dispatch({ type: MasterTable_Modal_Status, payload: true })
  }
  const newStatus = () => {
    dispatch(get_Single_PlantService_Data(''));
    setStatus(false); dispatch({ type: Get_Single_PlantService_Data, payload: [] });
    dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
    dispatch({ type: MasterTable_Modal_Status, payload: true })
  }
  const UpdActiveDeactive = () => {
    dispatch(delete_PlantService_Data(id, IsActive, pageStatus, userId));
  }
  return (
    <>
      <div className="col-12 col-md-12 col-lg-12  px-3">
        <div className="col-12 col-md-6 col-lg-12 ">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class={`nav-link ${pageStatus === '1' ? 'active' : ''}`} onClick={() => setPageStatus("1")} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true">Active</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class={`nav-link ${pageStatus === '0' ? 'active' : ''}`} onClick={() => setPageStatus("0")} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true">InActive</a>
            </li>
          </ul>
        </div>
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">{openPage}</p>
          {pageStatus === "1" && 
          <Link to={`/MasterTables?page=${openPage}`} className="text-white px-2 py-0 "
            data-toggle="modal" data-target="#PlantProcessModal" onClick={newStatus} >
            <i className="fa fa-plus"></i>
          </Link>}
        </div>
        <div className="col-12 mt-2 ">
          <div className="row">
            <div className="col-4">
              <input type="text" value={searchValue1} onChange={(e) => {
                setSearchValue1(e.target.value);
                const result = Two_Value_Filter(plantServiceData, e.target.value, searchValue2, filterTypeIdOption, 'ProcessName', 'ServiceType', )
                dispatch({ type: Get_Master_Table_Filter_Data, payload: result });
              }}
                className='form-control' placeholder='Search By Code...' />
            </div>
            <div className='col-1 '>
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  <i className="fa fa-filter"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className=' nav-filter-dropdown'>
                  <Dropdown.Item onClick={() => setfilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setfilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setfilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setfilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setfilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12  mb-3">
            <div className="table-responsive">
              {masterTableLoader ?
                <DataTable
                  columns={columns}
                  data={masterTableFilterData}
                  dense
                  paginationPerPage={'10'}
                  paginationRowsPerPageOptions={[5, 10, 15]}
                  highlightOnHover
                  noContextMenu
                  pagination
                  responsive
                  subHeaderAlign="right"
                  subHeaderWrap
                  noDataComponent={"There are no data to display"}
                /> : <Loader />}
            </div>
          </div>
        </div>
      </div>
      {/* <Product_Add_Up  {...{ status, setStatus, pageStatus }} /> */}
      <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />
      <PlantSerivceProcess_Add_Up {...{ status, setStatus, pageStatus }} />
    </>
  )
}

export default PlantServiceProcess