import React, { useState } from 'react'
import Select from "react-select";
import DatePicker from 'react-datepicker'
import { Link } from 'react-router-dom';

const Payment = () => {
  // custuom style withoutColor
  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 31,
      minHeight: 30,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
      fontWeight: 500,
    }),
  }

  // state = {
  //   rows: [{}]
  // };
  const [state, setState] = useState({
    rows: [{}]
  })
  const handleChange = idx => e => {
    const { name, value } = e.target;
    const rows = [...state.rows];
    rows[idx] = {
      [name]: value
    };
    setState({
      rows
    });
  };
  const handleAddRow = () => {
    const item = {
      name: "",
      mobile: ""
    };
    setState({
      rows: [...state.rows, item]
    });
  };
  const handleRemoveRow = () => {
    setState({
      rows: state.rows.slice(0, -1)
    });
  };
  const handleRemoveSpecificRow = (idx) => () => {
    const rows = [...state.rows]
    rows.splice(idx, 1)
    setState({ rows })
  }
  return (
    <>
      <div className="col-12 col-md-12 col-lg-12 px-3">
        {/* <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">Payment Voucher</p>
        </div> */}
        <div className="bg-line  py-1 px-2 d-flex justify-content-between align-items-center ">
          <p className="p-0 m-0">Payment Voucher</p>
          <div style={{ marginLeft: 'auto' }}>
            <Link to="" className=" text-white px-2 py-0 mr-1" data-toggle="modal" data-target="#">
              <i className="fa fa-plus"></i>
            </Link>
            <Link>
              <span className=' text-white px-2 py-0 mr-1'>
                <i className='fa fa-save '></i>
              </span>
            </Link>
            <Link>
              <span className=' text-white px-2 py-0 mr-1'>
                <i className='fa fa-close '></i>
              </span>
            </Link>
          </div>
        </div>
        <div className="form-row mt-2">
          <div className="col-6 col-md-3 col-lg-2 mt-2 pt-1">
            <div class="text-field">
              <input
                type="text"
                name='ArrestIDNumber'
                className="readonlyColor"
                id='ArrestIDNumber'
                required
                readOnly
              />
              <label >Payment No.</label>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-2 mt-1">
            <div className="date__box">
              <DatePicker
                id='date'
                name='date'
                className=''
                isClearable
                placeholderText={'Select...'}
              />
              <label htmlFor="" >Date</label>
            </div>
          </div>
          <div className="col-6 col-md-5 col-lg-2 mt-2">
            <div className="dropdown__box">
              <Select
                name='type'
                styles={customStylesWithOutColor}
                isClearable
                placeholder="Select.."
              />
              <label htmlFor="">Type</label>
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-3 mt-2">
            <div className="dropdown__box">
              <Select
                name='Account'
                styles={customStylesWithOutColor}
                isClearable
                placeholder="Select.."
              />
              <label htmlFor="">Account</label>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-2 mt-4 ml-5">
            <label >Current Balance: &nbsp;</label>
            <span className='text-danger'>10000000</span>
          </div>
        </div>
        <div className="col-12  text-right">
          <button onClick={handleAddRow} className="btn btn-sm btn-success">
            Add Row
          </button>
        </div>
        <div className="row mt-1 bb">
          <div className="col-12" style={{ overflowY: 'scroll', maxHeight: '350px' }}>
            <table class="table table-bordered" >
              <thead style={{ backgroundColor: '#f2f2f2' }}>
                <tr>
                  <th >S.no</th>
                  <th>Particular</th>
                  <th>Amount</th>
                  <th className='text-right'>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {state.rows.map((item, idx) => (
                  <tr id="addr0" key={idx}>
                    <td className='payment-serial'>{idx}</td>
                    <td>
                      <input
                        type="text"
                        name="name"
                        value={state.rows[idx].name}
                        onChange={handleChange(idx)}
                        className="form-control"
                      />
                    </td>
                    <td className='payment-amount'>
                      <input
                        type="text"
                        name="mobile"
                        value={state.rows[idx].mobile}
                        onChange={handleChange(idx)}
                        className="form-control"
                      />
                    </td>
                    <td className='payment-button'>
                      <button
                        className="btn btn-outline-danger btn-sm"

                        onClick={handleRemoveSpecificRow(idx)}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="row">
              <div className="col-5  col-md-6 col-lg-5 mt-1 " >
                <div className="text-field " >
                  <textarea name='Narration' id="Narration" cols="30" rows='1' className="form-control pt-2 pb-2 " ></textarea>
                  <label htmlFor="" >Narration:</label>
                </div>
              </div>
              <div className="col-1 col-md-1 col-lg-4 mt-3  ">
              </div>
              <div className="col-4 col-md-3 col-lg-2  " style={{ marginTop: '2px' }}>
                <div class="text-field">
                  <input
                    type="text"
                    className='readonlyColor'
                    name='amount'
                    id='amount'
                    required
                    readOnly
                  />
                  <label className="pt-1">Total Amount:</label>
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* <div className="form-row">
          <div className="col-6 col-md-2 col-lg-1 mt-3">
            <label htmlFor="" >Narration:</label>
          </div>
          <div className="col-12  col-md-4 col-lg-5 mt-1  " >
            <textarea name='Narration' id="Narration" cols="30" rows='1' className="form-control pt-2 pb-2 " ></textarea>
          </div>
          <div className="col-6 col-md-1 col-lg-3   ">
          </div>
          <div className="col-6 col-md-2 col-lg-1 pt-1" >
            <label >Total Amount:</label>
          </div>
          <div className="col-6 col-md-3 col-lg-2 ml-4" style={{ marginTop: '6px' }}>
            <div class="text-field">
              <input
                type="text"
                className='readonlyColor'
                name='amount'
                id='amount'
                required
                readOnly
              />
              <label className="pt-1">Total Amount:</label>
            </div>
          </div>
        </div>
        <div className="btn-box text-right mt-1 mr-1 mb-2">
          <button type="button" class="btn btn-sm btn-success mr-1">Save</button>
          <button type="button" class="btn btn-sm btn-success mr-1">New</button>
        </div> */}
      </div>
    </>
  )
}

export default Payment
