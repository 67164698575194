import React from 'react'
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import GSTModel from './GSTModel';
// import Challan_Add_Up from './Challan_Add_Up';

const CreateChallan = () => {
  // Custom Style
  const colourStyles = {
    control: (styles) => ({
      ...styles, backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 30,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),

  };
  // custuom style withoutColor
  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 31,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
      fontWeight: 500,
    }),
  };
  return (
    <>
      <div className="section-body view_page_design " style={{ overflowX: 'hidden' }}>
        <div className="row clearfix" >
          <div className="col-md-12">
            <div className='tab-header ' style={{ background: '#c0083e' }}>
              <ul className="nav nav-tabs " id="myTab" role="tablist" style={{ color: '#fff', padding: '2px 18px' }}>
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="home-tab" data-toggle="tab" data-target="#challan" type="button" role="tab" aria-controls="challan" aria-selected="true">Challan</button>
                </li>

                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="history-tab" data-toggle="tab" data-target="#history" type="button" role="tab" aria-controls="history" aria-selected="false">History</button>
                </li>
                <Link to={'/Sale?sub=Challan%20Search'} style={{ marginLeft: 'auto', color: '#fff' }}>
                  <span className='colse-nav'>
                    <button type="button" className="close mr-2 text-left" data-dismiss="modal" style={{ fontSize: '20px', fontWeight: '900', color: '#fff' }}>×</button>
                  </span>
                </Link>
              </ul>
            </div>
            <div className="tab-content mt-2 px-2" id="myTabContent">
              <div className="tab-pane fade show active" id="challan" role="tabpanel" aria-labelledby="home-tab">
                <div className="form-row">
                  <div className="col-12 col-md-2 col-lg-2 ">
                    <div class=" text-field">
                      <input type="text" className='readonlyColor' name='challan' readOnly required />
                      <label className='r-only'>Challan No:</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 col-lg-2 ">
                    <div class="  text-field">
                      <input type="text" className='' name='challan' required placeholder='Adv.Amount' readOnly />
                      <label className='r-only'>Adv.Amount</label>
                    </div>
                  </div>
                  <div className="form-check mt-3 col-12 col-md-2 col-lg-1 pl-4" >
                    <input className="form-check-input " type="radio" value="Cash" name="AttemptComplete" id="flexRadioDefault1" />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Cash
                    </label>
                  </div>
                  <div className="form-check mt-3 col-12 col-md-1 col-lg-1">
                    <input className="form-check-input mt-1" type="radio" value="Credit" name="AttemptComplete" id="flexRadioDefault1" />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Credit
                    </label>
                  </div>
                  <div className="col-12 col-md-2 col-lg-1 mt-3">
                    <label className='text-danger'>Balance:</label>
                    <span className='ml-2'>0</span>
                  </div>
                  <div className="col-12 col-md-2 col-lg-3 mt-3">
                    <label className='text-danger'>Limit:</label>
                    <span className='ml-2'>0</span>
                  </div>
                  <div className="col-12 col-md-6 col-lg-2 mt-3">
                    <label className='text-danger'>Date:</label>
                    <span className=''>02-05-2023 10:10:20</span>
                  </div>
                </div>
                {/* Consignee Details */}
                <div className="row mt-2">
                  <div className="col-12 col-md-12 col-lg-7">
                    <div className="row">
                      <div className="col-12 col-md-12" >
                        <div className="bg-line  py-0 px-2 d-flex justify-content-between align-items-center ">
                          <p className="p-0 m-0">Consignee Details</p>
                        </div>
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-3 ">
                        <label htmlFor="" className='new-label'>Consignee</label>
                      </div>
                      <div class="col-3 text-field mt-2">
                        <input type="text" className='' name='Name' required />
                      </div>
                      <div className="col-1 mt-2 px-0" data-toggle="modal" data-target="#GSTModal"  >
                        <span
                          className=" btn btn-sm bg-green text-white"
                        >
                          <i className="fa fa-plus" > </i>
                        </span>
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-3 ">
                        <label htmlFor="" className='new-label'>Adv.Amount</label>
                      </div>
                      <div class="col-3 text-field mt-2">
                        <input type="text" className='' name='Name' required />
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-3 ">
                        <label htmlFor="" className='new-label'>Address</label>
                      </div>
                      <div class="col-3 text-field mt-2">
                        <textarea name='Comments' id="Comments" cols="30" rows='1' className="" placeholder='Address'></textarea>
                      </div>
                      <div className="col-2 col-md-3 col-lg-3 mt-3 ">
                        <label htmlFor="" className='new-label'>Vehicle Type</label>
                      </div>
                      <div class="col-3 text-field mt-2">
                        <Select
                          name='state'
                          styles={customStylesWithOutColor}
                          isClearable
                          placeholder="Select"
                        />
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                        <label htmlFor="" className='new-label'>State</label>
                      </div>
                      <div class="col-3 text-field ">
                        <Select
                          name='state'
                          styles={customStylesWithOutColor}
                          isClearable
                          placeholder="Select"
                        />
                      </div>
                      <div className="col-2 col-md-3 col-lg-3 mt-3 ">
                        <label htmlFor="" className='new-label'>Vehicle No.</label>
                      </div>
                      <div class="col-3 text-field mt-2">
                        <Select
                          name='state'
                          styles={customStylesWithOutColor}
                          isClearable
                          placeholder="Select"
                        />
                      </div>
                      {/* <div className="col-1 mt-2 px-0" data-toggle="modal" data-target="#VehicleModal"  >
                        <span
                          className=" btn btn-sm bg-green text-white"
                        >
                          <i className="fa fa-plus" > </i>
                        </span>
                      </div> */}
                      <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                        <label htmlFor="" className='new-label'>District</label>
                      </div>
                      <div class="col-3 text-field ">
                        <Select
                          name='state'
                          styles={customStylesWithOutColor}
                          isClearable
                          placeholder="Select"
                        />
                      </div>
                      <div className="col-2 col-md-3 col-lg-3 mt-3 ">
                        <label htmlFor="" className='new-label'>Vehicle Remarks</label>
                      </div>
                      <div class="col-3 text-field mt-2">
                        <textarea name='Comments' id="Comments" cols="30" rows='1' className="" placeholder='Remarks'></textarea>
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                        <label htmlFor="" className='new-label'>Pin</label>
                      </div>
                      <div class="col-3 text-field ">
                        <Select
                          name='state'
                          styles={customStylesWithOutColor}
                          isClearable
                          placeholder="Select"
                        />
                      </div>
                      <div className="col-2 col-md-3 col-lg-3 mt-2 ">
                        <label htmlFor="" className='new-label'>Driver Name</label>
                      </div>
                      <div class="col-3 text-field mt-1">
                        <input type="text" className='' name='Owner No' required />
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                        <label htmlFor="" className='new-label'>Contact&nbsp;No.</label>
                      </div>
                      <div class="col-3 text-field mt-1">
                        <input type="text" className='' name='Conatct No' required />
                      </div>
                      <div className="col-2 col-md-3 col-lg-3 mt-2 ">
                        <label htmlFor="" className='new-label'>Driver No.</label>
                      </div>
                      <div class="col-3 text-field mt-1">
                        <input type="text" className='' name='Driver No' required />
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                        <label htmlFor="" className='new-label'>Email Id</label>
                      </div>
                      <div class="col-3 text-field mt-1">
                        <input type="text" className='' name='Conatct No' required />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-5">
                    {/* GST DETAILS */}
                    <div className="row">
                      <div className="col-12 col-md-12  p-0 px-1 " >
                        <div className="bg-line  py-0 px-2 d-flex justify-content-between align-items-center ">
                          <p className="p-0 m-0">GST Details</p>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-12 ml-5 pl-5 mt-1">
                        <div class="form-check ">
                          <input class="form-check-input" type="checkbox" name='PropertyAbandoned' id="flexCheckDefault" />
                          <label class="form-check-label" for="flexCheckDefault">
                            GST Bill
                          </label>
                        </div>
                      </div>
                      {/* <div className="col-5 col-md-10 col-lg-10 mt-1">
                        <div class="form-check ">
                          <input class="form-check-input" type="checkbox" name='PropertyAbandoned' id="flexCheckDefault" />
                          <label class="form-check-label" for="flexCheckDefault">
                            Use Consignee Detail
                          </label>
                        </div>
                      </div> */}
                      <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                        <label htmlFor="" className='new-label'>GST No.</label>
                      </div>
                      <div class="col-9 text-field mt-1">
                        <input type="text" className='' name='GST No' required />
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-3 ">
                        <label htmlFor="" className='new-label'>Name</label>
                      </div>
                      <div class="col-9 text-field mt-2">
                        <Select
                          name='address'
                          styles={customStylesWithOutColor}
                          isClearable
                          placeholder="Select"
                        />
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-3 ">
                        <label htmlFor="" className='new-label'>Address</label>
                      </div>
                      <div class="col-9 text-field mt-2">
                        <textarea name='Comments' id="Comments" cols="30" rows='1' className="" placeholder='Remarks'></textarea>
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                        <label htmlFor="" className='new-label'>State</label>
                      </div>
                      <div class="col-4 text-field mt-1">
                        <Select
                          name='address'
                          styles={customStylesWithOutColor}
                          isClearable
                          placeholder="Select"
                        />
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                        <label htmlFor="" className='new-label'>Appx Time</label>
                      </div>
                      <div class="col-3 text-field mt-1">
                        <input type="text" className='' name='GST No' required />
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                        <label htmlFor="" className='new-label'>District</label>
                      </div>
                      <div class="col-4 text-field mt-2">
                        <Select
                          name='address'
                          styles={customStylesWithOutColor}
                          isClearable
                          placeholder="Select"
                        />
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-3 ">
                        <label htmlFor="" className='new-label'>Appx Wgt</label>
                      </div>
                      <div class="col-3 text-field mt-2">
                        <input type="text" className='' name='GST No' required />
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                        <label htmlFor="" className='new-label'>Pin</label>
                      </div>
                      <div class="col-4 text-field mt-2">
                        <Select
                          name='address'
                          styles={customStylesWithOutColor}
                          isClearable
                          placeholder="Select"
                        />
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 mt-3 ">
                        <label htmlFor="" className='new-label'>Dist.</label>
                      </div>
                      <div class="col-3 text-field mt-2">
                        <input type="text" className='' name='GST No' required />
                      </div>
                    </div>
                  </div>
                </div>
                {/* Product Details */}
                <div className="row mt-2" >
                  <div className="col-12 col-md-12" >
                    <div className="bg-line  py-0 px-2 d-flex justify-content-between align-items-center ">
                      <p className="p-0 m-0">Product Details</p>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-2 mt-2">
                    <div className=" text-field">
                      <Select
                        name='product'
                        styles={customStylesWithOutColor}
                        isClearable
                        placeholder="Select Product"
                      />
                      <label className='pt-1'>Name</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-2 col-lg-1 mt-2 pt-1">
                    <div class=" text-field">
                      <input type="text" name='Rate' required />
                      <label className='pt-1'>Rate</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 col-lg-2 mt-2 pt-1">
                    <div class=" text-field">
                      <input type="text" required readOnly />
                      <label className='pt-1'>Gross Weight</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 col-lg-2 mt-2 pt-1">
                    <div class=" text-field">
                      <input type="text" className='readonlyColor' placeholder='Date' required readOnly />
                      <label className='pt-1'>Date/Time</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-2 col-lg-1 mt-2 pt-1">
                    <div class=" text-field">
                      <input type="text" className='readonlyColor' placeholder='0' required readOnly />
                      <label className='pt-1'>Net Weight</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-2 mt-2 pt-1">
                    <div class=" text-field">
                      <input type="text" placeholder='Less Weight' required readOnly />
                      <label className='pt-1'>Less Weight</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 col-lg-1 mt-2 pt-1">
                    <div class=" text-field ">
                      <input type="text" className='readonlyColor' placeholder='0' required readOnly />
                      <label className='pt-1'>GT Weight</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 col-lg-1 mt-2  pt-1">
                    <div class=" text-field d-flex mr-1">
                      <input type="text" className='readonlyColor' placeholder='NaN' required readOnly />
                      <label className='pt-1'>Amount</label>
                      <span className='ml-2'  style={{ fontSize: '22px', color: '#1265a1' }} ><i className='fa fa-plus-circle'></i></span>
                    </div>
                  </div>
                  
                  {/* product-1 */}
                  <div className="row product1 active" style={{ display: 'none', }} >
                    <div className="col-6 col-md-4 col-lg-2 mt-2">
                      <div className=" text-field">
                        <Select
                          name='product'
                          styles={customStylesWithOutColor}
                          isClearable
                          placeholder="Select Product"
                        />
                        <label className='pt-1'>Name</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-2 col-lg-1 mt-2 pt-1">
                      <div class=" text-field">
                        <input type="text" name='Rate' required />
                        <label className='pt-1'>Rate</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-3 col-lg-2 mt-2 pt-1">
                      <div class=" text-field">
                        <input type="text" required readOnly />
                        <label className='pt-1'>Gross Weight</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-3 col-lg-2 mt-2 pt-1">
                      <div class=" text-field">
                        <input type="text" className='readonlyColor' placeholder='Date' required readOnly />
                        <label className='pt-1'>Date/Time</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-2 col-lg-1 mt-2 pt-1">
                      <div class=" text-field">
                        <input type="text" className='readonlyColor' placeholder='0' required readOnly />
                        <label className='pt-1'>Net Weight</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-2 mt-2 pt-1">
                      <div class=" text-field">
                        <input type="text" placeholder='Less Weight' required readOnly />
                        <label className='pt-1'>Less Weight</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-3 col-lg-1 mt-2 pt-1">
                      <div class=" text-field ">
                        <input type="text" className='readonlyColor' placeholder='0' required readOnly />
                        <label className='pt-1'>GT Weight</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-3 col-lg-1 mt-2  pt-1">
                      <div class=" text-field d-flex mr-1">
                        <input type="text" className='readonlyColor' placeholder='NaN' required readOnly />
                        <label className='pt-1'>Amount</label>
                        <span className='ml-2' style={{ fontSize: '22px', color: '#1265a1' }}><i className='fa fa-plus-circle'></i></span>
                      </div>
                    </div>
                  </div>
                  {/* product-2 */}
                  <div className="row product2 active" style={{ display: 'none' }}>
                    <div className="col-6 col-md-4 col-lg-2 mt-2">
                      <div className=" text-field">
                        <Select
                          name='product'
                          styles={customStylesWithOutColor}
                          isClearable
                          placeholder="Select Product"
                        />
                        <label className='pt-1'>Name</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-2 col-lg-1 mt-2 pt-1">
                      <div class=" text-field">
                        <input type="text" name='Rate' required />
                        <label className='pt-1'>Rate</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-3 col-lg-2 mt-2 pt-1">
                      <div class=" text-field">
                        <input type="text" required readOnly />
                        <label className='pt-1'>Gross Weight</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-3 col-lg-2 mt-2 pt-1">
                      <div class=" text-field">
                        <input type="text" className='readonlyColor' placeholder='Date' required readOnly />
                        <label className='pt-1'>Date/Time</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-2 col-lg-1 mt-2 pt-1">
                      <div class=" text-field">
                        <input type="text" className='readonlyColor' placeholder='0' required readOnly />
                        <label className='pt-1'>Net Weight</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-2 mt-2 pt-1">
                      <div class=" text-field">
                        <input type="text" placeholder='Less Weight' required readOnly />
                        <label className='pt-1'>Less Weight</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-3 col-lg-1 mt-2 pt-1">
                      <div class=" text-field ">
                        <input type="text" className='readonlyColor' placeholder='0' required readOnly />
                        <label className='pt-1'>GT Weight</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-3 col-lg-1 mt-2  pt-1">
                      <div class=" text-field d-flex mr-1">
                        <input type="text" className='readonlyColor' placeholder='NaN' required readOnly />
                        <label className='pt-1'>Amount</label>
                        <span className='ml-2' style={{ fontSize: '22px', color: '#1265a1' }}><i className='fa fa-plus-circle'></i></span>
                      </div>
                    </div>
                  </div>
                </div>


                {/* Weight Details */}
                <div className="row mt-2">
                  <div className="col-12 col-md-12  " >
                    <div className="bg-line  py-0 px-2 d-flex justify-content-between align-items-center ">
                      <p className="p-0 m-0">Weight Details</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 col-lg-2 mt-2">
                    <div class="text-field">
                      <input type="text" name='tare' required />
                      <label className='pt-1'>Tare Weight</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 col-lg-2 mt-2">
                    <div class="text-field">
                      <input type="text" className='readonlyColor' placeholder='Date' required readOnly />
                      <label className='pt-1'>Date/Time</label>

                    </div>
                  </div>
                  <div className="col-12 col-md-2 col-lg-2 mt-2">
                    <div class="text-field">
                      <input type="text" className='readonlyColor' placeholder='0' required readOnly />
                      <label className='pt-1'>Net Weight</label>

                    </div>
                  </div>
                  <div className="col-12 col-md-2 col-lg-2 mt-2">
                    <div class="text-field ">
                      <input type="text" className='readonlyColor' placeholder='0' required readOnly />
                      <label className='pt-1'>Less Weight</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-2 col-lg-2 mt-2">
                    <div class="text-field ">
                      <input type="text" className='readonlyColor' placeholder='0' required readOnly />
                      <label className='pt-1'>GT Weight</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-2 col-lg-2 mt-2">
                    <div class="text-field ">
                      <input type="text" className='readonlyColor' placeholder='0' required readOnly />
                      <label className='pt-1'>Vehicle Commission</label>
                    </div>
                  </div>
                </div>


                {/* Amount Details */}
                <div className="row mt-2">
                  <div className="col-12 col-md-12" >
                    <div className="bg-line  py-0 px-2 d-flex justify-content-between align-items-center ">
                      <p className="p-0 m-0">Amount Details</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-2 col-lg-2 mt-2">
                    <div class=" text-field">
                      <input type="text" className='readonlyColor' placeholder='0' required readOnly />
                      <label className='pt-1'>Amount</label>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-2 mt-2">
                    <div className="text-field ">
                      <Select
                        name='product'
                        styles={customStylesWithOutColor}
                        isClearable
                        placeholder="Select.."
                      />
                      <label className='pt-1'>Loading</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 col-lg-2 mt-2">
                    <div class=" text-field">
                      <input type="text" name='commission' required placeholder='commission' />
                      <label className='pt-1'>Commission</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 col-lg-2 mt-2">
                    <div class=" text-field ">
                      <input type="text" className='readonlyColor' placeholder='20' required readOnly />
                      <label className='pt-1'>Total</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-2 mt-2">
                    <div class=" text-field ">
                      <input type="text" className='readonlyColor' placeholder='GST Amount' required readOnly />
                      <label className='pt-1'>GST Amount</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-2 mt-2">
                    <div class=" text-field">
                      <input type="text" placeholder='Royality..' required readOnly />
                      <label className='pt-1'>Royality</label>

                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-2 mt-3">
                    <div className=" text-field ">
                      <Select
                        name='tp'
                        styles={customStylesWithOutColor}
                        isClearable
                        placeholder="Select TP"
                      />
                      <label className='pt-1'>TP Amount</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-2 mt-3">
                    <div class=" text-field">
                      <input type="text" placeholder='Freight Amt..' required readOnly />
                      <label className='pt-1'>Freight Amt</label>

                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-2 mt-3">
                    <div class=" text-field">
                      <input type="text" placeholder='Extra Amt..' required readOnly />
                      <label className='pt-1'>Extra Amt</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-2 mt-3">
                    <div class=" text-field">
                      <input type="text" className='readonlyColor' placeholder='20' required readOnly />
                      <label className='pt-1'>Total</label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 bt mt-1 mb-2">
                  <div className="row ">
                    <div className="from-group col-md-6 col-lg-5 mt-3 mb-2">
                      <button type="submit" className="btn btn-sm btn-success " id="Print">Print</button>
                      <button type="submit" className="btn btn-sm btn-success ml-2" id="save">Save</button>
                      <button type="button" className="btn btn-sm btn-success ml-2" id="new">New</button>
                      <button type="button" className="btn btn-sm btn-success ml-2" id="close">Close</button>
                      <button id="btnLoader" type="button" className="btn btn-sm btn-success ml-2">Check Images</button>
                    </div>
                    <div className="form-check mt-3 col-md-3 col-lg-1 ">
                      <input className="form-check-input mt-1" type="radio" name="take" id="flexRadioDefault1" />
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                        Take(+)
                      </label>
                    </div>
                    <div className="form-check mt-3 col-md-3 col-lg-1">
                      <input className="form-check-input mt-1" type="radio" name="take" id="flexRadioDefault1" />
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                        Return(-)
                      </label>
                    </div>
                    <div className="col-12 col-md-3 col-lg-2 ">
                      <div class="text-field">
                        <input type="text" name='total' className='readonlyColor' required placeholder='Total' />
                      </div>
                    </div>
                    <div className="col-5 col-md-9 col-lg-3  pl-5 mt-3">
                      <div class="form-check ">
                        <input class="form-check-input" type="checkbox" name='payment' id="flexCheckDefault" />
                        <label class="form-check-label" for="flexCheckDefault">
                          Payment Done
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade " id="history" role="tabpanel" aria-labelledby="profile-tab">History</div>
              <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...Bill</div>
            </div>
          </div>
        </div>
      </div >
      <GSTModel />

    </>
  )
}

export default CreateChallan