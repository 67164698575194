import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from "react-select";
import DatePicker from 'react-datepicker'
import { RequiredFieldSelectBox, SpaceNotAllow, SpaceNotAllowWithoutReq } from '../../../Validation/Validation';
import { Get_Error_Status, Get_Single_EmpLeave_Data } from '../../../../redux/actionTypes';
import { get_Leave_Status_Drop, get_Leave_Type_Drop, save_EmpLeave, update_EmpLeave } from '../../../../redux/actions/AllAction';
import { getShowingDayWithOutTime, getShowingWithOutTimeStartDate } from '../../../Include/Common/Utility';
import { Link } from 'react-router-dom';
import MasSideBar from '../../../Include/Common/MasSideBar';
import { changeArrayFormatDrop, changeThreeArrayFormat } from '../../../Include/Common/ArrayFormat';

const EmpLeave_Add_Up = (props) => {
    const { status, pageStatus, empData } = props
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.Data.userId);
    const leaveStatusDropDown = changeArrayFormatDrop( useSelector((state) => state?.Data?.leaveStatusDropDown),'Code','ID');
    const leaveTypeDropDown = changeArrayFormatDrop(useSelector((state) => state?.Data?.leaveTypeDropDown),'Code','ID');
    const editUpdateCount = useSelector((state) => state?.Data?.editUpdateCount);
    const modalStatus = useSelector((state) => state.Data.modalStatus)
    const singleEmpLeaveData = useSelector((state) => state?.Data?.singleEmpLeaveData[0]);
    const [urlName, setUrlName] = useState('')
    const [showCol, setShowCol] = useState('')
    const [idName, setIdName] = useState('')
    const [actionType, setActionType] = useState('')

    const [value, setValue] = useState({
        EmpID: empData.EmpID,
        EmpName: empData.EmpName,
        LeavetypeID: '',
        LeaveType: '',
        Leavefrom: '',
        LeaveTo: '',
        Leavefrom1: '',
        LeaveTo1: '',
        LeaveReason: '',
        LeaveStatusID: '',
        LeaveStatus: '',
        Updatedbyid: '',
        Createdbyid: userId
    });

    useEffect(() => {
         dispatch(get_Leave_Type_Drop());
        if(leaveStatusDropDown?.length === 0) dispatch(get_Leave_Status_Drop());
        if (leaveStatusDropDown) {
            const id = leaveStatusDropDown?.filter((val) => {if (val.label === "Pening") return val })
            if (id.length > 0) {
              setValue(prevValues => { return { ...prevValues, ['LeaveStatusID']: id[0].label } })
            };  dispatch(get_Leave_Status_Drop());
        //     setNameTypeIdDrp(changeThreeArrayFormat(data, 'NameTypeID', 'Description', 'NameTypeCode'))
        //   } else {
        //     setNameTypeIdDrp([]);
          }
    }, []);

    const [errors, setErrors] = useState({
        StatusError: '', TypeError: '', ReqFromError: '', ReqtoError: '', ReasonError: '',
    })

    const { StatusError, TypeError, ReqFromError, ReqtoError, ReasonError } = errors;

    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.LeaveStatus)) {
            setErrors(prevValues => { return { ...prevValues, ['StatusError']: SpaceNotAllow(value.LeaveStatus) } })
            dispatch({ type: Get_Error_Status, payload: true });
        };
        if (SpaceNotAllow(value.LeaveType)) {
            setErrors(prevValues => { return { ...prevValues, ['TypeError']: SpaceNotAllow(value.LeaveType) } })
        };
        if (SpaceNotAllow(value.Leavefrom)) {
            setErrors(prevValues => { return { ...prevValues, ['ReqFromError']: SpaceNotAllow(value.Leavefrom) } })
        };
        if (SpaceNotAllow(value.LeaveTo)) {
            setErrors(prevValues => { return { ...prevValues, ['ReqtoError']: SpaceNotAllow(value.LeaveTo) } })
        };
        if (SpaceNotAllow(value.LeaveReason)) {
            setErrors(prevValues => { return { ...prevValues, ['ReasonError']: SpaceNotAllow(value.LeaveReason) } })
        }
    };

    useEffect(() => {
        if (StatusError === 'true' && TypeError === 'true' && ReqFromError === 'true' && ReqtoError === 'true' && ReasonError === 'true') {
            if (status) { dispatch(update_EmpLeave(value, pageStatus)); setErrors({ ['StatusError']: '' }) }
            else { dispatch(save_EmpLeave(value, pageStatus)); setErrors({ ['StatusError']: '' }) }
        }
    }, [StatusError, TypeError, ReqFromError, ReqtoError, ReasonError]);

    const handelChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    }

    const handleDropdown = (e, Name) => {
        if (Name === 'LeaveStatusID') {
            setValue(pre => { return { ...pre, ['LeaveStatusID']: e?.value, ['LeaveStatus']: e?.label } })
        } else if (Name === 'LeavetypeID') {
            setValue(pre => { return { ...pre, ['LeavetypeID']: e?.value, ['LeaveType']: e?.label } })
        }
    }

    useEffect(() => {
        if (singleEmpLeaveData) {
            setValue({
                ...value, EmpID: empData?.EmpID, EmpName: empData?.EmpName, LeavetypeID: singleEmpLeaveData?.LeavetypeID, LeaveType: singleEmpLeaveData?.LeaveType, Leavefrom: singleEmpLeaveData?.Leavefrom, LeaveTo: singleEmpLeaveData?.LeaveTo, Leavefrom1: singleEmpLeaveData?.Leavefrom?.split("/").reverse().join("/"), LeaveTo1: singleEmpLeaveData?.LeaveTo?.split("/").reverse().join("/"), LeaveReason: singleEmpLeaveData?.LeaveReason, LeaveStatusID: singleEmpLeaveData?.LeaveStatusID, LeaveStatus: singleEmpLeaveData?.LeaveStatus, ID: singleEmpLeaveData?.ID, Updatedbyid: userId,
            })
        }
        else {
            setValue({
                ...value, EmpID: empData?.EmpID, EmpName: empData?.EmpName, LeavetypeID: '', LeaveType: '', Leavefrom: '', LeaveTo: '', Leavefrom1: '', LeaveTo1: '', LeaveReason: '', LeaveStatusID: '', LeaveStatus: '', Updatedbyid: '', ID: '',
            })
        }
    }, [singleEmpLeaveData, editUpdateCount]);

    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
        }
    };

    const resetHooks = () => {
        dispatch({ type: Get_Single_EmpLeave_Data, payload: [] });
        setValue({
            ...value, LeavetypeID: '', LeaveType: '', Leavefrom: '', LeaveTo: '', Leavefrom1: '', LeaveTo1: '', LeaveReason: '', LeaveStatusID: '', LeaveStatus: '', Updatedbyid: '', Createdbyid: userId
        });
        setErrors({ 'StatusError': '', 'TypeError': '', 'ReqFromError': '', 'ReqtoError': '', 'ReasonError': '' })
    }
    const setUrls = (idName, urlName, showCol, actionType) => {
        setIdName(idName); setUrlName(urlName); setShowCol(showCol); setActionType(actionType)
    }

    return (
        <>
            {modalStatus &&
                <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="EmpLeaveModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="m-1 ">
                                    <fieldset style={{ border: '1px solid gray' }}>
                                        <legend >Employee Leave</legend>
                                        <div className="form-row" style={{ marginTop: '-10px' }}>
                                            <div className="col-6 col-md-4 col-lg-4 d-flex pt-2  px-0  ">
                                                <div className="col-6 col-md-4 col-lg-11   ">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            name="LeaveStatusID"
                                                            styles={colourStyles}
                                                            isClearable
                                                            placeholder="Select.."
                                                            onChange={(e) => handleDropdown(e, 'LeaveStatusID')}
                                                            value={leaveStatusDropDown?.filter((obj) => obj?.label === value?.LeaveStatus)}
                                                            options={leaveStatusDropDown}
                                                        />
                                                        <label htmlFor="">Leave Status</label>
                                                        {errors.StatusError !== "true" ? (
                                                            <span
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "13px",
                                                                    margin: "0px",
                                                                    padding: "0px",
                                                                }}
                                                            >
                                                                {errors.StatusError}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-1 mt-2 pt-1 px-0">
                                                    <Link to={'/HR?sub=Emp-Leave'} className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal"
                                                          onClick={() =>
                                                            setUrls('ID', 'EmpStatus', 'EmpStatus', 'GetData_EmpStatus')}
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-4 col-lg-4 d-flex pt-2    ">

                                                <div className="col-6 col-md-4 col-lg-10  ">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            name="LeavetypeID"
                                                            styles={colourStyles}
                                                            isClearable
                                                            onChange={(e) => handleDropdown(e, 'LeavetypeID')}
                                                            value={leaveTypeDropDown?.filter((obj) => obj?.label === value?.LeaveType)}
                                                            options={leaveTypeDropDown}
                                                            placeholder="Select.."
                                                        />
                                                        <label htmlFor="">Leave Type</label>
                                                        {errors.TypeError !== "true" ? (
                                                            <span
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "13px",
                                                                    margin: "0px",
                                                                    padding: "0px",
                                                                }}
                                                            >
                                                                {errors.TypeError}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="col-1 mt-2 pt-1 px-0">
                                                    <Link to={'/HR?sub=Emp-Leave'} className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal"
                                                      onClick={() =>
                                                        setUrls('ID', 'EmpLeaveType', 'Code', 'GetData_EmpLeaveType')}
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-4 col-lg-4 " style={{ marginTop: '3px' }}>
                                                <div className="dropdown__box">
                                                    <DatePicker
                                                        id='TodoDate'
                                                        name='TodoDate'
                                                        ref={startRef}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => date ? setValue(pre => { return { ...pre, ['Leavefrom']: getShowingDayWithOutTime(date), ['Leavefrom1']: getShowingWithOutTimeStartDate(date) } }) :
                                                            setValue(pre => { return { ...pre, ['Leavefrom']: '', ['Leavefrom1']: '' } })
                                                        }
                                                        className='requiredColor'
                                                        dateFormat="dd/MM/yyyy"
                                                        timeInputLabel
                                                        isClearable={value.Leavefrom ? true : false}
                                                        selected={value.Leavefrom1 && new Date(value.Leavefrom1)}
                                                        placeholderText={'Select...'}
                                                        minDate={new Date()}
                                                        dropdownMode="select"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                    <label htmlFor="" className='pl-0 pt-1' >Leave Request From</label>
                                                    {errors.ReqFromError !== "true" ? (
                                                        <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.ReqFromError} </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-4 col-lg-4 " style={{ marginTop: '5px' }}>
                                                <div className="dropdown__box">
                                                    <DatePicker
                                                        id='TodoDate'
                                                        name='TodoDate'
                                                        ref={startRef}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => date ? setValue(pre => { return { ...pre, ['LeaveTo']: getShowingDayWithOutTime(date), ['LeaveTo1']: getShowingWithOutTimeStartDate(date) } }) :
                                                            setValue(pre => { return { ...pre, ['LeaveTo']: '', ['LeaveTo1']: '' } })
                                                        }
                                                        className='requiredColor'
                                                        dateFormat="dd/MM/yyyy"
                                                        timeInputLabel
                                                        isClearable={value.LeaveTo ? true : false}
                                                        selected={value.LeaveTo1 && new Date(value.LeaveTo1)}
                                                        placeholderText={'Select...'}
                                                        minDate={new Date()}
                                                        dropdownMode="select"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                    <label htmlFor="" className='pl-0 pt-1' >Leave Request To</label>
                                                    {errors.ReqtoError !== "true" ? (
                                                        <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} >{errors.ReqtoError} </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-8 col-md-8 col-lg-8 mt-1" >
                                                <div className="dropdown__box">
                                                    <textarea name='LeaveReason' id="LeaveReason"
                                                        onChange={handelChange} cols="30" rows='1' className="form-control requiredColor " value={value.LeaveReason} ></textarea>
                                                    <label htmlFor="">Reason for Leave </label>
                                                    {errors.ReasonError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.ReasonError}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div className="btn-box text-right  mr-1 mb-2">
                                {status ?
                                    <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}> Update</button> :
                                    <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Save</button>}
                                <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={resetHooks}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
             {
                urlName &&
                <MasSideBar
                    showCol1={showCol} idName={idName} urlName={urlName} actionType={actionType}
                    getUrl={`/${urlName}/GetData_${urlName}`}
                    addUrl={`/${urlName}/Insert${urlName}`}
                    singleDataUrl={`/${urlName}/GetSingleData_${urlName}`}
                    upUrl={`/${urlName}/Update${urlName}`}
                    delUrl={`/${urlName}/Delete${urlName}`}
                />
            }
        </>
    )
}

export default EmpLeave_Add_Up