import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Select from "react-select";
import DatePicker from 'react-datepicker'
import DataTable from 'react-data-table-component';
import AddRequest_Add_Up from './AddRequest_Add_Up';
import { delete_RequestItem_Data, get_AddItem_Data, get_Department_Drop_Data, get_Employee_Data, get_Request_List_Data, get_Single_RequestList_Data, save_Add_Request_Data, update_Add_Request_Data } from '../../../../redux/actions/AllAction';
import { Edit_Update_Count, Get_Error_Status, Get_Single_RequestList_Data, Modal_Status, Status } from '../../../../redux/actionTypes';
import { RequiredFieldSelectBox, SpaceNotAllow } from '../../../Validation/Validation';
import { changeArrayFormatDrop } from '../../../Include/Common/ArrayFormat';
import { getShowingDayWithOutTime, getShowingWithOutTime } from '../../../Include/Common/Utility';
import ConfirmModal from '../../../Include/Common/ConfirmModal';

const AddRequest = () => {
    const dispatch = useDispatch()
    const userId = useSelector((state) => state.Data.userId)
    const [pageStatus, setPageStatus] = useState("1")
    const [status, setStatus] = useState(false)
    const departmentData = changeArrayFormatDrop(useSelector((state) => state.Data.departmentDropData),'Department' ,'ID')
    console.log(departmentData,'departmentdata')
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const requestListData = useSelector((state) => state.Data.requestListData)
    const ReqId = useSelector((state) => state?.Data?.reqID)
    const [IsActive, setIsActive] = useState()
    const [confirmType, setConfirmType] = useState('Delete')
    const [id, setId] = useState('')
    const Status = useSelector((state) => state.Data.Status)
    const singleRequestData = useSelector((state) => state.Data.singleRequestData[0])
    const employeeData = changeArrayFormatDrop(useSelector((state) => state.Data.employeeData), 'EmpName', 'ID');
    const customStylesWithOutColor = {
        control: (base) => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    };
    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    const [value, setValue] = useState({
        RequestNo: '',
        RequestDate: getShowingDayWithOutTime(new Date()),
        RequestDate1: new Date(),
        JobNo: '',
        departmentID: '',
        department: '',
        RequestbyID: '',
        Requestby: '',
        Remarks: '',
        Createdbyid: userId,
        ID: ''
    })

    useEffect(() => {
        if (ReqId) dispatch(get_Request_List_Data(ReqId, pageStatus))
    }, [pageStatus]);

    const [errors, setErrors] = useState({ requestbyError: '', requestDateError: '' })

    const { requestbyError, requestDateError } = errors

    useEffect(() => {
        if (singleRequestData) {
            setValue({
                ...value,
                RequestNo: singleRequestData?.RequestNo,
                RequestDate: singleRequestData?.RequestDate,
                RequestDate1: singleRequestData?.RequestDate?.split("/")?.reverse()?.join("/"),
                JobNo: singleRequestData?.JobNo,
                departmentID: singleRequestData?.departmentID,
                department: singleRequestData?.department,
                RequestbyID: singleRequestData?.RequestbyID,
                Requestby: singleRequestData?.Requestby,
                Remarks: singleRequestData?.Remarks,
                ID: singleRequestData?.ID,
                Updatedbyid: userId,
            })
        }
        else {
            setValue({
                ...value,
                RequestNo: '',
                RequestDate: getShowingDayWithOutTime(new Date()),
                RequestDate1: new Date(),
                JobNo: '',
                departmentID: '',
                department: '',
                RequestbyID: '',
                Requestby: '',
                Remarks: '',
                Updatedbyid: '',
            })
        }
    }, [singleRequestData, editUpdateCount])


    useEffect(() => {
        if (requestbyError === 'true' && requestDateError === 'true') {
            if (Status) { dispatch(update_Add_Request_Data(value, ReqId)); }
            else { dispatch(save_Add_Request_Data(value, ReqId)) }
        }
    }, [requestbyError, requestDateError])

    useEffect(() => {
        if (departmentData.length === 0) dispatch(get_Department_Drop_Data())
        if (employeeData.length === 0) dispatch(get_Employee_Data('1'))
    }, [])

    const handleDropdown = (e, name) => {
        if (name === 'departmentID') {
            setValue(pre => { return { ...pre, ['departmentID']: e?.value, ['department']: e?.label } })
        }
        if (name === 'RequestbyID') {
            setValue(pre => { return { ...pre, ['RequestbyID']: e?.value, ['Requestby']: e?.label } })
        }
    }

    const handelChange = (e,) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    }

    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.Requestby)) {
            setErrors(prevValues => { return { ...prevValues, ['requestbyError']: SpaceNotAllow(value.Requestby) } })
            dispatch({ type: Get_Error_Status, payload: true })
        }
        if (SpaceNotAllow(value.RequestDate)) {
            setErrors(prevValues => { return { ...prevValues, ['requestDateError']: SpaceNotAllow(value.RequestDate) } })
        }
    }

    const columns = [
        {
            name: 'Item Code',
            selector: (row) => row.Product,
            sortable: true
        },
        {
            name: 'Req Qty',
            selector: (row) => row.ReqQty,
            sortable: true
        },
        {
            name: 'Aproved to be',
            selector: (row) => row.TobeApprovedby,
            sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 55, fontWeight: '700' }}>Action</p>,
            cell: row => <>
                <div style={{ position: 'absolute', top: 4, right: 40 }}>
                    {pageStatus === "1" ? <Link to='/addRequest'
                        className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn" onClick={(e) => setEditValue(row)} data-toggle="modal" data-target="#AddRequestModal"><i className="fa fa-edit"></i>
                    </Link> : ''}
                    <Link to='/addRequest' data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('0'); setId(row.ID); setConfirmType('Delete') }}
                        className="btn btn-sm bg-green text-white px-1 py-0 mr-1" >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </Link>
                </div>
            </>
        }
    ]
    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();
    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
        }
    }
    const setEditValue = (row) => {
        dispatch(get_Single_RequestList_Data(row.ID));
        setStatus(true);
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: Modal_Status, payload: true })
    }

    const newStatus = () => {
        dispatch({ type: Get_Single_RequestList_Data, payload: [] });
        setStatus(false);
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: Modal_Status, payload: true });
    }
    const UpdActiveDeactive = () => {
        dispatch(delete_RequestItem_Data(id, IsActive, userId, pageStatus, ReqId))
    }
    return (
        <>
            <div className="col-12 col-md-12 col-lg-12  px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Add Request</p>
                    {/* <Link to={`/Inventory?page=Add%20Request`} className="text-white px-2 py-0 "
                        data-toggle="modal" data-target="#" onClick={newStatus} >
                        <i className="fa fa-plus"></i>
                    </Link> */}
                </div>
                <div className="form-row px-1">
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className="label-field">Request No.</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-3">
                        <div class="text-field">
                            <input
                                type="text"
                                name="RequestNo"
                                id="RequestNo"
                                value={value.RequestNo}
                                onChange={handelChange}
                                required
                                className='readonlyColor'
                                readOnly    
                            />
                        </div>
                    </div>
                </div>
                <div className="form-row px-1">
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className="label-field">Department</label>
                    </div>
                    <div className="col-4 col-md-4 col-lg-3">
                        <div className="dropdown__box">
                            <Select
                                name='departmentID'
                                styles={customStylesWithOutColor}
                                value={departmentData?.filter((obj) => obj.value === value.departmentID)}
                                options={departmentData}
                                isClearable
                                placeholder="Select.."
                                onChange={(e) => handleDropdown(e, 'departmentID')}
                            />
                        </div>
                    </div>
                    <div className="col-1 col-md-1 col-lg-2 ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className="label-field">Enter Job No.</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-3">
                        <div class="text-field">
                            <input
                                type="text"
                                name="JobNo"
                                id="JobNo"
                                value={value.JobNo}
                                onChange={handelChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className="label-field">Request Date</label>
                    </div>
                    <div class="col-4 col-md-4 col-lg-3 ">
                        <div className="dropdown__box">
                            <DatePicker
                                id='RequestDate'
                                name='RequestDate'
                                dateFormat="dd/MM/yyyy "
                                timeInputLabel
                                className='requiredColor'
                                onChange={(date) => date ? setValue(pre => { return { ...pre, ['RequestDate']: getShowingDayWithOutTime(date), ['RequestDate1']: getShowingWithOutTime(date) } }) :
                                    setValue(pre => { return { ...pre, ['RequestDate']: '', ['RequestDate1']: '' } })
                                }
                                placeholderText={'Select...'}
                                selected={value?.RequestDate1 && new Date(value?.RequestDate1)}
                                dropdownMode="select"
                                isClearable={value?.RequestDate1 && new Date(value?.RequestDate1)}
                                showMonthDropdown
                                minDate={new Date()}
                                showYearDropdown
                            />
                        </div>
                        {errors.requestDateError !== 'true' ? (
                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.requestDateError}</span>
                        ) : null}
                    </div>
                    <div className="col-1 col-md-1 col-lg-2 ">
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className="label-field">Request By</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-3">
                        <div className="dropdown__box">
                            <Select
                                name='RequestbyID'
                                styles={colourStyles}
                                value={employeeData?.filter((obj) => obj.value === value.RequestbyID)}
                                options={employeeData}
                                isClearable
                                className="requiredColor"
                                placeholder="Select.."
                                onChange={(e) => handleDropdown(e, 'RequestbyID')}
                            />
                        </div>
                        {errors.requestbyError !== 'true' ? (
                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.requestbyError}</span>
                        ) : null}
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                        <label className="label-field">Remarks</label>
                    </div>
                    <div className="col-10  col-md-10 col-lg-10 ">
                        <div className="dropdown__box">
                            <textarea
                                name="Remarks"
                                id="Remarks"
                                cols="30"
                                rows="1"
                                className="form-control"
                                value={value.Remarks}
                                onChange={handelChange}
                            >
                            </textarea>

                        </div>
                    </div>
                    <div className="btn-box col-12 text-right  mr-1 mt-3 mb-2">
                        {Status ? <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button> :
                            <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Save</button>}
                        {requestListData ?
                            <Link to={'/Inventory?page=Add%20Request'}>
                                <button type="button" class="btn btn-sm btn-success mr-1" >Close</button>
                            </Link> : ''}
                    </div>
                </div>

                <div className="col-12 col-md-12 col-lg-12 ">
                    <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                        <p className="p-0 m-0 d-flex align-items-center">Item Request List</p>
                        {singleRequestData ? <Link to={`/addRequest`} onClick={newStatus} className="text-white px-2 py-0 " data-toggle="modal" data-target="#AddRequestModal"  >
                            <i className="fa fa-plus"></i>
                        </Link> : ''}
                    </div>
                </div>
                <div className="col-12 mt-1">
                    <DataTable
                        dense
                        columns={columns}
                        data={requestListData}
                        pagination
                        selectableRowsHighlight
                        highlightOnHover
                    />
                </div>
            </div>
            <AddRequest_Add_Up {...{ status, setPageStatus }} />
            <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />
        </>
    )
}

export default AddRequest