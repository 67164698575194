import React, { useEffect } from 'react'
import { useState } from 'react';
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { getShowingDayWithOutTime, getShowingWithOutTimeStartDate } from '../../../Include/Common/Utility';
import { PlantMDRecord, PlantTotalUnit, PlantDGUnit, PlantDirectUnit, Get_Error_Status } from '../../../../redux/actionTypes';
import { save_Consumption_Power_Data, update_Consumption_Power_Data } from '../../../../redux/actions/AllAction';
import { SpaceNotAllow } from '../../../Validation/Validation';
import { toastifyError } from '../../../Include/Common/AlertMsg';

const PowerConsumption_Add_Up = (props) => {
    const dispatch = useDispatch()
    const singleConsumptionData = useSelector((state) => state.Data.singleConsumptionData[0])
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const { status, pageStatus } = props
    const [rowCount, setRowCount] = useState(0);
    const startRef = React?.useRef();
    const startRef1 = React?.useRef();
    const startRef2 = React?.useRef();
    const startRef3 = React?.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
        }
    };
    // for text-field value
    const userId = useSelector((state) => state.Data.userId);
    const [value, setValue] = useState({
        ConsumptionDate: '',
        ConsumptionDate1: '',
        PlantDirectUnit1: '',
        PlantDGUnit1: '',
        PlantTotalUnit1: '',
        PlantMDRecord1: '',
        PlantDirectUnit2: '',
        PlantDGUnit2: '',
        PlantTotalUnit2: '',
        PlantMDRecord2: '',
        PlantDirectUnit3: '',
        PlantDGUnit3: '',
        PlantTotalUnit3: '',
        PlantMDRecord3: '',
        PlantDirectUnit4: '',
        PlantDGUnit4: '',
        PlantTotalUnit4: '',
        PlantMDRecord4: '',
        PlantDMDRecord: '',
        ID: '',
        Createdbyid: userId,
    })
    const [state, setState] = useState([])
    const [errors, setErrors] = useState({ ConsumptionDateError: '' })
    const { ConsumptionDateError } = errors;

    const handleChange = (idx, type) => e => {
        const upd_obj = state.map(obj => {
            if (obj.id == idx) {
                obj[e.target.name] = e.target.value;
                setValue(pre => { return { ...pre, [e.target.name]: e.target.value } })
            }
            return obj;
        })
        setState(upd_obj);
    };

    useEffect(() => {
        if (singleConsumptionData) {
            setRowCount(0)
            setValue(pre => { return { ...pre, ConsumptionDate: singleConsumptionData.ConsumptionDate, ConsumptionDate1: singleConsumptionData.ConsumptionDate.split("/").reverse().join("/"), PlantDirectUnit1: singleConsumptionData.PlantDirectUnit1, PlantDGUnit1: singleConsumptionData.PlantDGUnit1, PlantTotalUnit1: singleConsumptionData.PlantTotalUnit1, PlantMDRecord1: singleConsumptionData.PlantMDRecord1, PlantDirectUnit2: singleConsumptionData.PlantDirectUnit2, PlantDGUnit2: singleConsumptionData.PlantDGUnit2, PlantTotalUnit2: singleConsumptionData.PlantTotalUnit2, PlantMDRecord2: singleConsumptionData.PlantMDRecord2, PlantDirectUnit3: singleConsumptionData.PlantDirectUnit3, PlantDGUnit3: singleConsumptionData.PlantDGUnit3, PlantTotalUnit3: singleConsumptionData.PlantTotalUnit3, PlantMDRecord3: singleConsumptionData.PlantMDRecord3, PlantDirectUnit4: singleConsumptionData.PlantDirectUnit4, PlantDGUnit4: singleConsumptionData.PlantDGUnit4, PlantTotalUnit4: singleConsumptionData.PlantTotalUnit4, PlantMDRecord4: singleConsumptionData.PlantMDRecord4, ID: singleConsumptionData.ID, UpdatedByid: userId } })
            for (let i = 1; i < 5; i++) {
                if (singleConsumptionData[PlantDirectUnit + i] && singleConsumptionData[PlantDGUnit + i]) {
                    setRowCount(i)
                }
            }
        } else resetHooks()
    }, [singleConsumptionData, editUpdateCount])

    useEffect(() => {
        if (rowCount > 0) {
            const newArray = [];
            for (let i = 1; i <= rowCount; i++) {
                newArray.push({
                    id: i,
                    [PlantDirectUnit + i]: singleConsumptionData[PlantDirectUnit + i],
                    [PlantTotalUnit + i]: singleConsumptionData[PlantTotalUnit + i],
                    [PlantMDRecord + i]: singleConsumptionData[PlantMDRecord + i],
                    [PlantDGUnit + i]: singleConsumptionData[PlantDGUnit + i],
                })
            }
            setState(newArray);
        }
    }, [rowCount])

    const handleAddRow = () => {
        const item = {
            id: state.length + 1,
            [PlantMDRecord + (state.length + 1)]: "",
            [PlantTotalUnit + (state.length + 1)]: "",
            [PlantDGUnit + (state.length + 1)]: "",
            [PlantDirectUnit + (state.length + 1)]: "",
        };
        if (state.length < 4) {
            setState([...state, item]);
        }
    };

    const handleRemoveSpecificRow = (idx) => () => {
        const rows = [...state]
        const data = rows.filter(function (element) {
            return element.id !== idx;
        });
        setState(data);
        setValue(pre => { return { ...pre, [PlantDirectUnit + idx]: '', [PlantMDRecord + idx]: '', [PlantDGUnit + idx]: '', [PlantTotalUnit + idx]: '', } },)
    }

    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.ConsumptionDate)) {
            setErrors(prevValues => { return { ...prevValues, ['ConsumptionDateError']: SpaceNotAllow(value.ConsumptionDate) } });
            dispatch({ type: Get_Error_Status, payload: true });
        }
        const res = state.filter((element, id) => {
            return element[PlantDirectUnit + (id + 1)] === '' || element[PlantDirectUnit + (id + 1)] === null;
        });
       
    }   
   

    useEffect(() => {
        if (ConsumptionDateError === 'true') {
            if (status) { dispatch(update_Consumption_Power_Data(value, pageStatus)); setRowCount(0)  }
            else { dispatch(save_Consumption_Power_Data(value, pageStatus)); setRowCount(0) }
        }
    }, [ConsumptionDateError]);

    const resetHooks = () => {
        setState([]);
        setValue(pre => {
            return {
                ...pre,
                ConsumptionDate: '',
                ConsumptionDate1: '',
                PlantDirectUnit1: '',
                PlantDGUnit1: '',
                PlantTotalUnit1: '',
                PlantMDRecord1: '',
                PlantDirectUnit2: '',
                PlantDGUnit2: '',
                PlantTotalUnit2: '',
                PlantMDRecord2: '',
                PlantDirectUnit3: '',
                PlantDGUnit3: '',
                PlantTotalUnit3: '',
                PlantMDRecord3: '',
                PlantDirectUnit4: '',
                PlantDGUnit4: '',
                PlantTotalUnit4: '',
                PlantMDRecord4: '',
                PlantDMDRecord: '',
                ID: '',
                Updatedbyid:''
            }
        });
        setErrors(pre => { return { ...pre, ConsumptionDateError: '' } })
        setRowCount(0)
    }

    return (
        <>
            <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="PowerConsumptionModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="m-1 ">
                                <fieldset style={{ border: '1px solid gray' }}>
                                    <legend >Power Consumption</legend>
                                    <div className="form-row" style={{ marginTop: '-15px' }}>
                                        <div className="col-6 col-md-6 col-lg-4 ">
                                            <div className="dropdown__box ">
                                                <DatePicker
                                                    id='ConsumptionDate'
                                                    name='ConsumptionDate'
                                                    className='requiredColor' 
                                                    ref={startRef}
                                                    onKeyDown={onKeyDown}
                                                    onChange={(date) => date ? setValue(pre => { return { ...pre, ['ConsumptionDate']: getShowingDayWithOutTime(date), ['ConsumptionDate1']: getShowingWithOutTimeStartDate(date) } }) :
                                                        setValue(pre => { return { ...pre, ['ConsumptionDate']: '', ['ConsumptionDate1']: '' } })
                                                    }
                                                    dat
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={new Date()}
                                                    timeInputLabel
                                                    selected={value.ConsumptionDate1 && new Date(value.ConsumptionDate1)}
                                                    placeholderText={'Select...'}
                                                    dropdownMode="select"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                                <label htmlFor="" className='pt-1'>Date</label>
                                                {errors.ConsumptionDateError !== "true" ? (
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            fontSize: "13px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                        }}
                                                    >
                                                        {errors.ConsumptionDateError}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 pt-2 px-0">
                                        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                                            <p className="p-0 m-0 d-flex align-items-center">Power Consumption</p>
                                            <div style={{ marginLeft: 'auto' }}>
                                                <button onClick={handleAddRow} className="btn btn-sm btn-success px-2 py-0 ml-auto">
                                                    <i className='fa fa-plus'></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-12 col-md-12 col-lg-12" >
                                            <table class="table table-bordered" >
                                                <thead style={{ backgroundColor: 'blanchedalmond ' }}>
                                                    <tr>
                                                        <th >S.no</th>
                                                        <th>Direct Power Unit</th>
                                                        <th>DG power unit</th>
                                                        <th>Total power unit</th>
                                                        <th>MD recorded</th>
                                                        <th className='text-right'>
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {state?.map((item, idx) => (
                                                        <tr id="addr0" key={idx}>
                                                            <td className='payment-serial pt-3'>{` Plant ${idx + 1 === 1 ? 'A' : idx === 1 ? 'B' : idx === 2 ? 'C' : idx === 3 && 'D'}`}</td>
                                                            <td className='payment-amount1' >
                                                                <div className="dropdown__box" >
                                                                    <div className="text-field mt-3">
                                                                        <input
                                                                            type="text"
                                                                            name={`PlantDirectUnit${idx + 1}`}
                                                                            value={item[PlantDirectUnit + item.id]}
                                                                            onChange={handleChange(item.id)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className='payment-amount1'>
                                                                <div className="dropdown__box">
                                                                    <div className="text-field mt-3">
                                                                        <input
                                                                            type="text"
                                                                            name={`PlantDGUnit${idx + 1}`}
                                                                            value={item[PlantDGUnit + item.id]}
                                                                            onChange={handleChange(item.id)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className='payment-amount1 '>
                                                                <div className="text-field mt-3">
                                                                    <input
                                                                        type="text"
                                                                        name={`PlantTotalUnit${idx + 1}`}
                                                                        value={item[PlantTotalUnit + item.id]}
                                                                        onChange={handleChange(item.id)}
                                                                        required
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className='payment-amount1' >
                                                                <div className="dropdown__box">
                                                                    <div className="text-field mt-3">
                                                                        <input
                                                                            type="text"
                                                                            name={`PlantMDRecord${idx + 1}`}
                                                                            value={item[PlantMDRecord + item.id]}
                                                                            onChange={handleChange(item.id)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className='payment-button ' style={{ paddingTop: '23px' }}>
                                                                <button
                                                                    className="btn btn-outline-danger btn-sm"
                                                                    onClick={handleRemoveSpecificRow(item.id)} style={{ fontSize: '12px' }} >
                                                                    <i className="fa fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="btn-box text-right  mr-1 mb-2">
                            {status ?
                                <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error} >Update</button> :
                                <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error} >Save</button>}

                            <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={resetHooks} >Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PowerConsumption_Add_Up