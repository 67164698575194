import React from 'react'
import { useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { get_Attendance_Data } from '../../../../../redux/actions/AllAction'
import { getShowingDayWithOutTime, getShowingWithOutTimeStartDate } from '../../../../Include/Common/Utility'

const AttendanceList = () => {

    const dispatch = useDispatch()
    const attendanceData = useSelector((state) => state.Data.attendanceData)
    const userId = useSelector((state) => state.Data.userId)
    useEffect(() => {
        dispatch(get_Attendance_Data(userId, getShowingDayWithOutTime(new Date())))
    }, [])

    const columns = [
        {
            name: 'EmpName',
            selector: (row) => row.EmpName,
            sortable: true
        },
        {
            name: 'LoginDate',
            selector: (row) => row.LoginDate,
            sortable: true
        },
        {
            name: 'LoginTime',
            selector: (row) => row.LoginTime,
            sortable: true
        },
        {
            name: 'LogoutTime',
            selector: (row) => row.LogoutTime,
            sortable: true
        },
        {
            name: 'LogoutDate',
            selector: (row) => row.LogoutDate,
            sortable: true
        },
    ]

    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Attendance List</p>
                    {/* <div style={{ marginLeft: 'auto' }}>
                        <Link to={'/DashBoard?sub=Attendance'} className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#">
                            <i className="fa fa-plus"></i>
                        </Link>
                    </div> */}
                </div>
            </div>

            <div className="col-12 py-1 px-2 ">
                <DataTable
                    dense
                    columns={columns}
                    data={attendanceData}
                    pagination
                    selectableRowsHighlight
                    highlightOnHover
                />
            </div>
        </>
    )
}

export default AttendanceList