import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

const SubTab = ({ tabs, setShowPage, showPage, status}) => {
    return (
        <>
            <ul class="nav nav-tabs">
                {

                    tabs?.map((val) => (
                        <>
                            <li className='nav-item'>
                                <a className={`nav-link ${showPage === val.path ? 'active' : ''} ${!status ? 'disabled' : ''}`} aria-current="page" onClick={() => setShowPage(val.path) } >
                                    {val.tab}
                                </a>
                            </li>
                        </>
                    ))
                }
            </ul >



        </>
    )
}

export default SubTab