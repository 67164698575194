import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import ApproveQuotes_Add_Up from './ApproveQuotes_Add_Up';
import { get_Approve_Quotes_Data, get_Approve_Request_Data } from '../../../../redux/actions/AllAction';
import { Edit_Update_Count, Get_Approve_Request, Get_Quotes_Id, Get_Req_Id, MasterTable_Modal_Status, Modal_Status } from '../../../../redux/actionTypes';
import { useState } from 'react';

const ApproveRequest = () => {
    const dispatch = useDispatch()
    const approveQuotes = useSelector((state) => state.Data.approveQuotes)
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const [pageStatus, setPageStatus] = useState('1')
    const [status, setStatus] = useState(true)
    const [id, setID] = useState('');

    useEffect(() => {
        dispatch(get_Approve_Quotes_Data(pageStatus))
    }, [])

    const columns = [
        {
            name: 'SupplierName',
            selector: (row) => row.SupplierName,
            sortable: true,
        },
        {
            name: 'DiscountType',
            selector: (row) => row.DiscountType,
            sortable: true,
        },
        {
            name: 'Receivedby',
            selector: (row) => row.Receivedby,
            sortable: true,
        },
        {
            name: 'Insurance',
            selector: (row) => row.Insurance,
            sortable: true,
        },
        {
            name: <p className='text-end text-dark' style={{ position: 'absolute', top: '7px', fontWeight: '700' }}>Action</p>,
            cell: row => <>
                <Link to={'#'} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                    <i className="fa fa-eye"></i>
                </Link>
                <Link to={'/Inventory?page=Approve Quotes'} onClick={(e) => { setApprovedStatus(row) }} data-toggle="modal" data-target="#AddApproveModal" className="col-6 btn btn-sm bg-green text-white px-1 py-0 mr-1">
                    Approved
                </Link>
                <Link to={'/Inventory?page=Approve Quotes'} onClick={setRejectStatus} data-toggle="modal" data-target="#AddApproveModal" className=" col-4  btn btn-sm bg-danger text-white px-1 py-0 mr-1">
                    Reject
                </Link>

            </>
        }
    ];
    const setApprovedStatus = (row) => {
        dispatch(get_Approve_Quotes_Data(row.id));
        dispatch({ type: Get_Quotes_Id, payload: row.id });
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: Modal_Status, payload: true })
    }
    const setRejectStatus = (row) => {
        dispatch(get_Approve_Quotes_Data(row.id));
        dispatch({ type: Get_Quotes_Id, payload: row.id });
        setStatus(false)
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: Modal_Status, payload: true })
    }
    return (
        <>
            <div className="col-12 col-md-12 px-3 mb-1" >
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Approved Quotes</p>
                    {/* <p className="p-0 m-0">
                        <Link to={'#'} data-toggle="modal" data-target="#" className="btn btn-sm bg-green text-white px-2 py-0" >
                            <i className="fa fa-plus"></i>
                        </Link>
                    </p> */}
                </div>
            </div>
            <div className="col-12 col-md-12 mb-2 px-3" >
                <DataTable
                    dense
                    columns={columns}
                    data={approveQuotes}
                    pagination
                    selectableRowsHighlight
                    highlightOnHover
                />
            </div>
            <ApproveQuotes_Add_Up {...{ status }} />
        </>
    )
}

export default ApproveRequest