import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux'
import { get_Mantinance_Dropdown, save_Mantinance_Data, update_Mantinance_Data } from '../../../../redux/actions/AllAction';
import { Edit_Update_Count } from '../../../../redux/actionTypes';
import { getShowingDayWithOutTime } from '../../../Include/Common/Utility';


const ServiceMaintenanceAdd_Up = ({ status, setStatus }) => {
    const dispatch = useDispatch();
    const mantinanceDropdown = useSelector((state) => state.Data.mantinanceDropdown);
    const [errors, setErrors] = useState({})
    const singleMantinanceData = useSelector((state) => state.Data.singleMantinanceData)
    const userId = useSelector((state) => state.Data.userId);
    const [Checked, setChecked] = useState(true)
    const [ReadingDate, setReadingDate] = useState()
    const [LastFullTankDate, setLastFullTankDate] = useState()
    const [totalKm, setTotalKm] = useState('')
    const [totalHrs, setTotalHrs] = useState('')

    const HandleDropdown = (e, name, nameStr) => {
        if (e) {
            setValue({ ...value, [name]: e.value, [nameStr]: e.label, })
        } else {
            setValue({ ...value, [name]: null, [nameStr]: '' })
        }
    }
    const HandleService = (e) => {
        let isChecked = e.target.checked;
        setValue({
            ...value,
            [e.target.name]: isChecked ? true : false
        });
        setChecked(isChecked ? false : true)
    }

    const [value, setValue] = useState({
        "AccountID": '', 'AccountName': '', 'ReadingDate': '', 'ReadingStart': '', 'ReadingStop': '', 'TotalReading': '', 'RemDiesel': '', 'Average': '', 'Narration': '', 'Companyid': '', 'IsFullTank': '', 'LastFullTankDate': '', 'LastFullTankReading': '', 'ReadingStartKM': '', 'ReadingStopKm': '',
        'TotalReadingKM': '', 'VocuherNo': '',
        'Createdbyid': userId,
    }
    )
    const resetHooks = () => {
        setStatus(false)
        setValue(pre => {
            return {
                ...pre,
                AccountID: '', AccountName: '', ReadingDate: '', ReadingStart: '', ReadingStop: '', TotalReading: '', RemDiesel: '', Average: '', Narration: '', Companyid: '', IsFullTank: '', LastFullTankDate: '', LastFullTankReading: '', ReadingStartKM: '', ReadingStopKm: '', TotalReadingKM: '', VocuherNo: '', Createdbyid: userId,
            }
        })
        setErrors(pre => { return { ...pre } });
        setTotalHrs('');
        setTotalKm('');
    }
    
    const Handlechange = (e) => {
        setValue({
            ...value,
            "TotalReading": totalHrs,
            "TotalReadingKM": totalKm,
            [e.target.name]: e.target.value
        });
    };

    useEffect(() => {
        if (value?.ReadingStartKM && value?.ReadingStopKm) {
            if (value.ReadingStartKM < value.ReadingStopKm) {
                let TotalDistance = (value?.ReadingStopKm) - (value?.ReadingStartKM)
                setTotalKm(TotalDistance)
            }
        } else {
            setTotalKm()
        }
    }, [value.ReadingStartKM, value.ReadingStopKm]);

    useEffect(() => {
        if (value?.ReadingStart && value?.ReadingStop) {
            if (value.ReadingStart < value.ReadingStop) {
                let TotalValue = (value?.ReadingStop) - (value?.ReadingStart)
                setTotalHrs(TotalValue)
            }
        } else {
            setTotalHrs()
        }
    }, [value.ReadingStart, value.ReadingStop,]);

    useEffect(() => {
        dispatch(get_Mantinance_Dropdown(true));
    }, []);

    useEffect(() => {
        if (singleMantinanceData) {
            // console.log(singleMantinanceData, 'MantinanceDropdown')
            setValue({
                ...value,
                AccountID: singleMantinanceData[0]?.AccountID,
                AccountName: singleMantinanceData[0]?.AccountName,
                ReadingDate: singleMantinanceData[0]?.ReadingDate,
                ReadingStart: singleMantinanceData[0]?.ReadingStart,
                ReadingStop: singleMantinanceData[0]?.ReadingStop,
                TotalReading: singleMantinanceData[0]?.TotalReading,
                RemDiesel: singleMantinanceData[0]?.RemDiesel,
                Average: singleMantinanceData[0]?.Average,
                Narration: singleMantinanceData[0]?.Narration,
                Companyid: singleMantinanceData[0]?.Companyid,
                IsFullTank: singleMantinanceData[0]?.IsFullTank,
                LastFullTankDate: singleMantinanceData[0]?.LastFullTankDate,
                LastFullTankReading: singleMantinanceData[0]?.LastFullTankReading,
                ReadingStartKM: singleMantinanceData[0]?.ReadingStartKM,
                ReadingStopKm: singleMantinanceData[0]?.ReadingStopKm,
                TotalReadingKM: singleMantinanceData[0]?.TotalReadingKM,
                VocuherNo: singleMantinanceData[0]?.VocuherNo,
                ID: singleMantinanceData[0]?.ID,
                Createdbyid: userId,
                Updatedbyid: userId,
            });
        }
        else {
            setValue({
                ...value,
                AccountID: '', AccountName: '', ReadingDate: '', ReadingStart: '', ReadingStop: '', TotalReading: '', RemDiesel: '', Average: '', Narration: '', Companyid: '', IsFullTank: '', LastFullTankDate: '', Createdbyid: userId, LastFullTankReading: '', ReadingStartKM: '', ReadingStopKm: '', TotalReadingKM: '', VocuherNo: '', ID: '',

            })
        }
    }, [singleMantinanceData, Edit_Update_Count])

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    const startRef = React?.useRef();
    const startRef1 = React?.useRef();
    const startRef2 = React?.useRef()
    const startRef3 = React?.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
        }
    };

    return (
        <>
            <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="ServiceMaintenanceModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="m-1  ">
                                <div className="row px-3">
                                    <div className="col-3 col-md-3 col-lg-3 ">
                                        <div className="text-field ">
                                            <DatePicker
                                                id='ReadingDate'
                                                name='ReadingDate'
                                                ref={startRef}
                                                required
                                                onKeyDown={onKeyDown}
                                                dateFormat="dd/MM/yyyy"
                                                timeInputLabel
                                                className='requiredColor'
                                                isClearable
                                                placeholderText={'Select...'}
                                                dropdownMode="select"
                                                onChange={(date) => { setReadingDate(date); setValue({ ...value, ['ReadingDate']: date ? getShowingDayWithOutTime(date) : null }) }}
                                                selected={ReadingDate}
                                            />
                                            <label htmlFor="" className='pt-2 pl-0'>Reading Date</label>
                                        </div>
                                    </div>
                                    <div className="col-3 col-md-3 col-lg-4 mt-1">
                                        <div className="dropdown__box">
                                            <Select
                                                name='AccountID'
                                                styles={customStylesWithOutColor}
                                                isClearable
                                                placeholder="Select.."
                                                value={mantinanceDropdown?.filter((obj) => obj.value === value.AccountID)}
                                                options={mantinanceDropdown}
                                                onChange={(e) => HandleDropdown(e, 'AccountID', 'AccountName')}
                                                required
                                            />
                                            <label htmlFor="">Account</label>
                                        </div>
                                    </div>
                                </div>
                                <fieldset className='mt-2' style={{ border: '1px dashed #80808078', borderBottom: 'none', borderLeft: 'none', borderRight: 'none' }}>
                                    <legend >Reading (Km)</legend>
                                    <div className="row mb-0">
                                        <div className="col-3 col-md-3 col-lg-4 ">
                                            <div className="text-field ">
                                                <input
                                                    type="number"
                                                    name="ReadingStartKM"
                                                    className=""
                                                    id="ReadingStartKM"
                                                    required
                                                    value={value.ReadingStartKM}
                                                    onChange={Handlechange}
                                                />
                                                <label htmlFor="">Start Km</label>
                                            </div>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-4 ">
                                            <div className="text-field ">
                                                <input
                                                    type="number"
                                                    name="ReadingStopKm"
                                                    className=""
                                                    id="ReadingStopKm"
                                                    required
                                                    value={value.ReadingStopKm}
                                                    onChange={Handlechange}
                                                />
                                                <label htmlFor="">Stop Km</label>
                                            </div>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-4 ">
                                            <div className="text-field ">
                                                <input
                                                    type="text"
                                                    name="TotalReadingKM"
                                                    className=""
                                                    id="TotalReadingKM"
                                                    required
                                                    value={totalKm}
                                                    onChange={Handlechange}
                                                />
                                                <label htmlFor="">Total Km</label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className='' style={{ border: '1px dashed #80808078', borderBottom: 'none', borderLeft: 'none', borderRight: 'none', marginTop: '-10px' }}>
                                    <legend >Reading (hrs)</legend>
                                    <div className="row mb-0">
                                        <div className="col-3 col-md-3 col-lg-4 ">
                                            <div className="text-field ">
                                                <input
                                                    type="number"
                                                    name="ReadingStart"
                                                    className=""
                                                    id="ReadingStart"
                                                    required
                                                    value={value.ReadingStart}
                                                    onChange={Handlechange}
                                                />
                                                <label htmlFor="">Start</label>
                                            </div>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-4 ">
                                            <div className="text-field ">
                                                <input
                                                    type="number"
                                                    name="ReadingStop"
                                                    className=""
                                                    id="ReadingStop"
                                                    required
                                                    value={value.ReadingStop}
                                                    onChange={Handlechange}
                                                />
                                                <label htmlFor="">Stop</label>
                                            </div>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-4 ">
                                            <div className="text-field ">
                                                <input
                                                    type="text"
                                                    name="TotalReading"
                                                    className=""
                                                    id="TotalReading"
                                                    required
                                                    value={totalHrs}
                                                    onChange={Handlechange}
                                                />
                                                <label htmlFor="">Total Hrs</label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className='' style={{ border: '1px dashed #80808078', borderBottom: 'none', borderLeft: 'none', borderRight: 'none', marginTop: '-10px' }}>
                                    <legend >Diesel</legend>
                                    <div className="row mb-0">
                                        <div className="col-3 col-md-3 col-lg-3 ">
                                            <div className="text-field ">
                                                <input
                                                    type="text"
                                                    name="VocuherNo"
                                                    className=""
                                                    id="VocuherNo"
                                                    required
                                                    value={value.VocuherNo}
                                                    onChange={Handlechange}
                                                />
                                                <label htmlFor="">Vch#</label>
                                            </div>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 ">
                                            <div className="text-field ">
                                                <input
                                                    type="text"
                                                    name="Diesel"
                                                    className=""
                                                    id="Diesel"
                                                    required
                                                />
                                                <label htmlFor="">Diesel</label>
                                            </div>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 ">
                                            <div className="text-field ">
                                                <input
                                                    type="text"
                                                    name="RemDiesel"
                                                    className=""
                                                    id="RemDiesel"
                                                    required
                                                    value={value.RemDiesel}
                                                    onChange={Handlechange}
                                                />
                                                <label htmlFor="">Remaining Diesel</label>
                                            </div>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3">
                                            <div className="text-field ">
                                                <input
                                                    type="text"
                                                    name="Average"
                                                    className=""
                                                    id="Average"
                                                    required
                                                    value={value.RemDiesel / 2}
                                                    onChange={Handlechange}
                                                />
                                                <label htmlFor="">Average</label>
                                            </div>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                            <div className="form-check ">
                                                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault"
                                                    name='IsFullTank'
                                                    class="form-check-input"
                                                    onChange={(e) => HandleService(e)}
                                                    value={value.IsFullTank}
                                                    required
                                                />
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    Full Tank
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className='' style={{ border: '1px dashed #80808078', borderBottom: 'none', borderLeft: 'none', borderRight: 'none', marginTop: '-10px' }}>
                                    <legend >Last Tank Full</legend>
                                    <div className="row" style={{ marginTop: '-6px' }}>
                                        <div className="col-3 col-md-3 col-lg-3 ">
                                            <div className="text-field mt-1">
                                                <DatePicker
                                                    id='LastFullTankDate'
                                                    name='LastFullTankDate'
                                                    ref={startRef}
                                                    onKeyDown={onKeyDown}
                                                    dateFormat="dd/MM/yyyy"
                                                    timeInputLabel
                                                    className='requiredColor'
                                                    required
                                                    isClearable
                                                    placeholderText={'Select...'}
                                                    dropdownMode="select"
                                                    onChange={(date) => { setLastFullTankDate(date); setValue({ ...value, ['LastFullTankDate']: date ? getShowingDayWithOutTime(date) : null }) }}
                                                    selected={LastFullTankDate}
                                                />
                                                <label htmlFor="" className='pt-2 pl-0'>Date</label>
                                            </div>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 ">
                                            <div className="text-field mt-1 pt-1">
                                                <input
                                                    type="text"
                                                    name="LastFullTankReading"
                                                    className=""
                                                    id="LastFullTankReading"
                                                    required
                                                    value={value.LastFullTankReading}
                                                    onChange={Handlechange}
                                                    disabled={Checked}

                                                />
                                                <label htmlFor="" className='pt-2'>Reading</label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row px-3">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="text-field">
                                            <textarea
                                                name="Narration"
                                                id="Narration"
                                                cols="30"
                                                rows="1 "
                                                value={value.Narration}
                                                onChange={Handlechange}
                                                required
                                            >
                                            </textarea>
                                            <label htmlFor="">Narration</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btn-box text-right  mr-1 mb-2">
                            {
                                status ?
                                    <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { dispatch(update_Mantinance_Data(value)); resetHooks(); }}>Update</button>
                                    :
                                    <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { dispatch(save_Mantinance_Data(value));  resetHooks();  }}> Save</button>
                            }
                            <button type="button" class="btn btn-sm btn-success mr-1" onClick={resetHooks}>New</button>

                            <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" >Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ServiceMaintenanceAdd_Up;