import React from 'react'
import { useLocation } from 'react-router-dom';
import AddRequest from './AddRequest/AddRequest';
import POSection from './POSection/POSection';
import MainRequest from './AddRequest/MainRequest';
import ApproveRequest from './ApproveRequest/ApproveRequest';
import ApproveQuotes from './ApproveQuotes/ApproveQuotes'
import Quotes from './Quotes/Quotes';
import RejectRequest from './RejectRequest/RejectRequest';
import PurchaseInvoice from './PurchaseInvoice/PurchaseInvoice';

const Inventory = () => {
    // const dispatch = useDispatch();
    const useQuery = () => new URLSearchParams(useLocation().search);
    let openSubPage = useQuery().get('sub');
    let openPage = useQuery().get('page');


    // const companySingelData = useSelector((state) => state.Data.companySingelData)
    // const companyId = useSelector((state) => state.Data.companyId)

    // useEffect(() => {
    //     if (companySingelData.length === 0) dispatch(get_Single_Company_Data(companyId))
    // }, [])

    return (
        <>
            {
                openPage === 'Add Request' &&
                <MainRequest />
            }
            {
                openPage === 'Approve Request' &&
                <ApproveRequest />
            }
             {
                openPage === 'Reject Request' &&
                <RejectRequest/>
            }
            {
                openPage === 'Approve Quotes' &&
                <ApproveQuotes />
            }
            {
                openPage === 'Enter Quotes' &&
                <Quotes />
            }
            {
                openSubPage === 'PO Section' &&
                <POSection />
            }
            {
                openSubPage === 'Purchase Invoice' &&
                <PurchaseInvoice />
            }
        </>
    )
}

export default Inventory