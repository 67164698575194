import React, { useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import { filterPassedTime, getPreviousDate } from '../../Hooks/utility';

const ChartDate = (props) => {
    const { fromDate, setFromDate, toDate, setToDate } = props;


    return (
        <>


            <div className='showDate-picker' style={{ display: "flex", width: "100%", gap: "10px" }}>
                <div style={{ width: "15%" }}>
                    <ReactDatePicker
                        selected={fromDate ? fromDate : null}
                        onChange={(date) => {
                            if (date) {
                                setFromDate(date)
                            } else {
                                setFromDate(null)
                                setToDate(null)
                            }
                        }}
                        dateFormat="dd/MM/yyyy HH:mm"
                        minDate={new Date(getPreviousDate(new Date()))}
                        maxDate={new Date()}
                        isClearable={fromDate ? true : false}
                        placeholderText={"From Date..."}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                        showTimeSelect
                        timeIntervals={1}
                        timeCaption="Time"
                        filterTime={filterPassedTime}
                        className='ChartDateRange'
                    />
                </div>

                <div style={{ width: "15%" }}>
                    <ReactDatePicker
                        selected={toDate ? toDate : ''}
                        onChange={(date) => {
                            setToDate(date)
                        }}
                        dateFormat="dd/MM/yyyy HH:mm"
                        minDate={new Date(fromDate)}
                        maxDate={new Date()}
                        isClearable={toDate ? true : false}
                        placeholderText={"To Date..."}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                        showTimeSelect
                        timeIntervals={1}
                        timeCaption="Time"
                        filterTime={filterPassedTime}
                        className='ChartDateRange'
                        disabled={fromDate ? false : true}
                        readOnly={fromDate ? false : true}
                    />
                </div>
                <div className="date-picker-popup" style={{ display: "flex", gap: "10px" }}>
                    <div className="dateType" onClick={() => { setFromDate(new Date()); setToDate(new Date()) }}>
                        <span>From Today</span>
                    </div>
                    <div className="dateType" onClick={() => {
                        setFromDate(new Date(new Date().setDate(new Date().getDate() - 1))); setToDate(new Date())
                    }}>
                        <span>From YesterDay</span>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ChartDate
