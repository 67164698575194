import React from 'react'

const ChallanDeleted = () => {
  return (
    <>
    <div>ChallanDeleted</div>
    </>
  )
}

export default ChallanDeleted