import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { get_Consumption_Drop_Data, get_Group_Drop_Data, get_MaterialGroup_DrpData, get_MaterialType_DrpData, get_Party_Sale_Data, get_Unit_Drop_Data, get_Unit_Dropdown, get_supplier_DropData, save_Inventory_Material_Data, update_Inventory_Material_Data } from '../../../../redux/actions/AllAction';
import { Get_Error_Status, Get_MaterialGroup_DropData, Get_MaterialType_DropData } from '../../../../redux/actionTypes';
import { RequiredFieldSelectBox, SpaceNotAllow } from '../../../Validation/Validation';
import { changeArrayFormatDrop } from '../../../Include/Common/ArrayFormat';

const InventoryMaterial_Add_Up = (props) => {
    const dispatch = useDispatch()
    const { status, pageStatus } = props
    const userId = useSelector((state) => state.Data.userId)
    const unitDropdown = useSelector((state) => state.Data.unitDropdown);
    const partySaleData = changeArrayFormatDrop(useSelector((state) => state.Data.partySaleData), 'Name', 'Id');
    const masterTableModalStatus = useSelector((state) => state.Data.masterTableModalStatus)
    const consumptionDropDown = useSelector((state) => state.Data.consumptionDropDown)
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const singleinventoryData = useSelector((state) => state.Data.singleinventoryData)
    const materialtypeDropDown = useSelector((state) => state.Data.materialtypeDropDown)
    const materialgroupDropDown = useSelector((state) => state.Data.materialgroupDropDown)
    const groupDropDown = useSelector((state) => state.Data.groupDropDown)
    const [value, setValue] = useState({
        Code: '',
        ProductName: '',
        HSNCode: '',
        ConsumptionGroupID: '',
        ConsumptionGroup: '',
        ConsumptionTypeID: '',
        ConsumptionType: '',
        MaterialGroupID: '',
        MaterialGroup: '',
        MaterialTypeID: '',
        MaterialType: '',
        PurchaseUnitID: '',
        PurchaseUnit: '',
        IssueUnitID: '',
        IssueUnit: '',
        make: '',
        Size: '',
        Productcolor: '',
        Remarks: '',
        QtyPerUnit: '',
        CostPerUnit: '',
        StockQty: '',
        AlertQty: '',
        MaterialGroupLedger: '',
        SGST: '',
        CGST: '',
        IGST: '',
        ID: '',
    })
    const [value1, setValue1] = useState({
        IsLedger: '1',
        Iscommon: '0',
        IsUnRegular: '0',
        IsSupplier: '0',
        Iscredit: '0',
        IsActive: "1",
      })
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    useEffect(() => {
        dispatch(get_Party_Sale_Data(value1));
      }, [value1])
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }
    const [errors, setErrors] = useState({
        codeError: '', HSNcodeError: '', GroupError: '', TypeError: '', MaterialGroupError: '', MaterialError: '', PurchaseError: '', IssueError: '', MaterialLedgerError: '', CostError: '', QtyError: '', AlretError: ''
    })
    const { codeError, HSNcodeError, GroupError, TypeError, MaterialGroupError, MaterialError, PurchaseError, IssueError, MaterialLedgerError, QtyError, AlertError, CostError } = errors;

    useEffect(() => { dispatch(get_Consumption_Drop_Data()); dispatch(get_Group_Drop_Data()); dispatch(get_MaterialGroup_DrpData()); dispatch(get_MaterialType_DrpData()); dispatch(get_Unit_Dropdown()); dispatch(get_Party_Sale_Data('1')) }, [])

    const handelChange = (e) => { setValue({ ...value, [e.target.name]: e.target.value, }); }

    const handleDropdown = (Name) => e => {
        if (Name === 'ConsumptionGroupID') {
            setValue(pre => { return { ...pre, ['ConsumptionGroupID']: e?.value, ['ConsumptionGroup']: e?.label } })
        } else if (Name === 'ConsumptionTypeID') {
            setValue(pre => { return { ...pre, ['ConsumptionTypeID']: e?.value, ['ConsumptionType']: e?.label } })
        } else if (Name === 'MaterialGroupID') {
            setValue(pre => { return { ...pre, ['MaterialGroupID']: e?.value, ['MaterialGroup']: e?.label } })
        } else if (Name === 'MaterialTypeID') {
            setValue(pre => { return { ...pre, ['MaterialTypeID']: e?.value, ['MaterialType']: e?.label } })
        } else if (Name === 'PurchaseUnitID') {
            setValue(pre => { return { ...pre, ['PurchaseUnitID']: e?.value, ['PurchaseUnit']: e?.label } })
        } else if (Name === 'IssueUnitID') {
            setValue(pre => { return { ...pre, ['IssueUnitID']: e?.value, ['IssueUnit']: e?.label } })
        } else if(Name === 'MaterialGroupLedgerID') {
            setValue(pre => { return { ...pre, ['MaterialGroupLedgerID']: e?.value, ['MaterialGroupLedger']: e?.label } })
        }
    }
    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.Code)) {
            setErrors(prevValues => { return { ...prevValues, ['codeError']: SpaceNotAllow(value.Code) } })
            dispatch({ type: Get_Error_Status, payload: true });
        };
        if (SpaceNotAllow(value.ProductName)) {
            setErrors(prevValues => { return { ...prevValues, ['MaterialError']: SpaceNotAllow(value.ProductName) } })
        };
        if (SpaceNotAllow(value.HSNCode)) {
            setErrors(prevValues => { return { ...prevValues, ['HSNcodeError']: SpaceNotAllow(value.HSNCode) } })
        };
        if (RequiredFieldSelectBox(value.ConsumptionGroupID)) {
            setErrors(prevValues => { return { ...prevValues, ['GroupError']: RequiredFieldSelectBox(value.ConsumptionGroupID) } })
        };
        if (RequiredFieldSelectBox(value.ConsumptionType)) {
            setErrors(prevValues => { return { ...prevValues, ['TypeError']: RequiredFieldSelectBox(value.ConsumptionType) } })
        };
        if (RequiredFieldSelectBox(value.MaterialGroup)) {
            setErrors(prevValues => { return { ...prevValues, ['MaterialGroupError']: RequiredFieldSelectBox(value.MaterialGroup) } })
        }
        if (RequiredFieldSelectBox(value.PurchaseUnit)) {
            setErrors(prevValues => { return { ...prevValues, ['PurchaseError']: RequiredFieldSelectBox(value.PurchaseUnit) } })
        }
        if (RequiredFieldSelectBox(value.IssueUnit)) {
            setErrors(prevValues => { return { ...prevValues, ['IssueError']: RequiredFieldSelectBox(value.IssueUnit) } })
        }
        if (RequiredFieldSelectBox(value.MaterialGroupLedger)) {
            setErrors(prevValues => { return { ...prevValues, ['MaterialLedgerError']: RequiredFieldSelectBox(value.MaterialGroupLedger) } })
        }
        if (SpaceNotAllow(value.CostPerUnit)) {
            setErrors(prevValues => { return { ...prevValues, ['CostError']: SpaceNotAllow(value.CostPerUnit) } })
        };
        if (SpaceNotAllow(value.QtyPerUnit)) {
            setErrors(prevValues => { return { ...prevValues, ['QtyError']: SpaceNotAllow(value.QtyPerUnit) } })
        };
        if (SpaceNotAllow(value.AlertQty)) {
            setErrors(prevValues => { return { ...prevValues, ['AlertError']: SpaceNotAllow(value.AlertQty) } })
        };
    };

    useEffect(() => {
        if (codeError === 'true' && HSNcodeError === 'true' && GroupError === 'true' && TypeError === 'true' && MaterialGroupError === 'true' && MaterialError === 'true' && PurchaseError === 'true' && IssueError === 'true' && MaterialLedgerError === 'true', QtyError === 'true', AlertError === 'true', CostError === 'true') {
            if (status) { dispatch(update_Inventory_Material_Data(value, pageStatus)); setErrors({ ['codeError']: '' }) }
            else { dispatch(save_Inventory_Material_Data(value, pageStatus)); setErrors({ ['codeError']: '' }) }
        }
    }, [codeError, HSNcodeError, GroupError, TypeError, MaterialGroupError, MaterialError, PurchaseError, IssueError, MaterialLedgerError, QtyError, AlertError, CostError]);


    useEffect(() => {
        if (singleinventoryData) {
            setValue(pre => {
                return {
                    ...pre,
                    Code: singleinventoryData[0]?.Code,
                    ProductName: singleinventoryData[0]?.ProductName,
                    HSNCode: singleinventoryData[0]?.HSNCode,
                    ConsumptionGroupID: singleinventoryData[0]?.ConsumptionGroupID,
                    ConsumptionGroup: singleinventoryData[0]?.ConsumptionGroup,
                    ConsumptionTypeID: singleinventoryData[0]?.ConsumptionTypeID,
                    ConsumptionType: singleinventoryData[0]?.ConsumptionType,
                    MaterialGroupID: singleinventoryData[0]?.MaterialGroupID,
                    MaterialGroup: singleinventoryData[0]?.MaterialGroup,
                    MaterialTypeID: singleinventoryData[0]?.MaterialTypeID,
                    MaterialType: singleinventoryData[0]?.MaterialType,
                    PurchaseUnitID: singleinventoryData[0].PurchaseUnitID,
                    PurchaseUnit: singleinventoryData[0].PurchaseUnit,
                    IssueUnitID: singleinventoryData[0].IssueUnitID,
                    IssueUnit: singleinventoryData[0].IssueUnit,
                    make: singleinventoryData[0]?.make,
                    Size: singleinventoryData[0]?.Size,
                    Productcolor: singleinventoryData[0]?.Productcolor,
                    Remarks: singleinventoryData[0]?.Remarks,
                    QtyPerUnit: singleinventoryData[0]?.QtyPerUnit,
                    CostPerUnit: singleinventoryData[0]?.CostPerUnit,
                    StockQty: singleinventoryData[0]?.StockQty,
                    AlertQty: singleinventoryData[0]?.AlertQty,
                    MaterialGroupLedger: singleinventoryData[0]?.MaterialGroupLedger,
                    SGST: singleinventoryData[0]?.SGST,
                    CGST: singleinventoryData[0]?.CGST,
                    IGST: singleinventoryData[0]?.IGST,
                    ID: singleinventoryData[0]?.ID,
                    Updatedbyid: userId
                }
            })
        } else close_modal()

    }, [singleinventoryData, editUpdateCount])
    const close_modal = () => {
        setValue({
            ...value,
            Code: '',
            ProductName: '',
            HSNCode: '',
            ConsumptionGroupID: '',
            ConsumptionGroup: '',
            ConsumptionTypeID: '',
            ConsumptionType: '',
            MaterialGroupID: '',
            MaterialGroup: '',
            MaterialTypeID: '',
            MaterialType: '',
            PurchaseUnitID: '',
            PurchaseUnit: '',
            IssueUnitID: '',
            IssueUnit: '',
            make: '',
            Size: '',
            Productcolor: '',
            Remarks: '',
            QtyPerUnit: '',
            CostPerUnit: '',
            StockQty: '',
            AlertQty: '',
            MaterialGroupLedger: '',
            SGST: '',
            CGST: '',
            IGST: '',
            ID: '',
        });
        setErrors({
            codeError: '', HSNcodeError: '', GroupError: '', TypeError: '', MaterialGroupError: '', MaterialError: '', PurchaseError: '', IssueError: '', MaterialLedgerError: '',
        })

    }
    return (
        <>
            {masterTableModalStatus && <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="InventoryModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="m-1 ">
                                <fieldset style={{ border: '1px solid gray' }}>
                                    <legend >Inventory Material</legend>
                                    <div className="form-row" style={{ marginTop: '-10px' }}>
                                        <div className="col-6 col-md-6 col-lg-3 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='Code'
                                                    className="requiredColor"
                                                    id='Code'
                                                    required
                                                    value={value.Code}
                                                    onChange={handelChange}

                                                />
                                                <label className='pt-1'>Material Code</label>
                                                {errors.codeError !== "true" ? (
                                                    <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.codeError} </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* <div className="col-6 col-md-6 col-lg-3 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='ProductName'
                                                    className=""
                                                    id='ProductName'
                                                    required
                                                />
                                                <label className='pt-1'>Product Name</label>
                                            </div>
                                        </div> */}
                                        <div className="col-6 col-md-6 col-lg-3 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='HSNCode'
                                                    className="requiredColor"
                                                    id='HSNCode'
                                                    required
                                                    value={value.HSNCode}
                                                    onChange={handelChange}
                                                />
                                                <label className='pt-1'>HSN Code</label>
                                                {errors.HSNcodeError !== "true" ? (
                                                    <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.HSNcodeError} </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-3 mt-2 ">
                                            <div className="dropdown__box" >
                                                <Select
                                                    name='ConsumptionGroupID'
                                                    styles={colourStyles}
                                                    isClearable
                                                    options={groupDropDown}
                                                    onChange={handleDropdown('ConsumptionGroupID')}
                                                    value={groupDropDown?.filter((obj) => obj?.label === value?.ConsumptionGroup)}


                                                />
                                                <label htmlFor='' >Consumption Group</label>
                                                {errors.GroupError !== "true" ? (
                                                    <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.GroupError} </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* <div className="col-6 col-md-6 col-lg-3 mt-2 pt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='ConsumptionGroup'
                                                    className=""
                                                    id='ConsumptionGroup'
                                                    required
                                                />
                                                <label className='pt-1'>Consumption Group</label>
                                            </div>
                                        </div> */}
                                        <div className="col-6 col-md-6 col-lg-3 mt-2">
                                            <div className="dropdown__box">
                                                <Select
                                                    name='ConsumptionTypeID'
                                                    styles={colourStyles}
                                                    options={consumptionDropDown}
                                                    onChange={handleDropdown('ConsumptionTypeID')}
                                                    value={consumptionDropDown?.filter((obj) => obj?.label === value?.ConsumptionType)}
                                                    isClearable

                                                />
                                                <label >Consumption Type</label>
                                                {errors.TypeError !== "true" ? (
                                                    <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.TypeError} </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div className="dropdown__box">
                                                <Select
                                                    name='MaterialGroupID'
                                                    styles={colourStyles}
                                                    isClearable
                                                    placeholder="Select.."
                                                    options={materialgroupDropDown}
                                                    onChange={handleDropdown('MaterialGroupID')}
                                                    value={materialgroupDropDown?.filter((obj) => obj?.label === value?.MaterialGroup)}
                                                />
                                                <label >Material Group</label>
                                                {errors.MaterialGroupError !== "true" ? (
                                                    <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.MaterialGroupError} </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='ProductName'
                                                    className="requiredColor"
                                                    id='ProductName'
                                                    required
                                                    value={value.ProductName}
                                                    onChange={handelChange}
                                                />
                                                <label className='pt-1'>Material Name</label>
                                                {errors.MaterialError !== "true" ? (
                                                    <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.MaterialError} </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div className="dropdown__box">
                                                <Select
                                                    name='MaterialTypeID'
                                                    styles={customStylesWithOutColor}
                                                    isClearable
                                                    placeholder="Select.."
                                                    options={materialtypeDropDown}
                                                    onChange={handleDropdown('MaterialTypeID')}
                                                    value={materialtypeDropDown?.filter((obj) => obj?.label === value?.MaterialType)}
                                                />
                                                <label >Material Type</label>
                                            </div>
                                        </div>
                                        {/* <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='MaterialType'
                                                    className=""
                                                    id='MaterialType'
                                                    required
                                                />
                                                <label className='pt-1'>Material Type</label>
                                            </div>
                                        </div> */}
                                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div className="dropdown__box">
                                                <Select
                                                    name='PurchaseUnitID'
                                                    styles={colourStyles}
                                                    isClearable
                                                    placeholder="Select.."
                                                    onChange={handleDropdown('PurchaseUnitID')}
                                                    value={unitDropdown?.filter((obj) => obj?.label === value?.PurchaseUnit)}
                                                    options={unitDropdown}
                                                />
                                                <label >Purchase Unit</label>
                                                {errors.PurchaseError !== "true" ? (
                                                    <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.PurchaseError} </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='PurchaseUnit'
                                                    className=""
                                                    id='PurchaseUnit'
                                                    required
                                                />
                                                <label className='pt-1'>Purchase Unit</label>
                                            </div>
                                        </div> */}
                                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div className="dropdown__box">
                                                <Select
                                                    name='IssueUnitID'
                                                    styles={colourStyles}
                                                    isClearable
                                                    placeholder="Select.."
                                                    onChange={handleDropdown('IssueUnitID')}
                                                    value={unitDropdown?.filter((obj) => obj?.label === value?.IssueUnit)}
                                                    options={unitDropdown}
                                                />
                                                <label >Issue Unit</label>
                                                {errors.IssueError !== "true" ? (
                                                    <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.IssueError} </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='IssueUnit'
                                                    className=""
                                                    id='IssueUnit'
                                                    required
                                                />
                                                <label className='pt-1'>Issue Unit</label>
                                            </div>
                                        </div> */}
                                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='make'
                                                    className=""
                                                    id='make'
                                                    required
                                                    onChange={handelChange}
                                                    value={value.make}
                                                />
                                                <label className='pt-1'>Make</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='Size'
                                                    className=""
                                                    id='Size'
                                                    required
                                                    onChange={handelChange}
                                                    value={value.Size}
                                                />
                                                <label className='pt-1'>Size</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='Productcolor'
                                                    className=""
                                                    id='Productcolor'
                                                    required
                                                    onChange={handelChange}
                                                    value={value.Productcolor}
                                                />
                                                <label className='pt-1'>Product Color</label>
                                            </div>
                                        </div>

                                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='QtyPerUnit'
                                                    className="requiredColor"
                                                    id='QtyPerUnit'
                                                    required
                                                    onChange={handelChange}
                                                    value={value.QtyPerUnit}
                                                />
                                                <label className='pt-1'>Qty Per Unit</label>
                                                {errors.QtyError !== "true" ? (
                                                    <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.QtyError} </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='CostPerUnit'
                                                    className="requiredColor"
                                                    id='CostPerUnit'
                                                    required
                                                    onChange={handelChange}
                                                    value={value.CostPerUnit}
                                                />
                                                <label className='pt-1'>Cost Per Unit</label>
                                                {errors.CostError !== "true" ? (
                                                    <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.CostError} </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='StockQty'
                                                    className=""
                                                    id='StockQty'
                                                    required
                                                    onChange={handelChange}
                                                    value={value.StockQty}
                                                />
                                                <label className='pt-1'>Stock Qty</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='AlertQty'
                                                    className="requiredColor"
                                                    id='AlertQty'
                                                    required
                                                    onChange={handelChange}
                                                    value={value.AlertQty}
                                                />
                                                <label className='pt-1'>Alert Qty</label>
                                                {errors.AlertError !== "true" ? (
                                                    <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.AlertError} </span>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div className="dropdown__box">
                                                <Select
                                                    name='MaterialGroupLedgerID'
                                                    styles={colourStyles}
                                                    isClearable
                                                    placeholder="Select.."
                                                    onChange={handleDropdown('MaterialGroupLedgerID')}
                                                    value={partySaleData?.filter((obj) => obj?.label === value?.MaterialGroupLedger)}
                                                    options={partySaleData}
                                                />
                                                <label >Material Group Ledger</label>
                                                {errors.MaterialLedgerError !== "true" ? (
                                                    <span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.MaterialLedgerError} </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='SGST'
                                                    className=""
                                                    id='SGST'
                                                    required
                                                    onChange={handelChange}
                                                    value={value.SGST}
                                                />
                                                <label className='pt-1'>SGST</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='CGST'
                                                    className=""
                                                    id='CGST'
                                                    required
                                                    onChange={handelChange}
                                                    value={value.CGST}
                                                />
                                                <label className='pt-1'>CGST</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='IGST'
                                                    className=""
                                                    id='IGST'
                                                    required
                                                    onChange={handelChange}
                                                    value={value.IGST}
                                                />
                                                <label className='pt-1'>IGST</label>
                                            </div>
                                        </div>
                                        <div className="col-12  col-md-12 col-lg-12 mt-2" >
                                            <div className=" dropdown__box" >
                                                <textarea name='Remarks' id="Remarks" cols="30" rows='1' className="form-control pt-2 pb-2 " onChange={handelChange}
                                                    value={value.Remarks}></textarea>
                                                <label htmlFor="" >Dispatch Comments</label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="btn-box text-right  mr-1 mb-2">
                            {status ? <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error} >Update</button> :
                                <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error} >Save</button>}

                            <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={close_modal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>}

        </>
    )
}

export default InventoryMaterial_Add_Up