import React from 'react'

const POSection = () => {
  return (
    <>
      <h2>
        PO Section
      </h2>
    </>
  )
}

export default POSection