import React, { useState } from 'react'
import Home from './EmplyeeTab/Home/Home';
import TabsMap from '../../../../Include/Headers/TabsMap';
import { EmplyeeTabs } from '../../../Utility/TabsArray';
import SubTab from '../../../Utility/SubTab';
import PersonalDetails from './EmplyeeTab/PersonalDetails/PersonalDetails';
import ContactAddress from './EmplyeeTab/ContactAddress/ContactAddress';
import FamilyDetails from './EmplyeeTab/FamilyDetails/FamilyDetails';
import Educational from './EmplyeeTab/Educational/Educational';
import PreviousEmployement from './EmplyeeTab/PreviousEmployement/PreviousEmployement';
import BankDetails from './EmplyeeTab/BankDetails/BankDetails';
import Salary from './EmplyeeTab/Salary/Salary';
import Documents from './EmplyeeTab/Documents/Documents';
import Status from './EmplyeeTab/Status/Status';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const EmplyeeTab = () => {
    const [showPage, setShowPage] = useState('home');
    const pageStatus = useSelector((state) => state.Data.Status)
    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get('sub');
    return (
        <>
            <div className="col-12 col-sm-12">
                <div className="card Agency">
                    <div className="card-body">
                        <div className="row ">
                            <div className={`col-12 col-md-12`}>
                                <div className="row">
                                    <div className="col-12 pl-3 ">
                                        <SubTab tabs={EmplyeeTabs} showPage={showPage} setShowPage={setShowPage} status={pageStatus} />
                                    </div>
                                </div>
                            </div>
                            {
                                showPage === 'home' ?
                                    <Home {...{openPage}} />
                                    :
                                showPage === 'personaldetails' ?
                                    <PersonalDetails {...{openPage}} />
                                    :
                                showPage === 'contactaddress' ?
                                    <ContactAddress {...{openPage}} />
                                    :
                                showPage === 'familydetails' ?
                                    <FamilyDetails {...{openPage}} />
                                    :
                                showPage === 'educational' ?
                                    <Educational {...{openPage}} />
                                    :
                                showPage === 'previousemployement' ?
                                    <PreviousEmployement {...{openPage}} />
                                    :
                                showPage === 'bankdetails' ?
                                    <BankDetails {...{openPage}}/>
                                    :
                                // showPage === 'salary' ?
                                //     <Salary />
                                //     :
                                showPage === 'documents' ?
                                    <Documents {...{openPage}} />
                                    :
                                showPage === 'status' ?
                                    <Status {...{openPage}} />
                                    :

                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmplyeeTab