import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from "react-select";
import DatePicker from 'react-datepicker'
import { get_Status_Drop_Data, save_EmpStatus, update_EmpStatus } from '../../../../../../../redux/actions/AllAction';
import { getShowingWithOutTime } from '../../../../../../Include/Common/Utility';
import { Get_Error_Status } from '../../../../../../../redux/actionTypes';
import { RequiredFieldSelectBox, SpaceNotAllow, SpaceNotAllowWithoutReq } from '../../../../../../Validation/Validation';
import { toastifyError } from '../../../../../../Include/Common/AlertMsg';
import { changeArrayFormatDrop } from '../../../../../../Include/Common/ArrayFormat';


const EmpStatus_Add_Up = (props) => {
    const dispatch = useDispatch()
    const { status, pageStatus } = props
    const statusDropDown = changeArrayFormatDrop(useSelector((state) => state.Data.statusDropDown),'EmpStatus','ID');
    const errorStatus = useSelector((state) => state.Data.errorStatus)
    const singleEmpStatusData = useSelector((state) => state?.Data?.singleEmpStatusData[0])
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const employeeId = useSelector((state) => state.Data.employeeId)
    const masterTableModalStatus = useSelector((state) => state.Data.masterTableModalStatus)
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };
    const [StatusDate, setdatefrom] = useState();
    const [value, setValue] = useState({
        EmpID: employeeId,
        CurrentStatus: '',
        Reason: '',
        StatusDate: '',
        ID: ''
    });
    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
        }
    };
    const [errors, setErrors] = useState({
        StatusError: '', DateError: '', ReasonError: ''
    })
    useEffect(() => {
        if (statusDropDown.length === 0) dispatch(get_Status_Drop_Data())
    }, []);

    const { StatusError, ReasonError, DateError } = errors
    const handleDropdown = (e, Name) => {
        if (Name === 'CurrentStatus') {
            setValue(pre => { return { ...pre, ['CurrentStatus']: e?.value, ['CurrentStatus']: e?.label } })
        } 
    }
    useEffect(() => {
        if (singleEmpStatusData) {
            setValue({
                ...value, CurrentStatus: singleEmpStatusData?.CurrentStatus, Reason: singleEmpStatusData?.Reason, StatusDate: singleEmpStatusData?.StatusDate, ID: singleEmpStatusData?.ID, EmpID: employeeId,
            })
        }
        else {
            setValue({
                ...value,
                CurrentStatus: '',
                Reason: '',
                StatusDate: '',
                ID: '', EmpID: employeeId,
            })
        }
    }, [singleEmpStatusData, editUpdateCount])

    const check_Validation_Error = (e) => {
        if (RequiredFieldSelectBox(value.CurrentStatus)) {
            setErrors(prevValues => { return { ...prevValues, ['StatusError']: RequiredFieldSelectBox(value.CurrentStatus) } })
        }
        if (RequiredFieldSelectBox(value.StatusDate)) {
            setErrors(prevValues => { return { ...prevValues, ['DateError']: RequiredFieldSelectBox(value.StatusDate) } })
        }
        if (SpaceNotAllow(value.Reason)) {
            setErrors(prevValues => { return { ...prevValues, ['ReasonError']: RequiredFieldSelectBox(value.Reason) } })
        }
    }

    useEffect(() => {
        if (StatusError === 'true' && ReasonError === 'true' && DateError === 'true') {
            if (status) { dispatch(update_EmpStatus(value, employeeId, pageStatus)); setErrors({ ['StatusError']:'' })  }
            else { dispatch(save_EmpStatus(value, employeeId, pageStatus)); setErrors({ ['StatusError']:'' }) }
        }
    }, [StatusError, ReasonError, DateError])
    const handelChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    }
    const resetHooks = () => {
        setValue({
            EmpID: '',
            CurrentStatus: '',
            Reason: '',
            StatusDate: '',
            ID: ''
        }); setdatefrom("");
    }
    return (
        <>
            {masterTableModalStatus &&
                <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="EmpStatusModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="m-1 ">
                                    <fieldset style={{ border: '1px solid gray' }}>
                                        <legend >Employee Status</legend>
                                        <div className="form-row" style={{ marginTop: '-10px' }}>
                                            <div class="col-6 col-md-6 col-lg-6 mt-1">
                                                <div className="dropdown__box">
                                                    <Select
                                                        name="CurrentStatus"
                                                        styles={colourStyles}
                                                        isClearable
                                                        className='requiredColor'
                                                        onChange={(e) => handleDropdown(e, 'CurrentStatus')}
                                                        value={statusDropDown?.filter((obj) => obj?.label?.toString() === value?.CurrentStatus?.toString())}
                                                        options={statusDropDown}
                                                        placeholder="Select.."
                                                    />
                                                    <label htmlFor="">Status</label>
                                                    {errors.StatusError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.StatusError}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                           <div class="col-6 col-md-6 col-lg-6" >
                                                <div className="dropdown__box">
                                                    <DatePicker
                                                        id='StatusDate'
                                                        name='StatusDate'
                                                        ref={startRef1}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => { setdatefrom(date); setValue({ ...value, ['StatusDate']: date ? getShowingWithOutTime(date) : null }) }}
                                                        className='requiredColor'
                                                        dateFormat="MM/dd/yyyy"
                                                        timeInputLabel
                                                        isClearable={value?.StatusDate ? true : false}
                                                        selected={StatusDate}
                                                        placeholderText={value?.StatusDate ? value.StatusDate : 'Select...'}
                                                        dropdownMode="select"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        maxDate={new Date()}
                                                    />
                                                    <label htmlFor="" className='pl-0 pt-1' >Date From</label>
                                                    {errors.DateError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.DateError}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12 mt-1" >
                                                <div className="dropdown__box">
                                                    <textarea name='Reason' id="Reason" value={value.Reason}
                                                        onChange={handelChange} cols="30" rows='1' className="form-control requiredColor " ></textarea>
                                                    <label htmlFor="">Reason </label>
                                                    {errors.ReasonError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.ReasonError}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div className="btn-box text-right  mr-1 mb-2">
                                {status ?
                                    <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button>
                                    :
                                    <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Save</button>
                                }
                                <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={resetHooks} >Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default EmpStatus_Add_Up