export const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() > selectedDate.getTime();
};

export function getPreviousDate(currentDate) {
    const date = new Date(currentDate);
    date.setDate(date.getDate() - 25);
    return date;
}

export const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);
    return `${year}-${month}-${day}`;
};
