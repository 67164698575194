import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker'
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { Get_Error_Status } from '../../../../../../../redux/actionTypes';
import { SpaceNotAllow, SpaceNotAllowWithoutReq } from '../../../../../../Validation/Validation';
import { toastifyError } from '../../../../../../Include/Common/AlertMsg';
import { get_Relation_Drop_Data, update_Employee_Bank } from '../../../../../../../redux/actions/AllAction';
import { getShowingDayWithOutTime, getShowingWithOutTimeStartDate } from '../../../../../../Include/Common/Utility';
import { changeArrayFormatDrop } from '../../../../../../Include/Common/ArrayFormat';

const BankDetails = ({ openPage }) => {

    const dispatch = useDispatch()
    const errorStatus = useSelector((state) => state.Data.errorStatus)
    const pageStatus = useSelector((state) => state.Data.Status)
    const employeeId = useSelector((state) => state.Data.employeeId)
    const relationData = changeArrayFormatDrop(useSelector((state) => state.Data.relationDropData),'Description','ID')
    const employeeSingleData = useSelector((state) => state.Data.employeeSingleData[0]);
    const userId = useSelector((state) => state.Data.userId)

    const [value, setValue] = useState({
        BankName: '', BankAcNo: '', BranchName: '', IFSCCode: '', NomineeName: '', NomineeDOB: '', NomineeDOB1: '', NomineeRelation: '', NomineeContactNo: '', ID: '', Updatedbyid: userId
    })

    const [errors, setErrors] = useState({
        BankNameErrors: '', BankAcNoErrors: '', IFSCCodeErrors: '',
    })

    useEffect(() => {
        if (relationData.length === 0) dispatch(get_Relation_Drop_Data())
    }, [])

    useEffect(() => {
        if (employeeSingleData) {
            setValue(pre => { return { ...pre, BankName: employeeSingleData.BankName, BankAcNo: employeeSingleData.BankAcNo, BranchName: employeeSingleData.BranchName, IFSCCode: employeeSingleData.IFSCCode, NomineeName: employeeSingleData.NomineeName, NomineeDOB: employeeSingleData?.NomineeDOB, NomineeDOB1: employeeSingleData?.NomineeDOB?.split("/").reverse().join("/"), NomineeRelation: employeeSingleData.NomineeRelation, NomineeContactNo: employeeSingleData.NomineeContactNo, ID: employeeSingleData.ID } });
        }
    }, [employeeSingleData])

    const handleChange = (e) => {
        if (e.target.name === 'BankAcNo'|| e.target.name ==='NomineeContactNo') {
             const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
             setValue({
                 ...value,
                 [e.target.name]: checkNumber
             })
         } else if(e.target.name === 'BankName' ||  e.target.name ==='NomineeName' ||  e.target.name ==='IFSCCode') {
            setValue({
                ...value,
                [e.target.name]: e.target.value.toUpperCase(),
            });
         }
         else {
             setValue({
                 ...value,
                 [e.target.name]: e.target.value,
             });
         }
 
     }

    const handleDropdown = (e, name) => {
        setValue(pre => { return { ...pre, [name]: e.value } })
    }

    // Check validation on Field
    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.BankName)) {
            setErrors(prevValues => { return { ...prevValues, ['BankNameErrors']: SpaceNotAllow(value.BankName) } })
            dispatch({ type: Get_Error_Status, payload: true })
        }
        if (SpaceNotAllow(value.BankAcNo)) {
            setErrors(prevValues => { return { ...prevValues, ['BankAcNoErrors']: SpaceNotAllow(value.BankAcNo) } })
        }
        if (SpaceNotAllow(value.IFSCCode)) {
            setErrors(prevValues => { return { ...prevValues, ['IFSCCodeErrors']: SpaceNotAllow(value.IFSCCode) } })
        }

    }

    // Check All Field Format is True Then Submit 
    const { BankNameErrors, BankAcNoErrors,  IFSCCodeErrors } = errors

    useEffect(() => {
        if (BankNameErrors === 'true' && BankAcNoErrors === 'true' && IFSCCodeErrors === 'true' && errorStatus) {
            if (pageStatus) dispatch(update_Employee_Bank(value, employeeId))
        }
    }, [BankNameErrors, BankAcNoErrors, IFSCCodeErrors, errorStatus])

    // custuom style withoutColor
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }

    const resetHooks = () => {
        setValue({ BankName: '', BankAcNo: '', BranchName: '', IFSCCode: '', NomineeName: '', NomineeDOB: '', NomineeDOB1: '', NomineeRelation: '', NomineeContactNo: '', ID: '' })
    }

    return (
        <>
            <>
                <div className="col-12 col-md-6 col-lg-6 pt-2 px-3">
                    <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                        <p className="p-0 m-0 d-flex align-items-center">Bank Details</p>
                    </div>
                    <div className="form-row mt-1 px-1">
                        <div className="col-2 col-md-4 col-lg-2 mt-2 pt-2">
                            <label className='label-field'>bank name:</label>
                            {/* <span className="form-required">*</span> */}
                        </div>
                        <div className="col-6 col-md-8 col-lg-6 ">
                            <div class="text-field">
                                <input
                                    type="text"
                                    name='BankName'
                                    id='BankName'
                                    className='requiredColor'
                                    value={value.BankName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            {errors.BankNameErrors !== "true" ? (
                                <span
                                    style={{
                                        color: "red",
                                        fontSize: "13px",
                                        margin: "0px",
                                        padding: "0px",
                                    }}
                                >
                                    {errors.BankNameErrors}
                                </span>
                            ) : null}
                        </div>

                        <div className=" col-lg-3">
                        </div>
                        <div className="col-2 col-md-4 col-lg-2 mt-2 pt-2">
                            <label className='label-field '>bank a/c no.</label>
                        </div>
                        <div className="col-6 col-md-8 col-lg-6 ">
                            <div class="text-field">
                                <input
                                    type="text"
                                    name='BankAcNo'
                                    className='requiredColor'
                                    value={value.BankAcNo}
                                    onChange={handleChange}
                                    minLength={5}
                                    maxLength={18}
                                    id='BankAcNo'
                                    required
                                />
                            </div>
                            {errors.BankAcNoErrors !== "true" ? (
                                <span
                                    style={{
                                        color: "red",
                                        fontSize: "13px",
                                        margin: "0px",
                                        padding: "0px",
                                    }}
                                >
                                    {errors.BankNameErrors}
                                </span>
                            ) : null}
                        </div>
                        <div className=" col-lg-3 ">
                        </div>
                        <div className="col-2 col-md-4 col-lg-2 mt-2 pt-2">
                            <label className='label-field '>branch name</label>
                        </div>
                        <div className="col-6 col-md-8 col-lg-6 ">
                            <div class="text-field">
                                <input
                                    type="text"
                                    name='BranchName'
                                    value={value.BranchName}
                                    onChange={handleChange}
                                    id='BranchName'
                                    required
                                />
                            </div>
                        </div>
                        <div className=" col-lg-3 ">
                        </div>
                        <div className="col-2 col-md-4 col-lg-2 mt-2 pt-2">
                            <label className='label-field '>ifsc code:</label>
                        </div>
                        <div className="col-6 col-md-8 col-lg-6 ">
                            <div class="text-field">
                                <input
                                    type="text"
                                    name='IFSCCode'
                                    className='requiredColor'
                                    value={value.IFSCCode}
                                    onChange={handleChange}
                                    maxLength={11}
                                    id='IFSCCode'
                                    required
                                />
                            </div>
                            {errors.IFSCCodeErrors !== "true" ? (
                                <span
                                    style={{
                                        color: "red",
                                        fontSize: "13px",
                                        margin: "0px",
                                        padding: "0px",
                                    }}
                                >
                                    {errors.IFSCCodeErrors}
                                </span>
                            ) : null}
                        </div>
                    </div>

                </div>
                <div className="col-12 col-md-6 col-lg-6 pt-2 px-3">
                    <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                        <p className="p-0 m-0 d-flex align-items-center">Nominee Details</p>
                    </div>
                    <div className="form-row  px-2">
                        <div className="col-2 col-md-4 col-lg-4 mt-2 pt-2">
                            <label className='label-field'>nominee name:</label>
                            {/* <span className="form-required">*</span> */}
                        </div>
                        <div className="col-6 col-md-8 col-lg-6 ">
                            <div class="text-field">
                                <input
                                    type="text"
                                    name='NomineeName'
                                    value={value.NomineeName}
                                    onChange={handleChange}
                                    id='NomineeName'
                                    required
                                />
                            </div>
                        </div>

                        <div className=" col-lg-2">
                        </div>
                        <div className="col-2 col-md-4 col-lg-4 mt-2 pt-2">
                            <label className='label-field '>DOB nominee:</label>
                        </div>
                        <div className="col-6 col-md-8 col-lg-6 ">
                            <div className="dropdown__box">
                                <DatePicker
                                    id='NomineeDOB'
                                    name='NomineeDOB'
                                    dateFormat="MM/dd/yyyy"
                                    onChange={(date) => date ? setValue({ ...value, ['NomineeDOB']: getShowingDayWithOutTime(date), ['NomineeDOB1']: getShowingWithOutTimeStartDate(date) }) :
                                        setValue({ ...value, ['NomineeDOB']: '', ['NomineeDOB1']: '' })
                                    }
                                    isClearable
                                    selected={value?.NomineeDOB1 && new Date(value?.NomineeDOB1)}
                                    placeholderText={'Select...'}
                                    dropdownMode="select"
                                    maxDate={new Date()}
                                    showMonthDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>
                        <div className="col-lg-2">
                        </div>
                        <div className="col-2 col-md-4 col-lg-4 mt-2 pt-2">
                            <label className='label-field '>relation with employee:</label>
                        </div>
                        <div className="col-6 col-md-8 col-lg-6 ">
                            <div className="dropdown__box">
                                <Select
                                    name='NomineeRelation'
                                    styles={customStylesWithOutColor}
                                    options={relationData}
                                    value={relationData?.filter((obj) => obj.value === value.NomineeRelation)}
                                    onChange={(e) => handleDropdown(e, 'NomineeRelation')}
                                    isClearable
                                    placeholder="Select.."
                                />
                            </div>
                        </div>
                        <div className=" col-lg-2 ">
                        </div>
                        <div className="col-2 col-md-4 col-lg-4 mt-2 pt-2">
                            <label className='label-field'>nominee contact details:</label>
                        </div>
                        <div className="col-6 col-md-8 col-lg-6 ">
                            <div class="text-field">
                                <input
                                    type="text"
                                    name='NomineeContactNo'
                                    value={value.NomineeContactNo}
                                    onChange={handleChange}
                                    id='NomineeContactNo'
                                    required
                                />
                            </div>
                        </div>

                    </div>
                    <div className="btn-box text-end col-12 col-md-12 col-lg-12 mt-2">
                        <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button>
                        <Link to={openPage === 'Attendance' ? '/HR?sub=Attendance%20Report' : '/AdminSetting?sub=Operator'}>
                            <button type="button" class="btn btn-sm btn-success mr-1" onClick={() => resetHooks()}>Close</button>
                        </Link>
                    </div>
                </div>

            </>
        </>
    )
}

export default BankDetails