import React from 'react'

const ChallanApprove = () => {
  return (
    <>
    <div>Challan Approve</div>
    </>
  )
}

export default ChallanApprove