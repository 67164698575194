import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import Loader from '../../../Include/Common/Loader';
import { delete_Sale_Data, get_Party_Sale_Data, get_Single_Party_Data } from '../../../../redux/actions/AllAction'
import { type } from "@testing-library/user-event/dist/type";
import { Edit_Update_Count, Get_Master_Table_Filter_Data, Get_Single_Party_Data, Page_Status, } from "../../../../redux/actionTypes";
import { Three_Search_Filter } from '../../../Filter/Filter';
import ExcelExData from "../../../Include/Common/ExcelExData";
import ConfirmModal from "../../../Include/Common/ConfirmModal";

const SaleList = (props) => {

  const dispatch = useDispatch();
  const [filterTypeIdOption, setfilterTypeIdOption] = useState('Contains');
  const [filterTypeDescOption, setfilterTypeDescOption] = useState('Contains');
  const [filterTypeAddOption, setfilterTypeAddOption] = useState('Contains');
  const [searchValue1, setSearchValue1] = useState('')
  const [searchValue2, setSearchValue2] = useState('')
  const [searchValue3, setSearchValue3] = useState('')
  const [pageStatus, setPageStatus] = useState("1")
  const [confirmType, setConfirmType] = useState('')
  const [IsActive, setIsActive] = useState()
  const [id, setId] = useState('')
  const userId = useSelector((state) => state.Data.userId)
  const partySaleData = useSelector((state) => state.Data.partySaleData)
  const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
  const masterTableLoader = useSelector((state) => state.Data.masterTableLoader)
  const masterTableFilterData = useSelector((state) => state.Data.masterTableFilterData)

  const [value, setValue] = useState({
    IsLedger: '1',
    Iscommon: '0',
    IsUnRegular: '0',
    IsSupplier: '0',
    Iscredit: '0',
    IsActive: "1",
  })

  useEffect(() => {
    dispatch(get_Party_Sale_Data(value));
  }, [value])

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.Name,
      sortable: true
    },

    {
      name: 'Gst No.',
      selector: (row) => row.GSTNo,
      sortable: true
    },
    {
      name: 'Mobile No.',
      selector: (row) => row.MobileNo,
      sortable: true
    },
    {
      name: 'Address',
      selector: (row) => row.Address,
      sortable: true
    },

    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 35, fontWeight: '700' }}>Action</p>,
      cell: row => <>
        <div style={{ position: 'absolute', top: 0, right: 40 }}>

          <Link to='/salepartytab' onClick={(e) => setEditValue(row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1"><i className="fa fa-edit"></i>
          </Link>
          <Link to='/MasterTables?sub=Ledger' data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('0'); setConfirmType("InActive"); setId(row.ID) }}
            className="btn btn-sm bg-green text-white px-1 py-0 mr-1" >
            <i class="fa fa-trash" aria-hidden="true"></i>
          </Link>
        </div>
      </>
    }
  ]

  const setEditValue = (row) => {
    dispatch(get_Single_Party_Data(row.Id));
    dispatch({ type: Get_Single_Party_Data, payload: row });
    dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
    dispatch({ type: Page_Status, payload: true })
  }
  const newStatus = () => {
    dispatch({ type: Get_Single_Party_Data, payload: [] });
    dispatch({ type: Page_Status, payload: false })
  }

  const UpdActiveDeactive = () => {
    dispatch(delete_Sale_Data(id, IsActive, userId, pageStatus))
  }

  const handlChange = (e) => {
    if (e.target.name === 'All') setValue({ ...value, IsLedger: '0', Iscommon: '0', IsUnRegular: '0', IsSupplier: '0', Iscredit: '0', })
    else setValue({ ...value, [e.target.name]: e.target.checked === true ? '1' : '0' })
  }

  return (
    <>
      <div className="col-12 col-md-12 col-lg-12  px-3">
        <div className="row">
          <div className="col-3 col-md-3 col-lg-2 ">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="IsLedger" id="IsLedger" value={value.IsLedger} onChange={handlChange} checked={value.IsLedger === '1' ? true : false} />
              <label className="form-check-label" htmlFor="flexRadioDefault">
                Ledger
              </label>
            </div>
          </div>
          <div className="col-3 col-md-3 col-lg-2 ">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="Iscommon" value={value.Iscommon} onChange={handlChange} checked={value.Iscommon === '1' ? true : false} id="Iscommon" />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Common
              </label>
            </div>
          </div>
          <div className="col-3 col-md-3 col-lg-2 ">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="IsUnRegular" value={value.IsUnRegular} onChange={handlChange} id="IsUnRegular" checked={value.IsUnRegular === '1' ? true : false} />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                UnRegular
              </label>
            </div>
          </div>
          <div className="col-3 col-md-3 col-lg-2 ">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="IsSupplier" value={value.IsSupplier} onChange={handlChange} id="IsSupplier" checked={value.IsSupplier === '1' ? true : false} />
              <label className="form-check-label" htmlFor="flexRadioDefault3">
                Supplier
              </label>
            </div>
          </div>
          <div className="col-3 col-md-3 col-lg-2 ">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="Iscredit" value={value.Iscredit} onChange={handlChange} id="Iscredit" checked={value.Iscredit === '1' ? true : false} />
              <label className="form-check-label" htmlFor="flexRadioDefault3">
                Credit
              </label>
            </div>
          </div>
          <div className="col-3 col-md-3 col-lg-2 ">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="All" onChange={handlChange} />
              <label className="form-check-label" htmlFor="flexRadioDefault3">
                All
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">Ledger Party</p>
          <div style={{ marginLeft: 'auto' }}>
            <ExcelExData csvData={partySaleData} fileName={'Ledger Party'} />
            <Link to={'/salepartytab'} className="btn btn-sm  text-white px-2 py-0" onClick={newStatus} data-toggle="modal" data-target="#">
              <i className="fa fa-plus"></i>
            </Link>
          </div>
        </div>
      </div>


      <div className="col-12 mt-2 px-3">
        <div className="row">

          <div className="col-3">
            <input type="text" value={searchValue1} onChange={(e) => {
              setSearchValue1(e.target.value);
              const result = Three_Search_Filter(partySaleData, e.target.value, searchValue2, searchValue3, filterTypeIdOption, 'Name', 'MobileNo', 'Address')
              dispatch({ type: Get_Master_Table_Filter_Data, payload: result });
            }}
              className='form-control' placeholder='Search By Name...' />
          </div>
          <div className='col-1 '>
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                <i className="fa fa-filter"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className=' nav-filter-dropdown'>
                <Dropdown.Item onClick={() => setfilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeIdOption('End with')}>End with</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-3">
            <input type="text" value={searchValue2} onChange={(e) => {
              setSearchValue2(e.target.value);
              const result = Three_Search_Filter(partySaleData, searchValue1, e.target.value, searchValue3, filterTypeAddOption, 'Name', 'MobileNo', 'Address')
              dispatch({ type: Get_Master_Table_Filter_Data, payload: result });
            }}
              className='form-control' placeholder='Search By Mobile No. ...' />
          </div>
          <div className='col-1 '>
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                <i className="fa fa-filter"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className=' nav-filter-dropdown'>
                <Dropdown.Item onClick={() => setfilterTypeAddOption('Contains')}>Contains</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeAddOption('is equal to')}>is equal to</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeAddOption('is not equal to')}>is not equal to </Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeAddOption('Starts With')}>Starts With</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeAddOption('End with')}>End with</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-3">
            <input type="text" value={searchValue3} onChange={(e) => {
              setSearchValue3(e.target.value);
              const result = Three_Search_Filter(partySaleData, searchValue1, searchValue2, e.target.value, filterTypeDescOption, 'Name', 'MobileNo', 'Address')
              dispatch({ type: Get_Master_Table_Filter_Data, payload: result });
            }}
              className='form-control' placeholder='Search By Address...' />
          </div>
          <div className='col-1 '>
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                <i className="fa fa-filter"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className=' nav-filter-dropdown'>
                <Dropdown.Item onClick={() => setfilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeDescOption('End with')}>End with</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className="col-12  mb-3 px-3 mt-2">
        <div className="table-responsive">
          {
            masterTableLoader ?
              <DataTable
                columns={columns}
                data={masterTableFilterData}
                dense
                paginationPerPage={'10'}
                paginationRowsPerPageOptions={[5, 10, 15]}
                highlightOnHover
                noContextMenu
                pagination
                responsive
                subHeaderAlign="right"
                subHeaderWrap
                noDataComponent={"There are no data to display"}
              />
              : <Loader />}
        </div>

      </div>
      <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType}></ConfirmModal>
    </>
  )
}

export default SaleList