import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown'
import Loader from '../../../Include/Common/Loader';
import { delete_Equipment_Vechicle_Data, get_EquipmentVehicle_Data, get_Single_EquipmentVehicle_Data } from '../../../../redux/actions/AllAction'
import { Edit_Update_Count, Get_Master_Table_Filter_Data, Get_Vechicle_ID, Page_Status, } from '../../../../redux/actionTypes';
import Home from './VehicleDetailsFormTab/Home/Home';
import { Three_Search_Filter } from '../../../Filter/Filter';
import ConfirmModal from '../../../Include/Common/ConfirmModal';


const VehicleDetailsForm = () => {
  const dispatch = useDispatch();
  const equipmentVehicleData = useSelector((state) => state.Data.equipmentVehicleData)
  const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
  const masterTableLoader = useSelector((state) => state.Data.masterTableLoader)
  const masterTableFilterData = useSelector((state) => state.Data.masterTableFilterData)
  const [pageStatus, setPageStatus] = useState("1")
  const [confirmType, setConfirmType] = useState('Delete')
  const [IsActive, setIsActive] = useState()
  const [id, setId] = useState('')
  const userId = (useSelector((state) => state?.Data?.userId))
  const [filterTypeIdOption, setfilterTypeIdOption] = useState('Contains');
  const [filterTypeDescOption, setfilterTypeDescOption] = useState('Contains');
  const [filterTypeAddOption, setfilterTypeAddOption] = useState('Contains');
  const [searchValue1, setSearchValue1] = useState('')
  const [searchValue2, setSearchValue2] = useState('')
  const [searchValue3, setSearchValue3] = useState('')

  useEffect(() => {
    dispatch(get_EquipmentVehicle_Data());
  }, [])
  const columns = [
    {
      name: 'Registration No.',
      selector: (row) => row.RegNumber,
      sortable: true
    },

    {
      name: 'Identification No.',
      selector: (row) => row.IdentificationNo,
      sortable: true
    },
    {
      name: 'Engine Chassis No',
      selector: (row) => row.EngineChasisNo,
      sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 35, fontWeight: '700' }}>Action</p>,
      cell: row => <>
        <div style={{ position: 'absolute', top: 4, right: 40 }}>

          <Link to={`/vehicle-details`} data-toggle="modal" onClick={(e) => setEditValue(row)} data-target="#ProductModal" className="btn btn-sm bg-green text-white px-1 py-0 mr-1"><i className="fa fa-edit"></i>
          </Link>
          <Link to='/Plant-Transport?sub=Vehicle%20Details%20Form' data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('0');  setId(row.ID) }}
            className="btn btn-sm bg-green text-white px-1 py-0 mr-1" >
            <i class="fa fa-trash" aria-hidden="true"></i>
          </Link>
        </div>
      </>
    }
  ]
  const setEditValue = (row) => {
    dispatch(get_Single_EquipmentVehicle_Data(row.ID));
    dispatch({ type: Get_Vechicle_ID, payload: row.ID })
    dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
    dispatch({ type: Page_Status, payload: true })
  }

  const newStatus = () => {
    // dispatch(get_Single_EquipmentVehicle_Data(''));
    dispatch({ type: Get_Vechicle_ID, payload: '' })
    dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
    dispatch({ type: Page_Status, payload: false })
  }
  const UpdActiveDeactive = () => {
    dispatch(delete_Equipment_Vechicle_Data(id, IsActive, userId, pageStatus));
}
  return (
    <>
      <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">Vehicle Details Form</p>
          <div style={{ marginLeft: 'auto' }}>
            <Link to={'/vehicle-details'} onClick={newStatus} className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#">
              <i className="fa fa-plus"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-12 mt-2 px-3">
        <div className="row">
          <div className="col-3">
            <input type="text" value={searchValue1} onChange={(e) => {
              setSearchValue1(e.target.value);
              const result = Three_Search_Filter(equipmentVehicleData, e.target.value, searchValue2, searchValue3, filterTypeIdOption, 'RegNumber', 'IdentificationNo', 'EngineChasisNo')
              dispatch({ type: Get_Master_Table_Filter_Data, payload: result });
            }}
              className='form-control' placeholder='Search By Registration No...' />
          </div>
          <div className='col-1 '>
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                <i className="fa fa-filter"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className=' nav-filter-dropdown'>
                <Dropdown.Item onClick={() => setfilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeIdOption('End with')}>End with</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-3">
            <input type="text" value={searchValue2} onChange={(e) => {
              setSearchValue2(e.target.value);
              const result = Three_Search_Filter(equipmentVehicleData, searchValue1, e.target.value, searchValue3, filterTypeAddOption, 'RegNumber', 'IdentificationNo', 'EngineChasisNo')
              dispatch({ type: Get_Master_Table_Filter_Data, payload: result });
            }}
              className='form-control' placeholder='Search By Identification No ...' />
          </div>
          <div className='col-1 '>
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                <i className="fa fa-filter"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className=' nav-filter-dropdown'>
                <Dropdown.Item onClick={() => setfilterTypeAddOption('Contains')}>Contains</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeAddOption('is equal to')}>is equal to</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeAddOption('is not equal to')}>is not equal to </Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeAddOption('Starts With')}>Starts With</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeAddOption('End with')}>End with</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-3">
            <input type="text" value={searchValue3} onChange={(e) => {
              setSearchValue3(e.target.value);
              const result = Three_Search_Filter(equipmentVehicleData, searchValue1, searchValue2, e.target.value, filterTypeDescOption, 'RegNumber', 'IdentificationNo', 'EngineChasisNo')
              dispatch({ type: Get_Master_Table_Filter_Data, payload: result });
            }}
              className='form-control' placeholder='Search By EngineChasisNo...' />
          </div>
          <div className='col-1 '>
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                <i className="fa fa-filter"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className=' nav-filter-dropdown'>
                <Dropdown.Item onClick={() => setfilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeDescOption('End with')}>End with</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="col-12 px-3 mt-2">
        {masterTableLoader ?
          <DataTable
            dense
            columns={columns}
            data={masterTableFilterData}
            pagination
            selectableRowsHighlight
            highlightOnHover
          /> : <Loader />}

      </div>
      <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType}></ConfirmModal>
    </>
  )
}

export default VehicleDetailsForm