import React from 'react'
import { Link } from 'react-router-dom'

const Voucher = () => {
    return (
        <div className="col-12 col-md-12 col-lg-12 pt-2 px-4 new-scroll  printElement1 mt-3" >
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, 300px)', gap: '20px' }}>

                <div className="card " style={{
                    height: '19.5rem', boxShadow: '1px 1px 1px gray'
                }}>
                    <div className="card-header " >
                        <h5 className=" d-flex ">
                            Contra
                        </h5>
                    </div>
                    <div className='mt-2'>
                        <Link to={'/Contra'} >
                            <li className='pl-3'>Contra List</li>
                        </Link>
                    </div>

                </div>
                <div className="card " style={{
                    height: '19.5rem', boxShadow: '1px 1px 1px gray'
                }}>
                    <div className="card-header " >
                        <h5 className=" d-flex " >
                            Journal
                        </h5>
                    </div>
                    <div className='mt-2'>
                        <Link to={'/Journal'} >
                            <li className='pl-3'> Journal List</li>
                        </Link>
                    </div>

                </div>
                <div className="card " style={{
                    height: '19.5rem', boxShadow: '1px 1px 1px gray'
                }}>
                    <div className=" card-header" >
                        <h5 className=" d-flex " >
                            Receipt
                        </h5>
                    </div>
                    <div className='mt-2'>
                        <Link to={'/Receipt'} >
                            <li className='pl-3'> Receipt List</li>
                        </Link>
                    </div>

                </div>
                <div className="card " style={{
                    height: '19.5rem', boxShadow: '1px 1px 1px gray'
                }}>
                    <div className=" card-header " >
                        <h5 className="d-flex " >
                            Payment
                        </h5>
                    </div>
                    <div className='mt-2'>
                        <Link to={'/Payment'} >
                            <li className='pl-3'>Payment List</li>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Voucher