export const Get_Menu = "Get_Menu";
export const Get_Url = "Get_Url";
export const Edit_Update_Count = "Edit_Update_Count";
export const Get_Error_Status = "Get_Error_Status";
export const Party_State = "Party_State";
export const Party_City = "Party_City";
export const Contact_city = "Contact_city";
export const Update_Status = "Update_Count";
export const Page_Status = "Page_Status";
export const Sidebar_Status = "Sidebar_Status";
export const Status = "Status";
export const Edit_Id = "Edit_Id";
export const Sub_Tab_Name = "Sub_Tab_Name";
export const Modal_Status = "Modal_Status";
export const Page_Loader = "Page_Loader";
export const Check_Page = "Check_Page";


// Menu
export const Get_SubMenu_Data = "Get_SubMenu_Data";
export const Get_ChildMenu_Data = "Get_ChildMenu_Data";

// Master Table
export const Get_Master_Table_Data = "Get_Master_Table_Data";
export const Get_Master_Table_Filter_Data = "Get_Master_Table_Filter_Data";
export const Get_Single_Master_Table_Data = "Get_Single_Master_Table_Data";
export const MasterTable_Page_Loader = "MasterTable_Page_Loader";
export const MasterTable_Modal_Status = "MasterTable_Modal_Status";

// Admin Setting
export const Get_Single_Company_Data = "Get_Single_Company_Data";
// Search Challan
export const Search_challan_Data = "Search_challan_Data";
// Sale party
export const Get_Single_Party_Data = "Get_Single_Party_Data";
export const Get_Party_List_Data = "Get_Party_List_Data";

// Product data
export const Get_Product_Dropdown = "Get_Product_Dropdown";
export const Get_Unit_DropDown = "Get_Unit_DropDown";
export const Get_Product_Data = "Get_Product_Data";
export const Get_Single_Product_Data = "Get_Single_Product_Data";
// Vechile Details
export const Get_EquipmentVehicle_Data = "Get_EquipmentVehicle_Data";
export const Get_Single_Vechicle_Data = "Get_Single_Vechicle_Data";
export const Get_EquipmentVehicle_DropDown = "Get_EquipmentVehicle_DropDown";
export const Get_Vechicle_ID = "Get_Vechicle_ID";

// Insurance Details
export const Get_EquipmentInsurance_Data = "Get_EquipmentInsurance_Data";
export const Get_Single_Insurance_Data = "Get_Single_Insurance_Data";

// Rto Details
export const Get_EquipmentRto_Data = "Get_EquipmentRto_Data";
export const Get_Single_Rto_Data = "Get_Single_Rto_Data";
// Rto Details
export const Get_EquipmentFitness_Data = "Get_EquipmentFitness_Data";
export const Get_Single_Fitness_Data = "Get_Single_Fitness_Data";
// Rto Details
export const Get_EquipmentPuc_Data = "Get_EquipmentPuc_Data";
export const Get_Single_Puc_Data = "Get_Single_Puc_Data";
// Plant Data
export const Get_Service_Dropdown = "Get_Service_Dropdown";
export const Get_PlantService_Data = "Get_PlantService_Data";
export const Get_Single_PlantService_Data = "Get_Single_PlantService_Data";
// Cost Head Data
export const Get_Nature_Dropdown = "Get_Nature_Dropdown ";
export const Get_CostHead_Dropdown = "Get_CostHead_Dropdown";
export const Get_CostSubHead_Dropdown = "Get_CostSubHead_Dropdown";
export const Get_Center_Dropdown = "Get_Center_Dropdown";
export const Get_CostHead_Data = "Get_CostHead_Data";
export const Get_Single_CostHead_Data = "Get_Single_CostHead_Data";

// Asign Group
export const Get_Consumption_Dropdown = "Get_Consumption_Dropdown";
export const Get_Group_Dropdown = "Get_Group_Dropdown";
export const Get_AssignGroup_Data = "Get_AssignGroup_Data";
// Emp Status
export const Get_Status_DropDown = "Get_Status_DropDown";
export const Get_EmpStatus_Data = "Get_EmpStatus_Data";
export const Get_EmpId = "Get_EmpId";
export const Get_Single_EmpStatus_Data = "Get_Single_EmpStatus_Data";
export const Get_EmpStatus_Filter_Data = "Get_EmpStatus_Filter_Data";
// Emp Leave
export const Get_EmpLeave_Data = "Get_EmpLeave_Data";
export const Get_StatusLeave_Drop = "Get_StatusLeave_Drop";
export const Get_TypeLeave_Drop = "Get_TypeLeave_Drop";
export const Get_EmpLeave_Filter_Data = "Get_EmpLeave_Filter_Data";
export const Get_Single_EmpLeave_Data = "Get_Single_EmpLeave_Data";

// ------------------------------------> | AdminSetting -> Opertor/Employee | <-------------------------------------

export const Relation = "Relation";
export const RelationMobile = "RelationMobile";
export const RelationAge = "RelationAge";
export const RelationName = "RelationName";

export const LeavingReason = "LeavingReason";
export const OrganizationSalary = "OrganizationSalary";
export const LeavingDate = "LeavingDate";
export const JoiningDate = "JoiningDate";
export const OrganizationName = "OrganizationName";

export const Get_Department_Dropdown = "Get_Department_Dropdown";
export const Get_Designation_Dropdown = "Get_Designation_Dropdown";
export const Get_Employee_Data = "Get_Employee_Data";
export const Get_Employee_Filter_Data = "Get_Employee_Filter_Data";
export const Get_BloodGroup_Dropdown = "Get_BloodGroup_Dropdown";
export const Get_Martial_Dropdown = "Get_Martial_Dropdown";
export const Get_Religion_Dropdown = "Get_Religion_Dropdown";
export const Get_Gender_Dropdown = "Get_Gender_Dropdown";
export const Get_Relation_Dropdown = "Get_Relation_Dropdown";
export const Unit_Dropdown = "Unit_Dropdown";
export const Employee_ID = "Employee_ID";
export const Get_Single_Employee_Data = "Get_Single_Employee_Data";
export const Get_PermanentDistrict_Data = "Get_PermanentDistrict_Data";
export const Get_State_Data = "Get_State_Data";
export const Get_LocalDistrict_Data = "Get_LocalDistrict_Data";

// ------------------------------------> | AdminSetting -> Permission | <-------------------------------------
export const Get_Menu_Permission_Data = "Get_Menu_Permission_Data";
export const Permission_UserId = "Permission_UserId";

// ------------------------------------> | Emp -> Task | <-------------------------------------
export const Get_TaskType_Dropdown = "Get_TaskType_Dropdown";
export const Get_Emp_Task_Data = "Get_Emp_Task_Data";
export const Get_Single_Emp_Task_Data = "Get_Single_Emp_Task_Data";
export const Get_Emp_Task_Detail_Data = "Get_Emp_Task_Detail_Data";
export const Replay_Status = "Replay_Status";
export const Get_EmpCount_Task_Data = "Get_EmpCount_Task_Data";
export const Get_SingleCount_Data = "Get_SingleCount_Data"
export const Get_Emp_Task_ID = "Get_Emp_Task_ID";
export const Get_Emp_ID = "Get_Emp_ID";
export const Get_Status_ID = "Get_Status_ID";
export const Get_EmpStatus_TaskType_Dropdown = "Get_EmpStatus_TaskType_Dropdown";

// ------------------------------------> | Emp -> Attendance Report | <-------------------------------------
export const Get_Emp_Attendance = "Get_Emp_Attendance";

// ------------------------------------> | Dashbord -> Attendance Report | <-------------------------------------
export const Get_Attendance = "Get_Attendance";
export const Get_Year = "Get_Year";
export const Get_Month = "Get_Month";

// -------------------------------------> | Mining -> Pit Bank | <--------------------------------
export const Get_PitBlock_Dropdown = "Get_PitBlock_Dropdown";
export const Get_PitBlock_Data = "Get_PitBlock_Data";
export const Get_Single_PitBlock_Data = "Get_Single_PitBlock_Data";
export const Get_ExplosiveType_Dropdown = "Get_ExplosiveType_Dropdown";
export const Get_Explosive_Data = "Get_Explosive_Data";
export const Get_Single_Explosive_Data = "Get_Single_Explosive_Data";
export const Get_PitBlock_Filter_Data = "Get_PitBlock_Filter_Data";
export const Get_Explosive_Filter_Data = "Get_Explosive_Filter_Data";

// -----------------------> Mining ->Explosive  <------------------------------
export const Get_Machine_Name_Dropdown = "Get_Machine_Name_Dropdown";
export const Get_Drilling_Data = "Get_Drilling_Data";
export const Get_Drilling_Filter_Data = "Get_Drilling_Filter_Data";
export const Get_Single_Drilling_Data = "Get_Single_Drilling_Data";

// -----------------------> Mining ->Blast  <------------------------------
export const Get_BenchNo_Dropdown = "Get_BenchNo_Dropdown";
export const Get_Site_Direction_Dropdown = "Get_Site_Direction_Dropdown";
export const Get_Blast_Entry_Data = "Get_Blast_Entry_Data";
export const Get_Single_Blast__Data = "Get_Single_Blast__Data";
export const Get_Blast_Filter_Data = "Get_Blast_Filter_Data";

// ----------------------> Plant - transport -> Meter Reading ---------------
export const Get_MeterReading_Data = "Get_MeterReading_Data";
export const Get_Single_MeterReading_Data = "Get_Single_MeterReading_Data";
export const Get_Meter_Filter_Data = "Get_Meter_Filter_Data";

// ----------------------> Plant - transport -> Consumption power ---------------
export const PlantDirectUnit = "PlantDirectUnit";
export const PlantDGUnit = "PlantDGUnit";
export const PlantTotalUnit = "PlantTotalUnit";
export const PlantMDRecord = "PlantMDRecord";
export const Get_Consumption_Power_Data = "Get_Consumption_Power_Data";
export const Get_Single_Consumption_Power_Data = "Get_Single_Consumption_Power_Data";
export const Get_Consumption_Power_Filter_Data = "Get_Consumption_Power_Filter_Data";

// -----------------------------------> Dashbord <-------------------------------

export const Get_Chart_Data = "Get_Chart_Data";
export const Get_PurchaseChart_Data = "Get_PurchaseChart_Data";
export const Get_Task_Data = "Get_Task_Data";

//------------------------------DashBoard_Chart-----------------------------

export const Get_SalebyProduct_Chart_Data = "Get_SalebyProduct_Chart_Data";
export const Get_SalebybyCash_Chart_Data = "Get_Salebyby_Chart_Data";
export const Get_SalebyUdhar_Chart_Data = "Get_SalebyUdhar_Chart_Data";

// ---------------------------> Inventory Add request<-------------------
export const Get_RequestBy_DropDown = "Get_RequestBy_DropDown"
export const Get_AddRequest_Data = "Get_AddRequest_Data"
export const Get_Single_Request_Data = "Get_Single_Request_Data"

// -----------------------> Request List < -------------------
export const Get_RequestList_Data = "Get_RequestList_Data"
export const Get_Single_RequestList_Data = "Get_Single_RequestList_Data"

// -------------------Enter Quotes---------------
export const Get_Quotes_Data = "Get_Quotes_Data"
export const Get_Supplier_DropData = "Get_Supplier_DropData"
export const Get_Gst_DropData = "Get_Gst_DropData"
export const Get_Receivedby_DropData = "Get_Receivedby_DropData"
export const Get_DiscountType_DropData = "Get_DiscountType_DropData"
export const Get_Single_Quotes_Data = "Get_Single_Quotes_Data"

// ---------------------> Approve Request < ----------
export const Get_Approve_Request = "Get_Approve_Request"
export const Get_Req_Id = "Get_Req_Id"
// --------------------> Reject Request <----------
export const Get_UnApprove_Request = "Get_UnApprove_Request"
export const Get_DeApprove_Request = "Get_DeApprove_Request"

// --------------------> MasterTables ---Inventory------------
export const Get_MaterialGroup_DropData = "Get_MaterialGroup_DropData"
export const Get_MaterialType_DropData = "Get_MaterialType_DropData"
export const Get_Inventory_Material_Data = "Get_Inventory_Material_Data"
export const Get_Single_Inventory_Data = "Get_Single_Inventory_Data"
export const Get_Inventory_Material_Filter_Data = "Get_Inventory_Material_Filter_Data";

// ------------------> Approve Quotes < ------------------
export const Get_Approve_Quotes = "Get_Approve_Quotes"

// ------------------> Inventory >- Purchase invoice --------------
export const Get_Invoice_Type_DropData = 'Get_Invoice_Type_DropData'
export const Get_PurchaseInvoice_Data = 'Get_PurchaseInvoice_Data'
export const Get_Single_PurchaseInvoice_Data = 'Get_Single_PurchaseInvoice_Data'
// --------------->Inventory > Add request-----------
export const Get_AddItem_Data = 'Get_AddItem_Data'
export const Get_Single_AddItem_Data = "Get_Single_AddItem_Data"
export const Get_Invoice_ID = "Get_Invoice_ID"
export const Get_Invoice_Filter_Data = "Get_Invoice_Filter_Data"
export const Get_Quotes_Id = "Get_Quotes_Id"
// --------------Admin Setting > Phone Sms-------------
export const Get_PhoneSms_Data = 'Get_PhoneSms_Data'
export const Get_Single_PhoneSms_Data = 'Get_Single_PhoneSms_Data'
export const Get_Filter_PhoneSms_Data = "Get_Filter_PhoneSms_Data"
// ------------------------------------------Service>ServiceConfiguration-----------------
export const Get_ServiceConfiguration_Dropdown = "Get_ServiceConfiguration_Dropdown";
export const Get_ServiceConfiguration_Data = 'Get_ServiceConfiguration_Data'
export const Get_Filter_ServiceConfiguration_Data = "Get_Filter_ServiceConfiguration_Data"
export const Get_Single_ServiceConfiguration_Data = 'Get_Single_ServiceConfiguration_Data'

// --------------------------------Service>Mantinance------------------------------------------------------
export const Get_Mantinance_Data = 'Get_Mantinance_Data';
export const Get_Single_Mantinance_Data = 'Get_Single_Mantinance_Data'
export const Get_Mantinance_Dropdown = 'Get_Mantinance_Dropdown';
export const Get_Filter_Mantinance_Data = "Get_Filter_Mantinance_Data"














































































//---------------------------------------------------------Banti--------------------------------------------

































































































































//---------------------------------------------------------Mitali--------------------------------------------





























