import React, { useState } from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Quotes_Add_Up from './Quotes_Add_Up'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { delete_Quotes_Enter_Data, get_Quotes_Enter_Data, get_Single_Quotes_Enter_Data } from '../../../../redux/actions/AllAction'
import { Edit_Update_Count, Get_Single_Quotes_Data, Modal_Status } from '../../../../redux/actionTypes'
import ConfirmModal from '../../../Include/Common/ConfirmModal'
import Loader from '../../../Include/Common/Loader'

const Quotes = () => {
  const dispatch = useDispatch()
  const modalStatus = useSelector((state) => state.Data.modalStatus)
  const quotesData = useSelector((state) => state.Data.quotesData);
  const userId = useSelector((state) => state.Data.userId)
  const pageLoader = useSelector((state) => state.Data.pageLoader)
  const editUpdateCount = useSelector((state) => state.Data.editUpdateCount);
  const [pageStatus, setPageStatus] = useState("1")
  const [IsActive, setIsActive] = useState()
  const [confirmType, setConfirmType] = useState('')
  const [status, setStatus] = useState(false)
  const [id, setId] = useState()


  useEffect(() => {
    dispatch(get_Quotes_Enter_Data(pageStatus))
  }, [pageStatus])

  const columns = [
    {
      name: 'ReqID',
      selector: (row) => row.RequestId,
      sortable: true
    },
    {
      name: 'Rate',
      selector: (row) => row.Rate,
      sortable: true
    },
    {
      name: 'Supplier Name',
      selector: (row) => row.SupplierName,
      sortable: true
    },
    {
      name: 'Receivedby',
      selector: (row) => row.Receivedby,
      sortable: true
    },
    {
      name: 'Discount',
      selector: (row) => row.Discount,
      sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 55, fontWeight: '700' }}>Action</p>,
      cell: row => <>
        <div style={{ position: 'absolute', top: 4, right: 40 }}>
          {
            pageStatus === "1" ?
              <Link to='/Inventory?page=Enter%20Quotes' onClick={() => setEditStatus(row)} data-toggle="modal" data-target="#AddQuotesModal"
                className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn" ><i className="fa fa-edit"></i>
              </Link>
              : <></>
          }
          {
            pageStatus === "1" ?
              < Link to={`/Inventory?page=Enter%20Quotes`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive(0); setConfirmType("InActive"); setId(row.id) }}
                className="btn btn-sm  text-white px-1 py-0 mr-1" style={{ background: "#ddd" }}>
                <i class="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true"></i>
              </Link>
              :
              <Link to={`/Inventory?page=Enter%20Quotes`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive(1); setConfirmType("Active"); setId(row.id) }}
                className="btn btn-sm  text-white px-1 py-0 mr-4" style={{ background: "#ddd" }}>
                <i class="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true"></i>
              </Link>
          }

        </div>
      </>
    }
  ]

  const setEditStatus = (row) => {
    dispatch(get_Single_Quotes_Enter_Data(row.id)); setStatus(true); dispatch({ type: Modal_Status, payload: true })
    dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
  }

  const setNewStatus = () => {
    setStatus(false); setId(''); dispatch({ type: Get_Single_Quotes_Data, payload: [] }); dispatch({ type: Modal_Status, payload: true })
  };

  const UpdActiveDeactive = () => {
    dispatch(delete_Quotes_Enter_Data(id, IsActive, userId, pageStatus))
  }
  return (
    <>

      <div className="col-12 col-md-12 col-lg-12  px-3">
        <div className="col-12 col-md-6 col-lg-12 ">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class={`nav-link ${pageStatus === '1' ? 'active' : ''}`} onClick={() => setPageStatus("1")} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true">Active</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class={`nav-link ${pageStatus === '0' ? 'active' : ''}`} onClick={() => setPageStatus("0")} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true">InActive</a>
            </li>
          </ul>
        </div>
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">Enter Quotes</p>
          <Link to={`/Inventory?page=Enter%20Quotes`} className="text-white px-2 py-0 "
            data-toggle="modal" data-target="#AddQuotesModal" onClick={setNewStatus}  >
            <i className="fa fa-plus"></i>
          </Link>
        </div>
      </div>
      <div className="col-12 mt-1">
        {pageLoader ? 
        <DataTable
          dense
          pagination
          columns={columns}
          data={quotesData}
          selectableRowsHighlight
          highlightOnHover
        /> : <Loader /> }
      </div>
      {modalStatus && <Quotes_Add_Up {...{ status, pageStatus }} />}
      <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />
    </>
  )
}

export default Quotes