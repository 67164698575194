import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import Select from "react-select";
import MasSideBar from '../../../Include/Common/MasSideBar';
import { get_Pit_Drop_Data, get_Site_Direction_Drop_Data, get_State, save_Pit_Block_Data, update_Pit_Block_Data } from '../../../../redux/actions/AllAction';
import { Get_Error_Status, Get_Single_PitBlock_Data, MasterTable_Modal_Status } from '../../../../redux/actionTypes';
import { RequiredFieldSelectBox, SpaceNotAllow, SpaceNotAllowWithoutReq } from '../../../Validation/Validation';
import { changeArrayFormatDrop } from '../../../Include/Common/ArrayFormat';

const PitBlockBench_Add_Up = (props) => {
    const dispatch = useDispatch();
    const { status, pageStatus } = props
    const userId = useSelector((state) => state.Data.userId)
    const State = changeArrayFormatDrop(useSelector((state) => state.Data.State),'StateName','id')
    const pitblockDropData = changeArrayFormatDrop(useSelector((state) => state?.Data?.pitblockDropdata),'Description','ID')
    const siteDirectionDrpData = changeArrayFormatDrop(useSelector((state) => state.Data.siteDirectionDrpData),'Description','ID');
    const modalStatus = useSelector((state) => state.Data.modalStatus)
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const singlePitBlockData = useSelector((state) => state?.Data?.singlePitBlockData[0])
    const [urlName, setUrlName] = useState('')
    const [showCol, setShowCol] = useState('')
    const [idName, setIdName] = useState('')
    const [actionType, setActionType] = useState('')
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }
    const [errors, setErrors] = useState({
        BlockError: '', BenchNoError: '', StateError: '', DirectionError: '', RLError: '', NameError: '', LocationError: ''
    })

    const { BlockError, BenchNoError, DirectionError } = errors;

    useEffect(() => {
        if (State?.length === 0) dispatch(get_State())
        if (siteDirectionDrpData.length === 0) dispatch(get_Site_Direction_Drop_Data());
        if (pitblockDropData.length === 0) dispatch(get_Pit_Drop_Data());
    }, [])
    const [value, setValue] = useState({
        PitID: '',
        Pit: '',
        Bench: '',
        StateID: '',
        State: '',
        SiteDirection: '',
        RL: '',
        PattaHolderName: '',
        Location: '',
        Remarks: '',
        Updatedbyid: '',
        Createdbyid: userId,
    });

    const handelChange = (e) => {
        if (e.target.name === 'Bench' || e.target.name === 'RL') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setValue({
                ...value,
                [e.target.name]: checkNumber
            })
        } else
            setValue({
                ...value,
                [e.target.name]: e.target.value,
            });
    }
    const handleDropdown = (e, Name) => {
        if (Name === 'PitID') {
            setValue(pre => { return { ...pre, ['PitID']: e?.value, ['Pit']: e?.label } })
        } else if (Name === 'StateID') {
            setValue(pre => { return { ...pre, ['StateID']: e?.value, ['State']: e?.label } })
        } else if (Name === 'SiteDirectionId') {
            setValue(pre => { return { ...pre, ['SiteDirectionId']: e?.value, ['SiteDirection']: e?.label } })
        }
    }


    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.Pit)) {
            setErrors(prevValues => { return { ...prevValues, ['BlockError']: SpaceNotAllow(value.Pit) } })
            dispatch({ type: Get_Error_Status, payload: true });
        };
        if (SpaceNotAllow(value.Bench)) {
            setErrors(prevValues => { return { ...prevValues, ['BenchNoError']: SpaceNotAllow(value.Bench) } })
        };
        if (SpaceNotAllow(value.SiteDirection)) {
            setErrors(prevValues => { return { ...prevValues, ['DirectionError']: SpaceNotAllow(value.SiteDirection) } })
        };
    };

    useEffect(() => {
        if (BlockError === 'true', BenchNoError === 'true', DirectionError === 'true') {
            if (status) { dispatch(update_Pit_Block_Data(value, pageStatus));  setErrors({ ['BlockError']: '' })}
            else { dispatch(save_Pit_Block_Data(value, pageStatus)); setErrors({ ['BlockError']: '' }) }
        }
    }, [BlockError, BenchNoError, DirectionError]);

    useEffect(() => {
        if (singlePitBlockData) {
            setValue({
                ...value,
                PitID: singlePitBlockData.PitID,
                Pit: singlePitBlockData.Pit,
                Bench: singlePitBlockData.Bench,
                StateID: singlePitBlockData.StateID,
                State: singlePitBlockData.State,
                SiteDirection: singlePitBlockData.SiteDirection,
                RL: singlePitBlockData.RL,
                PattaHolderName: singlePitBlockData.PattaHolderName,
                Location: singlePitBlockData.Location,
                Remarks: singlePitBlockData.Remarks,
                Updatedbyid: userId,
                ID: singlePitBlockData?.ID
            })
        }
        else {
            setValue({
                ...value,
                PitID: '',
                Pit: '',
                Bench: '',
                StateID: '',
                State: '',
                SiteDirection: '',
                RL: '',
                PattaHolderName: '',
                Location: '',
                Remarks: '',
                Updatedbyid: '',
            })
        }
    }, [singlePitBlockData, editUpdateCount])
    const resetHooks = () => {
        setValue(pre => {
            return {
                ...pre, PitID: '',
                Pit: '',
                Bench: '',
                StateID: '',
                State: '',
                SiteDirection: '',
                RL: '',
                PattaHolderName: '',
                Location: '',
                Remarks: '',
                ID: ''
            }
        })
        setErrors(pre => { return { ...pre, BlockError: '', BenchNoError: '', StateError: '', DirectionError: '', RLError: '', NameError: '', LocationError: '' } })
    }
    const setUrls = (idName, urlName, showCol, actionType) => {
        setIdName(idName); setUrlName(urlName); setShowCol(showCol); setActionType(actionType)
    }

    return (
        <>
            {modalStatus &&
                <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="PitBlockBenchModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="m-1 ">
                                    <fieldset style={{ border: '1px solid gray' }}>
                                        <legend >Pit Block Bench</legend>
                                        <div className="form-row" style={{ marginTop: '-10px' }}>
                                            <div className="col-12 col-md-12 col-lg-12 d-flex px-0">
                                                <div className="col-11 col-md-11 col-lg-8 mt-1">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            name='pitblock'
                                                            styles={colourStyles}
                                                            isClearable
                                                            placeholder="Select.."
                                                            onChange={(e) => handleDropdown(e, 'PitID')}
                                                            value={pitblockDropData?.filter((obj) => obj?.label === value?.Pit)}
                                                            options={pitblockDropData}
                                                        />
                                                        <label htmlFor="">Pit/Block No/River</label>
                                                        {errors.BlockError !== "true" ? (
                                                            <span
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "13px",
                                                                    margin: "0px",
                                                                    padding: "0px",
                                                                }}
                                                            >
                                                                {errors.BlockError}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-1 col-md-1 col-lg-1   px-0 py-0" style={{ marginTop: '17px' }}>
                                                    <Link to="/Mining?sub=Pit%20Block%20Bench" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal"
                                                        onClick={() =>
                                                            setUrls('ID', 'MiningPit', 'Description', 'Get_PitBlock_Dropdown')}
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4" style={{ marginTop: '10px' }}>
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='Bench'
                                                        className='requiredColor'
                                                        id='Bench'
                                                        required
                                                        value={value.Bench}
                                                        onChange={handelChange}
                                                    />
                                                    <label className='pt-1'>Bench No./Gate</label>
                                                    {errors.BenchNoError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.BenchNoError}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-4 mt-1 pt-1">
                                                <div className="dropdown__box">
                                                    <Select
                                                        name='State'
                                                        styles={customStylesWithOutColor}
                                                        isClearable
                                                        placeholder="Select.."
                                                        onChange={(e) => handleDropdown(e, 'StateID')}
                                                        value={State?.filter((obj) => obj?.label === value?.State)}
                                                        options={State}
                                                    />
                                                    <label htmlFor="">State</label>

                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 " style={{ marginTop: '10px' }}>
                                                <div className="dropdown__box">
                                                    <Select
                                                        name='SiteDirectionID'
                                                        value={siteDirectionDrpData?.filter((obj) => obj?.label === value?.SiteDirection)}
                                                        options={siteDirectionDrpData}
                                                        onChange={(e) => handleDropdown(e, 'SiteDirectionId')}
                                                        isClearable
                                                        styles={colourStyles}
                                                        placeholder="Select.."
                                                    />
                                                    <label htmlFor="" className='pl-0'>Site Direction</label>
                                                </div>
                                                {errors.DirectionError !== "true" ? (
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            fontSize: "13px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                        }}
                                                    >
                                                        {errors.DirectionError}
                                                    </span>
                                                ) : null}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 mt-1 pt-2">
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='RL'
                                                        className=""
                                                        id='RL'
                                                        required
                                                        value={value.RL}
                                                        onChange={handelChange}
                                                    />
                                                    <label className='pt-1'>RL(Reduce Level in Mtrs)</label>

                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 mt-1 pt-2">
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='PattaHolderName'
                                                        className=""
                                                        id='PattaHolderName'
                                                        value={value.PattaHolderName}
                                                        onChange={handelChange}
                                                        required
                                                    />
                                                    <label className='pt-1'>Patta Holder Name</label>

                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 mt-1" >
                                                <div className="dropdown__box">
                                                    <textarea name='Location' id="Location" value={value.Location} onChange={handelChange}
                                                        cols="30" rows='1' className="form-control "  ></textarea>
                                                    <label htmlFor="">Location </label>

                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12 mt-1" >
                                                <div className="dropdown__box">
                                                    <textarea name='Remarks' id="Remarks"
                                                        cols="30" rows='1' className="form-control " value={value.Remarks} onChange={handelChange} ></textarea>
                                                    <label htmlFor="">Remarks </label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div className="btn-box text-right  mr-1 mb-2">
                                {status ? <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button> : <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Save</button>}
                                <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={resetHooks}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                urlName &&
                <MasSideBar
                    showCol1={showCol} idName={idName} urlName={urlName} actionType={actionType}
                    getUrl={`/${urlName}/GetData_${urlName}`}
                    addUrl={`/${urlName}/Insert${urlName}`}
                    singleDataUrl={`/${urlName}/GetSingleData_${urlName}`}
                    upUrl={`/${urlName}/Update${urlName}`}
                    delUrl={`/${urlName}/Delete${urlName}`}
                />
            }
        </>
    )
}

export default PitBlockBench_Add_Up