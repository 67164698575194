import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { set_Approve_Quotes_Data, set_Approve_Request_Data, set_UnApprove_Request_Data } from '../../../../redux/actions/AllAction';
import { useDispatch, useSelector } from 'react-redux';
import { getShowingDayWithOutTime, getShowingWithOutTimeStartDate } from '../../../Include/Common/Utility';
import { Get_Error_Status, Get_Req_Id, MasterTable_Modal_Status, Modal_Status } from '../../../../redux/actionTypes';
import { SpaceNotAllow } from '../../../Validation/Validation';

const ApproveRequest_Add_Up = (props) => {
    const { status, pageStatus, } = props
    const ReqId = useSelector((state) => state.Data.reqID)
    const userId = useSelector((state) => state.Data.userId)
    const modalStatus = useSelector((state) => state.Data.modalStatus)
    const dispatch = useDispatch()
    const [value, setValue] = useState({
        ID: ReqId,
        Remarks: '',
        DeletedByid: userId
    });
    const [value1, setValue1] = useState({
        ID: ReqId,
        Remarks: '',
        ApprovedDate: '',
        ApprovedDate1: '',
    });

    const [errors, setErrors] = useState({
        RemarksError: ''
    })

    useEffect(() => { setValue({ 'ID': ReqId, 'DeletedByid': userId }) }, [ReqId, userId]);

    useEffect(() => {
        setValue1({ 'ID': ReqId })
    }, [ReqId]);

    const handelChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    }
    const handelChange1 = (e) => {
        setValue1({
            ...value1,
            [e.target.name]: e.target.value,
        });
    }
    const { RemarksError } = errors

    useEffect(() => {
        if (RemarksError === 'true') {
            if (status) { dispatch(set_Approve_Request_Data(value1, pageStatus)); setErrors({ ['RemarksError']: '' }) }
            else { dispatch(set_UnApprove_Request_Data(value, pageStatus)); setErrors({ ['RemarksError']: '' }) }
        }
    }, [RemarksError]);

    const setRejectReq = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.Remarks)) {
            setErrors(prevValues => { return { ...prevValues, ['RemarksError']: SpaceNotAllow(value.Remarks) } })
            dispatch({ type: Get_Error_Status, payload: true })
        } dispatch({ type: Get_Req_Id, payload: '' })
    }
    const setApproveReq = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value1.Remarks)) {
            setErrors(prevValues => { return { ...prevValues, ['RemarksError']: SpaceNotAllow(value1.Remarks) } })
            dispatch({ type: Get_Error_Status, payload: true })
        } dispatch({ type: Get_Req_Id, payload: '' })
    }

    const resetHooks = () => {
        setValue(pre => {
            return {
                ...pre,
                ID: '',
                Remarks: '',
            }
        })
        setErrors(pre => { return { ...pre, RemarksError: '' } })
    }
    const resetHooks1 = () => {
        setValue1(pre => {
            return {
                ...pre,
                ID: '',
                Remarks: '',
                ApprovedDate: '',
                ApprovedDate1: '',
            }
        })
        setErrors(pre => { return { ...pre, RemarksError: '' } })
    }
    return (
        <>
            {modalStatus &&
                <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="AddApproveModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="m-1 ">
                                    {status ?
                                        <fieldset style={{ border: '1px solid gray' }}>
                                            <legend > Approve Request</legend>
                                            <div className="col-6 col-md-4 col-lg-4 px-0">
                                                <div className="dropdown__box">
                                                    <DatePicker
                                                        id='ApprovedDate'
                                                        name='ApprovedDate'
                                                        dateFormat="dd/MM/yyyy "
                                                        timeInputLabel
                                                        isClearable={value1.ApprovedDate ? true : false}
                                                        selected={value1.ApprovedDate1 && new Date(value1.ApprovedDate1)}
                                                        onChange={(date) => date ? setValue1(pre => { return { ...pre, ['ApprovedDate']: getShowingDayWithOutTime(date), ['ApprovedDate1']: getShowingWithOutTimeStartDate(date) } }) :
                                                            setValue1(pre => { return { ...pre, ['ApprovedDate']: '', ['ApprovedDate1']: '' } })
                                                        }
                                                        placeholderText={'Select...'}
                                                        dropdownMode="select"
                                                        showMonthDropdown
                                                        minDate={new Date()}
                                                        showYearDropdown
                                                    />
                                                    <label htmlFor="" className='pl-0 pt-1' >Approved Date</label>
                                                    {/* {errors.ReqFromError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.ReqFromError}
                                                        </span>
                                                    ) : null} */}
                                                </div>
                                            </div>

                                            <div className="form-row" >
                                                <div className="col-12  col-md-12 col-lg-12 mt-2 ">
                                                    <div className="dropdown__box">
                                                        <textarea
                                                            name="Remarks"
                                                            id="Remarks"
                                                            cols="30"
                                                            rows="1"
                                                            className="form-control"
                                                            onChange={handelChange1}
                                                            value={value1.Remarks}
                                                        >
                                                        </textarea>
                                                        <label htmlFor='' >Remarks</label>
                                                        {errors.RemarksError !== "true" ? (
                                                            <span
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "13px",
                                                                    margin: "0px",
                                                                    padding: "0px",
                                                                }}
                                                            >
                                                                {errors.RemarksError}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                        </fieldset>
                                        : <fieldset style={{ border: '1px solid gray' }}>
                                            <legend > Reject Request</legend>


                                            <div className="form-row" style={{ marginTop: '-10px' }}>
                                                <div className="col-12  col-md-12 col-lg-12 mt-2 ">
                                                    <div className="dropdown__box">
                                                        <textarea
                                                            name="Remarks"
                                                            id="Remarks"
                                                            cols="30"
                                                            rows="1"
                                                            className="form-control"
                                                            onChange={handelChange}
                                                            value={value.Remarks}
                                                        >
                                                        </textarea>
                                                        <label htmlFor='' >Remarks</label>
                                                        {errors.RemarksError !== "true" ? (
                                                            <span
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "13px",
                                                                    margin: "0px",
                                                                    padding: "0px",
                                                                }}
                                                            >
                                                                {errors.RemarksError}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                        </fieldset>
                                    }

                                    <div className="btn-box text-right  mr-1  mt-2">
                                        {status ?
                                            <button type="button" class="btn btn-sm btn-success mr-1" onClick={setApproveReq}>Submit</button> : <button type="button" class="btn btn-sm btn-success mr-1" onClick={setRejectReq}>Submit</button>
                                        }
                                        {status ?  <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={resetHooks1} >Close</button> :  <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={resetHooks} >Close</button>}
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ApproveRequest_Add_Up