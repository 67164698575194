import React from 'react'
import Select from "react-select";
import { getShowingWithOutTime } from '../../../Include/Common/Utility';
import DatePicker from 'react-datepicker'
import { useState } from 'react';

const EmpAssignShift_Add_Up = () => {
    const customStylesWithOutColor = {
        control: (base) => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    };
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    const [reqDate, setreqDate] = useState();
    const [datefrom, setdatefrom] = useState();
    const [dateto, setdateTo] = useState();
    const [value, setValue] = useState();
    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
        }
    };
    return (
        <>
            <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="EmpAssignShiftModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="m-1 ">
                                <fieldset style={{ border: '1px solid gray' }}>
                                    <legend >Employee Assign Shift</legend>
                                    <div className="form-row" style={{ marginTop: '-10px' }}>
                                        <div className="col-6 col-md-6 col-lg-4 pt-1">
                                            <div className="dropdown__box">
                                                <DatePicker
                                                    id='reqDate'
                                                    name='reqDate'
                                                    ref={startRef}
                                                    onKeyDown={onKeyDown}
                                                    onChange={(date) => { setreqDate(date); setValue({ ...value, ['reqDate']: date ? getShowingWithOutTime(date) : null }) }}
                                                    className='requiredColor'
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    isClearable={value?.reqDate ? true : false}
                                                    selected={reqDate}
                                                    placeholderText={value?.reqDate ? value.reqDate : 'Select...'}
                                                    dropdownMode="select"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                                <label htmlFor="" className='pl-0 pt-1' >Request Date</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 pt-2    ">
                                            <div className="dropdown__box">
                                                <Select
                                                    name="EmployeeName"
                                                    styles={colourStyles}
                                                    isClearable
                                                    placeholder="Select.."
                                                />
                                                <label htmlFor="">Employee Name</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 pt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='empcode'
                                                    className="readonlyColor"
                                                    id='empcode'
                                                    required
                                                    readOnly
                                                />
                                                <label className='pt-1'>Emp Code</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 pt-2    ">
                                            <div className="dropdown__box">
                                                <Select
                                                    name="shift"
                                                    styles={colourStyles}
                                                    isClearable
                                                    placeholder="Select.."
                                                />
                                                <label htmlFor="">Shift</label>
                                            </div>
                                        </div>
                              
                                        <div className="col-6 col-md-6 col-lg-4 " style={{ marginTop: '5px' }}>
                                            <div className="dropdown__box">
                                                <DatePicker
                                                    id='datefrom'
                                                    name='datefrom'
                                                    ref={startRef1}
                                                    onKeyDown={onKeyDown}
                                                    onChange={(date) => { setdatefrom(date); setValue({ ...value, ['datefrom']: date ? getShowingWithOutTime(date) : null }) }}
                                                    className='requiredColor'
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    isClearable={value?.datefrom ? true : false}
                                                    selected={datefrom}
                                                    placeholderText={value?.datefrom ? value.datefrom : 'Select...'}
                                                    dropdownMode="select"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                                <label htmlFor="" className='pl-0 pt-1' >Date From</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 " style={{ marginTop: '5px' }}>
                                            <div className="dropdown__box">
                                                <DatePicker
                                                    id='dateto'
                                                    name='dateto'
                                                    ref={startRef2}
                                                    onKeyDown={onKeyDown}
                                                    onChange={(date) => { setdateTo(date); setValue({ ...value, ['dateto']: date ? getShowingWithOutTime(date) : null }) }}
                                                    className='requiredColor'
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    isClearable={value?.dateto ? true : false}
                                                    selected={dateto}
                                                    placeholderText={value?.dateto ? value.dateto : 'Select...'}
                                                    dropdownMode="select"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                                <label htmlFor="" className='pl-0 pt-1' >Date To</label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="btn-box text-right  mr-1 mb-2">
                            <button type="button" class="btn btn-sm btn-success mr-1">Save</button>
                            <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" >Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmpAssignShift_Add_Up