import React from 'react'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component';

const MaintenanceReport = () => {
  return (
    <>

      <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">Maintenance Report Fill</p>
          <div style={{ marginLeft: 'auto' }}>
            <Link to={'/Plant-Transport?sub=Maintenance%20Report%20Fill'} className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#">
              <i className="fa fa-plus"></i>
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="col-12 px-2  mb-3">
        <div className="table-responsive">
          <table className="table table-bordered">
            <tr className="border-bottom">
              <th>date</th>
              <th>machine</th>
              <th>equipment name</th>
              <th>frequency</th>
              <th>assign to</th>
              <th>complete/not</th>
              <th className='text-right'>Action</th>
            </tr>
            <tr>
              <td>12-06-23 </td>
              <td>Testing </td>
              <td>Testing </td>
              <td>Testing </td>
              <td>Testing </td>
              <td>Testing </td>

              <td className='text-right'>
                <Link to={'/Plant-Transport?sub=Maintenance%20Report%20Fill'}>
                  <button type='button' className="btn btn-sm bg-green text-white  py-0 " data-toggle="modal" data-target="#"><i className="fa fa-edit"></i>
                  </button>
                </Link>
                <button type='button' className="btn btn-sm bg-green text-white py-0 ml-1"><i className="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div> */}
      <div className="col-12 ">
        <DataTable
          dense
          // columns={columns}
          // data={nameSearchValue}
          pagination
          selectableRowsHighlight
          highlightOnHover
        />
      </div>
    </>
  )
}

export default MaintenanceReport