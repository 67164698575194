import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker'
import { update_PhoneSms_Data } from '../../../../redux/actions/AllAction';
import { SpaceNotAllow } from '../../../Validation/Validation';
import { Get_Error_Status } from '../../../../redux/actionTypes';
import { getShowingDayWithOutTime, getShowingWithOutTime } from '../../../Include/Common/Utility';

const Phone_Add_Up = (props) => {
    const dispatch = useDispatch()
    const userId = useSelector((state) => state.Data.userId);
    const errorStatus = useSelector((state) => state.Data.errorStatus)
    const modalStatus = useSelector((state) => state.Data.modalStatus)
    const editUpdateCount = useSelector((state) => state.Data.singleAddItemData)
    const singlePhoneSmsData = useSelector((state) => state.Data.singlePhoneSmsData[0])
    const companyId = useSelector((state) => state.Data.companyId)
    const { status, pageStatus } = props
    const startRef = React.useRef()
    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
        }
    };
    const [value, setValue] = useState({
        PhoneNumber: '',
        SMSBody: '',
        SMSDate: '',
        SMSDate1: '',
        companyid: companyId,
        ID: '',
        UpdatedByid: userId
    })
    const [errors, setErrors] = useState({
        PhoneNumberError: ''
    })
    const { PhoneNumberError } = errors

    const handlChange = (e) => {
        if (e.target.name === 'PhoneNumber') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setValue({
                ...value,
                [e.target.name]: checkNumber
            })
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value,
            });
        }

    }

    useEffect(() => {
        if (PhoneNumberError === 'true' && errorStatus) {
            if (status) { dispatch(update_PhoneSms_Data(value)); console.log(value); setErrors({ ['PhoneNumberError']: '' }) }
        }
    }, [PhoneNumberError, errorStatus])

    useEffect(() => {
        if (singlePhoneSmsData) {
            setValue({
                ...value,
                PhoneNumber: singlePhoneSmsData.PhoneNumber,
                SMSBody: singlePhoneSmsData?.SMSBody,
                SMSDate: getShowingDayWithOutTime(parseInt(singlePhoneSmsData?.SMSDate)),
                SMSDate1: getShowingDayWithOutTime(parseInt(singlePhoneSmsData?.SMSDate))?.split("/")?.reverse()?.join("/"),
                companyid: singlePhoneSmsData.companyId,
                ID: singlePhoneSmsData.ID,
                UpdatedByid: userId
            })
        }
        else {
            setValue({
                ...value,
                PhoneNumber: '',
                SMSBody: '',
                SMSDate: '',
                companyid: companyId,
                ID: '',
                UpdatedByid: userId
            })
        }
    }, [singlePhoneSmsData, editUpdateCount])

    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.PhoneNumber)) {
            setErrors(prevValues => { return { ...prevValues, ['PhoneNumberError']: SpaceNotAllow(value.PhoneNumber) } });
            dispatch({ type: Get_Error_Status, payload: true });
        }
    };

    const resetHooks = () => {
        setValue(pre => {
            return {
                ...pre,
                PhoneNumber: '',
                SMSBody: '',
                SMSDate: '',
                companyid: '',
                ID: '',
                UpdatedByid: userId
            }
        })
        setErrors(pre => { return { ...pre, PhoneNumber: '' } })
    }
    return (
        <>{modalStatus &&
            <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="AddPhoneModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="m-1 ">
                                <fieldset style={{ border: '1px solid gray' }}>
                                    <legend >Phone Sms</legend>
                                    <div className="form-row" style={{ marginTop: '-10px' }}>
                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div className="text-field">
                                                <input
                                                    type="text"
                                                    name='PhoneNumber'
                                                    className=""
                                                    id='PhoneNumber'
                                                    maxLength={10}
                                                    onChange={handlChange}
                                                    value={value.PhoneNumber}
                                                    required
                                                />
                                                <label className='pt-1'>Phone Number</label>
                                            </div>
                                            {errors.PhoneNumberError !== 'true' ? (
                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.PhoneNumberError}</span>
                                            ) : null}
                                        </div>
                                        <div className="col-5 col-md-5 col-lg-5 pt-1">
                                            <div className="dropdown__box">
                                                <DatePicker
                                                    id='SMSDate'
                                                    name='SMSDate'
                                                    ref={startRef}
                                                    onKeyDown={onKeyDown}
                                                    onChange={(date) => date ? setValue(pre => {
                                                        return {
                                                            ...pre,
                                                            ['SMSDate']: getShowingDayWithOutTime(date),
                                                            ['SMSDate1']: getShowingWithOutTime(date)
                                                        }
                                                    }) :
                                                        setValue(pre => { return { ...pre, ['SMSDate']: '', ['SMSDate1']: '' } })
                                                    }
                                                    dateFormat="dd/MM/yyyy"
                                                    timeInputLabel
                                                    isClearable
                                                    selected={value.SMSDate1 && new Date(value.SMSDate1)}
                                                    placeholderText={'Select...'}
                                                    dropdownMode="select"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />

                                                <label htmlFor="" className='pl-0 pt-1' >SMS Date</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-12 mt-2">
                                            <div className="text-field">
                                                <textarea
                                                    type="text"
                                                    name='SMSBody'
                                                    className=""
                                                    id='SMSBody'
                                                    value={value.SMSBody}
                                                    onChange={handlChange}
                                                    required
                                                />
                                                <label className='pt-1'>Sms Body</label>
                                            </div>
                                        </div>

                                    </div>
                                </fieldset>
                                <div className="btn-box text-right  mr-1  mt-2">
                                    <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error} >Update</button>
                                    <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={resetHooks}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default Phone_Add_Up