import React from 'react'
import Select from "react-select";


const BankDetails_Add_Up = () => {
    // custuom style withoutColor
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    return (
        <>
            <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="BankModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="m-1 ">
                                <fieldset style={{ border: '1px solid gray' }}>
                                    <legend >Bank Details</legend>
                                    <div className="form-row">
                                        <div className="col-6 col-md-4 col-lg-4 ">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='benificiaryname'
                                                    className=""
                                                    id='benificiaryname'
                                                    required
                                                />
                                                <label className='pt-1'>Beneficiary Name</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-4 ">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='bankac'
                                                    className=""
                                                    id='bankac'
                                                    required
                                                />
                                                <label className='pt-1'>Account No.</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-4 ">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='bankac'
                                                    className=""
                                                    id='bankac'
                                                    required
                                                />
                                                <label className='pt-1'>Confirm Account No.</label>
                                            </div>
                                        </div>
                                        {/* <div className="col-6 col-md-4 col-lg-3 ">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='bankid'
                                                    className=""
                                                    id='bankid'
                                                    required
                                                />
                                                <label className='pt-1'>Bank Name ID</label>
                                            </div>
                                        </div> */}

                                        <div className="col-6 col-md-4 col-lg-4 mt-2">
                                            <div className="dropdown__box">
                                      
                                                <Select
                                                    name='state'
                                                    styles={customStylesWithOutColor}
                                                    isClearable
                                                    placeholder="Select.."
                                                />
                                                <label >Bank Name</label>

                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-4 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='ifsc'
                                                    className=""
                                                    id='ifsc'
                                                    required
                                                />
                                                <label className='pt-1'>IFSC Code</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-4 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='branch'
                                                    className=""
                                                    id='branch'
                                                    required
                                                />
                                                <label className='pt-1'>Branch Name</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-4 mt-2 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='branch'
                                                    className=""
                                                    id='branch'
                                                    required
                                                />
                                                <label className='pt-1'>Cheque Number</label>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-8  col-lg-4 mt-2">
                                            <div className="text-field  ">
                                                <input type="file" name='DocumentFile' multiple required />
                                                <label className='mt-1'>Cheque Image</label>
                                            </div>
                                        </div>

                                        {/* <div className="col-12  col-md-12 col-lg-12 mt-2" >
                                            <div className="dropdown__box">
                                                <textarea name='location' id="location" cols="30" rows='1' className="form-control pt-2 pb-2 " ></textarea>
                                                <label htmlFor="">Location</label>
                                            </div>
                                        </div> */}



                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="btn-box text-right  mr-1 mb-2">
                            <button type="button" class="btn btn-sm btn-success mr-1">Save</button>
                            <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" >Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BankDetails_Add_Up