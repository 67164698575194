export const changeArrayFormat = (data, col1, col2, idName) => {
  const result = data?.map((sponsor) => ({ Name: sponsor[col1],Code: sponsor[col2], ID: sponsor[idName], }));
  return result;
};

export const changeArrayFormatExcel = (data, col1, col2) => {
  if (col1 && col2) {
    const result = data?.map((sponsor) => ({ [col1]: sponsor[col1], [col2]: sponsor[col2], }));
    return result;
  } else if (col1) {
    const result = data?.map((sponsor) => ({ [col1]: sponsor[col1] }));
    return result;
  } else if (col2) {
    const result = data?.map((sponsor) => ({ [col2]: sponsor[col2] }));
    return result;
  }
};

export const changeArrayFormatDrop = (data, col1, col2) => {
  const result = data?.map((sponsor) => ({label: sponsor[col1],value: sponsor[col2],}));
  return result;
};

export const changeThreeArrayFormat = (data, Id, Code, col1) => {
  const result = data?.map((sponser) => ({ label: sponser[Id], name: sponser[Code], value: sponser[col1], }));
  return result;
};
