import React from 'react'
import { Link } from 'react-router-dom';
import Select from "react-select";

const MaintenanceForm_Add_Up = () => {
    // custuom style withoutColor
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    return (
        <>
            <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="MaintenanceFormModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="m-1 ">
                                <fieldset style={{ border: '1px solid gray' }}>
                                    <legend >Maintenace Form</legend>
                                    <div className="form-row" style={{ marginTop: '-10px' }}>
                                        <div className="col-6 col-md-6 col-lg-4 mt-1">
                                            <div className="dropdown__box">
                                                <Select
                                                    name='machine'
                                                    styles={customStylesWithOutColor}
                                                    isClearable
                                                    placeholder="Select.."
                                                />
                                                <label htmlFor="">Machine</label>
                                            </div>
                                        </div>

                                        <div className="col-6 col-md-6 col-lg-4 d-flex ">
                                            <div className="col-6 col-md-6 col-lg-10 mt-1 ">
                                                <div className="dropdown__box">
                                                    <Select
                                                        name='equipment'
                                                        styles={customStylesWithOutColor}
                                                        isClearable
                                                        placeholder="Select.."
                                                    />
                                                    <label htmlFor="">Equipment Name</label>
                                                </div>
                                            </div>
                                            <div className="col-1 mt-3 px-0">
                                                <Link to="" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal"
                                                >
                                                    <i className="fa fa-plus"></i>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-4 mt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='taskname'
                                                    className=""
                                                    id='taskname'
                                                    required
                                                />
                                                <label className='pt-1'>Task Name</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 mt-2 pt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='action'
                                                    className=""
                                                    id='action'
                                                    required
                                                />
                                                <label className='pt-1'>Action</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 d-flex ">
                                            <div className="col-6 col-md-6 col-lg-10 mt-1 pt-2">
                                                <div className="dropdown__box">
                                                    <Select
                                                        name='frequency'
                                                        styles={customStylesWithOutColor}
                                                        isClearable
                                                        placeholder="Select.."
                                                    />
                                                    <label htmlFor="">Frequency</label>
                                                </div>
                                            </div>
                                            <div className="col-1 col-md-1 col-lg-1   px-0 py-0" style={{ marginTop: '25px' }}>
                                                <Link to="" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal"
                                                >
                                                    <i className="fa fa-plus"></i>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 mt-1 pt-2">
                                            <div className="dropdown__box">
                                                <Select
                                                    name='assign'
                                                    styles={customStylesWithOutColor}
                                                    isClearable
                                                    placeholder="Select.."
                                                />
                                                <label htmlFor="">Assign</label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="btn-box text-right  mr-1 mb-2">
                            <button type="button" class="btn btn-sm btn-success mr-1">Save</button>
                            <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" >Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MaintenanceForm_Add_Up