import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from "react-select";
import { get_Department_Drop_Data, get_Designation_Drop_Data, get_Employee_Attendance_Data, get_Status_Drop_Data, get_Unit_Drop_Data, save_Employee, update_Employee } from '../../../../../../../redux/actions/AllAction';
import { Employee_ID, Get_Error_Status, Get_Single_Employee_Data, MasterTable_Modal_Status, Status } from '../../../../../../../redux/actionTypes';
import { RequiredFieldSelectBox, SpaceNotAllow, SpaceNotAllowWithoutReq } from '../../../../../../Validation/Validation';
import { toastifyError } from '../../../../../../Include/Common/AlertMsg';
import MasSideBar from '../../../../../../Include/Common/MasSideBar';
import { changeArrayFormatDrop } from '../../../../../../Include/Common/ArrayFormat';

const Home = ({ openPage }) => {

  const dispatch = useDispatch()
  const departmentData = changeArrayFormatDrop(useSelector((state) => state.Data.departmentDropData),'Department','ID')
  const statusDropDown = (useSelector((state) => state.Data.statusDropDown));
  const unitData = changeArrayFormatDrop(useSelector((state) => state.Data.unitDropData),'Unit','ID')
  const designationData = changeArrayFormatDrop(useSelector((state) => state.Data.designationDropData),'Designation','ID')
  const errorStatus = useSelector((state) => state.Data.errorStatus)
  const pageStatus = useSelector((state) => state.Data.Status)
  const employeeSingleData = useSelector((state) => state.Data.employeeSingleData[0])
  const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
  const employeeId = useSelector((state) => state.Data.employeeId);
  const userId = useSelector((state) => state.Data.userId)
  const employeeData = useSelector((state) => state.Data.employeeData)
  const attendanceMonth = useSelector((state) => state.Data.attendanceMonth)
  const attendanceYear = useSelector((state) => state.Data.attendanceYear)

  const [value, setValue] = useState({
    Empcode: '', EmpName: '', FatherName: '', DesignationID: '', Designation: '', UnitID: '', Unit: '', DepartmentID: '', Department: '', Remarks: '', ID: '', Updatedbyid: ''
  });
  const [errors, setErrors] = useState({
    EmpcodeErrors: '', EmpNameErrors: '', FatherNameErrors: '', DesignationIDErrors: '', DesignationErrors: '', UnitIDErrors: '', UnitErrors: '', DepartmentIDErrors: '', DepartmentErrors: '', RemarksErrors: '',
  })

  const [urlName, setUrlName] = useState('')
  const [showCol, setShowCol] = useState('')
  const [idName, setIdName] = useState('')

  useEffect(() => {
    if (departmentData.length === 0) dispatch(get_Department_Drop_Data())
    if (unitData.length === 0) dispatch(get_Unit_Drop_Data())
    if (statusDropDown.length === 0) dispatch(get_Status_Drop_Data())
    if (designationData.length === 0) dispatch(get_Designation_Drop_Data())
  }, [])

  useEffect(() => {
    if (employeeSingleData) {
      setValue(pre => { return { ...pre, Empcode: employeeSingleData.EmpCode, EmpName: employeeSingleData.EmpName, FatherName: employeeSingleData.FatherName, DesignationID: employeeSingleData.designationID, Designation: employeeSingleData.Designation, UnitID: employeeSingleData.UnitID, Unit: employeeSingleData.Unit, DepartmentID: employeeSingleData.DepartmentID, Department: employeeSingleData.Department, Remarks: employeeSingleData.Remarks, ID: employeeSingleData.ID, Updatedbyid: userId } })
    } else resetHooks()
  }, [employeeSingleData, editUpdateCount])

  const handlChange = (e) => {
    setValue(pre => { return { ...pre, [e.target.name]: e.target.value } })
  }

  const handleDropdown = (e, name) => {
    if (name === 'DepartmentID') {
      setValue(pre => { return { ...pre, ['DepartmentID']: e?.value, ['Department']: e?.label } })
    } else if (name === 'DesignationID') {
      setValue(pre => { return { ...pre, ['DesignationID']: e?.value, ['Designation']: e?.label } })
    } else if (name === 'UnitID') {
      setValue(pre => { return { ...pre, ['UnitID']: e?.value, ['Unit']: e?.label } })
    }
  }

  // Check validation on Field
  const check_Validation_Error = (e) => {
    e.preventDefault()
    if (SpaceNotAllow(value.Empcode)) {
      setErrors(prevValues => { return { ...prevValues, ['EmpcodeErrors']: SpaceNotAllow(value.Empcode) } })
      dispatch({ type: Get_Error_Status, payload: true })
    }
    if (SpaceNotAllow(value.EmpName)) {
      setErrors(prevValues => { return { ...prevValues, ['EmpNameErrors']: SpaceNotAllow(value.EmpName) } })
    }
    if (SpaceNotAllow(value.FatherName)) {
      setErrors(prevValues => { return { ...prevValues, ['FatherNameErrors']: SpaceNotAllow(value.FatherName) } })
    }
    if (RequiredFieldSelectBox(value.Department)) {
      setErrors(prevValues => { return { ...prevValues, ['DepartmentErrors']: RequiredFieldSelectBox(value.Department) } })
    }
    if (RequiredFieldSelectBox(value.Unit)) {
      setErrors(prevValues => { return { ...prevValues, ['UnitErrors']: RequiredFieldSelectBox(value.Unit) } })
    }
    if (SpaceNotAllowWithoutReq(value.Remarks)) {
      setErrors(prevValues => { return { ...prevValues, ['RemarksErrors']: SpaceNotAllowWithoutReq(value.Remarks) } })
    }
  }

  // Check All Field Format is True Then Submit 
  const { EmpcodeErrors, EmpNameErrors, FatherNameErrors, UnitErrors, DepartmentErrors, RemarksErrors } = errors

  useEffect(() => {
    if (EmpcodeErrors === 'true' && EmpNameErrors === 'true' && FatherNameErrors === 'true' && UnitErrors === 'true' && DepartmentErrors === 'true' && RemarksErrors === 'true' && errorStatus) {
      if (pageStatus) dispatch(update_Employee(value, employeeId, employeeData))
      else dispatch(save_Employee(value, employeeData))
    }
  }, [EmpcodeErrors, EmpNameErrors, FatherNameErrors, RemarksErrors, UnitErrors, DepartmentErrors, errorStatus])
  const colourStyles = {
    control: (styles) => ({
      ...styles, backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 30,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  }
  // custuom style withoutColor
  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 31,
      minHeight: 30,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
      fontWeight: 500,
    }),
  };

  const resetHooks = () => {
    setValue({
      Empcode: '', EmpName: '', FatherName: '', DesignationID: '', Designation: '', UnitID: '', Unit: '', DepartmentID: '', Department: '', Remarks: '', ID: '', Updatedbyid: ''
    });
  }

  const setUrls = (idName, urlName, showCol) => {
    setIdName(idName); setUrlName(urlName); setShowCol(showCol); dispatch({ type: MasterTable_Modal_Status, payload: true })
  }

  const setNewStatus = () => {
    dispatch({ type: Status, payload: false }); dispatch({ type: Employee_ID, payload: '' }); dispatch({ type: Get_Single_Employee_Data, payload: [] })
  };
  return (
    <>

      <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">Employee</p>
        </div>
        <div className="form-row mt-1 px-1">
          <div className="col-2 col-md-2 col-lg-1 mt-2 pt-2">
            <label className='label-field'>Emp Code:</label>
            {/* <span className="form-required">*</span> */}
          </div>
          <div className="col-3 col-md-3 col-lg-3 ">
            <div class="text-field">
              <input
                type="text"
                name='Empcode'
                value={value.Empcode}
                className='requiredColor'
                id='empcode' onChange={handlChange}
                required
              />
            </div>
            {errors.EmpcodeErrors !== 'true' ? (
              <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.EmpcodeErrors}</span>
            ) : null}
          </div>
          <div className="col-1 col-md-1 col-lg-3 mt-2 pt-2">
          </div>
          <div className="col-2 col-md-2 col-lg-1 mt-2 pt-2">
            <label className='label-field '>designation:</label>
          </div>
          <div className="col-4 col-md-4 col-lg-3 d-flex px-0">
            <div className="col-11 col-md-11 col-lg-11 ">
              <div className="dropdown__box">
                <Select
                  name='DesignationID'
                  styles={customStylesWithOutColor}
                  isClearable
                  value={designationData?.filter((obj) => obj.value === value.DesignationID)}
                  options={designationData}
                  placeholder="Select.."
                  onChange={(e) => handleDropdown(e, 'DesignationID')}
                />
              </div>

            </div>
            <div className="col-1 col-md-1 col-lg-1   px-0 py-0" style={{ marginTop: '14px' }}>
              <Link to="" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal" onClick={() =>
                setUrls('ID', 'Designation', 'Designation')
              }>
                <i className="fa fa-plus"></i>
              </Link>
            </div>
          </div>

          <div className="  col-lg-1  ">
          </div>
          <div className="col-2 col-md-2 col-lg-1 mt-2 pt-2">
            <label className='label-field'>Emp Name:</label>
          </div>
          <div className="col-3 col-md-3 col-lg-3 ">
            <div class="text-field">
              <input
                type="text"
                name='EmpName' value={value.EmpName}
                className='requiredColor'
                id='empname'
                onChange={handlChange}
                required
              />
            </div>
            {errors.EmpNameErrors !== 'true' ? (
              <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.EmpNameErrors}</span>
            ) : null}
          </div>
          <div className="col-1 col-md-1 col-lg-3 mt-2 pt-2">
          </div>
          <div className="col-2 col-md-2 col-lg-1 mt-2 pt-2">
            <label className='label-field '>select unit:</label>
          </div>
          <div className="col-4 col-md-4 col-lg-3 d-flex px-0">
            <div className="col-11 col-md-11 col-lg-11 ">
              <div className="dropdown__box">
                <Select
                  name='UnitID'
                  styles={colourStyles}
                  isClearable
                  value={unitData?.filter((obj) => obj.value === value.UnitID)}
                  options={unitData}
                  placeholder="Select.."
                  onChange={(e) => handleDropdown(e, 'UnitID')}
                />
              </div>
              {errors.UnitErrors !== 'true' ? (
                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.UnitErrors}</span>
              ) : null}
            </div>
            <div className="col-1 col-md-1 col-lg-1   px-0 py-0" style={{ marginTop: '14px' }}>
              <Link to="" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal" onClick={() =>
                setUrls('ID', 'Unit', 'Unit')
              }>
                <i className="fa fa-plus"></i>
              </Link>
            </div>
          </div>
          <div className=" col-lg-1 ">
          </div>
          <div className="col-2 col-md-2 col-lg-1 mt-2 pt-2">
            <label className='label-field '>father name:</label>
          </div>
          <div className="col-3 col-md-3 col-lg-3 ">
            <div class="text-field">
              <input
                type="text"
                name='FatherName'
                value={value.FatherName}
                className='requiredColor'
                id='fathername'
                onChange={handlChange}
                required
              />
            </div>
            {errors.FatherNameErrors !== 'true' ? (
              <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.FatherNameErrors}</span>
            ) : null}
          </div>
          <div className="col-1 col-md-1 col-lg-3 mt-2 pt-2">
          </div>
          <div className="col-2 col-md-2 col-lg-1 mt-2 pt-2">
            <label className='label-field '>department:</label>
          </div>
          <div className="col-4 col-md-4 col-lg-3 d-flex px-0">
            <div className="col-11 col-md-11 col-lg-11 ">
              <div className="dropdown__box">
                <Select
                  name='DepartmentID'
                  styles={colourStyles}
                  value={departmentData?.filter((obj) => obj?.value === value?.DepartmentID)}
                  options={departmentData}
                  isClearable
                  placeholder="Select.."
                  onChange={(e) => handleDropdown(e, 'DepartmentID')}
                />
              </div>
              {errors.DepartmentErrors !== 'true' ? (
                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DepartmentErrors}</span>
              ) : null}
            </div>
            <div className="col-1 col-md-1 col-lg-1  px-0 py-0" style={{ marginTop: '14px' }}>
              <Link to="" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal" onClick={() => setUrls('ID', 'Department', 'Department')}>
                <i className="fa fa-plus "></i>
              </Link>
            </div>
          </div>
          <div className=" col-lg-1 ">
          </div>
          <div className="col-2 col-md-2 col-lg-1 mt-2 pt-2">
            <label className='label-field '>Remarks:</label>
          </div>
          <div className="col-10  col-md-10 col-lg-3" >
            <div className="dropdown__box">
              <textarea name='Remarks' value={value.Remarks} id="Remarks" cols="30" rows='1' className="form-control" onChange={handlChange} ></textarea>
            </div>
          </div>
          <div className="  col-lg-3 ">
          </div>
          <div className="col-2 col-md-2 col-lg-1 mt-2 pt-2">
            <label className='label-field '>status:</label>
          </div>
          <div className="col-4 col-md-3 col-lg-3 ">
            <div class="text-field">
              <input
                type="text"
                name='fathername'
                className=""
                id='fathername'
                required
              />
            </div>
          </div>
          <div className="col-4 col-md-3 col-lg-2 mt-3">
            <div class="form-check ">
              <input class="form-check-input" name='Common' type="checkbox" id="flexCheckDefault" />
              <label class="form-check-label" for="flexCheckDefault">
                Common
              </label>
            </div>
          </div>
          <div className="col-12 col-md-3 col-lg-2 mt-3">
            <div class="form-check ">
              <input class="form-check-input" name='Operator' type="checkbox" id="flexCheckDefault1" />
              <label class="form-check-label" for="flexCheckDefault1">
                Is Operator
              </label>
            </div>
          </div>


          {/* {
            operator &&
            <>
              <div className="col-1 col-md-2 col-lg-1 mt-2 pt-2">
                <label className='label-field '>login:</label>
              </div>
              <div className="col-4 col-md-3 col-lg-2 ">
                <div class="text-field">
                  <input
                    type="text"
                    name='login'
                    className=""
                    id='login'
                    required
                  />
                </div>
              </div>
              <div className="col-2 col-md-2 col-lg-1 mt-2 pt-2">
                <label className='label-field '>password:</label>
              </div>
              <div className="col-4 col-md-3 col-lg-2 ">
                <div class="text-field">
                  <input
                    type="text"
                    name='password'
                    className=""
                    id='password'
                    required
                  />
                </div>
              </div>

            </>
          } */}
          <div className="btn-box text-end col-12 col-md-12 col-lg-12 ">
            {pageStatus ?
              <button type="button" className="btn btn-sm btn-success mr-1" onClick={setNewStatus} >New</button>
              : ''}

            {
              pageStatus ?
                <button type="button" className="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button>
                :
                <button type="button" className="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Save</button>
            }
            <Link to={openPage === 'Attendance' ? '/HR?sub=Attendance%20Report' : '/AdminSetting?sub=Operator'}>
              <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { resetHooks(); if(openPage === 'Attendance') dispatch(get_Employee_Attendance_Data(attendanceMonth, attendanceYear.getFullYear())) }}>Close</button>
            </Link>
          </div>
        </div>
      </div>
      {
        urlName &&
        <MasSideBar
          showCol1={showCol} idName={idName}
          getUrl={`/${urlName}/GetData_${urlName}`}
          addUrl={`/${urlName}/Insert${urlName}`}
          singleDataUrl={`/${urlName}/GetSingleData_${urlName}`}
          upUrl={`/${urlName}/Update${urlName}`}
          delUrl={`/${urlName}/Delete${urlName}`}
        />
      }
    </>
  )
}

export default Home