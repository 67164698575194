import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import ApproveRequest_Add_Up from './ApproveRequest_Add_Up';
import { get_Approve_Request_Data } from '../../../../redux/actions/AllAction';
import { Edit_Update_Count, Get_Approve_Request, Get_Req_Id, MasterTable_Modal_Status, Modal_Status } from '../../../../redux/actionTypes';
import { useState } from 'react';
import Loader from '../../../Include/Common/Loader';

const ApproveRequest = () => {
    const dispatch = useDispatch()
    const approveRequest = useSelector((state) => state.Data.approveRequest)
    const pageLoader = useSelector((state) => state.Data.pageLoader)
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const [pageStatus, setPageStatus] = useState('1')
    const [status, setStatus] = useState(true)
    const [id, setId] = useState('');

    useEffect(() => {
        dispatch(get_Approve_Request_Data(pageStatus))
    }, [])

    const columns = [
        {
            name: 'Request Date',
            selector: (row) => row.RequestDate,
            sortable: true,
        },
        {
            name: 'Request By',
            selector: (row) => row.Requestby,
            sortable: true,
        },
        {
            name: 'Item Code',
            selector: (row) => row.Product,
            sortable: true,
        },
        {
            name: 'Req Qty',
            selector: (row) => row.ReqQty,
            sortable: true,
        },
        {
            name: <p className='text-end text-dark' style={{ position: 'absolute', top: '7px', fontWeight: '700' }}>Action</p>,
            cell: row => <>
                <Link to={'#'} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                    <i className="fa fa-eye"></i>
                </Link>
                <Link to={'/Inventory?page=Approve Request'} onClick={(e) => { setApprovedStatus(row); setId(row.ID1) }}data-toggle="modal" data-target="#AddApproveModal" className="col-6 btn btn-sm bg-green text-white px-1 py-0 mr-1">
                    Approved
                </Link>
                <Link to={'/Inventory?page=Approve Request'} onClick={(e) => { setRejectStatus(row); setId(row.ID1) }} data-toggle="modal" data-target="#AddApproveModal" className=" col-4  btn btn-sm bg-danger text-white px-1 py-0 mr-1">
                    Reject
                </Link>

            </>
        }
    ];
    const setApprovedStatus = (row) => {
        dispatch(get_Approve_Request_Data(row.ID1));
        dispatch({ type: Get_Req_Id, payload: row.ID1})
        setStatus(true);
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: Modal_Status, payload: true })
    }
    const setRejectStatus = (row) => {
        dispatch(get_Approve_Request_Data(row.ID1));
        dispatch({ type: Get_Req_Id, payload: row.ID1 });
        setStatus(false)
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: Modal_Status, payload: true })
    }
    return (
        <>
            <div className="col-12 col-md-12 px-3 mb-1" >
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Approved Request</p>
                    {/* <p className="p-0 m-0">
                        <Link to={'#'} data-toggle="modal" data-target="#" className="btn btn-sm bg-green text-white px-2 py-0" >
                            <i className="fa fa-plus"></i>
                        </Link>
                    </p> */}
                </div>
            </div>
            <div className="col-12 col-md-12 mb-2 px-3" >
            {pageLoader ? 
                <DataTable
                    dense
                    columns={columns}
                    data={approveRequest}
                    pagination
                    selectableRowsHighlight
                    highlightOnHover
                />
                : <Loader /> }
            </div>
          <ApproveRequest_Add_Up {...{status}} /> 
        </>
    )
}

export default ApproveRequest