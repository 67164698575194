import React, { useState } from 'react'
import { VehicleDetailsTabs } from '../../Utility/TabsArray';
import SubTab from '../../Utility/SubTab';
import Home from './VehicleDetailsFormTab/Home/Home';

const VehicleDetailsTab = () => {
    const [showPage, setShowPage] = useState('home');
    const [status, setStatus] = useState()
    return (
        <>
            <div className="col-12 col-sm-12">
                <div className="card Agency">
                    <div className="card-body">
                        <div className="row ">
                            {
                                showPage === 'home' ?
                                    <Home />
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default VehicleDetailsTab