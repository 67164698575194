import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import PowerConsumption_Add_Up from './PowerConsumption_Add_Up'
import { Dropdown } from 'react-bootstrap'
import { Edit_Update_Count, Get_Consumption_Power_Filter_Data, Get_Single_Consumption_Power_Data, Modal_Status } from '../../../../redux/actionTypes'
import { Three_Search_Filter } from '../../../Filter/Filter'
import { delete_Consumption_Power_Data, get_Consumption_Power_Data, get_Single_Consumption_Power_Data } from '../../../../redux/actions/AllAction'
import ConfirmModal from '../../../Include/Common/ConfirmModal'
import Loader from '../../../Include/Common/Loader'

const PowerConsuption = () => {
    const dispatch = useDispatch()
    const [status, setStatus] = useState(false)
    const pageLoader = (useSelector((state) => state.Data.pageLoader))
    const modalStatus = useSelector((state) => state.Data.modalStatus)
    const userId = (useSelector((state) => state?.Data?.userId))
    const editUpdateCount = (useSelector((state) => state.Data.editUpdateCount))
    const consumptionData = (useSelector((state) => state.Data.consumptionData))
    const consumptionFilterData = (useSelector((state) => state.Data.consumptionFilterData))
    const [pageStatus, setPageStatus] = useState("1")
    const [confirmType, setConfirmType] = useState('Delete')
    const [IsActive, setIsActive] = useState()
    const [id, setId] = useState('')
    const [filterTypeIdOption, setfilterTypeIdOption] = useState('Contains');
    const [filterTypeDescOption, setfilterTypeDescOption] = useState('Contains');
    const [filterTypeAddOption, setfilterTypeAddOption] = useState('Contains');
    const [searchValue1, setSearchValue1] = useState('')
    const [searchValue2, setSearchValue2] = useState('')
    const [searchValue3, setSearchValue3] = useState('')

    useEffect(() => {
        dispatch(get_Consumption_Power_Data(pageStatus))
    }, [pageStatus]);

    const columns = [
        {
            name: 'Date',
            selector: (row) => row.ConsumptionDate,
            sortable: true
        },
        {
            name: 'Direct Power Units',
            selector: (row) => row.PlantDirectUnit1,
            sortable: true
        },
        {
            name: 'DG Power Units',
            selector: (row) => row.PlantDGUnit1,
            sortable: true
        },

        {
            name: 'Total Power Units',
            selector: (row) => row.PlantTotalUnit1,
            sortable: true
        },
        {
            name: 'MD Recorded',
            selector: (row) => row.PlantMDRecord1,
            sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 55, fontWeight: '700' }}>Action</p>,
            cell: row => <>
                <div style={{ position: 'absolute', top: 4, right: 40 }}>
                    {pageStatus === '1' ? <Link to='/Plant-Transport?sub=Power%20Consumption'
                        className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn" onClick={() => setEditStatus(row)} data-toggle="modal" data-target="#PowerConsumptionModal"><i className="fa fa-edit"></i>
                    </Link> : ''}
                    <Link to='/Plant-Transport?sub=Power%20Consumption' data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('0');  setId(row.ID) }}
                        className="btn btn-sm bg-green text-white px-1 py-0 mr-1" >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </Link>
                </div>
            </>
        }
    ]

    const setEditStatus = (row) => {
        // dispatch(get_Single_Consumption_Power_Data(row.ID))
        dispatch({ type: Get_Single_Consumption_Power_Data, payload: [row]})
        setStatus(true);
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: Modal_Status, payload: true });
    }

    const newStatus = () => {
        dispatch({ type: Get_Single_Consumption_Power_Data, payload: [] }); setId('');
        setStatus(false);
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: Modal_Status, payload: true });
    }

    const UpdActiveDeactive = () => {
        dispatch(delete_Consumption_Power_Data(id, IsActive, userId, pageStatus));
    }

    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Power Consumption</p>
                    <div style={{ marginLeft: 'auto' }}>
                        <Link to={'/Plant-Transport?sub=Power%20Consumption'} onClick={newStatus} className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#PowerConsumptionModal">
                            <i className="fa fa-plus"></i>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-2 px-3">
                <div className="row">
                    <div className="col-3">
                        <input type="text" value={searchValue1} onChange={(e) => {
                            setSearchValue1(e.target.value);
                            const result = Three_Search_Filter(consumptionData, e.target.value, searchValue2, searchValue3, filterTypeIdOption, 'PlantDirectUnit1', 'PlantDGUnit1', 'PlantTotalUnit1')
                            dispatch({ type: Get_Consumption_Power_Filter_Data, payload: result });
                        }}
                            className='form-control' placeholder='Search By Plant Power Units...' />
                    </div>
                    <div className='col-1 '>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('End with')}>End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="col-3">
                        <input type="text" value={searchValue2} onChange={(e) => {
                            setSearchValue2(e.target.value);
                            const result = Three_Search_Filter(consumptionData, searchValue1, e.target.value, searchValue3, filterTypeAddOption, 'PlantDirectUnit1', 'PlantDGUnit1', 'PlantTotalUnit1')
                            dispatch({ type: Get_Consumption_Power_Filter_Data, payload: result });
                        }}
                            className='form-control' placeholder='Search By Plant DG Unit...' />
                    </div>
                    <div className='col-1 '>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('Contains')}>Contains</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('is equal to')}>is equal to</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('is not equal to')}>is not equal to </Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('Starts With')}>Starts With</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('End with')}>End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="col-3">
                        <input type="text" value={searchValue3} onChange={(e) => {
                            setSearchValue3(e.target.value);
                            const result = Three_Search_Filter(consumptionData, searchValue1, searchValue2, e.target.value, filterTypeDescOption, 'PlantDirectUnit1', 'PlantDGUnit1', 'PlantTotalUnit1')
                            dispatch({ type: Get_Consumption_Power_Filter_Data, payload: result });
                        }}
                            className='form-control' placeholder='Search By Plant Power Unit....' />
                    </div>
                    <div className='col-1 '>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('End with')}>End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="col-12 pt-2 px-3 ">
                {pageLoader ?  <DataTable
                    columns={columns}
                    data={consumptionFilterData}
                    dense
                    pagination
                    selectableRowsHighlight
                    highlightOnHover
                /> : <Loader/>}
            </div>
            {modalStatus && <PowerConsumption_Add_Up {...{ status, pageStatus }} />}
            <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType}></ConfirmModal>
        </>
    )
}

export default PowerConsuption