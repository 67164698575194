import React, { useCallback, useEffect, useState } from 'react'
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SpaceNotAllowWithoutReq, SpaceNotAllow } from "../../../Validation/Validation";
import { get_Product_Dropdown, get_Unit_Dropdown, save_Product_Data, update_Product_Data } from '../../../../redux/actions/AllAction';
import { toastifyError } from '../../../Include/Common/AlertMsg';

const Product_Add_Up = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { status, pageStatus } = props
    const productDropdown = useSelector((state) => state?.Data?.productDropdown)
    const userId = useSelector((state) => state?.Data?.userId)
    const masterTableModalStatus = useSelector((state) => state.Data.masterTableModalStatus)
    const unitDropdown = useSelector((state) => state?.Data?.unitDropdown)
    const singleproductData = useSelector((state) => state?.Data?.singleproductData[0])
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    const [value, setValue] = useState({
        Productgroupid: "",
        ProductName: "",
        Rate: "",
        UnitTypeid: "",
        Inchallan: 0,
        IsReuse: 0,
        IsPurchase: 0,
        RoyaltyRate: "",
        DMGProduct: "",
        GSTRate: "",
        ProductCode: "",
        ID: "",
        Updatedbyid: ''
    })
    const [errors, setErrors] = useState({
        'ProductError': '', 'RateError': '', 'RoyaltyRateError': ''
    });
    const { ProductError, RateError, RoyaltyRateError } = errors;
    useEffect(() => {
        if (ProductError === "true" && RateError === "true" && RoyaltyRateError === "true") {
            if (status) {
                dispatch(update_Product_Data(value, pageStatus)); setErrors({ ['ProductError']: '' });
            }
            else {
                dispatch(save_Product_Data(value, pageStatus)); setErrors({ ['ProductError']: '' });
            }
        }
    }, [ProductError, RateError, RoyaltyRateError])
    useEffect(() => {
        if (singleproductData) {
            setValue({
                ...value,
                Productgroupid: singleproductData?.Productgroupid,
                ProductName: singleproductData?.ProductName,
                Rate: singleproductData?.Rate,
                UnitTypeid: singleproductData?.UnitTypeid,
                Inchallan: singleproductData?.Inchallan,
                IsReuse: '0',
                IsPurchase: '0',
                RoyaltyRate: singleproductData?.RoyaltyRate,
                DMGProduct: '',
                GSTRate: '',
                ProductCode: '',
                ID: singleproductData.ID,
                Updatedbyid: userId
            })
        }
        else {
            setValue({
                ...value,
                Productgroupid: "",
                ProductName: "",
                Rate: "",
                UnitTypeid: "",
                Inchallan: 0,
                IsReuse: 0,
                IsPurchase: 0,
                RoyaltyRate: "",
                DMGProduct: "",
                GSTRate: "",
                ProductCode: "",
                ID: '',
                Updatedbyid: ''

            })
        }
    }, [singleproductData, editUpdateCount])
    const check_Validation_Error = (e) => {
        e.preventDefault()
        const { ProductName, Rate, RoyaltyRate, } = value
        if (ProductName || Rate || RoyaltyRate) {
            if (SpaceNotAllowWithoutReq(value.ProductName)) {
                setErrors((prevValues) => { return { ...prevValues, ["ProductError"]: SpaceNotAllowWithoutReq(value.ProductName) } });
            }
            if (SpaceNotAllowWithoutReq(value.Rate)) {
                setErrors((prevValues) => { return { ...prevValues, ["RateError"]: SpaceNotAllowWithoutReq(value.Rate) } });
            }
            if (SpaceNotAllowWithoutReq(value.RoyaltyRate)) {
                setErrors((prevValues) => { return { ...prevValues, ["RoyaltyRateError"]: SpaceNotAllowWithoutReq(value.RoyaltyRate) } });
            }
        } else toastifyError('Empty Field')
    }
    const ChangeDropDown = (e, Name) => {
        if (e) {
            setValue({
                ...value,
                [Name]: e.value
            })
        } else setValue({
            ...value,
            [Name]: null
        })
    }
    const handelChange = (e) => {
        if (e.target.name === 'Inchallan') {
            setValue({
                ...value,
                [e.target.name]: e.target.checked ? 1 : 0
            });
        }
        else {
            setValue({
                ...value,
                [e.target.name]: e.target.value,
            });
        }

    }
    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            close_modal()
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);
    useEffect(() => {
        if (pageStatus) close_modal()
    }, [pageStatus])

    const close_modal = () => {
        setValue({
            ...value,
            Productgroupid: '',
            ProductName: '',
            Rate: '',
            UnitTypeid: '',
            Inchallan: 0,
            IsReuse: 0,
            IsPurchase: 0,
            RoyaltyRate: '',
            DMGProduct: '',
            GSTRate: '',
            ProductCode: '',
            ID: '',
        });
        setErrors({
            'ProductError': '', 'RateError': '', 'RoyaltyRateError': ''
        })

    }
    useEffect(() => {
        if (productDropdown.length === 0) dispatch(get_Product_Dropdown(value));
        if (productDropdown.length === 0) dispatch(get_Unit_Dropdown(value));
    }, [])
    return (
        <>
            {masterTableModalStatus && <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="ProductModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="m-1 ">
                                <fieldset style={{ border: '1px solid gray' }}>
                                    <legend >Product</legend>
                                    <div className="form-row" style={{ marginTop: '-18px' }}>
                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div className="dropdown__box" >
                                                <Select
                                                    name='Productgroupid'
                                                    styles={customStylesWithOutColor}
                                                    isClearable
                                                    options={productDropdown}
                                                    onChange={(e) => ChangeDropDown(e, 'Productgroupid')}
                                                    value={productDropdown?.filter((obj) => obj.value === value?.Productgroupid
                                                    )}
                                                />
                                                <label htmlFor='' >Product Group</label>

                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div className="dropdown__box">
                                                <Select
                                                    name='UnitTypeid'
                                                    styles={customStylesWithOutColor}
                                                    isClearable
                                                    options={unitDropdown}
                                                    onChange={(e) => ChangeDropDown(e, 'UnitTypeid')}
                                                    value={unitDropdown?.filter((obj) => obj.value === value?.UnitTypeid
                                                    )}
                                                    placeholder="Select.."
                                                />
                                                <label >Unit Type</label>

                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='ProductName'
                                                    className=""
                                                    id='ProductName'
                                                    required
                                                    value={value.ProductName}
                                                    onChange={handelChange}
                                                />
                                                {errors.ProductError !== "true" ? (
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            fontSize: "13px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                        }}
                                                    >
                                                        {errors.ProductError}
                                                    </span>
                                                ) : null}
                                                <label className='pt-1'>Product</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='Rate'
                                                    className=""
                                                    id='Rate'
                                                    required
                                                    value={value.Rate}
                                                    onChange={handelChange}
                                                />
                                                {errors.RateError !== "true" ? (
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            fontSize: "13px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                        }}
                                                    >
                                                        {errors.RateError}
                                                    </span>
                                                ) : null}
                                                <label className='pt-1'>Rate</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='billrate'
                                                    className=""
                                                    id='billrate'
                                                    required
                                                    // value={value.Rate}
                                                    onChange={handelChange}
                                                />
                                                <label className='pt-1'>Bill Rate</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='RoyaltyRate'
                                                    className=""
                                                    id='RoyaltyRate'
                                                    required
                                                    value={value.RoyaltyRate}
                                                    onChange={handelChange}
                                                />
                                                {errors.RoyaltyRateError !== "true" ? (
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            fontSize: "13px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                        }}
                                                    >
                                                        {errors.RoyaltyRateError}
                                                    </span>
                                                ) : null}
                                                <label className='pt-1'>Royality Rate</label>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-6 col-lg-6 mt-3">
                                            <div class="form-check ">
                                                <input class="form-check-input" name='Inchallan' checked={value.Inchallan}
                                                    value={value.Inchallan}
                                                    id="Inchallan"
                                                    onChange={handelChange} type="checkbox" />
                                                <label class="form-check-label" for="Inchallan">
                                                    In Challan
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="btn-box text-right  mr-1 mb-2">
                            {
                                status ?
                                    <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button>
                                    :
                                    <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Save</button>
                            }
                            <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={close_modal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default Product_Add_Up