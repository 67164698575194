import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import DataTable from "react-data-table-component";
import { Email_Field, SpaceNotAllow, SpaceNotAllowWithoutReq } from "../../../../../Validation/Validation";
import { get_City, get_Party_Sale_Data, get_Single_Party_Data, get_State, save_Party_Sale, upadate_Party_Sale } from "../../../../../../redux/actions/AllAction";
import { Contact_city, Get_Error_Status, Get_Single_Party_Data, Party_City } from "../../../../../../redux/actionTypes";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ID = useSelector((state) => state.Data.ID)
  const pageStatus = useSelector((state) => state?.Data?.pageStatus)
  const partySingleData = useSelector((state) => state?.Data?.partySingleData)
  const errorStatus = useSelector((state) => state?.Data?.errorStatus)
  const editUpdateCount = useSelector((state) => state?.Data?.editUpdateCount)
  const ContactCity = useSelector((state) => state?.Data?.contactCity)
  const State = useSelector((state) => state?.Data?.State)
  const City = useSelector((state) => state?.Data?.City)
  const userId = useSelector((state) => state.Data.userId)

  const [value, setValue] = useState({
    Name: '',
    FirmName: '',
    AliasName: '',
    Address: '',
    IsLedger: '',
    IsEmployee: 0,
    Iscommon: 0,
    IsUnRegular: 0,
    IsSupplier: 0,
    Iscredit: 0,
    IsSale: 0,
    Ispurchase: 0,
    Isvehicle: 0,
    GSTNo: '',
    MobileNo: '',
    PhoneNo: '',
    Email: '',
    FaxNo: '',
    PanNo: '',
    Creditlimit: '',
    PStateName: '',
    PStateid: '',
    PDistrictName: '',
    PDistrictID: '',
    Createdbyid: '',
    ContactPersonName: '',
    ContactAddress: '',
    ContactPin: '',
    ContactPhoneNo: '',
    ContactMobileNo: '',
    ContactEmailID: '',
    ContactState: '',
    ContactCity: '',
    ContactAdhar: '',
    AdharNo: '',
    Updatedbyid: ''
  });

  //   useEffect(() => {
  //     if (pageStatus) reset()
  // }, [pageStatus])

  const handleDropDownChange = (e, type, field) => {
    if (e) {
      if (type === 'PStateName') {
        setValue({
          ...value,
          [type]: e.label, [field]: e.value,
        });
        dispatch(get_City(e.value, 'Firm'))
      }
      else if (type === 'PDistrictName') {
        setValue({
          ...value,
          ['PDistractName']: e.label, ['PDistrictID']: e.value
        });
      }
    } else {
      if (type === 'PStateName') {
        setValue({
          ...value,
          ['PStateName']: null, ['PStateid']: null
        });
        dispatch({ type: Party_City, payload: [] })

      }

    }
  }

  const handelStateChange = (e, type) => {
    if (e) {
      if (type === 'ContactState') {
        setValue({
          ...value,
          ['ContactState']: e.label
        });
        dispatch(get_City(e.value, 'Contact'))
      }
      else if (type === 'ContactCity') {
        setValue({
          ...value,
          ['ContactCity']: e.label
        });
      }
    } else {
      if (type === 'ContactState') {
        setValue({
          ...value,
          ['ContactState']: null
        });
        dispatch({ type: Contact_city, payload: [] })

      }

    }
  }

  //   const escFunction = useCallback((event) => {
  //     if (event.key === "Escape") {
  //         reset()
  //     }
  // }, []);
  // useEffect(() => {
  //   document.addEventListener("keydown", escFunction, false);
  //   return () => {
  //       document.removeEventListener("keydown", escFunction, false);
  //   };
  // }, [escFunction]);


  useEffect(() => {
    if (State?.length === 0) dispatch(get_State(value))
  }, [])

  useEffect(() => {
    if (partySingleData) {
      setValue({
        Name: partySingleData.Name,
        FirmName: partySingleData.FirmName,
        AliasName: partySingleData.AliasName,
        Address: partySingleData.Address,
        IsLedger: 0,
        IsEmployee: partySingleData.IsEmployee,
        Iscommon: partySingleData.Iscommon,
        IsUnRegular: 0,
        IsSupplier: partySingleData.IsSupplier,
        Iscredit: partySingleData.Iscredit,
        IsSale: partySingleData.IsSale,
        Ispurchase: partySingleData.Ispurchase,
        Isvehicle: partySingleData.Isvehicle,
        GSTNo: partySingleData.GSTNo,
        MobileNo: partySingleData.MobileNo,
        PhoneNo: partySingleData.PhoneNo,
        Email: partySingleData.Email,
        FaxNo: partySingleData.FaxNo,
        PanNo: partySingleData.PanNo,
        Creditlimit: "",
        PStateName: partySingleData.PStateName,
        PStateid: partySingleData.PStateID,
        PDistrictName: partySingleData.PDistrictName,
        PDistrictID: partySingleData.PDistrictID,
        Createdbyid: "",
        ContactPersonName: partySingleData.ContactPersonName,
        ContactAddress: partySingleData.ContactAddress,
        ContactPin: partySingleData.ContactPIN,
        ContactPhoneNo: partySingleData.ContactPhoneNo,
        ContactMobileNo: partySingleData.ContactMobileNo,
        ContactEmailID: partySingleData.ContactEmailID,
        ContactState: partySingleData.ContactState,
        ContactCity: partySingleData.ContactCity,
        ContactAdhar: partySingleData.ContactAdhar,
        AdharNo: partySingleData.AdharNo,
        Id: partySingleData.Id,
        Updatedbyid: userId
      })
    }
    else {
      setValue({
        Name: "",
        FirmName: "",
        AliasName: "",
        Address: "",
        IsLedger: 0,
        IsEmployee: 0,
        Iscommon: 0,
        IsUnRegular: 0,
        IsSupplier: 0,
        Iscredit: 0,
        IsSale: 0,
        Ispurchase: 0,
        Isvehicle: 0,
        GSTNo: "",
        MobileNo: "",
        PhoneNo: "",
        Email: "",
        FaxNo: "",
        PanNo: "",
        Creditlimit: "",
        PStateName: "",
        PStateid: "",
        PDistrictName: "",
        PDistrictID: "",
        Createdbyid: "",
        ContactPersonName: "",
        ContactAddress: "",
        ContactPin: "",
        ContactPhoneNo: "",
        ContactMobileNo: "",
        ContactEmailID: "",
        ContactState: "",
        ContactCity: "",
        ContactAdhar: "",
        AdharNo: "",
        Id: '',
        Updatedbyid: ''
      })
    }
  }, [partySingleData, editUpdateCount])

  const customStylesWithOutColor = {
    control: (base) => ({
      ...base,
      height: 31,
      minHeight: 30,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
      fontWeight: 500,
    }),
  };

  const [errors, setErrors] = useState({
    NameError: "", AlliasError: "", FirmError: "", AccountingError: "", GstNoError: "",
    PanNoError: "", EmailError: "", PhoneError: "", MobileError: "", FaxnoError: "",
    AddressError: "", StateError: "", CityError: "", contactNameError: "",
    contactAddressError: "", contactPhoneNoError: "", contactMobileNoError: "", contactPinError: "",
  });

  const { NameError, AlliasError, FirmError, GstNoError, PanNoError, PhoneError, MobileError,
    FaxnoError, AddressError, contactNameError, contactAddressError, contactPhoneNoError,
    contactMobileNoError, contactPinError } = errors;

  const check_Validation_Error = () => {
    if (SpaceNotAllow(value.Name)) {
      setErrors((prevValues) => { return { ...prevValues, ["NameError"]: SpaceNotAllow(value.Name) } });
      dispatch({ type: Get_Error_Status, payload: true });
    }
    if (SpaceNotAllowWithoutReq(value.AliasName)) {
      setErrors((prevValues) => { return { ...prevValues, ["AlliasError"]: SpaceNotAllowWithoutReq(value.AliasName) } });
    }
    if (SpaceNotAllowWithoutReq(value.FirmName)) {
      setErrors((prevValues) => { return { ...prevValues, ["FirmError"]: SpaceNotAllowWithoutReq(value.FirmName) } });
    }
    if (SpaceNotAllowWithoutReq(value.GSTNo)) {
      setErrors((prevValues) => { return { ...prevValues, ["GstNoError"]: SpaceNotAllowWithoutReq(value.GSTNo) } });
    }
    if (SpaceNotAllowWithoutReq(value.PanNo)) {
      setErrors((prevValues) => { return { ...prevValues, ["PanNoError"]: SpaceNotAllowWithoutReq(value.PanNo) } });
    }
    if (SpaceNotAllowWithoutReq(value.Address)) {
      setErrors((prevValues) => { return { ...prevValues, ["AddressError"]: SpaceNotAllowWithoutReq(value.Address) } });
    }
    if (SpaceNotAllowWithoutReq(value.PhoneNo)) {
      setErrors((prevValues) => { return { ...prevValues, ["PhoneError"]: SpaceNotAllowWithoutReq(value.PhoneNo) } });
    }
    if (SpaceNotAllowWithoutReq(value.MobileNo)) {
      setErrors((prevValues) => { return { ...prevValues, ["MobileError"]: SpaceNotAllowWithoutReq(value.MobileNo) } });
    }
    if (SpaceNotAllowWithoutReq(value.FaxNo)) {
      setErrors((prevValues) => { return { ...prevValues, ["FaxnoError"]: SpaceNotAllowWithoutReq(value.FaxNo) } });
    }
    if (SpaceNotAllowWithoutReq(value.ContactPersonName)) {
      setErrors((prevValues) => { return { ...prevValues, ["contactNameError"]: SpaceNotAllowWithoutReq(value.ContactPersonName) } });
    }
    if (SpaceNotAllowWithoutReq(value.ContactAddress)) {
      setErrors((prevValues) => { return { ...prevValues, ["contactAddressError"]: SpaceNotAllowWithoutReq(value.ContactAddress) } });
    }
    if (SpaceNotAllowWithoutReq(value.ContactMobileNo)) {
      setErrors((prevValues) => { return { ...prevValues, ["contactMobileNoError"]: SpaceNotAllowWithoutReq(value.ContactMobileNo) } });
    }
    if (SpaceNotAllowWithoutReq(value.ContactPhoneNo)) {
      setErrors((prevValues) => { return { ...prevValues, ["contactPhoneNoError"]: SpaceNotAllowWithoutReq(value.ContactPhoneNo) } });
    }
    if (SpaceNotAllowWithoutReq(value.ContactPin)) {
      setErrors((prevValues) => { return { ...prevValues, ["contactPinError"]: SpaceNotAllowWithoutReq(value.ContactPin) } });
    }
  };

  const handlChange = (e) => {
    if (e.target.name === 'Iscredit' || e.target.name === 'IsSupplier' || e.target.name === 'Iscommon' || e.target.name === 'IsSale' || e.target.name === 'Ispurchase' || e.target.name === 'Isvehicle' || e.target.name === 'IsEmployee') {
      setValue({
        ...value,
        [e.target.name]: e.target.checked ? 1 : 0
      })

    } else if (e.target.name === 'PhoneNo' || e.target.name === 'ContactPin' || e.target.name === 'AdharNo' || e.target.name === 'ContactAdhar' || e.target.name === 'MobileNo' || e.target.name === 'FaxNo' || e.target.name === 'PanNo' || e.target.name === 'ContactMobileNo' || e.target.name === 'ContactPhoneNo' || e.target.name === 'ContactPinNo') {
      const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
      setValue({
        ...value,
        [e.target.name]: checkNumber
      })
    } else {
      setValue({
        ...value,
        [e.target.name]: e.target.value,
      });
    }

  };

  useEffect(() => {
    if (NameError === "true" && AlliasError === "true" && FirmError === "true" && GstNoError === "true" && PanNoError === "true" && PhoneError === "true" && MobileError === "true" && FaxnoError === "true" && AddressError === "true" && contactNameError === "true" && contactAddressError === "true" && contactPhoneNoError === "true" && contactMobileNoError === "true" && contactPinError === "true") {
      if (pageStatus) { dispatch(upadate_Party_Sale({ value, NavigateUrl })); setErrors({ 'NameError': '' }) }
      else {
        dispatch(save_Party_Sale({ value, NavigateUrl })); setErrors({ 'NameError': '' })
      }
    }
  }, [NameError, AlliasError, FirmError, GstNoError, PanNoError, PhoneError, MobileError, FaxnoError, AddressError, contactNameError, contactAddressError, contactPhoneNoError, contactMobileNoError, contactPinError])

  const NavigateUrl = () => {
    navigate("/MasterTables?sub=Ledger")
  }
  const reset = () => {
    dispatch({ type: Get_Single_Party_Data, payload: [] });
    setValue({
      ...value,
      Name: "",
      FirmName: "",
      AliasName: "",
      Address: "",
      IsLedger: "",
      IsEmployee: 0,
      Iscommon: 0,
      IsUnRegular: "",
      IsSupplier: 0,
      Iscredit: 0,
      IsSale: 0,
      Ispurchase: 0,
      Isvehicle: 0,
      GSTNo: "",
      MobileNo: "",
      PhoneNo: "",
      Email: "",
      FaxNo: "",
      PanNo: "",
      Creditlimit: "",
      PStateName: "",
      PStateid: "",
      PDistrictName: "",
      PDistrictID: "",
      Createdbyid: "",
      ContactPersonName: "",
      ContactAddress: "",
      ContactPin: "",
      ContactPhoneNo: "",
      ContactMobileNo: "",
      ContactEmailID: "",
      ContactState: "",
      ContactCity: "",
      ContactAdhar: "",
      AdharNo: "",
      Id: ''
    })
    setErrors({
      NameError: "",
      AlliasError: "",
      FirmError: "",
      AccountingError: "",
      GstNoError: "",
      PanNoError: "",
      EmailError: "",
      PhoneError: "",
      MobileError: "",
      FaxnoError: "",
      AddressError: "",
      StateError: "",
      CityError: "",
      contactNameError: "",
      contactAddressError: "",
      contactPhoneNoError: "",
      contactMobileNoError: "",
      contactPinError: "",
    })
  }

  return (

    <>
      {/* firm details */}
      <div className="col-12">
        <div className="form-row">
          {/* firm details */}
          <div className="col-12 col-md-12 col-lg-6 pt-1 px-2">
            <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
              <p className="p-0 m-0 d-flex align-items-center">Firm Details:</p>
            </div>
            <div className="form-row ">
              <div className="col-2 col-md-2 col-lg-3 mt-2 pt-2">
                <label className="label-field">Ledger Name</label>
                <span className="form-required">*</span>
              </div>
              <div className="col-10 col-md-4 col-lg-9">
                <div class="text-field">
                  <input
                    type="text"
                    name="Name"
                    className="requiredColor"
                    id="Name"
                    required
                    value={value.Name}
                    onChange={handlChange}
                  />
                  {errors.NameError !== "true" ? (
                    <span
                      style={{
                        color: "red",
                        fontSize: "13px",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      {errors.NameError}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-2 col-md-2 col-lg-3 mt-2 pt-2">
                <label className="label-field ">Alias Name:</label>
                {/* <span className="form-required">*</span> */}
              </div>
              <div className="col-10 col-md-4 col-lg-9 ">
                <div class="text-field">
                  <input
                    type="text"
                    name="AliasName"
                    value={value.AliasName}
                    className=""
                    id="AliasName"
                    required
                    onChange={handlChange}
                  />
                  {errors.AlliasError !== "true" ? (
                    <span
                      style={{
                        color: "red",
                        fontSize: "13px",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      {errors.AlliasError}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-2 col-md-2 col-lg-3 mt-2 pt-2">
                <label className="label-field ">Firm Name:</label>
                {/* <span className="form-required">*</span> */}
              </div>
              <div className="col-10 col-md-4 col-lg-9">
                <div class="text-field">
                  <input
                    type="text"
                    name="FirmName"
                    className=""
                    value={value.FirmName}
                    id="FirmName"
                    required
                    onChange={handlChange}
                  />
                  {errors.FirmError !== "true" ? (
                    <span
                      style={{
                        color: "red",
                        fontSize: "13px",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      {errors.FirmError}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-2 col-md-2 col-lg-3 mt-2 pt-2">
                <label className="label-field">Accounting Head</label>
              </div>
              <div className="col-10 col-md-4 col-lg-9">
                <div className="dropdown__box">
                  <Select
                    name="accounting"
                    styles={customStylesWithOutColor}
                    isClearable
                    placeholder="Select.."
                  />
                </div>
              </div>
              <div className="col-2 col-md-2 col-lg-3 mt-2 pt-2">
                <label className='label-field'>Aadhaar Card:</label>
              </div>
              <div className="col-4 col-md-4 col-lg-4">
                <div class="text-field">
                  <input
                    type="text"
                    name='AdharNo'
                    className=""
                    value={value.AdharNo}
                    id='AdharNo'
                    onChange={handlChange}
                    required
                  />
                </div>
              </div>
              <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                <label className="label-field ">Pan No.</label>
              </div>
              <div className="col-4 col-md-4 col-lg-3 ">
                <div class="text-field">
                  <input
                    type="text"
                    name="PanNo"
                    className=""
                    value={value.PanNo}
                    id="PanNo"
                    required
                    onChange={handlChange}
                  />
                  {errors.PanNoError !== "true" ? (
                    <span
                      style={{
                        color: "red",
                        fontSize: "13px",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      {errors.PanNoError}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-2 col-md-2 col-lg-3 mt-2 pt-2">
                <label className="label-field ">GSTIn No.</label>
              </div>
              <div className="col-4 col-md-4 col-lg-4 ">
                <div class="text-field">
                  <input
                    type="text"
                    name="GSTNo"
                    className=""
                    value={value.GSTNo}
                    id="GSTNo"
                    required
                    onChange={handlChange}
                  />
                </div>
                {errors.GstNoError !== "true" ? (
                  <span
                    style={{
                      color: "red",
                      fontSize: "13px",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    {errors.GstNoError}
                  </span>
                ) : null}
              </div>

            </div>

          </div>
          {/* contact details */}
          <div className="col-12 col-md-12 col-lg-6 pt-1 px-3">
            <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
              <p className="p-0 m-0 d-flex align-items-center">
                Contact Details:
              </p>
            </div>
            <div className="form-row px-1">
              <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                <label className="label-field ">Phone No:</label>
              </div>
              <div className="col-4 col-md-4 col-lg-4 ">
                <div class="text-field">
                  <input
                    type="text"
                    name="PhoneNo"
                    className=""
                    id="PhoneNo"
                    value={value.PhoneNo}
                    required
                    onChange={handlChange}
                  />
                  {errors.PhoneError !== "true" ? (
                    <span
                      style={{
                        color: "red",
                        fontSize: "13px",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      {errors.PhoneError}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                <label className="label-field ">Mobile No:</label>
              </div>
              <div className="col-4 col-md-4 col-lg-4 ">
                <div class="text-field">
                  <input
                    type="text"
                    name="MobileNo"
                    className=""
                    value={value.MobileNo}
                    id="MobileNo"
                    required
                    onChange={handlChange}
                  />
                  {errors.MobileError !== "true" ? (
                    <span
                      style={{
                        color: "red",
                        fontSize: "13px",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      {errors.MobileError}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                <label className="label-field ">Fax No:</label>
              </div>
              <div className="col-4 col-md-4 col-lg-4 ">
                <div class="text-field">
                  <input
                    type="text"
                    name="FaxNo"
                    className=""
                    value={value.FaxNo}
                    id="FaxNo"
                    required
                    onChange={handlChange}
                  />
                  {errors.FaxnoError !== "true" ? (
                    <span
                      style={{
                        color: "red",
                        fontSize: "13px",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      {errors.FaxnoError}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                <label className="label-field ">Email Id:</label>
              </div>
              <div className="col-4 col-md-4 col-lg-4">
                <div class="text-field">
                  <input
                    type="text"
                    name="Email"
                    value={value?.Email}
                    className=""
                    id="Email"
                    required
                    onChange={handlChange}
                  />
                  {errors.EmailError !== "true" ? (
                    <span
                      style={{
                        color: "red",
                        fontSize: "13px",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      {errors.EmailError}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                <label className="label-field ">Address:</label>
              </div>
              <div className="col-10  col-md-10 col-lg-10 ">
                <div className="dropdown__box">
                  <textarea
                    name="Address"
                    id="Address"
                    cols="30"
                    rows="1"
                    className="form-control   "
                    value={value.Address}
                    onChange={handlChange}
                  ></textarea>
                </div>
                {errors.AddressError !== "true" ? (
                  <span
                    style={{
                      color: "red",
                      fontSize: "13px",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    {errors.AddressError}
                  </span>
                ) : null}
              </div>
              <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                <label className="label-field">State:</label>
              </div>
              <div className="col-4 col-md-4 col-lg-4 ">
                <div className="dropdown__box">
                  <Select
                    name="PStateName"
                    styles={customStylesWithOutColor}
                    options={State}
                    isClearable
                    placeholder="Select.."
                    onChange={(e) => handleDropDownChange(e, 'PStateName', 'PStateid')}
                    value={State?.filter((obj) => obj.value === value?.PStateid
                    )}
                  />
                </div>
              </div>
              <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                <label className="label-field">City:</label>
              </div>
              <div className="col-4 col-md-4 col-lg-4  ">
                <div className="dropdown__box">
                  <Select
                    name="PDistrictName"
                    styles={customStylesWithOutColor}
                    options={City}
                    isClearable
                    placeholder="Select.."
                    onChange={(e) => handleDropDownChange(e, 'PDistrictName', 'PDistrictID')}
                    value={City?.filter((obj) => obj.value === value?.PDistrictID)}
                  />
                </div>
              </div>
              <div className="col-2 col-md-2 col-lg-2 mt-2 pt-2">
                <label className="label-field">Pin Code:</label>
              </div>
              <div className="col-4 col-md-4 col-lg-4">
                <div class="text-field">
                  <input
                    type="text"
                    name="pincode"
                    className=""
                    id="pincode"
                    // value={value.PanNo}
                    required
                    onChange={handlChange}
                  />
                  {/* {errors.PincodeError !== 'true' ? (
                                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.PincodeError}</span>
                                        ) : null} */}
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="form-row">
          {/* other details */}
          <div className="col-12 col-md-12 col-lg-12 pt-2 px-2">
            <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
              <p className="p-0 m-0 d-flex align-items-center">
                Other Details:
              </p>
            </div>
            <div className="form-row ">
              <div className="col-4 col-md-4 col-lg-3 mt-3">
                <div class="form-check ">
                  <input
                    class="form-check-input"
                    name="Iscredit"
                    type="checkbox"
                    id="Iscredit"
                    checked={value.Iscredit}
                    onChange={handlChange}
                    value={value.Iscredit}
                  />
                  <label class="form-check-label" for="Iscredit">
                    Udhar Party
                  </label>
                </div>
              </div>
              <div className="col-4 col-md-4 col-lg-3 mt-3">
                <div class="form-check ">
                  <input
                    class="form-check-input"
                    name="IsSupplier"
                    type="checkbox"
                    checked={value.IsSupplier}
                    value={value.IsSupplier}
                    id="IsSupplier"
                    onChange={handlChange}
                  />
                  <label class="form-check-label" for="IsSupplier">
                    Supplier
                  </label>
                </div>
              </div>
              <div className="col-4 col-md-4 col-lg-3 mt-3">
                <div class="form-check ">
                  <input
                    class="form-check-input"
                    name="Iscommon"
                    type="checkbox"
                    value={value.Iscommon}
                    checked={value.Iscommon}
                    id="Iscommon"
                    onChange={handlChange}
                  />
                  <label class="form-check-label" for="Iscommon">
                    Common
                  </label>
                </div>
              </div>
              <div className="col-4 col-md-4 col-lg-3 mt-3">
                <div class="form-check ">
                  <input
                    class="form-check-input"
                    name="IsSale"
                    type="checkbox"
                    id="IsSale"
                    checked={value.IsSale}
                    value={value.IsSale}
                    onChange={handlChange}
                  />
                  <label class="form-check-label" for="IsSale">
                    Is Sale
                  </label>
                </div>
              </div>
              <div className="col-4 col-md-4 col-lg-3 mt-3">
                <div class="form-check ">
                  <input
                    class="form-check-input"
                    name="Ispurchase"
                    type="checkbox"
                    id="Ispurchase"
                    checked={value.Ispurchase}
                    value={value.Ispurchase}
                    onChange={handlChange}
                  />
                  <label class="form-check-label" for="Ispurchase">
                    Is Purchase
                  </label>
                </div>
              </div>
              <div className="col-4 col-md-4 col-lg-3 mt-3">
                <div class="form-check ">
                  <input
                    class="form-check-input"
                    name="Isvehicle"
                    type="checkbox"
                    id="Isvehicle"
                    checked={value.Isvehicle}
                    value={value.Isvehicle}
                    onChange={handlChange}
                  />
                  <label class="form-check-label" for="Isvehicle">
                    Is Vehicle
                  </label>
                </div>
              </div>
              <div className="col-4 col-md-6 col-lg-6 mt-3">
                <div class="form-check ">
                  <input
                    class="form-check-input"
                    name="IsEmployee"
                    type="checkbox"
                    id="IsEmployee"
                    checked={value.IsEmployee}
                    onChange={handlChange}
                    value={value.IsEmployee}
                  />
                  <label class="form-check-label" for="IsEmployee">
                    Is Employee
                  </label>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="col-12 col-md-12 col-lg-12 mt-2 ">
          <div className="accordion" id="accordionExample">
            <div className="arrow">
              <div
                className="arrow-header collapsed"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
              >
                <span className="accicon">
                  <i className="fa fa-angle-down rotate-icon mr-3" />
                </span>
                <span class="title ml-2 text-white">Contact Person/Owner</span>
              </div>
            </div>
          </div>
        </div>
        {/* clickable field */}
        <div className="col-12">
          <div
            id="collapseOne"
            className="collapse "
            data-parent="#accordionExample"
          >
            <div className="form-row ">
              <div className="col-6 col-6 col-md-6 col-lg-6">
                <div className="form-row">
                  <div className="col-4 col-md-4 col-lg-3 mt-2 pt-2">
                    <label className='label-field'>Name:</label>
                  </div>
                  <div className="col-7 col-md-8 col-lg-7">
                    <div class="text-field">
                      <input
                        type="text"
                        name="ContactPersonName"
                        className=""
                        id="ContactPersonName"
                        required
                        value={value.ContactPersonName}
                        onChange={handlChange}
                      />
                      {errors.contactNameError !== "true" ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          {errors.contactNameError}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-2 ">
                  </div>
                  <div className="col-4 col-md-4 col-lg-3 mt-2 pt-2">
                    <label className='label-field'>Aadhaar Card:</label>
                  </div>
                  <div className="col-7 col-md-8 col-lg-7">
                    <div class="text-field">
                      <input
                        type="text"
                        name='ContactAdhar'
                        value={value.ContactAdhar}
                        className=""
                        onChange={handlChange}
                        id='ContactAdhar'
                        required
                      />
                    </div>
                  </div>
                  <div className=" col-lg-2 ">
                  </div>
                  <div className="col-4 col-md-4 col-lg-3 mt-2 pt-2">
                    <label className='label-field'>mobile no:</label>
                  </div>
                  <div className="col-7 col-md-8 col-lg-7">
                    <div class="text-field">
                      <input
                        type="text"
                        name="ContactMobileNo"
                        className=""
                        value={value.ContactMobileNo}
                        id="ContactMobileNo"
                        required
                        onChange={handlChange}
                      />
                      {errors.contactMobileNoError !== "true" ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          {errors.contactMobileNoError}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className=" col-lg-2 ">
                  </div>
                  <div className="col-4 col-md-4 col-lg-3 mt-2 pt-2">
                    <label className='label-field'>Phone no:</label>
                  </div>
                  <div className="col-7 col-md-8 col-lg-7">
                    <div class="text-field">
                      <input
                        type="text"
                        name="ContactPhoneNo"
                        className=""
                        id="ContactPhoneNo"
                        value={value.ContactPhoneNo}
                        required
                        onChange={handlChange}
                      />
                      {errors.contactPhoneNoError !== "true" ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          {errors.contactPhoneNoError}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-6 col-md-6 col-lg-6">
                <div className="row px-3">

                  <div className="col-3 col-md-3 col-lg-2 mt-2 pt-2">
                    <label className="label-field">Address</label>
                  </div>
                  <div className="col-9  col-md-9 col-lg-10">
                    <div className="dropdown__box">
                      <textarea
                        name="ContactAddress"
                        id="ContactAddress"
                        cols="30"
                        rows="1"
                        value={value.ContactAddress}
                        className="form-control  "
                        onChange={handlChange}
                      ></textarea>
                      {errors.contactAddressError !== "true" ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          {errors.contactAddressError}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-3 col-md-3 col-lg-2 mt-2 pt-2">
                    <label className="label-field">state:</label>
                  </div>
                  <div className="col-9 col-md-9 col-lg-6 ">
                    <div className="dropdown__box">
                      <Select
                        name="ContactState"
                        styles={customStylesWithOutColor}
                        isClearable
                        options={State}
                        onChange={(e) => handelStateChange(e, 'ContactState')}
                        value={State?.filter((obj) => obj.label === value?.ContactState
                        )}
                        placeholder="Select.."
                      />
                    </div>
                  </div>
                  <div className=" col-lg-3 ">
                  </div>
                  <div className="col-3 col-md-3 col-lg-2 mt-2 pt-2">
                    <label className="label-field">city:</label>
                  </div>
                  <div className="col-9 col-md-9 col-lg-6  ">
                    <div className="dropdown__box">
                      <Select
                        name="city"
                        styles={customStylesWithOutColor}
                        isClearable
                        options={ContactCity}
                        onChange={(e) => handelStateChange(e, 'ContactCity')}
                        value={ContactCity?.filter((obj) => obj.label === value?.ContactCity)}
                        placeholder="Select.."
                      />
                    </div>
                  </div>
                  <div className=" col-lg-3 ">
                  </div>
                  <div className="col-3 col-md-3 col-lg-2 mt-2 pt-2">
                    <label className="label-field ">pin code:</label>
                  </div>
                  <div className="col-9 col-md-9 col-lg-6 ">
                    <div class="text-field">
                      <input
                        type="text"
                        name="ContactPin"
                        className=""
                        id="ContactPin"
                        value={value.ContactPin}
                        required
                        onChange={handlChange}
                      />
                      {errors.contactPinError !== "true" ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          {errors.contactPinError}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-box text-end  col-lg-12  mt-2  ">
            {
              pageStatus ?
                <button type="button" class="btn btn-sm btn-success mr-1 mb-1" onClick={check_Validation_Error}>Update</button>
                :
                <button type="button" class="btn btn-sm btn-success mr-1 mb-1" onClick={check_Validation_Error}>Save</button>
            }
            <Link to={'/MasterTables?sub=Ledger'}>
              <button type="button" class="btn btn-sm btn-success mr-1 mb-1" onClick={reset}>
                Close
              </button>
            </Link>
            <button type="button" class="btn btn-sm btn-success mr-1 mb-1">
              Clear
            </button>
          </div>
        </div>
      </div>

      {/* <SaleParty_Add_Up /> */}
    </>
  );
};
export default Home;
