import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux';
import { Edit_Update_Count, Get_Error_Status, Get_Master_Table_Filter_Data, Get_Single_Master_Table_Data, Sidebar_Status } from '../../../redux/actionTypes';
import { SpaceNotAllow } from '../../Validation/Validation';
import { delete_Master_Table, get_Master_Table, get_Single_Master_Table, save_Master_Table, update_Master_Table } from '../../../redux/actions/AllAction';
import { changeArrayFormat } from './ArrayFormat';
import { Link } from 'react-router-dom';
import ConfirmModal from './ConfirmModal';
import { Two_Value_Filter } from '../../Filter/Filter';
import Dropdown from 'react-bootstrap/Dropdown'
import Loader from './Loader';

const MasSideBar = (props) => {
    const { showCol1, idName, delUrl, upUrl, singleDataUrl, addUrl, getUrl, urlName, actionType } = props
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.Data.userId);
    const errorStatus = useSelector((state) => state.Data.errorStatus)
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const masterTableData = changeArrayFormat(useSelector((state) => state.Data.masterTableData), showCol1, '', idName)
    const singleMasterTableData = useSelector((state) => state.Data.singleMasterTableData[0])
    const masterTableFilterData = useSelector((state) => state.Data.masterTableFilterData)
    const status = useSelector((state) => state.Data.sidebarStatus)
    const masterTableLoader = useSelector((state) => state.Data.masterTableLoader)
    const subTabName = useSelector((state) => state.Data.subTabName)
    const [value, setValue] = useState({
        [showCol1]: '',
        ID: '',
        Createdbyid: userId
    })
    // const [status, setStatus] = useState(false)
    const [pageStatus, setPageStatus] = useState("1")
    const [IsActive, setIsActive] = useState()
    const [confirmType, setConfirmType] = useState('')
    const [id, setId] = useState('')
    const [searchValue1, setSearchValue1] = useState('')
    const [filterTypeIdOption, setfilterTypeIdOption] = useState('Contains');
    const [errors, setErrors] = useState({
        'nameError': '',
    })

    useEffect(() => {
        dispatch(get_Master_Table(pageStatus, getUrl, showCol1, '', idName))
    }, [pageStatus, getUrl])
    useEffect(() => {
        if (pageStatus) { setSearchValue1(''); }
    }, [pageStatus])

    useEffect(() => {
        if (singleMasterTableData) {
            setValue({
                ...value,
                [showCol1]: singleMasterTableData[showCol1],
                ID: singleMasterTableData[idName],
            })
        } else {
            setValue({
                ...value,
                [showCol1]: '',
                ID: '',
            })
        }
    }, [singleMasterTableData, editUpdateCount])

    useEffect(() => {
        if (!status) {
            setValue({
                ...value,
                [showCol1]: '',
                ID: '',
            })
        }
    }, [status])

    const handlChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
    }

    // Check validation on Field
    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value[showCol1])) {
            setErrors(prevValues => { return { ...prevValues, ['nameError']: SpaceNotAllow(value[showCol1]) } })
            dispatch({ type: Get_Error_Status, payload: true })
        }
    }

    // Check All Field Format is True Then Submit 
    const { nameError } = errors

    useEffect(() => {
        if (nameError === 'true' && errorStatus) {
            if (status) {dispatch(update_Master_Table(value, upUrl, pageStatus, getUrl, showCol1, '', idName, masterTableData, urlName, actionType));}
            else dispatch(save_Master_Table(value, addUrl, pageStatus, getUrl, showCol1, '', idName, masterTableData, urlName, actionType));
        }
    }, [nameError, errorStatus])

    const columns = [
        {
            name: 'Name',
            selector: (row) => row.Name,
            sortable: true,
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 42, fontWeight: '700' }}>Action</p>,
            cell: row => <>
                <div style={{ position: 'absolute', top: 4, right: 40 }}>

                    {
                        pageStatus === "1" ?
                            <Link to={`?sub=${subTabName}`} onClick={() => setEditValue(row)}
                                className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn"><i className="fa fa-edit"></i>
                            </Link>
                            : <></>
                    }
                    {
                        pageStatus === "1" ?
                            < Link to={`?sub=${subTabName}`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive(0); setConfirmType("InActive"); setId(row.ID) }}
                                className="btn btn-sm  text-white px-1 py-0 mr-1" style={{ background: "#ddd" }}>
                                <i class="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true"></i>
                            </Link>
                            :
                            <Link to={`?sub=${subTabName}`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive(1); setConfirmType("Active"); setId(row.ID) }}
                                className="btn btn-sm  text-white px-1 py-0 mr-4" style={{ background: "#ddd" }}>
                                <i class="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true"></i>
                            </Link>
                    }
                </div>
            </>
        }
    ];

    const newStatus = () => {
        dispatch({ type: Sidebar_Status, payload: false }); dispatch({ type: Get_Single_Master_Table_Data, payload: [] }); dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
    }

    const setEditValue = (row) => {
        dispatch(get_Single_Master_Table(row.ID, singleDataUrl)); dispatch({ type: Sidebar_Status, payload: true }); dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
    }

    const UpdActiveDeactive = () => {
        dispatch(delete_Master_Table(id, IsActive, delUrl, pageStatus, getUrl, showCol1, '', idName, userId));
    }

    return (
        <>
            {/* {
            masterTableModalStatus && */}
            <div className="container demo">
                {/* <div className="text-center">
                    <button type="button" className="btn btn-demo" data-toggle="modal" data-target="AddMasterModal">
                        Sidebar Modal
                    </button>
                </div> */}

                <div className="modal left fade" style={{ background: "rgba(0,0,0, 0.5)" }} id="AddMasterModal" tabIndex role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div class="modal-header px-3 p-2" >
                                <h5 class="modal-title">Search</h5>
                                <button type="button" className="close btn-modal" data-dismiss="modal" aria-label="Close" onClick={newStatus}>
                                    <span aria-hidden="true" style={{ color: 'red', fontSize: '20px', }}>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div class="col-10 col-md-10 col-lg-10 mt-2">
                                        <div className="text-field">
                                            <input type="text" name={showCol1} value={value[showCol1]} onChange={handlChange} />
                                            <label className=''>{showCol1}</label>
                                            {errors.nameError !== 'true' ? (
                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.nameError}</span>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-1 col-md-1 col-lg-1 p-0" >
                                        <div className=" mt-3 pt-1 p-0">
                                            {
                                                status ?
                                                    <button type="button" className="btn btn-sm btn-success mx-1 py-1 text-center" onClick={check_Validation_Error} >Update</button>
                                                    :
                                                    <button type="button" className="btn btn-sm btn-success mx-1 py-1 text-center" onClick={check_Validation_Error} >Save</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-12 mt-3 ">
                                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <a class={`nav-link ${pageStatus === '1' ? 'active' : ''}`} onClick={() => setPageStatus("1")} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true">Active</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class={`nav-link ${pageStatus === '0' ? 'active' : ''}`} onClick={() => setPageStatus("0")} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true">InActive</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-10 mt-3">
                                    <input type="text" value={searchValue1} onChange={(e) => {
                                        setSearchValue1(e.target.value);
                                        const result = Two_Value_Filter(masterTableData, e.target.value, '', filterTypeIdOption, 'Name', 'Code')
                                        dispatch({ type: Get_Master_Table_Filter_Data, payload: result });
                                    }}
                                        className='form-control' placeholder='Search By Code...' />
                                </div>
                                <div className="col-12 col-lg-12 mt-3 ">
                                    {
                                        masterTableLoader ?
                                            <DataTable
                                                dense
                                                columns={columns}
                                                data={masterTableFilterData}
                                                pagination
                                                selectableRowsHighlight
                                                highlightOnHover
                                            />
                                            :
                                            <Loader />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />

        </>
    )
}

export default MasSideBar