import React, { useEffect, useState } from 'react'
import Select from "react-select";
import DatePicker from 'react-datepicker'
import { Link } from 'react-router-dom';
import { getShowingDayWithOutTime, getShowingWithOutTime, getShowingWithOutTimeStartDate } from '../../../Include/Common/Utility';
import MasSideBar from '../../../Include/Common/MasSideBar';
import { useDispatch, useSelector } from 'react-redux';
import { changeDropDownArray, get_MachineName_Drop_Data, get_Pit_Drop_Data, save_Mining_Drilling_Data, update_Drilling_Data } from '../../../../redux/actions/AllAction';
import { Get_Error_Status, MasterTable_Modal_Status } from '../../../../redux/actionTypes';
import { RequiredFieldSelectBox, SpaceNotAllow, SpaceNotAllowWithoutReq } from '../../../Validation/Validation';
import { toastifyError } from '../../../Include/Common/AlertMsg';
import { changeArrayFormat, changeArrayFormatDrop } from '../../../Include/Common/ArrayFormat';

const DrillingEntry_Add_Up = ({ status, pageStatus }) => {

    const dispatch = useDispatch();
    const userId = useSelector((state) => state.Data.userId);
    const pitblockDropdata = changeArrayFormatDrop(useSelector((state) => state.Data.pitblockDropdata),'Description','ID');
    const machineNameData = changeArrayFormatDrop(useSelector((state) => state.Data.machineNameData),'Description','ID');
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount);
    const singleDrillingData = useSelector((state) => state.Data.singleDrillingData[0]);
    const modalStatus = useSelector((state) => state.Data.modalStatus)
    const [urlName, setUrlName] = useState('')
    const [showCol, setShowCol] = useState('')
    const [idName, setIdName] = useState('')
    const [actionType, setActionType] = useState('')
    const [value, setValue] = useState({
        BlastNoID: '', BlastNo: '', DrillingDate: '', DrillingDate1: '', NoofHoles: '', NoofBits: '', ContDrillRT: '', MachineName: '', MachineNameID: '', TotalDrillingMtrs: '', dieselUsed: '', RunningHours: '', Remarks: '', Updatedbyid: '', Createdbyid: userId, ID: ''
    });

    useEffect(() => { dispatch(get_Pit_Drop_Data()); dispatch(get_MachineName_Drop_Data()) }, [])

    useEffect(() => {
        if (singleDrillingData) {
            setValue(pre => { return { ...pre, BlastNoID: singleDrillingData.BlastNoID, BlastNo: singleDrillingData.BlastNo, DrillingDate: singleDrillingData.DrillingDate, DrillingDate1: singleDrillingData.DrillingDate.split("/").reverse().join("/"), NoofHoles: singleDrillingData.NoofHoles, NoofBits: singleDrillingData.NoofBits, ContDrillRT: singleDrillingData.ContDrillRT, MachineName: singleDrillingData.MachineName, MachineNameID: singleDrillingData.MachineNameID, TotalDrillingMtrs: singleDrillingData.TotalDrillingMtrs, dieselUsed: singleDrillingData.dieselUsed, RunningHours: singleDrillingData.RunningHours, Remarks: singleDrillingData.Remarks, Updatedbyid: userId, ID: singleDrillingData?.ID } })
        } else resetHooks()

    }, [singleDrillingData, editUpdateCount])

    const handelChange = (e) => {
        if (e.target.name === 'NoofHoles'  || e.target.name === 'dieselUsed'  || e.target.name === 'NoofBits' || e.target.name === 'ContDrillRT' || e.target.name === 'TotalDrillingMtrs' || e.target.name === 'RunningHours' ) {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setValue({
                ...value,
                [e.target.name]: checkNumber
            })
        }
        else {
            setValue({
                ...value,
                [e.target.name]: e.target.value,
            });
        }

    }

    const handleDropdown = (Name) => e => {
        if (Name === 'MachineNameID') {
            setValue(pre => { return { ...pre, ['MachineNameID']: e?.value, ['MachineName']: e?.label } })
        } else if (Name === 'BlastNoID') {
            setValue(pre => { return { ...pre, ['BlastNoID']: e?.value, ['BlastNo']: e?.label } })
        }
    }

    const [errors, setErrors] = useState({
        BlastError: '', MachineError: ''
    })

    const { BlastError, MachineError } = errors;

    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.BlastNo)) {
            setErrors(prevValues => { return { ...prevValues, ['BlastError']: SpaceNotAllow(value.BlastNo) } })
            dispatch({ type: Get_Error_Status, payload: true });
        }
        if (SpaceNotAllow(value.MachineName)) {
            setErrors(prevValues => { return { ...prevValues, ['MachineError']: SpaceNotAllow(value.MachineName) } })
        }
    };

    useEffect(() => {
        if (BlastError === 'true' && MachineError === 'true') {
            if (status) { dispatch(update_Drilling_Data(value, pageStatus)); setErrors({ ['NoofHolesError']: '' }) }
            else { dispatch(save_Mining_Drilling_Data(value, pageStatus)); setErrors({ ['NoofHolesError']: '' }) }
        }
    }, [BlastError, MachineError]);

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    };
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    const startRef = React.useRef()

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
        }
    };

    const resetHooks = () => {
        setValue(pre => { return { ...pre, BlastNoID: '', BlastNo: '', DrillingDate: '', DrillingDate1: '', NoofHoles: '', NoofBits: '', ContDrillRT: '', MachineName: '', MachineNameID: '', TotalDrillingMtrs: '', dieselUsed: '', RunningHours: '', Remarks: '', Updatedbyid: '', ID: '' } })
        setErrors(pre => { return { ...pre, BlastError: '', MachineError: '' } })
    }

    const setUrls = (idName, urlName, showCol, actionType) => {
        setIdName(idName); setUrlName(urlName); setShowCol(showCol); setActionType(actionType)
    }

    return (
        <>
            {
                modalStatus &&
                <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="DrillingEntryModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="m-1">
                                    <fieldset style={{ border: '1px solid gray' }}>
                                        <legend >Drilling Entry</legend>
                                        <div className="form-row" style={{ marginTop: '-10px' }}>
                                            <div className="col-7 col-md-7 col-lg-7 d-flex px-0">
                                                <div className="col-10 col-md-10 col-lg-10 mt-1">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            name='blast/BlastNoID'
                                                            styles={colourStyles}
                                                            isClearable
                                                            options={pitblockDropdata}
                                                            onChange={handleDropdown('BlastNoID')}
                                                            value={pitblockDropdata?.filter((obj) => obj?.value === value?.BlastNoID)}
                                                            placeholder="Select.."
                                                        />
                                                        <label htmlFor="">Blast/Bench Pit</label>
                                                    </div>
                                                    {errors.BlastError !== 'true' ? (
                                                        <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.BlastError}</span>
                                                    ) : null}
                                                </div>
                                                <div className="col-1 col-md-1 col-lg-1   px-0 py-0" style={{ marginTop: '17px' }}>
                                                    <Link to="/Mining?sub=Drilling%20Entry" className="btn btn-sm bg-green text-white " onClick={() => setUrls('ID', 'MiningPit', 'Description', 'Get_PitBlock_Dropdown')} data-toggle="modal" data-target="#AddMasterModal" >
                                                        <i className="fa fa-plus"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-5 col-md-5 col-lg-5 pt-1">
                                                <div className="dropdown__box">
                                                    <DatePicker
                                                        id='DrillingDate'
                                                        name='DrillingDate'
                                                        ref={startRef}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => date ? setValue(pre => { return { ...pre, ['DrillingDate']: getShowingDayWithOutTime(date), ['DrillingDate1']: getShowingWithOutTimeStartDate(date) } }) :
                                                            setValue(pre => { return { ...pre, ['DrillingDate']: '', ['DrillingDate1']: '' } })
                                                        }
                                                        dateFormat="dd/MM/yyyy"
                                                        timeInputLabel
                                                        isClearable
                                                        selected={value.DrillingDate1 && new Date(value.DrillingDate1)}
                                                        placeholderText={'Select...'}
                                                        dropdownMode="select"
                                                        showMonthDropdown
                                                        minDate={new Date()}
                                                        showYearDropdown
                                                    />

                                                    <label htmlFor="" className='pl-0 pt-1' >Drilling Date</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-7 d-flex px-0 pt-1">
                                                <div className="col-11 col-md-11 col-lg-10 mt-1">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            name='MachineNameID'
                                                            styles={colourStyles}
                                                            isClearable
                                                            options={machineNameData}
                                                            onChange={handleDropdown('MachineNameID')}
                                                            value={machineNameData?.filter((obj) => obj?.value === value?.MachineNameID)}
                                                            placeholder="Select.."
                                                        />
                                                        <label htmlFor="">Machine Name</label>
                                                    </div>
                                                    {errors.MachineError !== 'true' ? (
                                                        <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.MachineError}</span>
                                                    ) : null}
                                                </div>
                                                <div className="col-1 col-md-1 col-lg-1   px-0 py-0" style={{ marginTop: '17px' }}>
                                                    <Link to="/Mining?sub=Drilling%20Entry" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal" onClick={() => setUrls('ID', 'MiningMachineName', 'Description', 'Get_Machine_Name_Dropdown')}
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-5 " style={{ marginTop: '10px' }}>
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='NoofHoles'
                                                        id='NoofHoles'
                                                        value={value.NoofHoles}
                                                        onChange={handelChange}
                                                        required
                                                    />
                                                    <label className='pt-1'>No. Of Holes</label>
                                                    {errors.NoofHolesError !== "true" ? (<span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.NoofHolesError} </span>) : null}
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6 " style={{ marginTop: '10px' }}>
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='TotalDrillingMtrs'
                                                        id='TotalDrillingMtrs'
                                                        value={value.TotalDrillingMtrs}
                                                        onChange={handelChange}
                                                        required
                                                    />
                                                    <label className='pt-1'>Total Drilling Mtrs.</label>
                                                    {errors.TotalDrillingMtrsError !== "true" ? (<span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.TotalDrillingMtrsError} </span>) : null}
                                                </div>
                                            </div>
                                            <div className="col-1 col-md-1 col-lg-1">
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-5" style={{ marginTop: '10px' }}>
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='NoofBits'
                                                        id='NoofBits'
                                                        value={value.NoofBits}
                                                        onChange={handelChange}
                                                        required
                                                    />
                                                    <label className='pt-1'>No. Of Bits</label>
                                                    {errors.NoofBitsError !== "true" ? (<span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.NoofBitsError} </span>) : null}
                                                </div>
                                            </div>


                                            <div className="col-6 col-md-6 col-lg-6" style={{ marginTop: '10px' }}>
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='dieselUsed'
                                                        id='dieselUsed'
                                                        value={value.dieselUsed}
                                                        onChange={handelChange}
                                                        required
                                                    />
                                                    <label className='pt-1'>Diesel Used</label>
                                                    {errors.dieselUsedError !== "true" ? (<span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.dieselUsedError} </span>) : null}
                                                </div>
                                            </div>

                                            <div className="col-1 col-md-1 col-lg-1">
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-5" style={{ marginTop: '10px' }}>
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='burden'
                                                        id='burden'
                                                        disabled
                                                        // value={value.NoofHoles}
                                                        // onChange={handelChange}
                                                        required
                                                    />
                                                    <label className='pt-1'>Burden In Mtrs:</label>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6" style={{ marginTop: '10px' }}>
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='ContDrillRT'
                                                        id='ContDrillRT'
                                                        value={value.ContDrillRT}
                                                        onChange={handelChange}
                                                        required
                                                    />
                                                    <label className='pt-1'>Count Drill Rt@PM.</label>
                                                    {errors.ContDrillRTError !== "true" ? (<span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.ContDrillRTError} </span>) : null}
                                                </div>
                                            </div>
                                            <div className="col-1 col-md-1 col-lg-1">
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-5" style={{ marginTop: '10px' }}>
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='RunningHours'
                                                        id='RunningHours'
                                                        value={value.RunningHours}
                                                        onChange={handelChange}
                                                        required
                                                    />
                                                    <label className='pt-1'>Running Hours</label>
                                                    {errors.RunningHoursError !== "true" ? (<span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.RunningHoursError} </span>) : null}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12 mt-1" >
                                                <div className="dropdown__box">
                                                    <textarea name='Remarks' id="Remarks"
                                                        cols="30" rows='1' className="form-control "
                                                        value={value.Remarks}
                                                        onChange={handelChange}
                                                    ></textarea>
                                                    <label htmlFor="">Remarks </label>
                                                    {errors.RemarksError !== "true" ? (<span style={{ color: "red", fontSize: "13px", margin: "0px", padding: "0px", }} > {errors.RemarksError} </span>) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div className="btn-box text-right  mr-1 mb-2">
                                <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}> {status ? 'Update' : 'Save'}</button>
                                <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={resetHooks} >Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                urlName &&
                <MasSideBar
                    showCol1={showCol} idName={idName} urlName={urlName} actionType={actionType}
                    getUrl={`/${urlName}/GetData_${urlName}`}
                    addUrl={`/${urlName}/Insert${urlName}`}
                    singleDataUrl={`/${urlName}/GetSingleData_${urlName}`}
                    upUrl={`/${urlName}/Update${urlName}`}
                    delUrl={`/${urlName}/Delete${urlName}`}
                />
            }
        </>
    )
}

export default DrillingEntry_Add_Up




