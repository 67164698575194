import React, { useState } from 'react'
import MeterReading from './DailyRunningTab/MeterReading/MeterReading';
import HourReading from './DailyRunningTab/HourReading/HourReading';
import { DailyRunningTabs } from '../../Utility/TabsArray';
import SubTab from '../../Utility/SubTab';

const DailyRunning = () => {
  const [showPage, setShowPage] = useState('home');
  const [status, setStatus] = useState()
  return (
    <>

      <div className="col-12 col-sm-12 pt-1">
        <div className="card Agency ">
          <div className="card-body">
            <div className="row ">
              <div className="col-12 pl-3 ">
                <SubTab tabs={DailyRunningTabs} showPage={showPage} setShowPage={setShowPage} />
              </div>
            </div>
          </div>
          {
            showPage === 'meterreading' ?
              <MeterReading />
              :
              showPage === 'hourreading' ?
                <HourReading />
                :
                <></>
          }
        </div>
      </div>

    </>
  )
}

export default DailyRunning