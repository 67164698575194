import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SpaceNotAllowWithoutReq, SpaceNotAllow } from "../../../Validation/Validation";
import { get_Product_Dropdown, get_Service_Dropdown, get_Unit_Dropdown, save_PlantService_Data, save_Product_Data, update_PlantService_Data, update_Product_Data } from '../../../../redux/actions/AllAction';
import { Get_Single_PlantService_Data } from '../../../../redux/actionTypes';

const PlantSerivceProcess_Add_Up = (props) => {
    const dispatch = useDispatch();
    const { status, pageStatus } = props
    const serviceDropdown = useSelector((state) => state?.Data?.serviceDropdown)
    const userId = useSelector((state) => state?.Data?.userId)
    const masterTableModalStatus = useSelector((state) => state.Data.masterTableModalStatus)
    const singleServiceData = useSelector((state) => state?.Data?.singleServiceData[0])
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    const [value, setValue] = useState({
        ServiceTypeID: "",
        ServiceType: "",
        GroupNameID: "",
        GroupName: "",
        ProcessName: "",
        ID: "",
        Updatedbyid: "" 
    })
    const [errors, setErrors] = useState({
        'ProcessName': '',
    });
    const { ProcessError } = errors;

    useEffect(() => {
        if (serviceDropdown.length === 0) dispatch(get_Service_Dropdown(value));
    }, [])

    useEffect(() => {
        if (ProcessError === "true") {
            if (status) {
                dispatch(update_PlantService_Data(value, pageStatus)); setErrors({ ['ProcessError']: '' });
            }
            else {
                dispatch(save_PlantService_Data(value, pageStatus)); setErrors({ ['ProcessError']: '' });
            }
        }
    }, [ProcessError])
    useEffect(() => {
        if (singleServiceData) {
            setValue({
                ServiceTypeID: singleServiceData?.ServiceTypeID,
                ServiceType: singleServiceData?.ServiceType,
                GroupNameID: singleServiceData?.GroupNameID,
                GroupName: singleServiceData?.GroupName,
                ProcessName: singleServiceData?.ProcessName,
                ID: singleServiceData.ID, 
                Updatedbyid: userId
            })
        }
        else {
            setValue({
                ServiceTypeID: "",
                ServiceType: "",
                GroupNameID: "",
                GroupName: "",
                ProcessName: "",
                ID: '',
                Updatedbyid: ''
            })
        }
    }, [singleServiceData, editUpdateCount])
    const check_Validation_Error = (e) => {
        e.preventDefault()
        // if (SpaceNotAllowWithoutReq(value.ServiceType)) {
        //     setErrors((prevValues) => { return { ...prevValues, ["ServiceError"]: SpaceNotAllowWithoutReq(value.ServiceType) } });
        // }
        if (SpaceNotAllowWithoutReq(value.ProcessName)) {
            setErrors((prevValues) => { return { ...prevValues, ["ProcessError"]: SpaceNotAllowWithoutReq(value.ProcessName) } });
        }
    }
    const ChangeDropDown = (e, Name) => {
        if (e) {
            setValue({
                ...value,
                [Name]: e.label
            })
        } else setValue({
            ...value,
            [Name]: null
        })
    }
    const handelChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });


    }
    const close_modal = () => {
        // dispatch(get_Single_Insurance_Data(''));
        dispatch({ type: Get_Single_PlantService_Data, payload: [] });
        setValue({
            ...value,
            ServiceTypeID: "",
            ServiceType: "",
            GroupNameID: "",
            GroupName: "",
            ProcessName: "",
            ID: ""
        })
    ; 
    setErrors({
        'ProcessError': ''
    });
}
return (
    <>
        {masterTableModalStatus && <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="PlantProcessModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="m-1 ">
                            <fieldset style={{ border: '1px solid gray' }}>
                                <legend >Plant Service Process</legend>
                                <div className="form-row" style={{ marginTop: '-10px' }}>
                                    <div className="col-6 col-md-6 col-lg-4 mt-2">
                                        <div className="dropdown__box" >
                                            <Select
                                                name='ServiceType'
                                                styles={customStylesWithOutColor}
                                                isClearable
                                                options={serviceDropdown}
                                                onChange={(e) => ChangeDropDown(e, 'ServiceType')}
                                                value={serviceDropdown?.filter((obj) => obj.label === value?.ServiceType
                                                )}
                                            />
                                            {/* {errors.ServiceError !== "true" ? (
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            fontSize: "13px",
                                                            margin: "0px",
                                                            padding: "0px",
                                                        }}
                                                    >
                                                        {errors.ServiceError}
                                                    </span>
                                                ) : null} */}
                                            <label htmlFor='' >Service Type</label>
                                        </div>
                                    </div>

                                    <div className="col-6 col-md-6 col-lg-4 mt-2">
                                        <div className="dropdown__box">
                                            <Select
                                                name='GroupNameID'
                                                styles={customStylesWithOutColor}
                                                isClearable
                                                // options={unitDropdown}
                                                onChange={(e) => ChangeDropDown(e, 'GroupNameID')}
                                                // value={unitDropdown?.filter((obj) => obj.value === value?.GroupNameID
                                                // )}
                                                placeholder="Select.."
                                            />
                                            <label >Group Name</label>

                                        </div>
                                    </div>

                                    <div className="col-6 col-md-6 col-lg-4 mt-2">
                                        <div class="text-field">
                                            <input
                                                type="text"
                                                name='ProcessName'
                                                className=""
                                                id='ProcessName'
                                                required
                                                value={value.ProcessName}
                                                onChange={handelChange}
                                            />
                                            {errors.ProcessError !== "true" ? (
                                                <span
                                                    style={{
                                                        color: "red",
                                                        fontSize: "13px",
                                                        margin: "0px",
                                                        padding: "0px",
                                                    }}
                                                >
                                                    {errors.ProcessError}
                                                </span>
                                            ) : null}
                                            <label className='pt-1'>Process Name</label>
                                        </div>
                                    </div>

                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div className="btn-box text-right  mr-1 mb-2">
                        {
                            status ?
                                <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button>
                                :
                                <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Save</button>
                        }
                        <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={close_modal}>Close</button>
                    </div>
                </div>
            </div>
        </div>}
    </>
)
}

export default PlantSerivceProcess_Add_Up