import React from 'react'
import Select from "react-select";

const HourReading_Add = () => {
    // custuom style withoutColor
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    return (
        <>
            <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="HourReadingModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="m-1 ">
                                <fieldset style={{ border: '1px solid gray' }}>
                                    <legend >Hour Reading</legend>
                                    <div className="form-row" style={{ marginTop: '-10px' }}>
                                        <div className="col-12 col-md-6 col-lg-6 mt-1 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='equipment'
                                                    className=""
                                                    id='equipment'
                                                    required
                                                />
                                                <label className='pt-1'>Equipment Identification No.</label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 mt-1 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='make'
                                                    className="readonlyColor"
                                                    id='make'
                                                    required
                                                    readOnly
                                                />
                                                <label className='pt-1'>Make</label>
                                            </div>
                                        </div>
                                        <div className="form-check px-4 col-4 mt-2 col-md-4 col-lg-4">
                                            <input className="form-check-input mt-1" type="radio" name="AttemptComplete" id="flexRadioDefault1" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                Running
                                            </label>
                                        </div>
                                        <div className="form-check mt-2 col-4 col-md-4 col-lg-4">
                                            <input className="form-check-input mt-1" type="radio" name="AttemptComplete" id="flexRadioDefault1" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                Idle
                                            </label>
                                        </div>
                                        <div className="form-check mt-2 col-4 col-md-4 col-lg-4">
                                            <input className="form-check-input mt-1" type="radio" name="AttemptComplete" id="flexRadioDefault1" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                Break-Down
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 mt-1">
                                            <div className="dropdown__box">
                                                <Select
                                                    name='comon task'
                                                    styles={customStylesWithOutColor}
                                                    isClearable
                                                    placeholder="Select.."
                                                />
                                                <label htmlFor="">Common Task</label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 mt-1 pt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='taskmaster'
                                                    className=""
                                                    id='taskmaster'
                                                    required
                                                    readOnly
                                                />
                                                <label className='pt-1'>Task Master</label>
                                            </div>
                                        </div>
                                        <div className="col-12  col-md-12 col-lg-12 mt-2">
                                            <div className="dropdown__box">
                                                <textarea
                                                    name="remarks"
                                                    id="remarks"
                                                    cols="30"
                                                    rows="1"
                                                    className="form-control   "
                                                >
                                                </textarea>
                                                    <label htmlFor="">Remarks</label>
                                            </div>
                                        </div>

                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="btn-box text-right  mr-1 mb-2">
                            <button type="button" class="btn btn-sm btn-success mr-1">Save</button>
                            <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" >Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HourReading_Add