export const SpaceNotAllow = (field) => {
  if (!field || field === null) {
    return "Required";
  } else if (field?.match(/^[A-Za-z!@#$%&^*()-`.+,/]*( [A-Za-z!@#$&%^*()-`.+,/]+)*$/)) {
    return "true";
  } else if (field?.match(/^([0-9]+)*$/)) {
    return "true";
  } else {
    return "Space Not Allow";
  }
};

export const Email_Field = (email) => {
  if (email === "" || email === null) {
    return "true";
  } else if (
    email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    return "true";
  } else {
    return "Email not valid";
  }
};

export const SpaceNotAllowWithoutReq = (field) => {
  if (!field || field === null) {
    return "Required";
  } else if (String(field)?.match(/^[A-Za-z!@#$%&^*()-`.+,/]*( [A-Za-z!@#$&%^*()-`.+,/]+)*$/)) {
    return "true";
  } else if (field?.match(/^([0-9]+)*$/)) {
    return "true";
  }
  // else {
  //   return "Space Not Allow";
  // }
};
export const RequiredField = (field) => {
	if (field.trim() === '' || field.trim() === null) {
		return 'Required *';
	} else if(field.match(/^\S.*[a-zA-Z\s]*$/)) {
		return 'true';  
	} else{
		return 'Space Not Allow';
	}
};
export const RequiredFieldSelectBox = (field) => {
	if (field === '' || field === null) {
		return 'Required *';
	} else {
		return 'true';
	}
};


