import React from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ServiceConfiguration from './ServiceConfiguration/ServiceConfiguration';
import ServiceMaintenance from './ServiceMaintenance/ServiceMaintenance';

const Service = () => {

    const dispatch = useDispatch();
    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get('sub');

    return (
        <>
            {
                openPage === 'Service' &&  <ServiceConfiguration />
            }
            {
                openPage === 'Maintenance' &&  <ServiceMaintenance/>
            }
        </>
    )

}

export default Service