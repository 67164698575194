import React from "react";
import { Button } from "react-bootstrap";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

const ExcelExData = ({ csvData, fileName, wscols }) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (csvData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <Button
        style={{fontSize:'10px'}}
            variant="warning"
            onClick={e => exportToCSV(csvData, fileName, wscols)}
        >
           <i className="fa fa-file"></i>
        </Button>
    );
};

export default ExcelExData;

