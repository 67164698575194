import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get_Employee_Data, get_MenuPermission_Data, update_MenuPermission_Data } from '../../../../redux/actions/AllAction'
import Select from "react-select";
import { changeArrayFormatDrop } from '../../../Include/Common/ArrayFormat';
import { useState } from 'react';
import { Get_Menu_Permission_Data, Permission_UserId } from '../../../../redux/actionTypes';

const PermissionPage = () => {
    const dispatch = useDispatch()
    const menuPermissionData = useSelector((state) => state.Data.menuPermissionData)
    const permissionUserId = useSelector((state) => state.Data.permissionUserId);
    const employeeData = changeArrayFormatDrop(useSelector((state) => state.Data.employeeData), 'EmpName', 'ID');
   console.log(employeeData,'employeedata')
    useEffect(() => {
        if (employeeData.length === 0) dispatch(get_Employee_Data())
    }, []);

    const handleChange = (e) => {
        if (e) {
            dispatch({ type: Permission_UserId, payload: e.value }); dispatch(get_MenuPermission_Data(e.value))
        } else {
            dispatch({ type: Permission_UserId, payload: '' }); dispatch({ type: Get_Menu_Permission_Data, payload: [] });
        }
    };

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="col-11 col-md-11 col-lg-11 ">
                            <div className="dropdown__box">
                                <Select
                                    name='UnitID'
                                    styles={customStylesWithOutColor}
                                    isClearable
                                    onChange={handleChange}
                                    value={employeeData?.filter((obj) => obj.value === permissionUserId)}
                                    options={employeeData}
                                    placeholder="Select Employee.."
                                />
                            </div>
                        </div>
                        <div className="card mt-2">
                            <div className="card-body">
                                <div id="accordion">
                                    <div className="card">
                                        {
                                            menuPermissionData?.map((item, key) =>
                                                <>
                                                    <div className="card-header" id={`heading-${key + 1}`} style={{ display: 'flex' }}>
                                                        <div className='col-1'>
                                                            <span >
                                                                <input type="checkbox" checked={item.IsVisible === 'False' ? false : true} className='mr-2'
                                                                    onChange={
                                                                        (e) => {
                                                                            dispatch(update_MenuPermission_Data(e.target.checked ? '1' : '0', item.ID, permissionUserId.toString()))
                                                                        }
                                                                    }
                                                                />
                                                            </span>
                                                        </div>
                                                        <div style={{ width: '100%' }}>
                                                            <h5 className="col-12 mb-0">
                                                                <a role="button" className='ml-1' data-toggle="collapse" href={`#collapse-${key + 1}`} aria-expanded="true" aria-controls={`collapse-${key + 1}`} style={{ fontSize: '15px' }}>
                                                                    {item.MenuName}
                                                                </a>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div id={`collapse-${key + 1}`} className="collapse " data-parent="#accordion" aria-labelledby={`heading-${key + 1}`}>
                                                        <div className="card-body">
                                                            <div id="accordion-1">
                                                                <div className="card">
                                                                    <div className="card-header" id={`heading-${key + 1}`}>
                                                                        {
                                                                            item?.Submenuclass?.map((val) =>
                                                                                <div className="card-body">
                                                                                    <input type="checkbox" name="" id="" checked={val.IsVisible === 'False' ? false : true} className='mr-2' onChange={
                                                                                        (e) => {
                                                                                            dispatch(update_MenuPermission_Data(e.target.checked ? '1' : '0', val.ID, permissionUserId.toString()))
                                                                                        }
                                                                                    } />
                                                                                    {val.MenuName}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PermissionPage