import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { get_Approve_Request_Data, get_DeApprove_Request_Data, get_UnApprove_Request_Data } from '../../../../redux/actions/AllAction';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../../Include/Common/ConfirmModal';
import RejectRequest_Add_Up from './RejectRequest_Add_Up';
import { Edit_Update_Count, Get_Req_Id, Modal_Status } from '../../../../redux/actionTypes';
import Loader from '../../../Include/Common/Loader';


const RejectRequest = (props) => {
    const { openPage } = props
    const dispatch = useDispatch();
    const [pageStatus, setPageStatus] = useState("1")
    const [status, setStatus] = useState(true)
    const unapproveRequest = useSelector((state) => state.Data.unapproveRequest);
    const pageloader = useSelector((state) => state.Data.pageloader)
    const rejectRequestData = useSelector((state) => state.Data.rejectRequestData)
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const [IsActive, setIsActive] = useState()
    const [confirmType, setConfirmType] = useState('')
    const [id, setId] = useState('')

    const columns = [
        {
            name: 'Request Date',
            selector: (row) => row.RequestDate,
            sortable: true,
        },
        {
            name: 'Request By',
            selector: (row) => row.Requestby,
            sortable: true,
        },
        {
            name: 'Item Code',
            selector: (row) => row.Product,
            sortable: true,
        },
        {
            name: 'Req Qty',
            selector: (row) => row.ReqQty,
            sortable: true,
        },

        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8,  fontWeight: '700' }}>Action</p>,
            cell: row => <>
                {pageStatus === "1" ? <Link to={'/Inventory?page=Reject Request'} onClick={setRejetStatus(row)} data-toggle="modal" data-target="#AddRejectModal" className="col-5 btn btn-sm bg-green text-white px-1 py-0 mr-1">
                Reject
                </Link> : 
                <Link to={'/Inventory?page=Reject Request'} onClick={setApprovedStatus(row)} data-toggle="modal" data-target="#AddRejectModal" className="col-5 btn btn-sm bg-green text-white px-1 py-0 mr-1">
                    Approved
                </Link> }
            </>
        }
    ]
    useEffect(() => {
        if (unapproveRequest.length === 0) dispatch(get_UnApprove_Request_Data(pageStatus))
        if(rejectRequestData.length === 0) dispatch(get_DeApprove_Request_Data(pageStatus))
    }, [pageStatus])

    const getApproveData = () => {
        dispatch(get_UnApprove_Request_Data(pageStatus))
        setPageStatus("1")
    }
    const getRejectData = () => {
        dispatch(get_DeApprove_Request_Data())
        setPageStatus("0")
    }
    const setApprovedStatus = (row) => {
        dispatch({ type: Get_Req_Id, payload: row.ID1 })
        setStatus(true);
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: Modal_Status, payload: true })
    }
       
    const setRejetStatus = (row) => {
        dispatch({ type: Get_Req_Id, payload: row.ID1 })
        setStatus(false);
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: Modal_Status, payload: true })
    }
    return (
        <>
            <div className="col-12 col-md-12 col-lg-12  px-3">
                <div className="col-12 col-md-6 col-lg-12 ">
                    <label className="custom-control custom-radio custom-control-inline">
                        <input type="radio" className="custom-control-input" name="marrow" defaultValue="arrow-b" defaultChecked />
                        <span className="custom-control-label" onClick={getApproveData}>Approve</span>
                    </label>
                    <label className="custom-control custom-radio custom-control-inline">
                        <input type="radio" className="custom-control-input" name="marrow" defaultValue="arrow-c" />
                        <span className="custom-control-label" onClick={getRejectData}>Reject</span>
                    </label>
                </div>
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">{openPage}</p>
                </div>
                <div className="row mt-2">
                    <div className="col-12  mb-3">
                        <div className="table-responsive">
                            <DataTable
                                columns={columns}
                                data={pageStatus === "1" ? unapproveRequest : rejectRequestData}
                                dense
                                pagination
                                selectableRowsHighlight
                                highlightOnHover
                            /> 
                        </div>
                    </div>
                </div>
            </div>
            <RejectRequest_Add_Up {...{status}}/>
        </>
    )
}

export default RejectRequest