import React from 'react'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Select from "react-select";
const GSTModel = () => {
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 35,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    return (
        <>
            <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="GSTModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog  modal-xl" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="m-1 ">
                                <fieldset style={{ border: '1px solid gray' }}>
                                    <legend >Party</legend>
                                    <div className="row" >
                                        <div className="col-2 col-md-3 col-lg-1 mt-2">
                                            <label htmlFor="" className='new-label'>GST</label>
                                        </div>
                                        <div class="col-3 col-md-7 col-lg-3 text-field mt-1">
                                            <input type="text" className='' name='Name' required />
                                        </div>
                                        <div className="col-1 pt-1 px-0">
                                            <Link to="#" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal"
                                            >
                                                <i className="fa fa-search"></i>
                                            </Link>
                                        </div>
                                        <div className="col-2 col-md-3 col-lg-2 mt-2">
                                            <label htmlFor="" className='new-label'>Mobile No.</label>
                                        </div>
                                        <div class="col-12 col-md-7 col-lg-2 text-field mt-1">
                                            <input type="text" className='' name='Name' required />
                                        </div>
                                        <div className="col-2 col-md-3 col-lg-1 mt-2">
                                            <label htmlFor="" className='new-label'>Balance</label>
                                        </div>
                                        <div class="col-12 col-md-7 col-lg-2 text-field mt-1">
                                            <input type="text" className='' name='Name' required />
                                        </div>
                                    </div>
                                    <div className="row mt-2" >
                                        <div className="col-2 col-md-3 col-lg-1 mt-2">
                                            <label htmlFor="" className='new-label'>Name</label>
                                        </div>
                                        <div class="col-12 col-md-7 col-lg-4 text-field mt-1">
                                            <input type="text" className='' name='Name' required />
                                        </div>
                                        <div className="col-2 col-md-3 col-lg-2 mt-2">
                                            <label htmlFor="" className='new-label'>Email Id</label>
                                        </div>
                                        <div class="col-12 col-md-7 col-lg-2 text-field mt-1">
                                            <input type="text" className='' name='Name' required />
                                        </div>
                                        <div className="col-2 col-md-3 col-lg-1 mt-2">
                                            <label htmlFor="" className='new-label'>Limit</label>
                                        </div>
                                        <div class="col-12 col-md-7 col-lg-2 text-field mt-1">
                                            <input type="text" className='' name='Name' required />
                                        </div>
                                    </div>
                                    <fieldset className='mt-3' style={{ border: '1px solid #b8c7db', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', borderRadius: '0px' }}>
                                        <legend >Address</legend>
                                        <div className="row " >
                                            <div className="col-2 col-md-2 col-lg-1 mt-2">
                                                <label htmlFor="" className='new-label'>Address</label>
                                            </div>
                                            <div class="col-12 col-md-10 col-lg-2 text-field mt-1">
                                                <textarea name='Comments' id="Comments" cols="30" rows='3' className="" placeholder='Address'></textarea>

                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2">
                                                <label htmlFor="" className='new-label'>State</label>
                                            </div>
                                            <div class="col-12 col-md-9 col-lg-2 text-field mt-1">
                                                <Select
                                                    name='state'
                                                    styles={customStylesWithOutColor}
                                                    isClearable
                                                    placeholder="Select.."
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2">
                                                <label htmlFor="" className='new-label'>District</label>
                                            </div>
                                            <div class="col-12 col-md-9 col-lg-2 text-field mt-1">
                                                <Select
                                                    name='state'
                                                    styles={customStylesWithOutColor}
                                                    isClearable
                                                    placeholder="Select.."
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2">
                                                <label htmlFor="" className='new-label'>Pin Code</label>
                                            </div>
                                            <div class="col-12 col-md-9 col-lg-2 text-field mt-1">
                                                <input type="text" className='' name='Name' required />
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div className="btn-box text-right  mr-1 mt-1">
                                        <button type="button" class="btn btn-sm btn-success mr-1">New</button>
                                        <button type="button" class="btn btn-sm btn-success mr-1">Save</button>
                                        <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" >Close</button>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="col-12 mt-1">
                            <DataTable
                                dense
                                paginationPerPage={'10'}
                                // columns={columns}
                                // data={searchChallanData}
                                // onClick={changeColor}
                                paginationRowsPerPageOptions={[5, 10, 15]}
                                highlightOnHover
                                noContextMenu
                                selectableRows
                                // onSelectedRowsChange={handleChange}
                                pagination
                                subHeaderAlign="right"
                                subHeaderWrap
                            />
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default GSTModel