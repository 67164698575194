import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import ConfirmModal from '../../../Include/Common/ConfirmModal';
import { delete_PhoneSms_Data, get_PhoneSms_Data, get_Single_PhoneSms_Data, } from '../../../../redux/actions/AllAction'
import { Edit_Update_Count, Get_Filter_PhoneSms_Data, Modal_Status } from '../../../../redux/actionTypes'
import Phone_Add_Up from './Phone_Add_Up';
import { getShowingDayWithOutTime } from '../../../Include/Common/Utility';
import { Dropdown } from 'react-bootstrap';
import { Three_Search_Filter } from '../../../Filter/Filter';

const PhoneSms = () => {
  const dispatch = useDispatch()
  const phoneSmsData = useSelector((state) => state.Data.phoneSmsData)
  const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
  const filterPhoneSmsData = useSelector((state) => state.Data.filterPhoneSmsData)
  const userId = useSelector((state) => state.Data.userId)
  const [pageStatus, setPageStatus] = useState("1")
  const [status, setStatus] = useState(false)
  const [confirmType, setConfirmType] = useState('Delete')
  const [IsActive, setIsActive] = useState()
  const [id, setId] = useState('')
  const [filterTypeIdOption, setfilterTypeIdOption] = useState('Contains');
  const [filterTypeDescOption, setfilterTypeDescOption] = useState('Contains');
  const [filterTypeAddOption, setfilterTypeAddOption] = useState('Contains');
  const [searchValue1, setSearchValue1] = useState('')
  const [searchValue2, setSearchValue2] = useState('')
  const [searchValue3, setSearchValue3] = useState('')

  useEffect(() => {
    dispatch(get_PhoneSms_Data(pageStatus))
  }, [pageStatus]);

  const columns = [
    {
      name: 'PhoneNumber',
      selector: (row) => row.PhoneNumber,
      sortable: true
    },
    {
      name: 'SMSBody',
      selector: (row) => row.SMSBody?.length > 10 ? row?.SMSBody?.split(/\s+/)?.slice(0, 5)?.join(" ") :row?.SMSBody ,
      sortable: true
    },
    {
      name: 'SMSDate',
      // selector: (row) => row.SMSDate,
      selector: (row) => getShowingDayWithOutTime(parseInt(row?.SMSDate)),
      sortable: true
    },

    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 55, fontWeight: '700' }}>Action</p>,
      cell: row => <>
        <div style={{ position: 'absolute', top: 4, right: 40 }}>
          {pageStatus === "1" ?
            <Link to='/AdminSetting?sub=Phone%20SMS' className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn" onClick={() => setEditStatus(row)} data-toggle="modal" data-target="#AddPhoneModal"><i className="fa fa-edit"></i>
            </Link> : ''}
          <Link to={`/AdminSetting?sub=Phone%20SMS`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('0'); setConfirmType("Delete"); setId(row.ID) }}
            className="btn btn-sm bg-green text-white px-1 py-0 mr-1" >
            <i class="fa fa-trash" aria-hidden="true"></i>
          </Link>
        </div>
      </>
    }
  ]
  const setEditStatus = (row) => {
    dispatch(get_Single_PhoneSms_Data(row.ID)); setId(row.ID)
    setStatus(true);
    dispatch({ type: Modal_Status, payload: true })
    dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
  }
  const UpdActiveDeactive = () => {
    dispatch(delete_PhoneSms_Data(id, IsActive, userId, pageStatus))
  }
  return (
    <>
      <div className="col-12 col-md-12 col-lg-12  px-3">
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">Phone SMS</p>
        </div>
      </div>
      <div className="col-12 mt-2 px-3">
                <div className="row">
                    <div className="col-3">
                        <input type="text" value={searchValue1} onChange={(e) => {
                            setSearchValue1(e.target.value);
                            const result = Three_Search_Filter(phoneSmsData, e.target.value, searchValue2, searchValue3, filterTypeIdOption, 'PhoneNumber', 'SMSBody', 'Reason')
                            dispatch({ type: Get_Filter_PhoneSms_Data, payload: result });
                        }}
                            className='form-control' placeholder='Search By Phone No...' />
                    </div>
                    <div className='col-1 '>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeIdOption('End with')}>End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="col-3">
                        <input type="text" value={searchValue2} onChange={(e) => {
                            setSearchValue2(e.target.value);
                            const result = Three_Search_Filter(phoneSmsData, searchValue1, e.target.value, searchValue3, filterTypeAddOption, 'PhoneNumber', 'StatusDate', 'SMSDate')
                            dispatch({ type: Get_Filter_PhoneSms_Data, payload: result });
                        }}
                            className='form-control' placeholder='Search By  SmsBody...' />
                    </div>
                    <div className='col-1'>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('Contains')}>Contains</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('is equal to')}>is equal to</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('is not equal to')}>is not equal to </Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('Starts With')}>Starts With</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeAddOption('End with')}>End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="col-3">
                        <input type="text" value={searchValue3} onChange={(e) => {
                            setSearchValue3(e.target.value);
                            const result = Three_Search_Filter(phoneSmsData, searchValue1, searchValue2, e.target.value, filterTypeDescOption,'PhoneNumber', 'StatusDate', 'Reason')
                            dispatch({ type: Get_Filter_PhoneSms_Data, payload: result });
                        }}
                            className='form-control' placeholder='Search By SmsDate....' />
                    </div>
                    <div className='col-1 '>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=' nav-filter-dropdown'>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                                <Dropdown.Item onClick={() => setfilterTypeDescOption('End with')}>End with</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
      <div className="col-12 mt-1">
        <DataTable
          dense
          columns={columns}
          data={filterPhoneSmsData}
          pagination
          selectableRowsHighlight
          highlightOnHover
        />
      </div>
      <Phone_Add_Up {... { status, pageStatus }} />
      <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />
    </>
  )
}

export default PhoneSms;