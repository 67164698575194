import React, { useState } from 'react'
import { SalePartyTabs } from '../../Utility/TabsArray';
import Home from './SalePartyTab/Home/Home';
import BankDetails from './SalePartyTab/BankDetails/BankDetails';
import { useDispatch, useSelector } from "react-redux";
import SubTab from '../../Utility/SubTab';


const SalePartyTab = () => {
    const [showPage, setShowPage] = useState('home');
    const pageStatus = useSelector((state) => state.Data.pageStatus)
    const [status, setStatus] = useState()
    return (
        <>
            <div className="col-12 col-sm-12">
                <div className="card Agency">
                    <div className="card-body">
                        <div className="row ">
                            <div className={`col-12 col-md-12`}>
                                <div className="row">
                                    <div className="col-12 pl-3 ">
                                        <SubTab tabs={SalePartyTabs} showPage={showPage} setShowPage={setShowPage} status={pageStatus}/>
                                    </div>
                                </div>
                            </div>
                            {
                                showPage === 'home' ?
                                    <Home />
                                    :
                                showPage === 'bankdetails' ?
                                    <BankDetails />
                                    :


                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SalePartyTab