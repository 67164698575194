import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux';
import RTODetails_Add from './RTODetails_Add'
import Loader from '../../../../../Include/Common/Loader';
import { delete_Equipment_Rto_Data, get_EquipmentRto_Data, get_Single_Rto_Data, } from '../../../../../../redux/actions/AllAction'
import { Edit_Update_Count, MasterTable_Modal_Status, Update_Status } from '../../../../../../redux/actionTypes';
import ConfirmModal from '../../../../../Include/Common/ConfirmModal';
import { getShowingDayWithOutTime } from '../../../../../Include/Common/Utility';


const RTODetails = () => {
    const dispatch = useDispatch();
    const equipmentRtoData = useSelector((state) => state?.Data?.equipmentRtoData)
    const [status, setStatus] = useState(false)
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const masterTableLoader = useSelector((state) => state.Data.masterTableLoader)
    const vechicleID = useSelector((state) => state?.Data?.vechicleID)
    const userId = useSelector((state) => state.Data.userId)
    const [pageStatus, setPageStatus] = useState("1")
    const [IsActive, setIsActive] = useState()
    const [id, setId] = useState('')
    const [confirmType, setConfirmType] = useState('')

    useEffect(() => {
        if (vechicleID) dispatch(get_EquipmentRto_Data(vechicleID, pageStatus));
    }, [pageStatus])

    const columns = [
        {
            name: 'Road Tax Amount',
            selector: (row) => row.TaxAmt,
            sortable: true
        },

        {
            name: 'Renewal Date',
            selector: (row) => row.RenewDate,
            sortable: true
        },
        {
            name: 'Expiry Date',
            selector: (row) => row.ExpiryDate,
            sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 55, fontWeight: '700' }}>Action</p>,
            cell: row => <>
                <div style={{ position: 'absolute', top: 4, right: 40 }}>

                    <Link to={`/vehicle-details`} data-toggle="modal" onClick={(e) => setEditValue(row)} data-target="#RTOModal" className="btn btn-sm bg-green text-white px-1 py-0 mr-1"><i className="fa fa-edit"></i>
                    </Link>  <></>
                    <Link to='/vehicle-details' data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('0'); setConfirmType("Delete"); setId(row.ID) }}
                        className="btn btn-sm bg-green text-white px-1 py-0 mr-1" >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </Link>
                </div>
            </>
        }
    ]
    const setEditValue = (row) => {
        dispatch(get_Single_Rto_Data(row.ID)); setStatus(true)
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: MasterTable_Modal_Status, payload: true })
    }
    const newStatus = () => {
        dispatch(get_Single_Rto_Data('')); setStatus(false)
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: MasterTable_Modal_Status, payload: true })
    }

    const UpdActiveDeactive = () => {
        dispatch(delete_Equipment_Rto_Data(id, IsActive, userId, pageStatus, vechicleID))
    }

    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">RTO Details</p>
                    <div style={{ marginLeft: 'auto' }}>
                        <Link to={'/vehicle-details'} className="btn btn-sm  text-white px-2 py-0" onClick={newStatus} data-toggle="modal" data-target="#RTOModal">
                            <i className="fa fa-plus"></i>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1">
                {masterTableLoader ?
                    <DataTable
                        dense
                        columns={columns}
                        data={equipmentRtoData}
                        pagination
                        selectableRowsHighlight
                        highlightOnHover
                    /> : <Loader />}
            </div>
            <RTODetails_Add {...{ status, setStatus, }} />
            <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />
        </>
    )
}

export default RTODetails