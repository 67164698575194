import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

const Main = () => {
    const menuData = useSelector((state) => state.Data.menuData);

    const [searchValue, setSearchValue] = useState(null);
    var rgxp = new RegExp(searchValue?.toLowerCase() + ".*");

    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line mt-1 d-flex justify-content-between align-items-center">
                    <p className="px-3 p-0 m-0 d-flex align-items-center">Attendance Report</p>
                    <div style={{ marginLeft: 'auto' }}>
                        <div className='p-1'>
                            <input type="text" class="form-control" style={{ color: 'black' }} onChange={(e) => setSearchValue(e.target.value)} placeholder='Search .......' required />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-12 col-lg-12 pt-2 px-4  new-scroll  printElement1" >
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, 300px)', gap: '20px' }}>
                    {
                        menuData?.map((item) => {
                            if (item.MenuName === 'Master Tables') {
                                return item?.Submenuclass?.map((val, i) => {
                                    if (val?.ChildSubmenuclass.length > 0) {
                                        return (
                                            <div className="card " style={{
                                                height: '19.5rem', boxShadow: '1px 1px 1px gray'
                                            }} key={i}>

                                                <div className=" p-0 " >
                                                    <h5 className="card-header d-flex pl-2" style={{ color: '#000000d9', fontWeight: '600', borderBottom: '2px solid #1773ea', padding: "10px  0px", fontSize: '18px', background: 'aliceblue', width: '100%', position: 'sticky', top: '0px', justifyContent: "space-between" }}>
                                                        {val.MenuName}
                                                        <span className='d-flex col-6'>
                                                            {/* <input type="search" className=' form-control
                                                            ' name="" id="" placeholder='Search...' /> */}
                                                            {/* <i className='fa fa-search search-field"' style={{ position: 'absolute', right: '11px', top: '-7px', fontSize: "15px", color: 'darkgrey' }}></i> */}
                                                            <input type="search" className="search-field" placeholder="Search …"  />
                                                            {/* <i className='fa fa-close' style={{position:'absolute',right:'11px',top:'-7px',fontSize:"15px", color:'darkgrey'}}></i> */}
                                                        </span>
                                                    </h5>
                                                    {/* <hr /> */}
                                                </div>
                                                <div className="card-body p-0" style={{ overflowY: 'auto', }}>
                                                    {val?.ChildSubmenuclass?.map((child) => (<Link to={`?page=${child?.MenuName}`} style={{ textDecoration: 'none' }}><p className="card-text master-list" style={{ background: child?.MenuName?.toLowerCase()?.match(rgxp) && searchValue ? 'burlywood' : '', color: '#000' }}>{child.MenuName} </p></Link>))}
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                                )
                            }
                        }
                        )
                    }

                </div>
            </div>


        </>
    )
}

export default Main