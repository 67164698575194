import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DailyRunning from './DailyRunning/DailyRunning';
import MaintenanceForm from './MaintenanceFrom/MaintenanceForm';
import MaintenanceReport from './MaintenanceReport/MaintenanceReport';
import VehicleDetailsForm from './VehicleDetailsForm/VehicleDetailsForm';
import VehicleJobDetails from './VehicleJobDetails/VehicleJobDetails';
import MachineService from './MachineService/MachineService';
import VehicleDetailsTab from './VehicleDetailsForm/VehicleDetailsTab';
import MeterReading from './MeterReading/MeterReading';
import PowerConsuption from './PowerConsumption/PowerConsuption';

const PlantTransport = () => {

    const dispatch = useDispatch();
    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get('sub');

    // const companySingelData = useSelector((state) => state.Data.companySingelData)
    // const companyId = useSelector((state) => state.Data.companyId)

    // useEffect(() => {
    //     if (companySingelData.length === 0) dispatch(get_Single_Company_Data(companyId))
    // }, [])
    return (
        <>
            {
                openPage === 'Daily Running' &&
                <DailyRunning />
            }
            {
                openPage === 'Maintenance Form' &&
                <MaintenanceForm />
            }
            {
                openPage === 'Maintenance Report Fill' &&
                <MaintenanceReport />
            }
            {
                openPage === 'Vehicle Details Form' &&
                <VehicleDetailsForm />
            }
            {
                openPage === 'Vehicle Job Details' &&
                <VehicleJobDetails />
            }
            {
                openPage === 'Vehicle/Machine Service' &&
                <MachineService />
            }
            {
                openPage === 'vehicle-details' && 
                <VehicleDetailsTab />
            }
            {
                openPage === 'Meter Reading' &&
                <MeterReading />
            }
            {
                openPage === 'Power Consumption' &&
                <PowerConsuption/>
            }
        
        </>
    )
}

export default PlantTransport