import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from "react-redux";
import { SpaceNotAllow, SpaceNotAllowWithoutReq } from "../../../../../Validation/Validation";
import { getShowingDayWithOutTime, getShowingWithOutTime, getShowingWithOutTimeStartDate } from '../../../../../Include/Common/Utility';
import { save_Equipment_Rto_Data, update_Equipment_Rto_Data } from '../../../../../../redux/actions/AllAction';


const RTODetails_Add = (props) => {
    const dispatch = useDispatch();
    const { status, pageStatus, ID } = props
    const singleRtoData = useSelector((state) => state?.Data?.singleRtoData[0])
    const masterTableModalStatus = useSelector((state) => state.Data.masterTableModalStatus)
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const vechicleID = useSelector((state) => state?.Data?.vechicleID)
    const userId = useSelector((state) => state.Data.userId)

    const [value, setValue] = useState({
        EquipmentVehID: vechicleID,
        TaxAmt: '',
        RenewDate: '',
        ExpiryDate: '',
        RenewDate1: '',
        ExpiryDate1: '',
        ID: '',
        Updatedbyid: '',
    });
    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
        }
    };
    const [errors, setErrors] = useState({
        'TaxAmmountError': '', 'RenewDateError': '', 'ExpiryError': '',
    });
    const { TaxAmmountError, RenewDateError, ExpiryError } = errors;

    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.TaxAmt)) {
            setErrors((prevValues) => { return { ...prevValues, ["TaxAmmountError"]: SpaceNotAllow(value.TaxAmt) } });
        }
        if (SpaceNotAllow(value.RenewDate)) {
            setErrors((prevValues) => { return { ...prevValues, ["RenewDateError"]: SpaceNotAllow(value.RenewDate) } });
        }
        if (SpaceNotAllow(value.ExpiryDate)) {
            setErrors((prevValues) => { return { ...prevValues, ["ExpiryError"]: SpaceNotAllow(value.ExpiryDate) } });
        }
    }

    useEffect(() => {
        if (vechicleID) setValue(prs => { return { ...prs, ['EquipmentVehID']: vechicleID } })
    }, [vechicleID])

    const handelChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    }
    useEffect(() => {
        if (singleRtoData) {
            setValue({
                ...value,
                EquipmentVehID: singleRtoData?.EquipmentVehID,
                TaxAmt: singleRtoData?.TaxAmt,
                RenewDate: singleRtoData?.RenewDate,
                ExpiryDate: singleRtoData?.ExpiryDate,
                RenewDate1: singleRtoData?.RenewDate?.split("/").reverse().join("/"),
                ExpiryDate1: singleRtoData?.ExpiryDate?.split("/").reverse().join("/"),
                ID: singleRtoData?.ID,
                Updatedbyid: userId
            })
        }
        else {
            setValue({
                ...value,
                TaxAmt: '',
                RenewDate: '',
                ExpiryDate: '',
                RenewDate1: '',
                ExpiryDate1: '',
                ID: '',
                Updatedbyid: ''
            })
        }
    }, [singleRtoData, editUpdateCount])

    useEffect(() => {
        if (TaxAmmountError === "true" && RenewDateError === "true" && ExpiryError === "true") {
            if (status) {
                dispatch(update_Equipment_Rto_Data(value, vechicleID)); setErrors({ ...value['TaxAmmountError'] })
            }
            else {
                dispatch(save_Equipment_Rto_Data(value, vechicleID)); setErrors({ ...value['TaxAmmountError'] })
            }
        }
    }, [TaxAmmountError, RenewDateError, ExpiryError])

    const close_modal = () => {
        // dispatch({ type: Get_Single_Rto_Data, payload:[] });
        setValue({
            TaxAmt: '',
            RenewDate: '',
            ExpiryDate: '',
            RenewDate1: '',
            ExpiryDate1: '',
            ID: ''
        });
        setErrors({
            'TaxAmmountError': '', 'RenewDateError': '', 'ExpiryError': '',
        })

    }
    return (
        <>
            {masterTableModalStatus &&
                <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="RTOModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="m-1 ">
                                    <fieldset style={{ border: '1px solid gray' }}>
                                        <legend >RTO Details</legend>
                                        <div className="form-row" style={{ marginTop: '-10px' }}>
                                            <div className="col-4 col-md-6 col-lg-4 mt-1 ">
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='TaxAmt'
                                                        className="requiredColor"
                                                        id='TaxAmt'
                                                        required
                                                        value={value.TaxAmt}
                                                        onChange={handelChange}
                                                    />
                                                    {errors.TaxAmmountError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.TaxAmmountError}
                                                        </span>
                                                    ) : null}
                                                    <label className='pt-1'>Road Tax Amount</label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-6 col-lg-4 ">
                                                <div className="dropdown__box">
                                                    <DatePicker
                                                        id='RenewDate'
                                                        name='RenewDate'
                                                        ref={startRef}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => date ? setValue(pre => { return { ...pre, ['RenewDate']: getShowingDayWithOutTime(date), ['RenewDate1']: getShowingWithOutTimeStartDate(date) } }) :
                                                            setValue(pre => { return { ...pre, ['RenewDate']: '', ['RenewDate1']: '' } })
                                                        }
                                                        className="requiredColor"
                                                        dateFormat="dd/MM/yyyy"
                                                        timeInputLabel
                                                        isClearable={value?.RenewDate ? true : false}
                                                        selected={value.RenewDate1 && new Date(value?.RenewDate1)}
                                                        placeholderText={'Select...'}
                                                        dropdownMode="select"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                    {errors.RenewDateError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.RenewDateError}
                                                        </span>
                                                    ) : null}
                                                    <label htmlFor="">Road Tax Renewal Date</label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-6 col-lg-4 ">
                                                <div className="dropdown__box">
                                                    <DatePicker
                                                        id='ExpiryDate'
                                                        name='ExpiryDate'
                                                        ref={startRef1}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => date ? setValue(pre => { return { ...pre, ['ExpiryDate']: getShowingDayWithOutTime(date), ['ExpiryDate1']: getShowingWithOutTimeStartDate(date) } }) :
                                                            setValue(pre => { return { ...pre, ['ExpiryDate']: '', ['ExpiryDate1']: '' } })
                                                        }
                                                        className="requiredColor"
                                                        dateFormat="dd/MM/yyyy"
                                                        timeInputLabel
                                                        isClearable={value?.ExpiryDate ? true : false}
                                                        selected={value?.ExpiryDate1 && new Date(value?.ExpiryDate1)}
                                                        placeholderText={'Select...'}
                                                        dropdownMode="select"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                    {errors.ExpiryError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.ExpiryError}
                                                        </span>
                                                    ) : null}
                                                    <label htmlFor="">Road Tax Exp Date</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div className="btn-box text-right  mr-1 mb-2">
                                {status ?
                                    <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button> :
                                    <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Save</button>}

                                <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={close_modal} >Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default RTODetails_Add