import React, { useState } from 'react'
import DatePicker from 'react-datepicker';

const Documents_Add_Up = () => {
    // custuom style withoutColor
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    const [toDate, settoDate] = useState();
    const [value, setValue] = useState();
    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();
    const startRef3 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
        }
    };
    return (
        <>
            <div class="modal fade" style={{ background: "rgba(0,0,0, 0.5)" }} id="DocumentModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document" >
                    <div class="modal-content">
                        <div class="modal-body">
                            <div className="m-1 ">
                                <fieldset style={{ border: '1px solid gray' }}>
                                    <legend style={{ fontWeight: 'bold' }}>Document</legend>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-row"  style={{marginTop:'-10px'}}>
                                                <div class="col-6 col-md-6 col-lg-4" style={{ marginTop: '-5px' }}>
                                                    <div className="dropdown__box">
                                                        <DatePicker
                                                            ref={startRef2}
                                                            onKeyDown={onKeyDown}
                                                            id='toDate'
                                                            name='toDate'
                                                            className=''
                                                            dateFormat="MM/dd/yyyy HH:mm"
                                                            onChange={(date) => { settoDate(date); setValue({ ...value, ['toDate']: date ? (date) : null }) }}
                                                            timeInputLabel
                                                            isClearable
                                                            placeholderText={value?.toDate ? value?.toDate : 'Select...'}
                                                            selected={toDate}
                                                            timeIntervals={1}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                        />
                                                        <label htmlFor="" className='pl-0 pt-1'>Date/Time</label>

                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-8 ">
                                                    <div class="text-field">
                                                        <input
                                                            type="text"
                                                            name='name'
                                                            className=""
                                                            id='name'
                                                            required
                                                        />
                                                        <label className='pt-1'>Document Name</label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-12  col-lg-12 mt-3">
                                                    <div className="text-field ">
                                                        <input type="file" name='DocumentFile' multiple required />
                                                        <label>File Attachement</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="btn-box text-right  mr-1 mb-2">
                            <button type="button" class="btn btn-sm btn-success mr-1">Save</button>
                            <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" >Close</button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Documents_Add_Up