import React, { useState, useEffect } from 'react'
import Select from "react-select";
import { getShowingDayWithOutTime, getShowingDayWithTime, getShowingWithOutTime, getShowingWithOutTimeStartDate } from '../../../Include/Common/Utility';
import DatePicker from 'react-datepicker'
import { Link, useNavigate } from 'react-router-dom';
import { changeArrayFormatDrop } from '../../../Include/Common/ArrayFormat';
import { get_Employee_Data, get_Employee_Task_Detail_Data, get_Status_Drop_Data, get_Task_Drop_Data, save_Employee_Task_Data, save_Employee_Task_Detail_Data, update_Employee_Task_Data } from '../../../../redux/actions/AllAction';
import { useDispatch, useSelector } from 'react-redux';
import { Get_Emp_Task_Detail_Data, Get_Error_Status, Replay_Status } from '../../../../redux/actionTypes';
import { SpaceNotAllow, RequiredFieldSelectBox, SpaceNotAllowWithoutReq } from '../../../Validation/Validation';
import user from "../../../../Component/img/download.png"
import MasSideBar from '../../../Include/Common/MasSideBar';
const EmpTask_Add_Up = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const employeeData = changeArrayFormatDrop(useSelector((state) => state.Data.employeeData), 'EmpName', 'ID');
    const userId = useSelector((state) => state.Data.userId);
    const taskTypeDropData = changeArrayFormatDrop(useSelector((state) => state.Data.taskTypeDropData),'Description','ID');
    const Status = useSelector((state) => state.Data.Status);
    const editId = useSelector((state) => state.Data.editId);
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount);
    const empTaskDetailData = useSelector((state) => state.Data.empTaskDetailData);
    const empTaskDropDown = changeArrayFormatDrop(useSelector((state) => state.Data.empTaskDropDown),'TaskStatus','ID');
    const errorStatus = useSelector((state) => state.Data.errorStatus);
    const singleEmpTaskData = useSelector((state) => state.Data.singleEmpTaskData[0]);
    const replayStatus = useSelector((state) => state.Data.replayStatus);
    const [urlName, setUrlName] = useState('');
    const [showCol, setShowCol] = useState('');
    const [idName, setIdName] = useState('');
    const [actionType, setActionType] = useState('');
    const [value, setValue] = useState({
        TaskTypeID: '',
        Tasktype: '',
        AssignEmpID: '',
        AssignEmpName: '',
        StatusID: '',
        StatusName: '',
        AssignDate: getShowingDayWithOutTime(new Date()),
        AssignDate1: new Date(),
        // IsDone: 0,
        ExpectedDate: '',
        ExpectedDate1: '',
        Donedate: '',
        Task: '',
        Updatedbyid: '',
        ID: '',
        Createdbyid: userId
    })

    const [task, setTask] = useState('')

    const [errors, setErrors] = useState({ TasktypeError: '', AssignEmpNameError: '', ExpectedDateError: '', TaskError: '' })

    useEffect(() => { if (employeeData.length === 0) dispatch(get_Employee_Data('1')); dispatch(get_Task_Drop_Data()) }, []);

    useEffect(() => { if (Status && editId) { dispatch(get_Employee_Task_Detail_Data(editId)) } else dispatch({ type: Get_Emp_Task_Detail_Data, payload: [] }) }, [Status, editUpdateCount])
   
    useEffect(() => {
        if (singleEmpTaskData) {
            setValue({
                ...value,
                // IsDone: singleEmpTaskData?.IsDone,
                TaskTypeID: singleEmpTaskData?.TaskTypeID, StatusID: singleEmpTaskData?.StatusID, StatusName: singleEmpTaskData?.StatusName, Tasktype: singleEmpTaskData?.Tasktype, AssignEmpID: singleEmpTaskData?.AssignEmpID, AssignEmpName: singleEmpTaskData?.AssignEmpName, 
              AssignDate: singleEmpTaskData?.AssignDate,  AssignDate1: singleEmpTaskData?.AssignDate?.split("/")?.reverse()?.join("/"), ExpectedDate: singleEmpTaskData?.ExpectedDate, ExpectedDate1: singleEmpTaskData?.ExpectedDate?.split("/").reverse().join("/"), Donedate: singleEmpTaskData?.Donedate, Task: singleEmpTaskData?.Task, Updatedbyid: userId, ID: singleEmpTaskData?.ID,
            })
        } else resetHook()
    }, [singleEmpTaskData, editUpdateCount])

    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
        }
    }

    const handleChange = (type) => e => {
        if (type === 'check') {
            setValue(pre => { return { ...pre, [e.target.name]: e.target.checked, ['Donedate']: new Date() } })
        } else {
            setValue(pre => { return { ...pre, [e.target.name]: e.target.value } });
            setTask(e.target.value)
        }
    }

    const changeDropdown = (name, id) => e => {
        if (e) {
            setValue(pre => { return { ...pre, [name]: e.label, [id]: e.value } })
        } else {
            setValue(pre => { return { ...pre, [name]: null, [id]: null } })
        }
    }
    const check_Validation_Error = (e) => {
        e.preventDefault()
        const { Tasktype, AssignEmpName, ExpectedDate, Task } = value
        if (RequiredFieldSelectBox(Tasktype)) {
            setErrors(prevValues => { return { ...prevValues, ['TasktypeError']: RequiredFieldSelectBox(Tasktype) } })
            dispatch({ type: Get_Error_Status, payload: true })
        }
        if (RequiredFieldSelectBox(AssignEmpName)) {
            setErrors(prevValues => { return { ...prevValues, ['AssignEmpNameError']: RequiredFieldSelectBox(AssignEmpName) } })
        }
        if (RequiredFieldSelectBox(ExpectedDate)) {
            setErrors(prevValues => { return { ...prevValues, ['ExpectedDateError']: RequiredFieldSelectBox(ExpectedDate) } })
        } if (SpaceNotAllowWithoutReq(Task)) {
            setErrors(prevValues => { return { ...prevValues, ['TaskError']: SpaceNotAllowWithoutReq(Task) } })
        }
    }

    const { TaskError, ExpectedDateError, AssignEmpNameError, TasktypeError } = errors

    useEffect(() => {
        if (TaskError === 'true' && ExpectedDateError === 'true' && AssignEmpNameError === 'true' && TasktypeError === 'true' && errorStatus) {
            if (Status) {
                if (task) dispatch(save_Employee_Task_Detail_Data(task, editId, userId))
                dispatch(update_Employee_Task_Data(value))
            }
            else { dispatch(save_Employee_Task_Data(value, NavigateUrl)) }
        }
    }, [TaskError, ExpectedDateError, AssignEmpNameError, TasktypeError, errorStatus])

    const resetHook = () => {
        setValue({
            ...value, TaskTypeID: '', Tasktype: '', AssignEmpID: '', AssignEmpName: '', AssignDate: new Date(), IsDone: '', ExpectedDate: '', ExpectedDate1: '', Donedate: '', Task: '', Updatedbyid: '', Createdbyid: userId
        })
    }
    const setUrls = (idName, urlName, showCol, actionType) => {
        setIdName(idName); setUrlName(urlName); setShowCol(showCol); setActionType(actionType)
    }
    const NavigateUrl = () => {
        navigate("/HR?sub=Emp-Task")
    }
    return (
        <>
            <div className="col-12 col-sm-12">
                <div className="card Agency">
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-12 col-md-12 col-lg-12  px-3">
                                <div className="bg-line  py-1 px-2  d-flex justify-content-between align-items-center">
                                    <p className="p-0 m-0 d-flex align-items-center">Employee Task</p>
                                    <div style={{ marginLeft: 'auto' }}>
                                        {
                                            !replayStatus &&
                                            <>
                                                <Link to={'#'} onClick={() => dispatch({ type: Replay_Status, payload: true })} className="btn btn-sm  text-white px-2 py-0" >
                                                    <i className="fa fa-pencil"></i>
                                                </Link>
                                            </>
                                        }
                                        <Link to={'/employee-home'} className="btn btn-sm  text-white px-2 py-0" >
                                            <i className="fa fa-close"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-10 px-3">
                                <div className="form-row">
                                    <div className="col-3 col-md-4 col-lg-3 pt-2">
                                        <div class="text-field">
                                            <input
                                                type="text"
                                                name='taskno'
                                                className="readonlyColor"
                                                id='taskno'
                                                required
                                                readOnly
                                                placeholder='Auto Generated'
                                            />
                                            <label className='pt-1'>Task Number</label>
                                        </div>
                                    </div>
                                    <div className="col-3 col-md-3 col-lg-3"></div>
                                    <div className="col-4 col-md-4 col-lg-3 d-flex px-0 mt-2">
                                        <div className="col-12 col-md-12 col-lg-10    ">
                                            <div className="dropdown__box">
                                                <Select
                                                    name="Tasktype"
                                                    styles={colourStyles}
                                                    isClearable
                                                    isDisabled={Status}
                                                    onChange={changeDropdown('Tasktype', 'TaskTypeID')}
                                                    value={taskTypeDropData?.filter((obj) => obj.value === value.TaskTypeID)}
                                                    options={taskTypeDropData}
                                                    placeholder="Select.."
                                                />
                                                <label htmlFor="" className='pl-0'>Type</label>
                                                {errors.TasktypeError !== 'true' ? (
                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.TasktypeError}</span>
                                                ) : null}
                                            </div>
                                        </div>
                                        {Status ? '' :
                                        <div className="col-1 col-md-1 col-lg-1   px-0 py-0" style={{ marginTop: '14px' }}>
                                            <Link to="/employee-task" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal" onClick={() => setUrls('ID', 'TaskType', 'Description', 'get_Task_Drop_Data')} >
                                                <i className="fa fa-plus"></i>
                                            </Link>
                                        </div> }

                                    </div>
                                    <div className=" col-lg-3"></div>


                                    <div className="col-4 col-md-4 col-lg-3 d-flex px-0 mt-2 pt-1">
                                        <div className="col-12 col-md-12 col-lg-10     ">
                                            <div className="dropdown__box">
                                                <Select
                                                    name="AssignEmpName"
                                                    styles={colourStyles}
                                                    isClearable
                                                    isDisabled={Status}
                                                    onChange={changeDropdown('AssignEmpName', 'AssignEmpID')}
                                                    value={employeeData?.filter((obj) => obj.value === value.AssignEmpID)}
                                                    options={employeeData}
                                                    placeholder="Select.."
                                                />
                                                <label htmlFor="" className='pl-0'>Assignee</label>
                                                {errors.AssignEmpNameError !== 'true' ? (
                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.AssignEmpNameError}</span>
                                                ) : null}
                                            </div>
                                        </div>
                                        {Status ? '' :
                                        <div className="col-1 col-md-1 col-lg-1   px-0 py-0" style={{ marginTop: '14px' }}>
                                            <Link to="/employee-task" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal" onClick={() => setUrls('ID', 'Employee', 'EmpName', 'get_Employee_Data')}>
                                                <i className="fa fa-plus"></i>
                                            </Link>
                                        </div>
}
                                    </div>
                                    <div className="col-3 col-md-3 col-lg-3"></div>
                                    <div className="col-4 col-md-4 col-lg-3 d-flex px-0 mt-2">
                                        <div className="col-12 col-md-12 col-lg-10    ">
                                            <div className="dropdown__box">
                                                <Select
                                                    name="StatusName"
                                                    styles={colourStyles}
                                                    isClearable
                                                    isDisabled={Status}
                                                    onChange={changeDropdown('StatusName', 'StatusID')}
                                                    value={empTaskDropDown?.filter((obj) => obj.value === value.StatusID)}
                                                    options={empTaskDropDown}
                                                    placeholder="Select.."
                                                />
                                                <label htmlFor="" className='pl-0'>Status</label>
                                            </div>
                                        </div>
                                        {Status ? '' :
                                        <div className="col-1 col-md-1 col-lg-1   px-0 py-0" style={{ marginTop: '14px' }}>
                                            <Link to="/employee-task" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal" onClick={() => setUrls('ID', 'TaskStatus', 'EmpStatus', 'get_Task_Drop_Data')} >
                                                <i className="fa fa-plus"></i>
                                            </Link>
                                        </div>
}
                                    </div>
                                    <div className="col-lg-3"></div>
                                    {/* 
                                        <div className="col-6 col-md-6 col-lg-3 pt-2 mt-1">
                                            <div class="text-field">
                                                <input
                                                    type="text"
                                                    name='CREAT'
                                                    className=""
                                                    id='CREAT'
                                                    required
                                                    readOnly
                                                />
                                                <label className='pt-1'>Create By</label>
                                            </div>
                                        </div> */}
                                    <div className="col-12 col-md-4 col-lg-3 mt-2">
                                        <div className="dropdown__box">
                                            <DatePicker
                                                id='AssignDate'
                                                name='AssignDate'
                                                dateFormat="MM/dd/yyyy"
                                                disabled={Status}
                                                isClearable={value.AssignDate1 ? true : false}
                                                onChange={(date) => date ? setValue(pre => { return { ...pre, ['AssignDate']: getShowingDayWithOutTime(date), ['AssignDate1']: getShowingWithOutTime(date) } }) :
                                                setValue(pre => { return { ...pre, ['AssignDate']: '', ['AssignDate1']: '' } })
                                            }
                                                selected={value?.AssignDate1 && new Date(value?.AssignDate1)}
                                                placeholderText={value.AssignDate1 ? value.AssignDate1 : 'Select...'}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete='Off'
                                                minDate={new Date()}
                                            />
                                            <label htmlFor="" className='pl-0 pt-1' >Created Date</label>
                                        </div>
                                    </div>
                                    <div className="col-3"></div>
                                    <div className="col-12 col-md-4 col-lg-3 mt-2">
                                        <div className="dropdown__box">
                                            <DatePicker
                                                id='TodoDate'
                                                name='TodoDate'
                                                ref={startRef}
                                                onKeyDown={onKeyDown}
                                                onChange={(date) => date ? setValue(pre => { return { ...pre, ['ExpectedDate']: getShowingDayWithOutTime(date), ['ExpectedDate1']: getShowingWithOutTimeStartDate(date) } }) :
                                                    setValue(pre => { return { ...pre, ['ExpectedDate']: '', ['ExpectedDate1']: '' } })
                                                }
                                                className='requiredColor'
                                                dateFormat="MM/dd/yyyy"
                                                // disabled={Status}
                                                timeInputLabel
                                                isClearable
                                                selected={value.ExpectedDate1 && new Date(value.ExpectedDate1)}
                                                placeholderText={'Select...'}
                                                minDate={new Date()}
                                                dropdownMode="select"
                                                showMonthDropdown
                                                showYearDropdown
                                            />
                                            <label htmlFor="" className='pl-0 pt-1' >To Do Date</label>
                                            {errors.ExpectedDateError !== 'true' ? (
                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ExpectedDateError}</span>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div class="form-check ml-3 col-6 col-md-2 mt-md-1 ml-md-4 col-lg-2 mt-lg-4 pt-1">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" defaultValue />Self Close
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="col-12 col-md-12 col-lg-12 pt-1">
                                            <div class="form-check ml-2">
                                                <input class="form-check-input" type="checkbox" onChange={handleChange('check')} name='IsDone' id="flexCheckDefault" />
                                                <label class="form-check-label" name='done' id='done' for="flexCheckDefault">
                                                    Done
                                                </label>
                                            </div>
                                        </div> */}
                            </div>
                            {
                                replayStatus &&
                                <div className=" col-12 col-md-12 col-lg-9 mt-2 bt ">
                                    <div className="col-10 col-md-12 col-lg-12 pt-1">
                                        <div className="dropdown__box">
                                            <textarea name="Task" id="Task" cols="30" rows="4"
                                                className="form-control requiredColor"
                                                onChange={handleChange('Task')}
                                            ></textarea>
                                            <label htmlFor="" className='pl-0 '>Task Description</label>
                                            {errors.TaskError !== 'true' ? (
                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.TaskError}</span>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            }

                      
                                <div className="btn-box text-right  mr-1 mt-3 mb-2">
                                    {
                                        Status ?
                                            <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button>
                                            :
                                            <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Save</button>
                                    }
                                    <Link to={'/HR?sub=Emp-Task'}>
                                        <button type="button" class="btn btn-sm btn-success mr-1" >Close</button>
                                    </Link>
                                </div>
                            

                            <div className={`col-12 col-md-12`}>
                                <div className="form-row bt" >
                                    <div className="col-12 col-md-12 col-lg-9 pt-2">
                                        <div className="table-responsive">
                                            <table class="table " >
                                                <thead>
                                                    {
                                                        Status && singleEmpTaskData?.Task &&
                                                        <tr >
                                                            <td className='table-img'>
                                                                {/* <i className='fa fa-user' style={{color:'gray'}}>
                                                                </i> */}
                                                                <img src={user} alt="" style={{ width: '43px', height: '30px' }} />
                                                                <span className='ml-2 pt-1 user-task'>{singleEmpTaskData?.AssignEmpName}</span>
                                                                <p className='ml-5 pl-1  name-task'>{singleEmpTaskData?.Task}</p>

                                                            </td>

                                                            {/* <td colSpan={1} style={{ color: '#000', fontWeight: 800 }}>Firstname</td> */}
                                                            {/* <td>{singleEmpTaskData?.Task}</td> */}
                                                            <td className='task-date' >{getShowingDayWithTime(singleEmpTaskData?.CreatedDate)}</td>

                                                        </tr>
                                                    }
                                                    {
                                                        empTaskDetailData?.map((item, key) => (
                                                            <tr key={key} >
                                                                <td className='table-img'>
                                                                    <img src={user} alt="" style={{ width: '43px', height: '30px' }} />
                                                                    <span className='ml-2 pt-1 user-task'>{
                                                                        item.EmpName
                                                                    }</span>
                                                                    <p className='ml-5 mt-1 pl-1 name-task'>{item.Task}</p>
                                                                </td>
                                                                <td className='task-date' >{getShowingDayWithTime(item.CreatedDate)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                urlName &&
                <MasSideBar
                    showCol1={showCol} idName={idName} urlName={urlName} actionType={actionType}
                    getUrl={`/${urlName}/GetData_${urlName}`}
                    addUrl={`/${urlName}/Insert${urlName}`}
                    singleDataUrl={`/${urlName}/GetSingleData_${urlName}`}
                    upUrl={`/${urlName}/Update${urlName}`}
                    delUrl={`/${urlName}/Delete${urlName}`}
                />
            }
        </>
    )
}

export default EmpTask_Add_Up