import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get_Single_Company_Data } from '../../../redux/actions/AllAction';
import Employee from './NewCompany/Employee/Employee';
import PermissionPage from './PermissionPage/PermissionPage';
import Company from '../../OtherPages/Company/Company';
import Invoice from '../../OtherPages/Company/Invoice';
import Gst from '../../OtherPages/Company/Gst';
import PhoneSms from './PhoneSms/PhoneSms';
import profile from '../../img/company.png'
import gst from '../../img/gst.png'
import permisssion from '../../img/permission.jpg'
import operator from '../../img/operator.jpg'



const AdminSetting = () => {

    const dispatch = useDispatch();
    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get('sub');
    const companySingelData = useSelector((state) => state.Data.companySingelData)
    const companyId = useSelector((state) => state.Data.companyId)

    useEffect(() => {
        if (companySingelData.length === 0) dispatch(get_Single_Company_Data(companyId))
    }, [])

    return (
        <>
            {
                openPage ?
                    <>
                        {
                            openPage === 'Company Profile' &&
                            <Company />
                        }
                        {
                            openPage === 'Company-EInvoice' &&
                            <Invoice />
                        }
                        {
                            openPage === 'Company-GST' &&
                            <Gst />
                        }
                        {
                            openPage === 'Operator' &&
                            <Employee />
                        }
                        {
                            openPage === 'Permission' &&
                            <PermissionPage />
                        }
                        {openPage === 'Phone SMS' && <PhoneSms />}


                    </>
                    :
                    <>
                        <div className="section-body  px-2 ">
                            <div className="col-12">
                                <div className="card">
                                    <div className="md">
                                        <h5 className="form-set p-1 text-center text-danger" >Welcome To Admin Module</h5>
                                    </div>
                                    <div className="main-dashboard  px-2">
                                        <div className="box-container ">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-6 col-md-4 col-lg-3">
                                                        <Link to={'/AdminSetting?sub=Company%20Profile'} className='dash-p'>
                                                            <div className="box ">
                                                                <img src={profile} alt="" className='hr-images' />
                                                                <p className='bt'>Company Profile</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-lg-3">
                                                        <Link to={'/AdminSetting?sub=Company-GST'} className='dash-p'>
                                                            <div className="box ">
                                                                <img src={gst} alt="" className='hr-images' />
                                                                <p className='bt'>Company GST</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-lg-3">
                                                        <Link to={'/AdminSetting?sub=Permission'} className='dash-p'>
                                                            <div className="box ">
                                                                <img src={permisssion} alt="" className='hr-images' />
                                                                <p className='bt'>Permission</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-lg-3">
                                                        <Link to={'/AdminSetting?sub=Operator'} className='dash-p'>
                                                            <div className="box ">
                                                                <img src={operator} alt="" className='hr-images' style={{width:'300px'}}/>
                                                                <p className='bt'>Operator</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>

            }

        </>
    )
}

export default AdminSetting