import React, { useEffect, useState, memo } from 'react'
import DatePicker from 'react-datepicker'
import { set_Approve_Quotes_Data, set_Approve_Request_Data, set_Deapprove_Request_Data, set_Reject_Request_Data, set_UnApprove_Request_Data } from '../../../../redux/actions/AllAction';
import { useDispatch, useSelector } from 'react-redux';
import { getShowingDayWithOutTime, getShowingWithOutTimeStartDate } from '../../../Include/Common/Utility';
import { Get_Error_Status, Get_Req_Id, MasterTable_Modal_Status, Modal_Status } from '../../../../redux/actionTypes';
import { SpaceNotAllow } from '../../../Validation/Validation';
import RejectRequest from './RejectRequest';

const RejectRequest_Add_Up = (props) => {
    const { status, pageStatus } = props
    const ReqId = useSelector((state) => state.Data.reqID)
    const userId = useSelector((state) => state.Data.userId)
    const modalStatus = useSelector((state) => state.Data.modalStatus)
    const dispatch = useDispatch()

    const [value, setValue] = useState({
        ID: ReqId,
        Remarks: '',
        DeletedByid: userId
    });


    const [errors, setErrors] = useState({ RemarksError: '' })

    useEffect(() => { setValue({ 'ID': ReqId, 'DeletedByid': userId }) }, [ReqId, userId]);
    const handelChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    }
    const { RemarksError } = errors

    useEffect(() => {
        if (RemarksError === 'true') {
            if (status) { dispatch(set_Deapprove_Request_Data(value, pageStatus));  setErrors({ ['RemarksError']: '' }) }
            else { dispatch(set_Reject_Request_Data(value, pageStatus)); setErrors({ ['RemarksError']: '' }) }
        }
    }, [RemarksError]);

    const setDeapprove = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.Remarks)) {
            setErrors(prevValues => { return { ...prevValues, ['RemarksError']: SpaceNotAllow(value.Remarks) } })
            dispatch({ type: Get_Error_Status, payload: true })
        }   dispatch({ type: Get_Req_Id, payload: ''})
    }

    const resetHooks = () => {
        setValue(pre => {
            return {
                ...pre,
                ID: '',
                Remarks: '',
                DeletedByid: ''
            }
        })
        setErrors(pre => { return { ...pre, RemarksError: '' } })
    }

    return (
        <>
            {modalStatus &&
                <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="AddRejectModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="m-1 ">
                                    <fieldset style={{ border: '1px solid gray' }}>
                                        <legend > {status ? 'Approve' : 'Reject'}  Request</legend>
                                        <div className="form-row" style={{ marginTop: '-10px' }}>
                                            <div className="col-12  col-md-12 col-lg-12 mt-2 ">
                                                <div className="dropdown__box">
                                                    <textarea
                                                        name="Remarks"
                                                        id="Remarks"
                                                        cols="30"
                                                        rows="1"
                                                        className="form-control"
                                                        onChange={handelChange}
                                                        value={value.Remarks}
                                                    >
                                                    </textarea>
                                                    <label htmlFor='' >Remarks</label>
                                                    {errors.RemarksError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.RemarksError}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>

                                    </fieldset>

                                    <div className="btn-box text-right  mr-1  mt-2">
                                        {status ? <button type="button" class="btn btn-sm btn-success mr-1" onClick={setDeapprove}>Submit</button> : <button type="button" class="btn btn-sm btn-success mr-1" onClick={setDeapprove}>Submit</button>}
                                        <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={resetHooks} >Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default memo(RejectRequest_Add_Up)