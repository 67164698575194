import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SpaceNotAllow, Email_Field } from '../../Validation/Validation';
import { update_Company_Invoice_Data, update_Company_Profile_Data } from '../../../redux/actions/AllAction';

const Company = (props) => {

    const { status, upUrl, addUrl, pageStatus, getUrl } = props
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.Data.userId);
    const companySingelData = useSelector((state) => state.Data.companySingelData[0])
    const [value1, setValue1] = useState({
        EInvoiceUserName: '',
        EInvoicePassword: '',
        EInvoiceProduct: '',
        EInvoiceHSNCode: '',
        District: '',
        PIN: '',
        Updatedbyid: userId
    })
    const [value, setValue] = useState({
        ssoid: '',
        password: '',
        Ekey: '',
        weighbridgeNo: '',
        FirmName: '',
        Address: '',
        Email: '',
        BillProduct: '',
        Vehiclecommission: '',
        DMGDelearName: '',
        Updatedbyid: '',
    })
    const [errors1, setErrors1] = useState({
        'usernameError': '', 'passwordError1': '', 'productNameError': '', 'districtError': '', 'PincodeError': '', 'codeError1': ''
    })
    const [errors, setErrors] = useState({
        'ssoidError': '', 'passwordError': '', 'EkeyError': '', 'weighbridgeNoError': '', 'NameError': '', 'AddressError': '', 'emailError': '', 'VehiclecommissionError': '', 'billproductError': '', 'codeError': ''
    })
    useEffect(() => {
        if (companySingelData) {
            setValue({
                ssoid: companySingelData.ssoid,
                password: companySingelData.password,
                Ekey: companySingelData.ekey,
                weighbridgeNo: companySingelData.weighbridgeNo,
                FirmName: companySingelData.FirmName,
                Address: companySingelData.Address,
                Email: companySingelData.Email,
                BillProduct: companySingelData.BillProduct,
                Vehiclecommission: companySingelData.Vehiclecommission,
                DMGDelearName: companySingelData.DMGDelearName,
                Updatedbyid: companySingelData.updatedbyid,
            });
            setValue1({
                EInvoiceUserName: companySingelData.EInvoiceUserName,
                EInvoicePassword: companySingelData.EInvoicePassword,
                EInvoiceProduct: companySingelData.EInvoiceProduct,
                EInvoiceHSNCode: companySingelData.EInvoiceHSNCode,
                District: companySingelData.District,
                PIN: companySingelData.PIN,
                Updatedbyid: companySingelData.Updatedbyid
            })
        } else {
            setValue({
                ssoid: '',
                password: '',
                Ekey: '',
                weighbridgeNo: '',
                FirmName: '',
                Address: '',
                Email: '',
                BillProduct: '',
                Vehiclecommission: '',
                DMGDelearName: '',
                Updatedbyid: '',
            });
            setValue1({
                EInvoiceUserName: '',
                EInvoicePassword: '',
                EInvoiceProduct: '',
                EInvoiceHSNCode: '',
                District: '',
                PIN: '',
                Updatedbyid: ''
            })
        }
    }, [companySingelData])

    const handlChange = (e) => {
        if (e.target.name === 'weighbridgeNo') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setValue({
                ...value,
                [e.target.name]: checkNumber
            })
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }
    const handlChange1 = (e) => {
        if (e.target.name === 'PIN') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setValue1({
                ...value1,
                [e.target.name]: checkNumber
            })
        } else {
            setValue1({
                ...value1,
                [e.target.name]: e.target.value
            })
        }
    }

    // Check validation on Field
    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.ssoid)) {
            setErrors(prevValues => { return { ...prevValues, ['ssoidError']: SpaceNotAllow(value.ssoid) } })
        }
        if (SpaceNotAllow(value.password)) {
            setErrors(prevValues => { return { ...prevValues, ['passwordError']: SpaceNotAllow(value.password) } })
        }
        if (SpaceNotAllow(value.weighbridgeNo)) {
            setErrors(prevValues => { return { ...prevValues, ['weighbridgeNoError']: SpaceNotAllow(value.weighbridgeNo) } })
        }
        if (SpaceNotAllow(value.Ekey)) {
            setErrors(prevValues => { return { ...prevValues, ['EkeyError']: SpaceNotAllow(value.Ekey) } })
        }
        if (SpaceNotAllow(value.FirmName)) {
            setErrors(prevValues => { return { ...prevValues, ['NameError']: SpaceNotAllow(value.FirmName) } })
        }
        if (SpaceNotAllow(value.Address)) {
            setErrors(prevValues => { return { ...prevValues, ['AddressError']: SpaceNotAllow(value.Address) } })
        }
    }
    const check_Validation_Error1 = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value1.EInvoiceUserName)) {
            setErrors1(prevValues => { return { ...prevValues, ['usernameError']: SpaceNotAllow(value1.EInvoiceUserName) } })
        }
        if (SpaceNotAllow(value1.EInvoicePassword)) {
            setErrors1(prevValues => { return { ...prevValues, ['passwordError1']: SpaceNotAllow(value1.EInvoicePassword) } })
        }
        if (SpaceNotAllow(value1.EInvoiceProduct)) {
            setErrors1(prevValues => { return { ...prevValues, ['productNameError']: SpaceNotAllow(value1.EInvoiceProduct) } })
        }
        if (SpaceNotAllow(value1.District)) {
            setErrors1(prevValues => { return { ...prevValues, ['districtError']: SpaceNotAllow(value1.District) } })
        }
        if (SpaceNotAllow(value1.EInvoiceHSNCode)) {
            setErrors1(prevValues => { return { ...prevValues, ['codeError1']: SpaceNotAllow(value1.EInvoiceHSNCode) } })
        }
        if (SpaceNotAllow(value1.PIN)) {
            setErrors1(prevValues => { return { ...prevValues, ['PincodeError']: SpaceNotAllow(value1.PIN) } })
        }
    }

    const { ssoidError, passwordError, EkeyError, weighbridgeNoError, NameError, AddressError,  codeError } = errors

    useEffect(() => {
        if (ssoidError === 'true' && passwordError === 'true' && EkeyError === 'true' && weighbridgeNoError === 'true' && NameError === 'true' && AddressError === 'true' ) {
            dispatch(update_Company_Profile_Data(value)) ;
        }
    }, [ssoidError, passwordError, EkeyError, weighbridgeNoError, NameError, AddressError,  codeError])

    const { usernameError, passwordError1,  districtError, PincodeError, codeError1 } = errors1
    useEffect(() => {
        if (usernameError === 'true' && passwordError1 === 'true' &&  districtError === 'true' && PincodeError === 'true' & codeError1 === 'true') {
            dispatch(update_Company_Invoice_Data(value1))
        }
    }, [usernameError, passwordError1,  districtError, PincodeError, codeError1])

    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Company</p>
                </div>
                <div className="form-row mt-2">
                    <div className="col-6 col-md-3 col-lg-3 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='ssoid'
                                value={value.ssoid}
                                className= 'requiredColor'
                                id='ssoid'
                                required
                                onChange={handlChange}
                            />
                            <label >SSOID</label>
                            {errors.ssoidError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ssoidError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-3 col-lg-3 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='password'
                                value={value.password}
                                className= 'requiredColor'
                                id='password'
                                required
                                onChange={handlChange}
                            />
                            <label >Password</label>
                            {errors?.passwordError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors?.passwordError}</span>
                            ) : null}
                        </div>

                    </div>
                    <div className="col-6 col-md-3 col-lg-3 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='weighbridgeNo'
                                value={value.weighbridgeNo}
                                className= 'requiredColor'
                                id='weighbridgeNo'
                                maxLength={6}
                                required
                                onChange={handlChange}
                            />
                            <label >Bridge Id</label>
                            {errors.weighbridgeNoError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.weighbridgeNoError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-3 col-lg-3 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='Ekey'
                                value={value.Ekey}
                                className= 'requiredColor'
                                id='Ekey'
                                required
                                onChange={handlChange}
                            />
                            <label >Key</label>
                            {errors.EkeyError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.EkeyError}</span>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="form-row mt-3">
                    <div className="col-12 col-md-5 col-lg-6 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='FirmName'
                                value={value.FirmName}
                                className='requiredColor'
                                id='FirmName'
                                required
                                onChange={handlChange}
                            />
                            <label >Firm Name</label>
                            {errors.NameError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.NameError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-12  col-md-7 col-lg-6" >
                        <div className="dropdown__box">
                            <textarea name='Address' onChange={handlChange} value={value.Address} id="Address" cols="30" rows='1' className="form-control pt-2 pb-2 requiredColor" ></textarea>
                            <label htmlFor="">Address</label>
                            {errors.AddressError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.AddressError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-3 col-lg-3 mt-3 pt-1">
                        <div class="text-field">
                            <input
                                type="text"
                                name='Email'
                                value={value.Email}
                                id='Email'
                                required
                                onChange={handlChange}
                            />
                            <label >Email</label>
                         
                        </div>
                    </div>
                    <div className="col-6 col-md-3 col-lg-3 mt-3 pt-1">
                        <div class="text-field">
                            <input
                                type="text"
                                name='BillProduct'
                                value={value.BillProduct}
                                className=""
                                id='BillProduct'
                                required
                                onChange={handlChange}
                            />
                            <label >Bill Product</label>
                            {errors.billproductError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.billproductError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 mt-3 pt-1">
                        <div class="text-field">
                            <input
                                type="text"
                                name='Vehiclecommission'
                                value={value.Vehiclecommission}
                                className=""
                                id='Vehiclecommission'
                                required
                                onChange={handlChange}
                            />
                            <label >Vehicle Commission</label>
                            {errors.VehiclecommissionError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.VehiclecommissionError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="btn-box col-3 text-end mt-4 pt-2  mb-2">
                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button>
                        <button type="button" className="btn btn-sm btn-success mr-1">Cancel</button>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">E-Invoice</p>
                </div>
                <div className="form-row mt-2">
                    <div className="col-6 col-md-4 col-lg-2 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='EInvoiceUserName'
                                className="requiredColor"
                                value={value1.EInvoiceUserName}
                                id='EInvoiceUserName'
                                required
                                onChange={handlChange1}
                            />
                            <label className='pt-1'>User Name</label>
                            {errors1.usernameError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors1.usernameError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='EInvoicePassword'
                                className="requiredColor"
                                value={value1.EInvoicePassword}
                                id='EInvoicePassword'
                                required
                                onChange={handlChange1}
                            />
                            <label className='pt-1'>Password</label>
                            {errors1.passwordError1 !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors1.passwordError1}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='EInvoiceProduct'
                                value={value1.EInvoiceProduct}
                                id='EInvoiceProduct'
                                required
                                onChange={handlChange1}
                            />
                            <label className='pt-1'>Product Name</label>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='EInvoiceHSNCode'
                                className="requiredColor"
                                id='EInvoiceHSNCode'
                                value={value1.EInvoiceHSNCode}
                                required
                                onChange={handlChange1}
                            />
                            <label className='pt-1'>HSN Code</label>
                            {errors1.codeError1 !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors1.codeError1}</span>
                            ) : null}
                        </div>
                    </div>
                    {/* <div className="col-6 col-md-3 col-lg-3 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='District'
                                className=""
                                value={value1.District}
                                id='District'
                                required
                            />
                            <label className='pt-1'>Product Name</label>
                        </div>
                    </div> */}
                    {/* <div className="col-6 col-md-3 col-lg-3 mt-3">
                        <div class="text-field">
                            <input
                                type="text"
                                name='HSNcode'
                                className=""
                                id='HSNcode'
                                required
                            />
                            <label className='pt-1'>HSN Code</label>
                        </div>
                    </div> */}
                    <div className="col-4 col-md-3 col-lg-3 mt-2">
                        <div class="text-field">
                            <input
                                type="text"
                                name='District'
                                className="requiredColor"
                                id='District'
                                value={value1.District}
                                required
                                onChange={handlChange1}
                            />
                            <label className='pt-1'>District</label>
                            {errors1.districtError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors1.districtError}</span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-4 col-md-3 col-lg-2 mt-3">
                        <div class="text-field">
                            <input
                                type="text"
                                name='PIN'
                                className="requiredColor"
                                id='PIN'
                                required
                                value={value1.PIN}
                                onChange={handlChange1}
                            />
                            <label className='pt-1'>Pin Code</label>
                            {errors1.PincodeError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors1.PincodeError}</span>
                            ) : null}
                        </div>
                    </div>

                </div>

                <div className="btn-box text-right mt-2 mr-1 mb-2">
                    <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error1}>Update</button>
                    <button type="button" class="btn btn-sm btn-success mr-1">Cancel</button>
                </div>
            </div>
        </>
    )
}

export default Company