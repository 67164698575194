import React, { useEffect, useState } from 'react'
import Select from "react-select";
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { SpaceNotAllow, Email_Field } from '../../../Validation/Validation';
import { Get_challan_Data } from '../../../../redux/actions/AllAction';

const SearchChallan = () => {
  const dispatch = useDispatch();
  const [fromDate, setfromDate] = useState();
  const [toDate, settoDate] = useState();
  const [value, setValue] = useState();
  const [startDate, setStartDate] = useState();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const searchChallanData = useSelector((state) => state.Data.searchChallanData);

  const [searchvalue, setsearchvalue] = useState({
    ChallanFromDate: '',
    ChallanFromTime: '',
    ChallanToDate: '',
    ChallanToTime: '',
    consignee: '',
    ChallanNo: '',
    vehicleNo: ''
  });
  
  const [errors, setErrors] = useState({ 'DateFromeError' : '', })
  const [endDate, setendDate] = useState();
  // custuom style withoutColor
  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 31,
      minHeight: 30,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
      fontWeight: 500,
    }),
  }
  const startRef = React.useRef();
  const startRef1 = React.useRef();
  const startRef2 = React.useRef();
  const startRef3 = React.useRef();

  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
      startRef1.current.setOpen(false);
      startRef2.current.setOpen(false);
      startRef3.current.setOpen(false);
    }
  };
  const handleChange = (selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const search_challan = () => {
    dispatch(Get_challan_Data(searchvalue))
  }
  const handlChange = (e) => {
    if(e.target.name === 'ChallanNo') {
      
    }
    setsearchvalue({
        ...searchvalue,
        [e.target.name]: e.target.value
    })
}
const columns = [
  {
    name: 'Party Name',
    selector: (row) => row.PartyName,
    sortable: true
  },
  {
    name: 'Product Name',
    selector: (row) => row.ProductName,
    sortable: true
  },
  {
    name: 'Vechicle No.',
    selector: (row) => row.VehicleNo,
    sortable: true
  },
  {
    name: 'Challan No.',
    selector: (row) => row.ChallanNo,
    sortable: true
  },
  {
    name: 'Status',
    selector: (row) => row.Status,
    sortable: true
  },
]
const changeColor = selector => {
  if (selector !== undefined) {
    this.setState({ selector  });
  }
}
const check_Validation_Error = (e) => {
  if (SpaceNotAllow(searchvalue.ChallanFromDate)) {
    setErrors(prevValues => { return { ...prevValues, ['DateFromError']: SpaceNotAllow(searchvalue.ChallanFromDate) } })
}
if (SpaceNotAllow(searchvalue.ChallanFromTime)) {
    setErrors(prevValues => { return { ...prevValues, ['TimeFromError']: SpaceNotAllow(searchvalue.ChallanFromTime) } })
}
if (SpaceNotAllow(value.Mobile)) {
    setErrors(prevValues => { return { ...prevValues, ['MobileError']: SpaceNotAllow(value.Mobile) } })
}
}
  return (

    <>
      <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">Search Challan</p>
        </div>
        <div className="form-row mt-1">

          <div class="col-2 col-md-2 col-lg-1 pt-4">
            <label htmlFor="" className='' >From Date:</label>
          </div>
          <div class="col-3 col-md-3 col-lg-2 pt-1">
            <div className="dropdown__box">
              <DatePicker
                id='fromDate'
                name='fromDate'
                ref={startRef}
                onKeyDown={onKeyDown}
                className=''
                dateFormat="MM/dd/yyyy HH:mm"
                onChange={(date) => { setfromDate(date); setValue({ ...value, ['fromDate']: date ? (date) : null }) }}
                timeInputLabel
                isClearable
                placeholderText={value?.fromDate ? value?.fromDate : 'Select...'}
                selected={fromDate}
                timeIntervals={1}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
          <div class="col-1 col-md-1 col-lg-1 pt-1">
            <div className="dropdown__box">
              <DatePicker
                selected={startDate}
                ref={startRef1}
                onKeyDown={onKeyDown}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                showTimeInput
                timeInputLabel
              />
            </div>
          </div>
          <div class="col-2 col-md-2 col-lg-1 pt-4">
            <label htmlFor="" className='pl-4' >To Date:</label>
          </div>
          <div class="col-3 col-md-3 col-lg-2 pt-1">
            <div className="dropdown__box">
              <DatePicker
                ref={startRef2}
                onKeyDown={onKeyDown}
                id='toDate'
                name='toDate'
                className=''
                dateFormat="MM/dd/yyyy HH:mm"
                onChange={(date) => { settoDate(date); setValue({ ...value, ['toDate']: date ? (date) : null }) }}
                timeInputLabel
                isClearable
                placeholderText={value?.toDate ? value?.toDate : 'Select...'}
                selected={toDate}
                timeIntervals={1}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
          <div class="col-1 col-md-1 col-lg-1  pt-1 ">
            <div className="dropdown__box">
              <DatePicker
                ref={startRef3}
                onKeyDown={onKeyDown}
                selected={endDate}
                onChange={(date) => setendDate(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                showTimeInput
                timeInputLabel
              />
            </div>
          </div>
          <div className="col-6 col-md-6  col-lg-4 mt-2">
            <div className="challan-consignee">
              <Select
                name='consignee'
                styles={customStylesWithOutColor}
                isClearable
                placeholder="Select.."
              />
              <label htmlFor="">Consignee</label>
            </div>
          </div>
          <div className="col-1 col-md-2 col-lg-1 mt-2 pt-2">
            <label className='label-field'>Challn Id:</label>
          </div>
          <div className="col-6 col-md-3 col-lg-2 ">
            <div class="text-field">
              <input
                type="text"
                name='challanid'
                className=""
                onChange={handlChange}
                id='challanid'
                required
              />
            </div>
          </div>
          <div className="col-1 col-md-2 col-lg-1 mt-2 pt-2">
            <label className='label-field'>Vehicle Id:</label>
          </div>
          <div className="col-6 col-md-3 col-lg-2">
            <div class="text-field">
              <input
                type="text"
                name='vehicleid'
                className=""
                onChange={handlChange}
                id='vehicleid'
                required
              />
            </div>
          </div>
          <div className="btn-box col-lg-12 text-end ">
            <button type="button" class="btn btn-sm btn-success mr-1"  onClick={(e) => { search_challan();}}>Search</button>
            <button type="button" class="btn btn-sm btn-success mr-1">Clear</button>
            {/* <span>Total Records:1325</span> */}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 ">
            <DataTable
              dense
              paginationPerPage={'10'}
              columns={columns}
              data={searchChallanData}
              onClick={changeColor}
              paginationRowsPerPageOptions={[5, 10, 15]}
              highlightOnHover
              noContextMenu
              selectableRows
              onSelectedRowsChange={handleChange}
              pagination
              subHeaderAlign="right"
              subHeaderWrap
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default SearchChallan
