import React from 'react'
import { Link } from 'react-router-dom'

const Reports = () => {
    return (
        <div className="col-12 col-md-12 col-lg-12 pt-2 px-4 new-scroll  printElement1 mt-3" >
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, 300px)', gap: '50px' }}>

                <div className="card " style={{
                    height: '19.5rem', boxShadow: '1px 1px 1px gray'
                }}>
                    <div className="card-header " >
                        <h5 className=" d-flex ">
                            Reports
                        </h5>
                    </div>
                    <div className='mt-2 report-list' style={{ overflowY: 'auto', }}>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Compressor Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Quarry Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Conversion Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Crusher Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Productions Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>WorkDone Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Attendance Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Master Details Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Purchase Invoice Details Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>In Ward/Out Ward Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Sales Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Stock Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Diesel Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Driver Betta Pending Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Machine Approval Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Order Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Diesel Stock Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Betta Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Purchase Details Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>GST Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Rent Report</li>
                        </Link>
                    </div>

                </div>
                <div className="card " style={{
                    height: '19.5rem', boxShadow: '1px 1px 1px gray'
                }}>
                    <div className="card-header " >
                        <h5 className=" d-flex " >
                            Accounts Reports
                        </h5>
                    </div>
                    <div className='mt-2 report-list'  style={{ overflowY: 'auto', }}>
                        <Link to={'/DayBook'} >
                            <li className='pl-3 pt-2'>Day Book</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Credit Book</li>
                        </Link>
                        <Link to={'/CashBook'} >
                            <li className='pl-3 pt-2'>Cash Book</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Bank Book</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Petty Cash Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Amount Payable</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Amount Receivable</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Ledger Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Party Balance Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Suppiler Transcation Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Buyer Transcation Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Payment Type Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Bank Report</li>
                        </Link>
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Daily Expense Report</li>
                        </Link>
                 
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Employee Payment Report</li>
                        </Link>
                 
                 
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Employee Balance Payment</li>
                        </Link>
                 
                        <Link to={'#'} >
                            <li className='pl-3 pt-2'>Driver Salary Report</li>
                        </Link>
                 
                    </div>

                </div>
             
                <div className="card " style={{
                    height: '19.5rem', boxShadow: '1px 1px 1px gray'
                }}>
                    <div className=" card-header " >
                        <h5 className="d-flex " >
                            Inventory
                        </h5>
                    </div>
                    <div className='mt-2'>
                        <Link to={'#'} >
                            <li className='pl-3'>Payment List</li>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reports