import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Select from "react-select";
import DatePicker from 'react-datepicker'
import { customStylesWithOutColor, getShowingDayWithOutTime, getShowingWithOutTimeStartDate } from '../../../Include/Common/Utility';
import ItemDetails from './ItemDetails/ItemDetails';
import { useDispatch, useSelector } from 'react-redux';
import { get_InvoiceType_DropData, get_Party_Sale_Data, save_InvoiceType_Data, update_InvoiceType_Data } from '../../../../redux/actions/AllAction';
import { changeArrayFormatDrop } from '../../../Include/Common/ArrayFormat';
import { Get_Error_Status, Get_Single_PurchaseInvoice_Data, Status } from '../../../../redux/actionTypes';
import { RequiredFieldSelectBox, SpaceNotAllow } from '../../../Validation/Validation';

const PurchaseInvoiceAddUp = () => {
    const dispatch = useDispatch()
    const pageStatus = useSelector((state) => state?.Data?.pageStatus)
    const editUpdateCount = useSelector((state) => state?.Data?.editUpdateCount)
    const userId = useSelector((state) => state.Data.userId);
    const invoiceDropData = changeArrayFormatDrop(useSelector((state) => state.Data.invoiceDropData),'Description','ID')
    const companyId = useSelector((state) => state?.Data?.companyId)
    const singleInvoiceData = useSelector((state) => state.Data.singleInvoiceData[0])
    const [ID, setID] = useState('')
    const partySaleData = changeArrayFormatDrop(useSelector((state) => state.Data.partySaleData), 'Name', 'Id');
    const [value, setValue] = useState({
        SupplierID: '',
        Supplier: '',
        InvoiceNo: '',
        InvoiceDate: '',
        InvoiceDate1: '',
        InvoiceTypeID: '',
        InvoiceType: '',
        ChallanNo: '',
        VehicleNo: '',
        PONo: '',
        PODate: '',
        PODate1: '',
        TotalTAXAmt: '',
        IGSTAmt: '',
        SGSTAmt: '',
        CGSTAmt: '',
        TotalAmt: '',
        companyid: companyId,
        Createdbyid: '',
        CreatedDate: '',
        CreatedDate1: '',
        Createdbyid: userId,
    })
    const [value1, setValue1] = useState({
        IsLedger: '1',
        Iscommon: '0',
        IsUnRegular: '0',
        IsSupplier: '0',
        Iscredit: '0',
        IsActive: "1",
    })
    const [errors, setErrors] = useState({
        InvoiceError: '', SupplierError: ''
    })
    const { SupplierError, InvoiceError } = errors

    useEffect(() => {
        if (singleInvoiceData) {
            setValue({
                ...value, SupplierID: singleInvoiceData?.SupplierID,
                Supplier: singleInvoiceData?.Supplier,
                InvoiceNo: singleInvoiceData?.InvoiceNo,
                InvoiceDate: singleInvoiceData?.InvoiceDate,
                InvoiceDate1: singleInvoiceData?.InvoiceDate?.split("/").reverse().join("/"),
                InvoiceTypeID: singleInvoiceData?.InvoiceTypeID,
                InvoiceType: singleInvoiceData?.InvoiceType,
                ChallanNo: singleInvoiceData?.ChallanNo,
                VehicleNo: singleInvoiceData?.VehicleNo,
                PONo: singleInvoiceData?.PONo,
                PODate: singleInvoiceData?.PODate,
                PODate1: singleInvoiceData?.PODate?.split("/").reverse().join("/"),
                TotalTAXAmt: singleInvoiceData.TotalTAXAmt,
                IGSTAmt: singleInvoiceData.IGSTAmt,
                SGSTAmt: singleInvoiceData.SGSTAmt,
                CGSTAmt: singleInvoiceData.CGSTAmt,
                TotalAmt: singleInvoiceData.TotalAmt,
                companyid: singleInvoiceData.companyId,
                CreatedDate: singleInvoiceData.CreatedDate,
                CreatedDate1: singleInvoiceData.CreatedDate?.split("/").reverse().join("/"),
                Updatedid: userId,
                ID: singleInvoiceData.ID
            })
        }
        else {
            setValue({
                ...value, SupplierID: '',
                Supplier: '',
                InvoiceNo: '',
                InvoiceDate: '',
                InvoiceDate1: '',
                InvoiceTypeID: '',
                InvoiceType: '',
                ChallanNo: '',
                VehicleNo: '',
                PONo: '',
                PODate: '',
                PODate1: '',
                TotalTAXAmt: '',
                IGSTAmt: '',
                SGSTAmt: '',
                CGSTAmt: '',
                TotalAmt: '',
                companyid: companyId,
                Createdbyid: '',
                CreatedDate: '',
                CreatedDate1: '',
                Createdbyid: userId
            })
        }
    }, [singleInvoiceData, editUpdateCount])

    useEffect(() => {
        dispatch(get_Party_Sale_Data(value1));
    }, [value1])
    const handlChange = (e) => {
        if (e.target.name === 'InvoiceNo' || e.target.name === 'ChallanNo' || e.target.name === 'TotalTAXAmt' || e.target.name === 'IGSTAmt' || e.target.name === 'SGSTAmt' || e.target.name === 'CGSTAmt') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setValue({
                ...value,
                [e.target.name]: checkNumber
            })
        }
        else {
            setValue({
                ...value,
                [e.target.name]: e.target.value.toUpperCase(),
            });
        }

    }
    const handleDropdown = (e, Name) => {
        if (Name === 'InvoiceTypeID') {
            setValue(pre => { return { ...pre, ['InvoiceTypeID']: e?.value, ['InvoiceType']: e?.label } })
        } else if (Name === 'SupplierID') {
            setValue(pre => { return { ...pre, ['SupplierID']: e?.value, ['Supplier']: e?.label } })
        }
    }
    useEffect(() => {
        dispatch(get_InvoiceType_DropData())
    }, []);
    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (RequiredFieldSelectBox(value.InvoiceNo)) {
            setErrors(prevValues => { return { ...prevValues, ['InvoiceError']: RequiredFieldSelectBox(value.InvoiceNo) } })
            dispatch({ type: Get_Error_Status, payload: true })
        }
    }
    useEffect(() => {
        if (InvoiceError === 'true') {
            if (pageStatus) { dispatch(update_InvoiceType_Data(value)); setErrors({ ['InvoiceError']: '' }) }
            else { dispatch(save_InvoiceType_Data(value)); setErrors({ ['InvoiceError']: '' }) }
        }
    }, [InvoiceError])
    const Close_Model = () => {
        dispatch({ type: Get_Single_PurchaseInvoice_Data, payload: [] });
        setValue({
            SupplierID: '',
            Supplier: '',
            InvoiceNo: '',
            InvoiceDate: '',
            InvoiceDate1: '',
            InvoiceTypeID: '',
            InvoiceType: '',
            ChallanNo: '',
            VehicleNo: '',
            PONo: '',
            PODate: '',
            PODate1: '',
            TotalTAXAmt: '',
            IGSTAmt: '',
            SGSTAmt: '',
            CGSTAmt: '',
            TotalAmt: '',
            companyid: companyId,
            Createdbyid: '',
            CreatedDate: '',
            CreatedDate1: '',
            Createdbyid: userId,
            ID: ''
        });
        setErrors({
            'SupplierError': '', 'InvoiceError': '',
        })
    }
    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="row mt-1 px-1">
                    <div className="col-12 col-md-12 col-lg-12">
                        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                            <p className="p-0 m-0 d-flex align-items-center">Purchase Invoice Master</p>
                        </div>
                    </div>
                    <div className="row mt-2 px-3">
                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                            <div className="dropdown__box">
                                <Select
                                    name='SupplierID'
                                    styles={customStylesWithOutColor}
                                    value={partySaleData.filter((obj) => obj.label === value.Supplier)}
                                    options={partySaleData}
                                    isClearable
                                    placeholder="Select.."
                                    onChange={(e) => handleDropdown(e, 'SupplierID')}
                                />
                                <label className=''>Supplier</label>
                            </div>
                            {errors.SupplierError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.SupplierError}</span>
                            ) : null}
                        </div>
                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                            <div className="text-field">
                                <input
                                    type="text"
                                    name='InvoiceNo'
                                    className="requiredColor"
                                    value={value.InvoiceNo}
                                    onChange={handlChange}
                                    id='InvoiceNo'
                                    required
                                />
                                <label className='pt-1'>Invoice No.</label>
                            </div>
                            {errors.InvoiceError !== 'true' ? (
                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.InvoiceError}</span>
                            ) : null}
                        </div>
                        <div className="col-6 col-md-6 col-lg-4 mt-1">
                            <div class="text-field">
                                <DatePicker
                                    id='InvoiceDate'
                                    name='InvoiceDate'
                                    dateFormat="dd/MM/yyyy "
                                    timeInputLabel
                                    onChange={(date) => date ? setValue(pre => { return { ...pre, ['InvoiceDate']: getShowingDayWithOutTime(date), ['InvoiceDate1']: getShowingWithOutTimeStartDate(date) } }) :
                                        setValue(pre => { return { ...pre, ['InvoiceDate']: '', ['InvoiceDate1']: '' } })
                                    }
                                    placeholderText={'Select...'}
                                    selected={value.InvoiceDate1 && new Date(value.InvoiceDate1)}
                                    dropdownMode="select"
                                    showMonthDropdown
                                    maxDate={new Date()}
                                    showYearDropdown
                                />
                                <label className='pt-2'>Invoice Date</label>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-4 d-flex mt-3 ">
                            <div className="col-11 col-md-11 col-lg-11 ">
                                <div className="dropdown__box">
                                    <Select
                                        name='InvoiceTypeID'
                                        styles={customStylesWithOutColor}
                                        isClearable
                                        options={invoiceDropData}
                                        onChange={(e) => handleDropdown(e, 'InvoiceTypeID')}
                                        value={invoiceDropData?.filter((obj) => obj?.label === value?.InvoiceType)}
                                        className="requiredColor"
                                        placeholder="Select.."
                                    />
                                    <label className=''>Invoice Type</label>
                                </div>
                            </div>
                            <div className="col-1   px-0" style={{ marginTop: '13px' }}>
                                <Link to="" className="btn btn-sm bg-green text-white " data-toggle="modal" data-target="#AddMasterModal"
                                >
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-4 mt-3">
                            <div className="text-field">
                                <input
                                    type="text"
                                    name='ChallanNo'
                                    className=""
                                    value={value.ChallanNo}
                                    onChange={handlChange}
                                    id='ChallanNo'
                                    required
                                />
                                <label className='pt-1'>Challan No.</label>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-4 mt-3">
                            <div className="text-field">
                                <input
                                    type="text"
                                    name='VehicleNo'
                                    className=""
                                    value={value.VehicleNo}
                                    onChange={handlChange}
                                    id='VehicleNo'
                                    required
                                />
                                <label className='pt-1'>Vehicle No.</label>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-4 mt-3">
                            <div class="text-field">
                                <input
                                    type="text"
                                    name='PONo'
                                    className=""
                                    onChange={handlChange}
                                    value={value.PONo}
                                    id='PONo'
                                    required
                                />
                                <label className='pt-1'>P.O. No.</label>
                            </div>
                        </div>

                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                            <div class="text-field">
                                <DatePicker
                                    id='PODate'
                                    name='PODate'
                                    dateFormat="dd/MM/yyyy "
                                    timeInputLabel
                                    onChange={(date) => date ? setValue(pre => { return { ...pre, ['PODate']: getShowingDayWithOutTime(date), ['PODate1']: getShowingWithOutTimeStartDate(date) } }) :
                                        setValue(pre => { return { ...pre, ['PODate']: '', ['PODate1']: '' } })
                                    }
                                    placeholderText={'Select...'}
                                    dropdownMode="select"
                                    showMonthDropdown
                                    selected={value.PODate1 && new Date(value.PODate1)}
                                    minDate={new Date()}
                                    showYearDropdown
                                />
                                <label className='pt-2'>P.O. Date</label>
                            </div>
                        </div>
                        <div class="col-6 col-md-6 col-lg-4 mt-2">
                            <div className="dropdown__box">
                                <DatePicker
                                    id='CreatedDate'
                                    name='CreatedDate'
                                    onChange={(date) => date ? setValue(pre => { return { ...pre, ['CreatedDate']: getShowingDayWithOutTime(date), ['CreatedDate1']: getShowingWithOutTimeStartDate(date) } }) :
                                        setValue(pre => { return { ...pre, ['CreatedDate']: '', ['CreatedDate1']: '' } })
                                    }
                                    selected={value.CreatedDate1 && new Date(value.CreatedDate1)}
                                    dateFormat="dd/MM/yyyy "
                                    timeInputLabel
                                    placeholderText={'Select...'}
                                    dropdownMode="select"
                                    showMonthDropdown
                                    maxDate={new Date()}
                                    showYearDropdown
                                />
                                <label htmlFor="" className='pt-1'>Received Date</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Item Details */}

            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="row mt-1">
                    <ItemDetails />
                </div>
            </div>
            {/* Amount Details */}
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="row mt-1 px-1">
                    <div className="col-12 col-md-12 col-lg-12">
                        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                            <p className="p-0 m-0 d-flex align-items-center">Amount Details</p>
                        </div>
                    </div>
                    <div className="row mt-2 px-3">
                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                            <div className="text-field">
                                <input
                                    type="text"
                                    name='TotalTAXAmt'
                                    className=""
                                    onChange={handlChange}
                                    value={value.TotalTAXAmt}
                                    id='TotalTAXAmt'
                                    required
                                />
                                <label className='pt-1'>Total Taxable Amt</label>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                            <div className="text-field">
                                <input
                                    type="text"
                                    name='IGSTAmt'
                                    className=""
                                    onChange={handlChange}
                                    value={value.IGSTAmt}
                                    id='IGSTAmt'
                                    required
                                />
                                <label className='pt-1'>IGST Amount</label>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                            <div className="text-field">
                                <input
                                    type="text"
                                    name='SGSTAmt'
                                    className=""
                                    id='SGSTAmt'
                                    value={value.SGSTAmt}
                                    onChange={handlChange}
                                    required
                                />
                                <label className='pt-1'>SGST Amount</label>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-4 mt-2">
                            <div className="text-field">
                                <input
                                    type="text"
                                    name='CGSTAmt'
                                    className=""
                                    id='CGSTAmt'
                                    value={value.CGSTAmt}
                                    onChange={handlChange}
                                    required
                                />
                                <label className='pt-1'>CGST Amount</label>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-4 mt-1">
                            <div className="text-field">
                                <input
                                    type="text"
                                    name='TotalAmt'
                                    className=""
                                    id='TotalAmt'
                                    value={value.TotalAmt}
                                    onChange={handlChange}
                                    required
                                />
                                <label className='pt-1'>Grand Total Amt</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="btn-box text-right  mr-1 mb-2">
                {pageStatus ? <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}  >Update</button> : <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}  >Save</button>}

                <Link to='/Inventory?sub=Purchase%20Invoice'>
                    <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={Close_Model}>Close</button>
                </Link>
            </div>
        </>
    )
}

export default PurchaseInvoiceAddUp;