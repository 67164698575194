import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ServiceMaintenanceAdd_Up from './ServiceMaintenanceAdd_Up'
import { Edit_Update_Count, Modal_Status } from '../../../../redux/actionTypes'
import { delete_Mantinance_Data, get_Mantinance_Data, get_Single_Mantinance_Data } from '../../../../redux/actions/AllAction'
import ConfirmModal from '../../../Include/Common/ConfirmModal';


const ServiceMaintenance = () => {
  const dispatch = useDispatch();
  const [confirmType, setConfirmType] = useState('')
  const [IsActive, setIsActive] = useState()
  const [pageStatus, setPageStatus] = useState("1")
  const [Serviceid, setServiceid] = useState('')
  const [status, setStatus] = useState(false)
  const userId = useSelector((state) => state.Data.userId);
  const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
  const meterFilterData = useSelector((state) => state.Data.MantinanceData)

  const setEditStatus = (row) => {
    dispatch(get_Single_Mantinance_Data(row.ID)); setServiceid(row.ID)
    setStatus(true);
    dispatch({ type: Modal_Status, payload: true })
    dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
  }

  useEffect(() => {
    dispatch(get_Mantinance_Data(pageStatus))
  }, [pageStatus]);

  const UpdActiveDeactive = (row) => {
    dispatch(delete_Mantinance_Data(Serviceid, IsActive, userId, pageStatus))
  };

  const columns = [
    {
      name: 'Reading Date',
      selector: (row) => row.ReadingDate,
      sortable: true
    }, {
      name: 'AccountID',
      selector: (row) => row.AccountID,
      sortable: true
    }, 
    {
      name: 'AccountName',
      selector: (row) => row.AccountName,
      sortable: true
    },{
      name: 'ReadingStartKM',
      selector: (row) => row.ReadingStartKM,
      sortable: true
    }, {
      name: 'ReadingStopKm',
      selector: (row) => row.ReadingStopKm,
      sortable: true
    }, {
      name: 'TotalReadingKM',
      selector: (row) => row.TotalReadingKM,
      sortable: true
    }, {
      name: 'ReadingStart',
      selector: (row) => row.ReadingStart,
      sortable: true
    }, {
      name: 'ReadingStop',
      selector: (row) => row.ReadingStop,
      sortable: true
    }, {
      name: 'TotalReading',
      selector: (row) => row.TotalReading,
      sortable: true
    }, {
      name: 'RemDiesel',
      selector: (row) => row.RemDiesel,
      sortable: true
    }, {
      name: 'Average',
      selector: (row) => row.Average,
      sortable: true
    }, {
      name: 'Narration',
      selector: (row) => row.Narration,
      sortable: true
    }, {
      name: 'LastFullTankReading',
      selector: (row) => row.LastFullTankReading,
      sortable: true
    }, {
      name: 'LastFullTankDate ',
      selector: (row) => row.LastFullTankDate,
      sortable: true
    }, {
      name: 'VocuherNo ',
      selector: (row) => row.VocuherNo,
      sortable: true
    }, {
      name: 'ID',
      selector: (row) => row.ID,
      sortable: true
    },

    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 35, fontWeight: '700' }}>Action</p>,
      cell: row => <>
        <div style={{ position: 'absolute', top: 4, right: 40 }}>

          <button to={'#'} onClick={() => { setEditStatus(row); setStatus(true); }}
            className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn" data-toggle="modal" data-target="#ServiceMaintenanceModal"  ><i className="fa fa-edit"></i>
          </button>

          <button to={'#'} data-toggle="modal" data-target="#ConfirmModal"
            className="btn btn-sm bg-green text-white px-1 py-0 mr-1" onClick={(e) => { setServiceid(row.ID); setConfirmType(row.ID); setIsActive('0') }} >
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
      </>
    }
  ]
  return (
    <>
      <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
        <div className="col-12 col-md-6 col-lg-12 ">
        </div>
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">Maintenance</p>
          <div style={{ marginLeft: 'auto' }}>
            <Link to={'/Service?sub=Maintenance'} className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#ServiceMaintenanceModal">
              <i className="fa fa-plus"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-12 pt-2 px-3">
        <DataTable
          dense
          pagination
          columns={columns}
          data={meterFilterData}
          selectableRowsHighlight
          highlightOnHover
        />
      </div>
      <ServiceMaintenanceAdd_Up status={status} setStatus={setStatus}  />
      <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />

    </>
  )
}
export default ServiceMaintenance;