import React from 'react'
import { Link } from 'react-router-dom'
import Documents_Add_Up from './Documents_Add_Up'
import DataTable from 'react-data-table-component'

const Documents = () => {
  return (
    <>
      <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">Documents</p>
          <div style={{ marginLeft: 'auto' }}>
            <Link to={''} className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#DocumentModal">
              <i className="fa fa-plus"></i>
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="col-12  mb-3">
        <div className="table-responsive">
          <table className="table table-bordered">
            <tr className="border-bottom">
              <th>date/time</th>
              <th>document name</th>
              <th className='text-right'>Action</th>
            </tr>
            <tr>
              <td>Testing </td>
              <td>Testing </td>


              <td className='text-right'>
                <Link to={''}>
                  <button type='button' className="btn btn-sm bg-green text-white  py-0 " data-toggle="modal" data-target="#DocumentModal"><i className="fa fa-edit"></i>
                  </button>
                </Link>
                <button type='button' className="btn btn-sm bg-green text-white py-0 ml-1"><i className="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div> */}
      <div className="col-12 pt-2 px-3">
        <DataTable
          dense
          paginationPerPage={'10'}
          paginationRowsPerPageOptions={[5, 10, 15]}
          highlightOnHover
          noContextMenu
          pagination
          responsive
          subHeaderAlign="right"
          subHeaderWrap
          noDataComponent={"There are no data to display"}
        />
      </div>
      <Documents_Add_Up />
    </>
  )
}

export default Documents