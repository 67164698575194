import React, { useState } from 'react'
import './login.css'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { Get_Url, Page_Loader } from '../../../redux/actionTypes';
import { EncryptedList, Encrypted_Id_Name } from '../../Include/Common/Utility';
import axios from 'axios';
import { Decrypt_Fetch_Post_Data_Teble } from '../../../redux/actions/AllAction';
import img_new from './img_new.jpg'
import { toastifyError, toastifySuccess } from '../../Include/Common/AlertMsg';
import Loader from '../../Include/Common/Loader';
const NewLogin = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const pageLoader = useSelector((state) => state?.Data.pageLoader)
    const [passwordShown, setPasswordShown] = useState(false);
    const [companyName, setCompanyName] = useState([])
    const [companyUrl, setCompanyUrl] = useState('')
    const [loginDetails, setLoginDetails] = useState({
        "LoginUserID": '',
        "LoginPassword": '',
    })

    useEffect(() => {
        if (sessionStorage.getItem('userId')) navigate('/home')
    }, [])

    useEffect(() => {
        var arr = []
        fetch("./Data.txt").then(function (response) {
            response.text().then(function (text) {
                text.split("@").map((val) => {
                    // arr.push({ label: val.split(",")[0], value: val.split(",")[1], id: val.split(",")[2] })
                    arr.push({ label: val.split(",")[0], value: val.split(",")[1], id: val.split(",")[0] })
                })
                dispatch({ type: Get_Url, payload: arr[0].value })
                setCompanyName(arr)
                setCompanyUrl(arr[0].value)
                sessionStorage.setItem('url', arr[0].value)
                sessionStorage.setItem('CompanyName', Encrypted_Id_Name(arr[0].id, 'CForCompanyName'))
            });
        })
    }, [])

    const handleChange = (e) => {
        setCompanyUrl(e.value)
        sessionStorage.setItem('url', e.value)
        sessionStorage.setItem('CompanyName', Encrypted_Id_Name(e.id, 'CForCompanyName'))
    };

    const LoginSubmit = (e) => {
        e.preventDefault()
        dispatch({ type: Page_Loader, payload: true })

        // const values = EncryptedList(JSON.stringify(loginDetails));
        // const val = { "Data": values }


        axios.post(companyUrl + '/CompanyLogin/GetData_CompanyLogin', loginDetails)
            .then(function (response) {
                console.log(response,"dfg")
                console.log(response?.data)
                // const result = response?.data.Table;

                // console.log(JSON.parse(response?.data))
                const result = Decrypt_Fetch_Post_Data_Teble(response?.data)

                toastifySuccess('Login User')
                const userId = Encrypted_Id_Name(result[0]?.ID, 'UForUserID')
                const LoginUser = Encrypted_Id_Name(result[0]?.LoginUserID, 'LForLoginID')
                const isAdmin = Encrypted_Id_Name(result[0]?.isAdmin, 'IsForUserIsAdmin')
                const FirmName = Encrypted_Id_Name(result[0]?.FirmName, 'FiForUserFirmName')
                const CompanyId = Encrypted_Id_Name(result[0]?.companyid, 'CForCompanyID')
                sessionStorage.setItem('userId', userId)
                sessionStorage.setItem('LoginUser', LoginUser)
                sessionStorage.setItem('isAdmin', isAdmin)
                sessionStorage.setItem('FirmName', FirmName)
                sessionStorage.setItem('CompanyId', CompanyId)
                setTimeout(() => {
                    navigate('/DashBoard?sub=Home')
                    window.location.reload();
                    dispatch({ type: Page_Loader, payload: false })
                }, 1000);
            })
            .catch(function (error) {
                // toastifyError(error?.response?.data?.Message)
            });
    }
    // Show Password
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    return (
        <>

            <section className="background-radial-gradient" style={{ width: '100%' }}>
                <div className="container px-4 py-5 px-md-5 text-center text-lg-start ">
                    <div className="row  align-items-center mb-5">
                        <div className=" col-md-6 col-lg-7 mb-5 mb-lg-0" style={{ zIndex: 10 }}>
                            <h1 className="my-5 display-5 fw-bold ls-tight" style={{ color: 'hsl(218, 81%, 95%)' }}>
                                Welcome To <br />
                                <span className='newlogin-span' style={{ color: 'hsl(218, 81%, 75%)' }}>Crusher Management System</span>
                            </h1>
                            {/* <p className="mb-4 opacity-70" style={{ color: 'hsl(218, 81%, 85%)' }}>
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                Temporibus, expedita iusto veniam atque, magni tempora mollitia
                                dolorum consequatur nulla, neque debitis eos reprehenderit quasi
                                ab ipsum nisi dolorem modi. Quos?
                            </p> */}
                        </div>


                        <div className="col-md-6 col-lg-5 mb-5 mb-lg-0 position-relative">
                            <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong" />
                            <div id="radius-shape-2" className="position-absolute shadow-5-strong" />
                            <div className="card bg-glass mt-5" style={{ boxShadow: '1px 1px 10px 1px black', width: 'auto' }}>
                                <div className="text-center mt-2 name" style={{ marginBottom: '-20px' }}>
                                    Sign in to Continue
                                </div>
                                {pageLoader && <Loader />}
                                <div className="card-body  py-5 ">
                                    <form onSubmit={LoginSubmit}>
                                        <div className="form-outline mb-4">
                                            {/* <input type="email" id="form3Example3" className="form-control form-login" /> */}
                                            <input type="text" className="form-control form-login" onChange={(e) => setLoginDetails({ ...loginDetails, "LoginUserID": e.target.value })} name="userName" placeholder='username' />
                                            <label className="form-label pl-2 text-start" htmlFor="form3Example3">UserName</label>
                                        </div>
                                        <div className="form-outline mb-4">
                                            <i className={passwordShown ? "fa fa-eye" : "fa fa-eye-slash"} onClick={togglePassword} style={{ position: 'absolute', right: '25px', paddingTop: '10px' }}></i>

                                            {/* <input type={passwordShown ? "text" : "password"} id="form3Example4" className="form-control form-login" /> */}
                                            <input className="form-control form-login" type={passwordShown ? "text" : "password"} onChange={(e) => setLoginDetails({ ...loginDetails, "LoginPassword": e.target.value })} name="password" placeholder='password' />
                                            <label className="form-label pl-2 text-start" htmlFor="form3Example4">Password</label >
                                        </div>
                                        <div className="form-group mt-2">
                                            <Select
                                                className='text-start'
                                                name='state'
                                                value={companyName.filter((i) => i.value === companyUrl)}
                                                options={companyName}
                                                onChange={handleChange}
                                                isClearable
                                                placeholder="Select State"
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary btn-block mt-4 ">
                                            Login
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default NewLogin;