import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from "react-redux";
import { getShowingDayWithOutTime, getShowingWithOutTime, getShowingWithOutTimeStartDate } from '../../../../../Include/Common/Utility';
import { SpaceNotAllow, SpaceNotAllowWithoutReq } from "../../../../../Validation/Validation";
import { save_Equipment_Fitness_Data, update_Equipment_Fitness_Data } from '../../../../../../redux/actions/AllAction';
import { Get_Single_Fitness_Data } from '../../../../../../redux/actionTypes';

const OtherRenewal_Add = (props) => {
    const dispatch = useDispatch();
    const [permitRenewdate, setpermitRenewdate] = useState();
    const [permitExpdate, setpermitExpdate] = useState();
    const { status } = props
    const updateStatus = useSelector((state) => state.Data.updateStatus);
    const userId = useSelector((state) => state.Data.userId);
    const singleFitnessData = useSelector((state) => state?.Data?.singleFitnessData[0]);
    const masterTableModalStatus = useSelector((state) => state?.Data?.masterTableModalStatus);
    const editUpdateCount = useSelector((state) => state?.Data?.editUpdateCount);
    const vechicleID = useSelector((state) => state?.Data?.vechicleID);
    const [value, setValue] = useState({
        EquipmentVehID: vechicleID,
        RenewDate: '',
        ExpiryDate: '',
        RenewDate1: '',
        ExpiryDate1: '',
        ID: '',
        Updatedbyid: ''
    });
    const [errors, setErrors] = useState({
        'RenewDateError': '', 'ExpiryError': '',
    });
    const { RenewDateError, ExpiryError } = errors;
    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();
    const startRef3 = React.useRef();
    const startRef4 = React.useRef();
    const startRef5 = React.useRef();
    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
            startRef4.current.setOpen(false);
            startRef5.current.setOpen(false);
        }
    };

    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.RenewDate)) {
            setErrors((prevValues) => { return { ...prevValues, ["RenewDateError"]: SpaceNotAllow(value.RenewDate) } });
        }
        if (SpaceNotAllow(value.ExpiryDate)) {
            setErrors((prevValues) => { return { ...prevValues, ["ExpiryError"]: SpaceNotAllow(value.ExpiryDate) } });
        }
    }

    useEffect(() => {
        if (singleFitnessData) {
            setValue({
                ...value,
                EquipmentVehID: singleFitnessData?.EquipmentVehID,
                RenewDate: new Date(singleFitnessData?.RenewDate),
                ExpiryDate: new Date(singleFitnessData?.ExpiryDate),
                RenewDate1: singleFitnessData?.RenewDate?.split("/").reverse().join("/"),
                ExpiryDate1: singleFitnessData?.ExpiryDate?.split("/").reverse().join("/"),
                ID: singleFitnessData?.ID,
                Updatedbyid: userId
            })
        }
        else {
            setValue({
                ...value,
                RenewDate: '',
                ExpiryDate: '',
                RenewDate1: '',
                ExpiryDate1: '',
                ID: ''
            })
        }
    }, [singleFitnessData, editUpdateCount])

    useEffect(() => {
        if (RenewDateError === "true" && ExpiryError === "true") {
            if (status) {
                dispatch(update_Equipment_Fitness_Data(value, vechicleID)); setErrors({ ...value['RenewDateError'] })
            }
            else {
                dispatch(save_Equipment_Fitness_Data(value, vechicleID)); setErrors({ ...value['RenewDateError'] })
            }
        }
    }, [RenewDateError, ExpiryError])

    const Close_Model = () => {
        dispatch({ type: Get_Single_Fitness_Data, payload: [] });
        setValue({
            RenewDate: '',
            ExpiryDate: '', RenewDate1: '',
            ExpiryDate1: '',
        });
        setErrors({
            'RenewDateError': '', 'ExpiryError': '',
        })
    }
    return (
        <>
            {masterTableModalStatus &&
                <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="OtherRenewalModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="m-1 ">
                                    <fieldset style={{ border: '1px solid gray' }}>
                                        <legend >Fitness Details</legend>
                                        <div className="form-row" style={{ marginTop: '-10px' }}>
                                            <div class="col-6 col-md-6 col-lg-6 mt-1">
                                                <div className="dropdown__box">
                                                    <DatePicker
                                                        id='RenewDate'
                                                        name='RenewDate'
                                                        ref={startRef}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => date ? setValue(pre => { return { ...pre, ['RenewDate']: getShowingDayWithOutTime(date), ['RenewDate1']: getShowingWithOutTimeStartDate(date) } }) :
                                                            setValue(pre => { return { ...pre, ['RenewDate']: '', ['RenewDate1']: '' } })
                                                        }
                                                        dateFormat="dd/MM/yyyy"
                                                        timeInputLabel
                                                        className='requiredColor'
                                                        isClearable={value?.RenewDate ? true : false}
                                                        selected={value?.RenewDate1 && new Date(value?.RenewDate1)}
                                                        placeholderText={'Select...'}
                                                    />
                                                    {errors.RenewDateError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.RenewDateError}
                                                        </span>
                                                    ) : null}
                                                    <label htmlFor="">Fitness Renew Date</label>
                                                </div>
                                            </div>

                                            <div class="col-6 col-md-6 col-lg-6 mt-1">
                                                <div className="dropdown__box">
                                                    <DatePicker
                                                        id='ExpiryDate'
                                                        name='ExpiryDate'
                                                        className='requiredColor'
                                                        ref={startRef1}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => date ? setValue(pre => { return { ...pre, ['ExpiryDate']: getShowingDayWithOutTime(date), ['ExpiryDate1']: getShowingWithOutTimeStartDate(date) } }) :
                                                            setValue(pre => { return { ...pre, ['ExpiryDate']: '', ['ExpiryDate1']: '' } })
                                                        }
                                                        dateFormat="dd/MM/yyyy"
                                                        timeInputLabel
                                                        isClearable={value?.ExpiryDate ? true : false}
                                                        selected={value.ExpiryDate1 && new Date(value?.ExpiryDate1)}
                                                        placeholderText={'Select...'}
                                                    />
                                                    {errors.ExpiryError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.ExpiryError}
                                                        </span>
                                                    ) : null}
                                                    <label htmlFor="">Fitness Exp Date</label>
                                                </div>
                                            </div>
                                            {/* <div class="col-6 col-md-6 col-lg-6 mt-2">
                                                <div className="dropdown__box">
                                                    <DatePicker
                                                        id='permitRenewdate'
                                                        name='permitRenewdate'
                                                        ref={startRef2}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => { setpermitRenewdate(date); setValue({ ...value, }) }}
                                                        dateFormat="dd/MM/yyyy "
                                                        timeInputLabel
                                                        isClearable={value?.permitRenewdate ? true : false}
                                                        selected={permitRenewdate}
                                                        placeholderText={value?.permitRenewdate ? value.permitRenewdate : 'Select...'}
                                                    />
                                                    <label htmlFor="">Permit Renew Date</label>
                                                </div>
                                            </div> */}
                                            {/* <div class="col-6 col-md-6 col-lg-6 mt-2">
                                                <div className="dropdown__box">
                                                    <DatePicker
                                                        id='permitExpdate'
                                                        name='permitExpdate'
                                                        ref={startRef3}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => { setpermitExpdate(date); setValue({ ...value, }) }}
                                                        dateFormat="dd/MM/yyyy"
                                                        timeInputLabel
                                                        isClearable={value?.permitExpdate ? true : false}
                                                        selected={permitExpdate}
                                                        placeholderText={value?.permitExpdate ? value.permitExpdate : 'Select...'}
                                                    />
                                                    <label htmlFor="" >Permit Exp Date</label>
                                                </div>
                                            </div> */}
                                            <div className="col-12 col-md-12 col-lg-12 mt-2">
                                                <div className="dropdown__box">
                                                    <textarea
                                                        name="Address"
                                                        id="Address"
                                                        cols="30"
                                                        rows="1"
                                                        className="form-control"
                                                    >
                                                    </textarea>
                                                    <label htmlFor="">Remarks</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div className="btn-box text-right  mr-1 mb-2">
                                {
                                    status ?
                                        <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button>
                                        :
                                        <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Save</button>
                                }
                                <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={Close_Model} >Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default OtherRenewal_Add