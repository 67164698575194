import React from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import BankDetails_Add_Up from './BankDetails_Add_Up'

const BankDetails = () => {
  return (
    <>
      {/* bank details */}
      <div className="col-12 col-md-12 col-lg-12 pt-1 px-3">
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">Bank Details:</p>
          <div style={{ marginLeft: "auto" }}>
            <Link
              to=""
              className="btn btn-sm  text-white px-2 py-0"
              data-toggle="modal"
              data-target="#BankModal"
            >
              <i className="fa fa-plus"></i>
            </Link>
          </div>
        </div>
        <div className="form-row px-1">
          <div className=" col-12">
            <DataTable
              dense
              pagination
              selectableRowsHighlight
              highlightOnHover
            />
          </div>
        </div>
      </div>
      <BankDetails_Add_Up />
    </>
  )
}

export default BankDetails