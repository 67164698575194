import moment from "moment";

export const Two_Value_Filter = (data, searchValue1, searchValue2, type, firstFieldValue, secondfirstFieldValue) => {
    if (type === "Contains") {
        if (searchValue1 === "" && searchValue2 === "") {
            return data;
        } else {
            const result = data.filter((item) => {
                if (searchValue1 && searchValue2 === '') {
                    return item[firstFieldValue]?.toString()?.toLowerCase().includes(searchValue1?.toString().toLowerCase())
                } else if (searchValue2 && searchValue1 === '') {
                    return item[secondfirstFieldValue]?.toString()?.toLowerCase().includes(searchValue2?.toString().toLowerCase())
                } else if (searchValue1 && searchValue2) {
                    return item[firstFieldValue]?.toString()?.toLowerCase().includes(searchValue1?.toString().toLowerCase()) && item[secondfirstFieldValue]?.toString().toLowerCase().includes(searchValue2?.toString().toLowerCase());
                }
            });
            return result;
        }
    }
    if (type === "is equal to") {
        if (searchValue1 === "" && searchValue2 === "") {
            return data;
        } else {
            const result = data.filter((item) => {
                    if (searchValue1) {
                        return item[firstFieldValue]?.toString()?.toLowerCase() === searchValue1?.toString()?.toLowerCase()
                    } else if (searchValue2) {
                        return item[secondfirstFieldValue]?.toString()?.toLowerCase() === searchValue2?.toString()?.toLowerCase()
                    } if (searchValue1 && searchValue2) {
                        return item[firstFieldValue]?.toString()?.toLowerCase() === searchValue1?.toString()?.toLowerCase() && item[secondfirstFieldValue]?.toString()?.toLowerCase() === searchValue2?.toString()?.toLowerCase()
                    }
            });
            return result;
        }
    }

    if (type === "is not equal to") {
        if (searchValue1 === "" && searchValue2 === "") {
            return data;
        } else {
            const result = data.filter((item) => {
                    if (searchValue1) {
                        return item[firstFieldValue]?.toString()?.toLowerCase() !== searchValue1?.toString()?.toLowerCase()
                    } else if (searchValue2) {
                        return item[secondfirstFieldValue]?.toString()?.toLowerCase() !== searchValue2?.toString()?.toLowerCase()
                    } if (searchValue1 && searchValue2) {
                        return item[firstFieldValue]?.toString()?.toLowerCase() !== searchValue1?.toString()?.toLowerCase() && item[secondfirstFieldValue]?.toString()?.toLowerCase() !== searchValue2?.toString()?.toLowerCase()
                    }
            });
            return result;
        }

    }
    if (type === "Starts With") {
        if (searchValue1 === "" && searchValue2 === "") {
            return data;
        } else {
            const result = data.filter((item) => {
                    if (searchValue1) {
                        return String(item[firstFieldValue]?.toString()?.toLowerCase()).startsWith(searchValue1?.toString()?.toLowerCase())
                    } else if (searchValue2) {
                        return String(item[secondfirstFieldValue]?.toString()?.toLowerCase()).startsWith(searchValue2?.toString()?.toLowerCase())
                    } if (searchValue1 && searchValue2) {
                        return String(item[firstFieldValue]?.toString()?.toLowerCase()).startsWith(
                            searchValue1?.toString()?.toLowerCase()) && String(item[secondfirstFieldValue]?.toString()?.toLowerCase()).startsWith(
                                searchValue2?.toString()?.toLowerCase()
                            );
                    }
            });
            return result;
        }
    }
    if (type === "End with") {
        if (searchValue1 === "" && searchValue2 === "") {
            return data;
        } else {
            const result = data.filter((item) => {
                    if (searchValue1) {
                        return String(item[firstFieldValue]?.toString()?.toLowerCase()).endsWith(searchValue1?.toString()?.toLowerCase())
                    } else if (searchValue2) {
                        return String(item[secondfirstFieldValue]?.toString()?.toLowerCase()).endsWith(searchValue2?.toString()?.toLowerCase())
                    } if (searchValue1 && searchValue2) {
                        return String(item[firstFieldValue]?.toString()?.toLowerCase()).endsWith(
                            searchValue1?.toString()?.toLowerCase()) && String(item[secondfirstFieldValue]?.toString()?.toLowerCase()).endsWith(
                                searchValue2?.toString()?.toLowerCase())
                    }
            });
            return result;
        }
    }
};
export const Three_Search_Filter = (data, searchValue1, searchValue2, searchValue3, type, firstFieldValue, secondfirstFieldValue, thrdFieldValue) => {
    if (type === "Contains") {
      if (searchValue1 === "" && searchValue2 === "" && searchValue3 === "") {
        return data;
      } else {
        const result = data.filter((item) => {
          if (item[firstFieldValue] || item[secondfirstFieldValue] || item[thrdFieldValue])
            if (searchValue1 && searchValue2 === '' && searchValue3 === '') {
              return item[firstFieldValue]?.toLowerCase()?.includes(searchValue1?.toLowerCase())
            }
            else if (searchValue2 && searchValue1 === '' && searchValue3 === '') {
              return item[secondfirstFieldValue]?.toLowerCase()?.includes(searchValue2?.toLowerCase())
            }
            else if (searchValue3 && searchValue1 === '' && searchValue2 === '') {
              return item[thrdFieldValue]?.toLowerCase()?.includes(searchValue3?.toLowerCase())
            }
            else if (searchValue1 && searchValue2) {
              return item[firstFieldValue]?.toLowerCase()?.includes(searchValue1?.toLowerCase()) && item[secondfirstFieldValue]?.toLowerCase()?.includes(searchValue2?.toLowerCase());
            }
            else if (searchValue1 && searchValue3) {
              return item[firstFieldValue]?.toLowerCase().includes(searchValue1?.toLowerCase()) && item[thrdFieldValue]?.toLowerCase()?.includes(searchValue3?.toLowerCase());
            }
            else if (searchValue2 && searchValue3) {
              return item[secondfirstFieldValue]?.toLowerCase().includes(searchValue2?.toLowerCase()) && item[thrdFieldValue]?.toLowerCase()?.includes(searchValue3?.toLowerCase());
            }
            else if (searchValue1 && searchValue2 && searchValue3) {
              return item[firstFieldValue]?.toLowerCase()?.includes(searchValue1?.toLowerCase()) && item[secondfirstFieldValue]?.toLowerCase().includes(searchValue2?.toLowerCase()) && item[thrdFieldValue]?.toLowerCase()?.includes(searchValue3?.toLowerCase());
            }
        });
        return result;
      }
    }
    if (type === "is equal to") {
      if (searchValue1 === "" && searchValue2 === "") {
        return data;
      } else {
        const result = data.filter((item) => {
          if (item[firstFieldValue] && item[secondfirstFieldValue])
            if (searchValue1) {
              return item[firstFieldValue]?.toLowerCase() === searchValue1?.toLowerCase()
            } else if (searchValue2) {
              return item[secondfirstFieldValue]?.toLowerCase() === searchValue2?.toLowerCase()
            } if (searchValue1 && searchValue2) {
              return item[firstFieldValue]?.toLowerCase() === searchValue1?.toLowerCase() && item[secondfirstFieldValue]?.toLowerCase() === searchValue2.toLowerCase()
            }
        });
        return result;
      }
    }
    if (type === "is not equal to") {
      const result = data.filter((item) => {
        if (item[firstFieldValue] && item[secondfirstFieldValue])
          if (searchValue1) {
            return item[firstFieldValue]?.toLowerCase() !== searchValue1?.toLowerCase()
          } else if (searchValue2) {
            return item[secondfirstFieldValue]?.toLowerCase() !== searchValue2?.toLowerCase()
          } if (searchValue1 && searchValue2) {
            return item[firstFieldValue]?.toLowerCase() !== searchValue1?.toLowerCase() && item[secondfirstFieldValue]?.toLowerCase() !== searchValue2.toLowerCase()
          }
      });
      return result;
    }
    if (type === "Starts With") {
      const result = data.filter((item) => {
        if (item[firstFieldValue] && item[secondfirstFieldValue])
          if (searchValue1) {
            return String(item[firstFieldValue]?.toLowerCase()).startsWith(searchValue1?.toLowerCase())
          } else if (searchValue2) {
            return String(item[secondfirstFieldValue]?.toLowerCase()).startsWith(searchValue2?.toLowerCase())
          } if (searchValue1 && searchValue2) {
            return String(item[firstFieldValue]?.toLowerCase()).startsWith(
              searchValue1?.toLowerCase()) && String(item[secondfirstFieldValue]?.toLowerCase()).startsWith(
                searchValue2?.toLowerCase()
              );
          }
      });
      return result;
    }
    if (type === "End with") {
      const result = data.filter((item) => {
        if (item[firstFieldValue] && item[secondfirstFieldValue])
          if (searchValue1) {
            return String(item[firstFieldValue]?.toLowerCase()).endsWith(searchValue1?.toLowerCase())
          } else if (searchValue2) {
            return String(item[secondfirstFieldValue]?.toLowerCase()).endsWith(searchValue2?.toLowerCase())
          } if (searchValue1 && searchValue2) {
            return String(item[firstFieldValue]?.toLowerCase()).endsWith(
              searchValue1?.toLowerCase()) && String(item[secondfirstFieldValue]?.toLowerCase()).endsWith(
                searchValue2?.toLowerCase())
          }
      });
      return result;
    }
  };

  
  export const Three_Search_FilterWith_Date = (data, searchValue1, searchValue2, searchValue3, type1, type2, type3, firstFieldValue, secondfirstFieldValue, thrdFieldValue) => {
    if (type1 === "Contains" && type2 === "Contains" && type3 === "Contains") {
      if (!searchValue1 && !searchValue2 && !searchValue3) {
        return data;
      }
      else {
        const result = data.filter((item) => {
          if (item[firstFieldValue] && item[secondfirstFieldValue] && item[thrdFieldValue])
            if (searchValue1 && searchValue2 === '' && searchValue3 === '') {
              return item[firstFieldValue]?.includes(searchValue1)
            }
            else if (searchValue2 && searchValue1 === '' && searchValue3 === '') {
              return item[secondfirstFieldValue]?.toLowerCase().includes(searchValue2.toLowerCase())
            }
            else if (searchValue3 && searchValue1 === '' && searchValue2 === '') {
              return new Date(item[thrdFieldValue]).includes(searchValue3)
            }
            else if (searchValue1 && searchValue2 && searchValue3 === "" || searchValue3 === null) {
              return item[firstFieldValue]?.toLowerCase().includes(searchValue1.toLowerCase()) && item[secondfirstFieldValue]?.toLowerCase().includes(searchValue2.toLowerCase());
            }
            else if (searchValue1 && searchValue3 && searchValue2 === '') {
              return item[firstFieldValue]?.toLowerCase().includes(searchValue1.toLowerCase()) && item[thrdFieldValue]?.toLowerCase().includes(searchValue3.toLowerCase());
            }
            else if (searchValue2 && searchValue3 && searchValue1 === '') {
              return item[secondfirstFieldValue]?.toLowerCase().includes(searchValue2.toLowerCase()) && item[thrdFieldValue]?.toLowerCase().includes(searchValue3.toLowerCase());
            }
            else if (searchValue1 && searchValue2 && searchValue3) {
              return item[firstFieldValue]?.toLowerCase().includes(searchValue1.toLowerCase()) && item[secondfirstFieldValue]?.toLowerCase().includes(searchValue2.toLowerCase()) && moment(item[thrdFieldValue]).format("MM/DD/YYYY") === moment(searchValue3).format("MM/DD/YYYY")
            }
        });
        return result;
      }
    }
  }
  