import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from "react-redux";
import { SpaceNotAllow, SpaceNotAllowWithoutReq } from "../../../../../Validation/Validation";
import { save_Equipment_Insurance_Data, update_Equipment_Insurance_Data } from '../../../../../../redux/actions/AllAction';
import { Get_Single_Insurance_Data } from '../../../../../../redux/actionTypes';
import { getShowingDayWithOutTime, getShowingWithOutTimeStartDate } from '../../../../../Include/Common/Utility';

const InsuranceDetails_Add = (props) => {
    const dispatch = useDispatch();
    const { status } = props
    const singleInsuranceData = useSelector((state) => state?.Data?.singleInsuranceData[0])
    const masterTableModalStatus = useSelector((state) => state.Data.masterTableModalStatus)
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const vechicleID = useSelector((state) => state?.Data?.vechicleID)
    const userId = useSelector((state) => state.Data.userId)

    const [value, setValue] = useState({
        EquipmentVehID: vechicleID,
        PolicyNo: '',
        PolicyAmt: '',
        NewPolicyNo: '',
        DoneFrom: '',
        PolicyRenewDate: '',
        ExpiresOn: '',
        DoneFrom1: '',
        PolicyRenewDate1: '',
        ExpiresOn1: '',
        PolicyCompany: '',
        Updatedbyid: ''
    });
    const [errors, setErrors] = useState({
        'PolicyError': '', 'RenewDateError': '', 'ExpiryError': '',
    });
    const { PolicyError,  RenewDateError, ExpiryError,  } = errors;
    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
        }
    };
    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.PolicyNo)) {
            setErrors((prevValues) => { return { ...prevValues, ["PolicyError"]: SpaceNotAllow(value.PolicyNo) } });
        }

        if (SpaceNotAllow(value.PolicyRenewDate)) {
            setErrors((prevValues) => { return { ...prevValues, ["RenewDateError"]: SpaceNotAllow(value.PolicyRenewDate) } });
        }
        if (SpaceNotAllow(value.ExpiresOn)) {
            setErrors((prevValues) => { return { ...prevValues, ["ExpiryError"]: SpaceNotAllow(value.ExpiresOn) } });
        }

    }

    useEffect(() => {
        if (vechicleID) setValue(prs => { return { ...prs, ['EquipmentVehID']: vechicleID } })
    }, [vechicleID])

    const handelChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    }


    useEffect(() => {
        if (singleInsuranceData) {
            setValue({
                ...value,
                EquipmentVehID: singleInsuranceData?.EquipmentVehID,
                PolicyNo: singleInsuranceData?.PolicyNo,
                PolicyAmt: singleInsuranceData?.PolicyAmt,
                NewPolicyNo: '',
                DoneFrom: singleInsuranceData?.DoneFrom,
                PolicyRenewDate: singleInsuranceData?.PolicyRenewDate,
                ExpiresOn: singleInsuranceData?.ExpiresOn,
                DoneFrom1: singleInsuranceData?.DoneFrom?.split("/").reverse().join("/"),
                PolicyRenewDate1: singleInsuranceData?.PolicyRenewDate?.split("/").reverse().join("/"),
                ExpiresOn1: singleInsuranceData?.ExpiresOn?.split("/").reverse().join("/"),
                PolicyCompany: singleInsuranceData?.PolicyCompany,
                ID: singleInsuranceData?.ID,
                Updatedbyid: userId
            })
        }
        else {
            setValue({
                ...value,
                PolicyNo: '',
                PolicyAmt: '',
                NewPolicyNo: '',
                DoneFrom: '',
                PolicyRenewDate: '',
                ExpiresOn: '',
                DoneFrom1: '',
                PolicyRenewDate1: '',
                ExpiresOn1: '',
                PolicyCompany: '',
                ID: '',
                Updatedbyid: ''
            })
        }
    }, [singleInsuranceData, editUpdateCount])

    useEffect(() => {
        if (PolicyError === "true" && RenewDateError === "true" && ExpiryError === "true" ) {
            if (status) {
                dispatch(update_Equipment_Insurance_Data(value, vechicleID)); setErrors({ ['PolicyError']: '' });
            }
            else {
                dispatch(save_Equipment_Insurance_Data(value, vechicleID)); setErrors({ ['PolicyError']: '' });
            }
        }
    }, [PolicyError, RenewDateError, ExpiryError])

    const close_modal = () => {
        dispatch({ type: Get_Single_Insurance_Data, payload: [] });
        setValue({
            ...value,
            DoneFrom1: '',
            PolicyRenewDate1: '',
            ExpiresOn1: '',
            PolicyNo: '',
            PolicyAmt: '',
            NewPolicyNo: '',
            DoneFrom: '',
            PolicyRenewDate: '',
            ExpiresOn: '',
            PolicyCompany: '',
            ID: ''
        });
        setErrors({
            'PolicyError': '', 'CompanyError': '', 'DateFromError': '', 'RenewDateError': '', 'ExpiryError': '', 'InsuranceAmtError': ''
        });
    };

    return (
        <>
            {masterTableModalStatus &&
                <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)", }} id="InsuranceModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="m-1 ">
                                    <fieldset style={{ border: '1px solid gray' }}>
                                        <legend >Insurance Details</legend>
                                        <div className="form-row" style={{ marginTop: '-10px' }}>
                                            <div className="col-6 col-md-6 col-lg-4 mt-1 pt-1">
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='PolicyNo'
                                                        id='PolicyNo'
                                                        className='requiredColor'
                                                        value={value.PolicyNo}
                                                        required
                                                        onChange={handelChange}
                                                    />
                                                    {errors.PolicyError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.PolicyError}
                                                        </span>
                                                    ) : null}
                                                    <label className='pt-1'>Policy No.</label>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 mt-1 pt-1">
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='PolicyCompany'
                                                        id='PolicyCompany'
                                                        value={value.PolicyCompany}
                                                        required
                                                        onChange={handelChange}
                                                    />
                                                    {errors.CompanyError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.CompanyError}
                                                        </span>
                                                    ) : null}
                                                    <label className='pt-1'>Company</label>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 mt-1 pt-1">
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='newpolicy'
                                                        id='newpolicy'
                                                        required
                                                        onChange={handelChange}
                                                    />
                                                    <label className='pt-1'>Insured Declared Value</label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-6 col-lg-4 mt-1">
                                                <div className="dropdown__box">
                                                    <DatePicker
                                                        id='DoneFrom'
                                                        name='DoneFrom'
                                                        ref={startRef}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => date ? setValue(pre => { return { ...pre, ['DoneFrom']: getShowingDayWithOutTime(date), ['DoneFrom1']: getShowingWithOutTimeStartDate(date) } }) :
                                                            setValue(pre => { return { ...pre, ['DoneFrom']: '', ['DoneFrom1']: '' } })
                                                        }
                                                        dateFormat="dd/MM/yyyy"
                                                        timeInputLabel
                                                        isClearable={value?.DoneFrom ? true : false}
                                                        selected={value?.DoneFrom1 && new Date(value?.DoneFrom1)}
                                                        placeholderText={'Select...'}
                                                    />
                                                    {errors.DateFromError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.DateFromError}
                                                        </span>
                                                    ) : null}
                                                    <label htmlFor="" className='pt-1'>Date From</label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-6 col-lg-4 mt-1">
                                                <div className="dropdown__box">
                                                    <DatePicker
                                                        id='PolicyRenewDate'
                                                        name='PolicyRenewDate'
                                                        className='requiredColor'
                                                        ref={startRef1}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => date ? setValue(pre => { return { ...pre, ['PolicyRenewDate']: getShowingDayWithOutTime(date), ['PolicyRenewDate1']: getShowingWithOutTimeStartDate(date) } }) :
                                                            setValue(pre => { return { ...pre, ['PolicyRenewDate']: '', ['PolicyRenewDate1']: '' } })
                                                        }
                                                        dateFormat="dd/MM/yyyy "
                                                        timeInputLabel
                                                        isClearable={value?.PolicyRenewDate ? true : false}
                                                        selected={value?.PolicyRenewDate1 && new Date(value?.PolicyRenewDate1)}
                                                        placeholderText={'Select...'}
                                                    />
                                                    {errors.RenewDateError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.RenewDateError}
                                                        </span>
                                                    ) : null}
                                                    <label htmlFor="" className='pt-1'>Renews Date</label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-6 col-lg-4 mt-1">
                                                <div className="dropdown__box">
                                                    <DatePicker
                                                        id='ExpiresOn'
                                                        name='ExpiresOn'
                                                        className='requiredColor'
                                                        ref={startRef2}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => date ? setValue(pre => { return { ...pre, ['ExpiresOn']: getShowingDayWithOutTime(date), ['ExpiresOn1']: getShowingWithOutTimeStartDate(date) } }) :
                                                            setValue(pre => { return { ...pre, ['ExpiresOn']: '', ['ExpiresOn1']: '' } })
                                                        }
                        
                                                        dateFormat="dd/MM/yyyy "
                                                        timeInputLabel
                                                        isClearable={value?.ExpiresOn ? true : false}
                                                        selected={value?.ExpiresOn1 && new Date(value?.ExpiresOn1)}
                                                        placeholderText={'Select...'}
                                                    />
                                                    {errors.ExpiryError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.ExpiryError}
                                                        </span>
                                                    ) : null}
                                                    <label htmlFor="" className='pt-1'>Expiry Date</label>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-4 mt-2">
                                                <div class="text-field">
                                                    <input
                                                        type="text"
                                                        name='PolicyAmt'
                                                        id='PolicyAmt'
                                                        required
                                                        value={value.PolicyAmt}
                                                        onChange={handelChange}
                                                    />
                                                    {errors.InsuranceAmtError !== "true" ? (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "13px",
                                                                margin: "0px",
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            {errors.InsuranceAmtError}
                                                        </span>
                                                    ) : null}
                                                    <label className='pt-1'>Insurance Amount</label>
                                                </div>
                                            </div>

                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div className="btn-box text-right  mr-1 mb-2">
                                {status ?
                                    <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button>
                                    :
                                    <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Save</button>
                                }
                                <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={close_modal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default InsuranceDetails_Add