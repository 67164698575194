import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import MeterReading_Add_Up from './MeterReading_Add_Up'
import { Edit_Update_Count, Get_Meter_Filter_Data, Modal_Status } from '../../../../redux/actionTypes'
import { delete_Meter_Reading_Data, get_Meter_Reading_Data, get_Single_Meter_Reading_Data } from '../../../../redux/actions/AllAction'
import ConfirmModal from '../../../Include/Common/ConfirmModal'
import { Dropdown } from 'react-bootstrap'
import { Three_Search_Filter } from '../../../Filter/Filter'
import Loader from '../../../Include/Common/Loader'

const MeterReading = () => {
  const dispatch = useDispatch()
  const [status, setStatus] = useState(false)
  const meterReadingData = (useSelector((state) => state.Data.meterReadingData))
  const pageLoader = (useSelector((state) => state.Data.pageLoader))
  const modalStatus = useSelector((state) => state.Data.modalStatus)
  const userId = (useSelector((state) => state?.Data?.userId))
  const editUpdateCount = (useSelector((state) => state.Data.editUpdateCount))
  const meterFilterData = (useSelector((state) => state.Data.meterFilterData))
  const [pageStatus, setPageStatus] = useState("1")
  const [confirmType, setConfirmType] = useState('')
  const [IsActive, setIsActive] = useState()
  const [id, setId] = useState('')
  const [filterTypeIdOption, setfilterTypeIdOption] = useState('Contains');
  const [filterTypeDescOption, setfilterTypeDescOption] = useState('Contains');
  const [filterTypeAddOption, setfilterTypeAddOption] = useState('Contains');
  const [searchValue1, setSearchValue1] = useState('')
  const [searchValue2, setSearchValue2] = useState('')
  const [searchValue3, setSearchValue3] = useState('')

  useEffect(() => {
    dispatch(get_Meter_Reading_Data(pageStatus))
  }, [pageStatus]);

  const columns = [
    {
      name: 'Reading Date',
      selector: (row) => row.ReadingDate,
      sortable: true
    },
    {
      name: ' KWH Start Reading',
      selector: (row) => row.KWHStartReading,
      sortable: true
    },
    {
      name: 'KWH End Reading',
      selector: (row) => row.KWHEndReading,
      sortable: true
    },
    {
      name: 'KVAh Start Reading',
      selector: (row) => row.KVAHStartReading,
      sortable: true
    },

    {
      name: 'KVAh End Duration',
      selector: (row) => row.KWHEndReading,
      sortable: true
    },
    {
      name: 'Power cut Duration',
      selector: (row) => row.PowerCutDuration,
      sortable: true
    },

    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 55, fontWeight: '700' }}>Action</p>,
      cell: row => <>
        <div style={{ position: 'absolute', top: 4, right: 40 }}>
          {pageStatus === "1" ? <Link to='/Plant-Transport?sub=Meter%20Reading'
            className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn" onClick={() => setEditStatus(row)} data-toggle="modal" data-target="#MeterReadingModal"><i className="fa fa-edit"></i>
          </Link> : ''}
          <Link to={`/Plant-Transport?sub=Meter%20Reading`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('0'); setConfirmType("Delete"); setId(row.ID) }}
            className="btn btn-sm bg-green text-white px-1 py-0 mr-1" >
            <i class="fa fa-trash" aria-hidden="true"></i>
          </Link>


        </div>
      </>
    }
  ]


  const setEditStatus = (row) => {
    dispatch(get_Single_Meter_Reading_Data(row.ID)); setId(row.ID)
    setStatus(true);
    dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
    dispatch({ type: Modal_Status, payload: true });
  }

  const newStatus = () => {
    // dispatch(get_Single_Meter_Reading_Data('')); setId('')
    setStatus(false);
    dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
    dispatch({ type: Modal_Status, payload: true });
  }

  const UpdActiveDeactive = () => {
    dispatch(delete_Meter_Reading_Data(id, IsActive, pageStatus, userId));
  }
  return (
    <>
      <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
        <div className="col-12 col-md-6 col-lg-12 ">
        </div>
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">Meter Reading</p>
          <div style={{ marginLeft: 'auto' }}>
            <Link to={'/Plant-Transport?sub=Meter%20Reading'} onClick={newStatus} className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#MeterReadingModal">
              <i className="fa fa-plus"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-12 mt-2 px-3">
        <div className="row">
          <div className="col-3">
            <input type="text" value={searchValue1} onChange={(e) => {
              setSearchValue1(e.target.value);
              const result = Three_Search_Filter(meterReadingData, e.target.value, searchValue2, searchValue3, filterTypeIdOption, 'KWHStartReading', 'KWHEndReading', 'KVAHStartReading')
              dispatch({ type: Get_Meter_Filter_Data, payload: result });
            }}
              className='form-control' placeholder='Search By KWHStartingReading...' />
          </div>
          <div className='col-1 '>
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                <i className="fa fa-filter"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className=' nav-filter-dropdown'>
                <Dropdown.Item onClick={() => setfilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeIdOption('End with')}>End with</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            
          </div>
          <div className="col-3">
            <input type="text" value={searchValue2} onChange={(e) => {
              setSearchValue2(e.target.value);
              const result = Three_Search_Filter(meterReadingData, searchValue1, e.target.value, searchValue3, filterTypeAddOption, 'KWHStartReading', 'KWHEndReading', 'KVAHStartReading')
              dispatch({ type: Get_Meter_Filter_Data, payload: result });
            }}
              className='form-control' placeholder='Search By KWHEndReading...' />
          </div>
          <div className='col-1 '>
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                <i className="fa fa-filter"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className=' nav-filter-dropdown'>
                <Dropdown.Item onClick={() => setfilterTypeAddOption('Contains')}>Contains</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeAddOption('is equal to')}>is equal to</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeAddOption('is not equal to')}>is not equal to </Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeAddOption('Starts With')}>Starts With</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeAddOption('End with')}>End with</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-3">
            <input type="text" value={searchValue3} onChange={(e) => {
              setSearchValue3(e.target.value);
              const result = Three_Search_Filter(meterReadingData, searchValue1, searchValue2, e.target.value, filterTypeDescOption, 'KWHStartReading', 'KWHEndReading', 'KVAHStartReading')
              dispatch({ type: Get_Meter_Filter_Data, payload: result });
            }}
              className='form-control' placeholder='Search By KVAHStartReading....' />
          </div>
          <div className='col-1 '>
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                <i className="fa fa-filter"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className=' nav-filter-dropdown'>
                <Dropdown.Item onClick={() => setfilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                <Dropdown.Item onClick={() => setfilterTypeDescOption('End with')}>End with</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="col-12 pt-2 px-3">
        {pageLoader ?
          <DataTable
            dense
            pagination
            columns={columns}
            data={meterFilterData}
            selectableRowsHighlight
            highlightOnHover
          /> : <Loader />
          }
      </div>
      {modalStatus && <MeterReading_Add_Up {... { status, pageStatus, }} />}
      <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />
    </>
  )
}

export default MeterReading