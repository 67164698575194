import React, { useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import { get_EmpCount_Task_Data, get_Employee_Task_Data, get_Single_Count_Task_Data } from '../../../../redux/actions/AllAction';
import { useDispatch, useSelector } from 'react-redux';
import { Edit_Update_Count, Get_Emp_ID, Get_Emp_Task_Data, Get_Emp_Task_ID, Get_SingleCount_Data, Get_Status_ID, Status } from '../../../../redux/actionTypes';

const EmpTaskMain = () => {
    const dispatch = useDispatch()
    const empCountTaskData = useSelector((state) => state.Data.empCountTaskData)
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)

    useEffect(() => {
        dispatch(get_EmpCount_Task_Data())
    }, []);
    const columns = [
        {
            name: 'Emp Name',
            sortable: true,
            cell: row => (<Link className='attendance-name' to='/employee-home' onClick={() => setEditStatus(row)} style={{ cursor: 'pointer' }}>
                {row.EmpName}</Link>)
        },
        {
            name: 'Assign',
            sortable: true,
            cell: row => (<Link className='attendance-name' to='/employee-home' onClick={() => setEditStatus(row, row?.AssignId
                )} style={{ cursor: 'pointer' }}>
                {row.Assign_count}</Link>)
        },
        {
            name: 'OverDue',
            sortable: true,
            cell: row => (<Link className='attendance-name' to='/employee-home' onClick={() => setEditStatus(row, row?.OverDueId)} style={{ cursor: 'pointer' }}>
                {row.OverDue_count}</Link>)
        },
        {
            name: 'Close',
            sortable: true,
            cell: row => (<Link className='attendance-name' to='/employee-home' onClick={() => setEditStatus(row, row?.CloseId)} style={{ cursor: 'pointer' }}>
                {row.Close_count}</Link>)
        },

    ]
    const setEditStatus = (row, StatusID) => {
        dispatch({ type: Get_Status_ID, payload: StatusID }); dispatch({ type: Get_Emp_ID, payload: row.EmpID });
        dispatch(get_Single_Count_Task_Data(row.EmpID, StatusID));
        dispatch({ type: Get_Emp_Task_Data, payload: [] });
        dispatch({ type: Status, payload: true });
        dispatch({ type: Get_Emp_Task_ID, payload: row.ID })
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
    }
    
    const setNewStatus = () => {
        dispatch(get_Single_Count_Task_Data('')); 
        dispatch({ type: Get_SingleCount_Data, payload: [] });
        dispatch({ type: Get_Status_ID, payload: '' }); 
        dispatch({ type: Get_Emp_ID, payload: ''});
        dispatch({ type: Status, payload: false})
    }

    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Employee Task</p>
                    <div style={{ marginLeft: 'auto' }}>
                        <Link to={'/employee-home'} onClick={setNewStatus} className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#" >
                            <i className="fa fa-plus"></i>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-4 printElement1">
                <DataTable
                    dense
                    columns={columns}
                    data={empCountTaskData}
                    paginationPerPage={'10'}
                    paginationRowsPerPageOptions={[5, 10, 15]}
                    highlightOnHover
                    noContextMenu
                    pagination
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    noDataComponent={"There are no data to display"}
                />
            </div>


        </>
    )
}

export default EmpTaskMain